import React, { useState, useContext, useEffect } from "react";
import { GetAuthToken } from "../services/authServices";
import { Logout, GetNewToken } from "../services/authServices";
var jwt = require("jsonwebtoken");

type AuthContextType = {
  user: any;
  setUser: any;
  isAuthenticated: boolean;
  setIsAuthenticated: any;
  isValidated: boolean;
  setIsValidated: any;
};

type AuthContextProviderType = {
  children: React.ReactNode;
};

type AuthUser = {
  info: any;
};

export const AuthContext = React.createContext({} as AuthContextType);

export const AuthProvider = ({ children }: AuthContextProviderType) => {

  const initialState = () => {            
    return GetAuthToken().auth;    
  };

  useEffect(() => {        
    if (initialState() !== null) {
      let expiryDate = initialState().exp;
      var expiryDateMS = expiryDate * 1000;
      var expired = Date.now() >= expiryDateMS;       
      if (expired) {        
        GetNewToken()
      }
    }
  }, [])

  const test = () => {
    var validated = false;

    validated = localStorage.getItem("validated") !== null && true;    
    return validated;
  };

  const [user, setUser] = useState<AuthUser | null>(
    initialState() !== null ? initialState().User : null,
  );
  const [isAuthenticated, setIsAuthenticated] = useState(
    initialState() !== null ? true : false,
  );
  const [isValidated, setIsValidated] = useState(test());

  const values = {
    user,
    setUser,
    isAuthenticated,
    setIsAuthenticated,
    isValidated,
    setIsValidated,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
