var columns = {
  ContractId: {
    name: "Contract Id",
  },
  ClientId: {
    name: "Client Id",
  },
  Advertiser: {
    id: "Advertiser",
    name: "Advertiser",
  },
  Agency: {
    id: "Agency",
    name: "Agency",
  },
  AgencyGroup: {
    id: "AgencyGroup",
    name: "Agency Group",
  },
  Services: {
    id: "Services",
    name: "Services",
  },
  DateOfReview: {
    id: "DateOfReview",
    name: "Date of Review",
  },
  PreparedByAbintus: {
    id: "PreparedByAbintus",
    name: "Prepared by Abintus?",
  },
  Country: {
    id: "Country",
    name: "Country",
  },
  AnnualMediaBudget: {
    id: "AnnualMediaBudget",
    name: "Annual Media Budget",
  },
  ContractScore: {
    id: "ContractScore",
    name: "Contract Score",
  },
  ContractRating: {
    id: "ContractRating",
    name: "Contract Rating",
  },
  AnnualNetSpend: {
    id: "AnnualNetSpend",
    name: "Annual Net Spend (EUR)",
  },
  MaxFees: {
    id: "MaxFees",
    name: "Max Fees (EUR)",
  },
  RemunerationModel: {
    id: "RemunerationModel",
    name: "Remuneration Model",
  },
  EstimatedAVBs: {
    id: "EstimatedAVBs",
    name: "Estimated AVBs",
  },
  AVBCalculation: {
    id: "AVBCalculation",
    name: "AVB Calculation",
  },
  StartDate: {
    id: "StartDate",
    name: "Start Date",
  },
  ContractLength: {
    id: "LengthOfContract",
    name: "Length of Contract",
  },
  AutoRenew: {
    id: "AutoRenew",
    name: "Auto Renew",
  },
  PaymentTerms: {
    id: "PaymentTerms",
    name: "Payment Terms",
  },
  Currency: {
    id: "Currency",
    name: "Currency",
  },
  ContractUrl: {
    id: "ContractDocuments",
    name: "Contract Document(s)",
  },
};

export default columns;
