import React, { useContext, useEffect } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { Navigate } from "react-router-dom";

interface ProtectedRoutesProps {
  route: string;
  children: any;
}

const ProtectedRoute = (props: ProtectedRoutesProps) => {
  const context = useContext(AuthContext);    

  if (props.route.includes("verify-email")) {
    if (!context.isAuthenticated || context.user[7] == 0) {
      return <Navigate to="/" replace />;
    }
  }
  if (props.route.includes("dashboard")) {
    if (!context.isValidated || context.user[7] == 0) {
      return <Navigate to="/" replace />;
    }
  }
  if (props.route.includes("lead-management")) {
    if (context.user[7] !== 2) {
      return <Navigate to="/dashboard" />
    }
  }

  return props.children;
};

export default ProtectedRoute;
