import React, { useEffect, useLayoutEffect, useState } from "react";
import styles from "./AllBenchmarks.module.scss";
import { GetBenchmarks } from "../../services/authServices";
import {
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Hidden,
} from "@mui/material";
import cx from "classnames";

const AllBenchmarksView = () => {
  const [columns, setColumns] = useState(null);
  const [rows, setRows] = useState(null);

  const initialColumns = [
    {
      name: "Id",
      options: {
        filter: false,
      },
    },
    {
      name: "Contract Name",
      options: {
        filter: false,
      },
    },
    {
      name: "Advertiser",
      options: {
        filter: true,
      },
    },
    {
      name: "Agency",
      options: {
        filter: true,
      },
    },
    {
      name: "Agency Group",
      options: {
        filter: true,
      },
    },
    {
      name: "AVB Rewarded",
      options: {
        filter: true,
      },
    },
    {
      name: "Annual Media Budget",
      options: {
        filter: true,
      },
    },
    {
      name: "Prepared By Abintus",
      options: {
        filter: true,
      },
    },
    {
      name: "Country",
      options: {
        filter: true,
      },
    },
  ];

  const getColumns = async () => {
    var test = [...initialColumns];
    var test2 = [];

    await GetBenchmarks().then((res) => {
      res.headers.forEach((column) => {
        test.push({ name: column, options: { filter: true } });
      });
      res.benchmarks.forEach((row) => {
        test2.push([
          row.Id,
          row.ContractName,
          row.Advertiser,
          row.Agency,
          row.AgencyGroup,
          row.AVBRewarded,
          row.AnnualMediaBudget,
          row.PreparedByAbintus,
          row.Country,
          row.TotalTV,
          row.OpenTV,
          row.PayTV,
          row.NetworkTV,
          row.CableTV,
          row.SNL_TV,
          row.SportTV,
          row.LocalTV,
          row.OpenAztecaTV,
          row.OpenTelevisaTV,
          row.TVFreeSpace,
          row.TotalPrint,
          row.Newspapers,
          row.Magazines,
          row.PrintFreeSpace,
          row.Radio,
          row.DigitalRadio,
          row.RadioFreeSpace,
          row.OOH,
          row.DOOH,
          row.ProductionCost,
          row.OOHFreeSpace,
          row.Cinema,
          row.CinemaFreeSpace,
          row.TotalOnline,
          row.Display,
          row.Video,
          row.Search,
          row.Social,
          row.MobileBuy,
          row.PBU,
          row.SpecialAAEE,
          row.SpecificMobileMedia,
          row.ProgrammaticPMP,
          row.SkyWeb,
          row.ProgrammaticDeals,
          row.ProgrammaticGuaranteed,
          row.Youtube,
          row.DisplayFreeSpace,
          row.VideoFreeSpace,
          row.SocialFreeSpace,
        ]);
      });
    });
    setColumns(test);
    setRows(test2);
  };

  useEffect(() => {
    getColumns();
  }, []);

  return (
    <Hidden mdDown>
      {columns !== null ? (
        <div className={styles.container}>
          <h1 className={styles.header}>AVB Benchmarks</h1>
          <div className={styles.card}>
            <TableContainer sx={{ padding: 0, height: "80vh" }}>
              <Table sx={{ borderCollapse: "separate" }}>
                <TableHead
                  sx={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#FFFFFF",
                    zIndex: 999,
                  }}
                >
                  <TableRow sx={{ borderBottom: "solid 1px grey" }}>
                    {columns.map((column, i) => (
                      <TableCell
                        key={column.name}
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderBottom: "solid 1px rgb(180, 180, 180)",
                        }}
                        className={
                          i == 0 ? styles.tableCellFirstCol : styles.tableCell
                        }
                      >
                        {column.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => {
                    return (
                      <TableRow key={row.Id}>
                        {row.map((cell, i) => {
                          return (
                            <TableCell
                              key={i}
                              style={{
                                backgroundColor:
                                  index % 2 == 0 ? "#EEEEEE" : "#FFFFFF",
                              }}
                              className={
                                i == 0
                                  ? styles.tableCellFirstCol
                                  : styles.tableCell
                              }
                            >
                              <p style={{ margin: 0 }}>{cell}</p>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <h1 className={styles.header}>AVB Benchmarks</h1>
          <div className={styles.skeletonCard}></div>
        </div>
      )}
    </Hidden>
  );
};

export default AllBenchmarksView;
