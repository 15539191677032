import React, { useContext, useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import cx from "classnames";
import styles from "./RecentRuns.module.scss";
import { ThemeContext } from "../../contexts/ThemeContext";
import {
  GetRecentRunsAllTables,
  GetAllUsers,
  DeleteAutomationRun,
} from "../../services/authServices";
import AnonPhoto from "../../assets/media/anon.jpeg";
import { Filter, X } from "react-feather";
import { Menu, Dialog } from "@mui/material";
import { calculateDuration } from "../../common/helpers/calculateDuration";

interface RecentRun {
  Id: number;
  User: string;
  UserPhoto: string;
  Location: string;
  // Agency: string,
  OutputLength: Number | null;
  FileName: string;
  Type: string;
  Date: string;
}

interface User {
  Name: string;
  Photo: string;
}

const RecentRuns = (props: any) => {
  const theme = useContext(ThemeContext);
  const [unfilteredRuns, setUnfilteredRuns] = useState<RecentRun[]>([]);
  const [recentRuns, setRecentRuns] = useState<RecentRun[]>([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openFilter = Boolean(anchorEl);
  const [expanded, setExpanded] = useState(props.collapsed);
  const [filters, setFilters] = useState<{ Users: string[]; Types: string[] }>({
    Users: [],
    Types: [],
  });
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [toDelete, setToDelete] = useState<RecentRun>();
  const [listening, setListening] = useState(false);
  const mobile = useMediaQuery("(max-width: 1200px)");

  const getAutomationRuns = async () => {
    setLoading(true);
    try {
      var users: any = await GetAllUsers();      
      setUsers(users);
      var results: any = await GetRecentRunsAllTables(props.demo);
      var resultsParsed: RecentRun[] = [];
      let avbRuns: number = 0;
      let mpqRuns: number = 0;
      let pitchRuns: number = 0;
      let stageRuns: number = 0;
      let comRuns: number = 0;

      results.forEach((item: any, index: any) => {
        var user = users.filter((x: any) => x.id == item[1])[0];        

        resultsParsed.push({
          Id: item[0],
          User: user.name,
          UserPhoto: item[6],
          Location: item[2],
          FileName: item[5],
          OutputLength: item[8],
          Type: item[7],
          Date: item[3],
        });

        switch (item[7]) {
          case "AVB":
            avbRuns++;
            break;
          case "MPQ":
            mpqRuns++;
            break;
          case "Pitch":
            pitchRuns++;
            break;
          case "Stage":
            stageRuns++;
            break;
          case "COM":
            comRuns++;
            break;
        }
      });

      props.setRunAmount(mpqRuns, avbRuns, pitchRuns, stageRuns, comRuns);
      setUnfilteredRuns(resultsParsed);
      setRecentRuns(resultsParsed);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleMenu = (e: any) => {
    if (anchorEl == null) setAnchorEl(e.currentTarget);
    else setAnchorEl(null);
  };

  useEffect(() => {
    if (!listening) {
      // const events = new EventSource("http://localhost:4242/sse");
      const events = new EventSource("https://abintus-app-api-linux.azurewebsites.net/sse");
  
      events.onmessage = (event) => {        
        const parsedData = JSON.parse(event.data);  
        console.log({parsedData})      
        if (parsedData.message == "UPDATE RUNS") getAutomationRuns();
      };

      setListening(true);
    }

  }, [listening]);

  const handleExpand = () => {
    setExpanded((prev: any) => !prev);
    props.collapseMenu();
  };

  const handleFilter = (item: string, filterType: string) => {
    if (filterType == "user") {
      if (!filters.Users.includes(item)) {
        let updatedFilters = { ...filters, Users: [...filters.Users, item] };
        setFilters(updatedFilters);
      } else if (filters.Users.includes(item)) {
        let updatedUsers = filters.Users.filter((x) => x !== item);
        setFilters({ ...filters, Users: updatedUsers });
      }
    } else if (filterType == "type") {
      if (!filters.Types.includes(item)) {
        let updatedFilters = { ...filters, Types: [...filters.Types, item] };
        setFilters(updatedFilters);
      } else if (filters.Types.includes(item)) {
        let updatedTypes = filters.Types.filter((x) => x !== item);
        setFilters({ ...filters, Types: updatedTypes });
      }
    }
  };

  const handleDeleteDialog = (run: any) => {
    setToDelete(run);
    setDeleteDialog(true);
  };

  const deleteRun = async (run: RecentRun | undefined) => {
    await DeleteAutomationRun(run, props.demo);

    getAutomationRuns();
    setDeleteDialog(false);
    setToDelete(undefined);
  };

  const handleCancel = () => {
    setToDelete(undefined);
    setDeleteDialog(false);
  };

  useEffect(() => {
    // whoever sees this code, just know that I am ashamed of how ineffecient this filter solution is
    var newRuns: any = [];
    if (!filters.Users.length && !filters.Types.length) {
      newRuns = unfilteredRuns;
    } else if (filters.Users.length && filters.Types.length) {
      unfilteredRuns.forEach((x) => {
        if (filters.Users.includes(x.User) && filters.Types.includes(x.Type))
          newRuns.push(x);
      });
    } else if (filters.Users.length && !filters.Types.length) {
      unfilteredRuns.forEach((x) => {
        if (filters.Users.includes(x.User)) newRuns.push(x);
      });
    } else if (!filters.Users.length && filters.Types.length) {
      unfilteredRuns.forEach((x) => {
        if (filters.Types.includes(x.Type)) newRuns.push(x);
      });
    }

    setRecentRuns(newRuns);
  }, [filters]);

  useEffect(() => {
    getAutomationRuns();
  }, []);

  return (
    <div
      className={cx(styles.recentRuns, theme.darkMode && styles.darkMode)}
      style={{ width: mobile ? "100%" : props.collapsed ? "90%" : "30%" }}
    >
      <div
        className={cx(
          styles.recentRunsTopBar,
          theme.darkMode && styles.darkMode,
        )}
      >
        <h2>Recent Runs</h2>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p className={styles.expandButton} onClick={handleExpand}>
            {props.collapsed ? "Collapse" : "Expand"}
          </p>
          <Filter
            style={{ marginLeft: "7px" }}
            onClick={(e) => handleMenu(e)}
            className={styles.filterIcon}
          />
        </div>
        <Menu
          open={openFilter}
          onClose={(e) => handleMenu(e)}
          anchorEl={anchorEl}
          sx={{
            padding: 0,

            "& .MuiPaper-root": {
              maxHeight: "300px",
              width: "500px",
              backgroundColor: theme.darkMode ? "#002837" : "#FFFFFF",
              color: theme.darkMode ? "#D0E5F2" : "#000000",
              display: "flex",
              overflowY: "hidden",
              boxSizing: "border-box",
            },
          }}
        >
          <div className={styles.filterContainer} style={{ padding: "8px" }}>
            {filters.Users.map((item, index) => {
              return (
                <button
                  key={index}
                  onClick={() => handleFilter(item, "user")}
                  className={cx(
                    styles.filterDisplay,
                    theme.darkMode && styles.darkMode,
                  )}
                >
                  <p>{item}</p>
                  <X style={{ height: "15px", width: "15px" }} />
                </button>
              );
            })}
            {filters.Types.map((item, index) => {
              return (
                <button
                  key={index}
                  onClick={() => handleFilter(item, "type")}
                  className={cx(
                    styles.filterDisplay,
                    theme.darkMode && styles.darkMode,
                  )}
                >
                  <p>{item}</p>
                  <X style={{ height: "15px", width: "15px" }} />
                </button>
              );
            })}
          </div>
          <div
            style={{
              paddingLeft: "10px",
              position: "relative",
              overflowY: "hidden",
              width: "500px",
              display: "flex",
            }}
          >
            <div style={{ marginRight: "40px" }}>
              <p style={{ margin: "0 0 5px 0" }}>Type</p>
              <div
                className={cx(
                  styles.filterColumn,
                  theme.darkMode && styles.darkMode,
                )}
              >
                <div onClick={() => handleFilter("AVB", "type")}>
                  <p>AVB</p>
                </div>
                <div onClick={() => handleFilter("MPQ", "type")}>
                  <p>MPQ</p>
                </div>
                <div onClick={() => handleFilter("Pitch", "type")}>
                  <p>Pitch</p>
                </div>
              </div>
            </div>
            <div>
              <p style={{ margin: "0 0 5px 0" }}>User</p>
              <div
                className={cx(
                  styles.filterColumn,
                  theme.darkMode && styles.darkMode,
                )}
              >
                {users.length &&
                  users.map((user: any) => {
                    return (
                      <div
                        key={user.id}
                        onClick={() => handleFilter(user.name, "user")}
                      >
                        <img
                          src={user.photo}
                          style={{
                            objectFit: "cover",
                            height: "30px",
                            width: "30px",
                            borderRadius: "50px",
                            marginRight: "10px",
                          }}
                        />
                        <p>{user.name}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </Menu>
      </div>
      <div
        className={cx(styles.recentRunItem, props.collapsed && styles.expanded)}
        id={cx(
          styles.headerRow,
          props.collapsed && styles.collapsed,
          theme.darkMode && styles.darkMode,
        )}
        style={{
          position: "sticky",
          top: "9%",
          backdropFilter: "blur(10px)",
          zIndex: 1000,          
        }}
      >
        <div style={{ textAlign: "center" }}>
          <p>
            <strong>User</strong>
          </p>
        </div>
        <div style={{ textAlign: "center" }}>
          <p>
            <strong>Type</strong>
          </p>
        </div>
        {props.collapsed && (
          <div style={{ textAlign: "center" }}>
            <p>
              <strong>Input File</strong>
            </p>
          </div>
        )}
        {props.collapsed && (
          <div style={{ textAlign: "center" }}>
            <p>
              <strong>ID</strong>
            </p>
          </div>
        )}
        {props.collapsed && (
          <div style={{ textAlign: "center" }}>
            <p>
              <strong># of Rows</strong>
            </p>
          </div>
        )}
        <div
          style={{
            textAlign: "center",
            paddingRight: !props.collapsed ? "20px" : "",
          }}
        >
          <p>
            <strong>Time</strong>
          </p>
        </div>
        <div
          style={{
            textAlign: "center",
            paddingRight: !props.collapsed ? "20px" : "",
          }}
        >
          <p>
            <strong>File</strong>
          </p>
        </div>
      </div>
      {!loading ? (
        <div
          className={cx(
            styles.recentRunsRows,
            props.collapsed && styles.expanded,
          )}
        >
          {recentRuns.length ? (
            recentRuns.slice(0, 30).map((item: any, index: number) => {
              var duration = calculateDuration(item.Date);
              var lineColor = theme.darkMode ? "#4B4948" : "#D6D6D6";

              return (
                <div
                  className={cx(
                    styles.recentRunItem,
                    props.collapsed && styles.expanded,
                  )}
                  key={index}
                >
                  <div
                    style={{
                      position: "relative",
                      justifyContent: "flex-start",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        height: mobile ? "40px" : "50px",
                        width: mobile ? "40px" : "50px",
                        marginRight: props.collapsed ? "15px" : 0,
                        objectFit: 'cover'

                      }}
                      src={item.UserPhoto == null ? AnonPhoto : item.UserPhoto}
                      alt="user-photo"
                    />
                    {props.collapsed && <p>{item.User}</p>}
                    <svg
                      height="50"
                      width="1"
                      className={cx(
                        styles.svg,
                        props.collapsed && styles.expanded,
                      )}
                    >
                      <line
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="50"
                        stroke={lineColor}
                        strokeWidth="2"
                      />
                    </svg>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <p>{item.Type}</p>
                  </div>
                  {props.collapsed && (
                    <div style={{ textAlign: "center" }}>
                      <p>
                        {item.FileName.length > 22
                          ? `${item.FileName.slice(0, 22)}...`
                          : item.FileName}
                      </p>
                    </div>
                  )}
                  {props.collapsed && (
                    <div style={{ textAlign: "center" }}>
                      <p>#{item.Id}</p>
                    </div>
                  )}
                  {props.collapsed && (
                    <div style={{ textAlign: "center" }}>
                      <p>{item.OutputLength}</p>
                    </div>
                  )}
                  <div style={{ textAlign: "center" }}>
                    <p>{duration}</p>
                  </div>
                  <div style={{ textAlign: "right", display: "flex" }}>
                    <a href={item.Location} target="_#blank">
                      <button
                        className={cx(
                          styles.recentRunButton,
                          theme.darkMode && styles.darkMode,
                        )}
                      >
                        View
                      </button>
                    </a>
                    {props.collapsed && (
                      <button
                        onClick={() => handleDeleteDialog(item)}
                        style={{
                          marginLeft: "10px",
                          backgroundColor: "#EA4242",
                          color: "#FFFFFF",
                        }}
                        className={styles.recentRunButton}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <p style={{ textAlign: "center" }}>No runs to display</p>
          )}
          <Dialog
            open={deleteDialog}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: theme.darkMode ? "#3b3a39" : "#FFFFFF",
                color: theme.darkMode ? "#FFFFFF" : "#000000",
                minHeight: "200px",
                padding: "30px 0 40px 0",
                boxSizing: "border-box",
                width: "600px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
              },
            }}
          >
            <h1 style={{ margin: "0 0 15px 0", fontFamily: "PoppinsBold" }}>
              Are you sure?
            </h1>
            <div style={{ marginBottom: "30px" }}>
              <p style={{ margin: "0 0 10px 0" }}>
                You are deleting {toDelete?.Type} run #{toDelete?.Id}.
              </p>
              <p style={{ margin: 0 }}>This will affect your dashboards.</p>
            </div>
            <div style={{ display: "flex" }}>
              <button
                onClick={() => deleteRun(toDelete)}
                style={{
                  backgroundColor: "#EA4242",
                  color: "#FFFFFF",
                  fontSize: "1rem",
                  padding: "7px",
                  width: "150px",
                }}
                className={styles.recentRunButton}
              >
                Delete
              </button>
              <button
                onClick={() => handleCancel()}
                style={{ marginLeft: "10px" }}
                className={cx(
                  styles.cancelButton,
                  theme.darkMode && styles.darkMode,
                )}
              >
                Cancel
              </button>
            </div>
          </Dialog>
        </div>
      ) : (
        <>
          <div className={styles.recentRunItem}>
            <div
              style={{ height: "60px", width: "60px", borderRadius: "50px" }}
              className={styles.loadingItem}
            ></div>
            <div
              style={{ height: "20px", width: "50px", borderRadius: "1px" }}
              className={styles.loadingItem}
            ></div>
            <div
              style={{ height: "20px", width: "50px", borderRadius: "1px" }}
              className={styles.loadingItem}
            ></div>
            <div
              style={{ height: "40px", width: "60px", borderRadius: "10px" }}
              className={styles.loadingItem}
            ></div>
          </div>
          <div className={styles.recentRunItem}>
            <div
              style={{ height: "60px", width: "60px", borderRadius: "50px" }}
              className={styles.loadingItem}
            ></div>
            <div
              style={{ height: "20px", width: "50px", borderRadius: "1px" }}
              className={styles.loadingItem}
            ></div>
            <div
              style={{ height: "20px", width: "50px", borderRadius: "1px" }}
              className={styles.loadingItem}
            ></div>
            <div
              style={{ height: "40px", width: "60px", borderRadius: "10px" }}
              className={styles.loadingItem}
            ></div>
          </div>
          <div className={styles.recentRunItem}>
            <div
              style={{ height: "60px", width: "60px", borderRadius: "50px" }}
              className={styles.loadingItem}
            ></div>
            <div
              style={{ height: "20px", width: "50px", borderRadius: "1px" }}
              className={styles.loadingItem}
            ></div>
            <div
              style={{ height: "20px", width: "50px", borderRadius: "1px" }}
              className={styles.loadingItem}
            ></div>
            <div
              style={{ height: "40px", width: "60px", borderRadius: "10px" }}
              className={styles.loadingItem}
            ></div>
          </div>
        </>
      )}
    </div>
  );
};

export default RecentRuns;
