import React, { useState, useEffect, useContext, useRef } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';
import { AddNewClientContact, UploadContactFile } from '../../services/authServices';
import styles from "./styles/ContactsIndex.module.scss";
import cx from "classnames";
import Form from './Form';
import ContactTable from './ContactTable';
import { Check, X } from 'react-feather';

interface Contact {
  Id: number,
  Name: string,
  Email: string,
  Title: string,
  AgencyGroup: string,
  Agency: string,
  ProvidedDate: string,
  PhoneNumber: string,
  Country: string,
  Notes: string,
}

const ContactView = () => {
  const [contacts, setContacts] = useState<Array<Contact>>([]);
  const [headerExpanded, setHeaderExpanded] = useState(false);
  const [contactAdded, setContactAdded] = useState(false); // the val is not important here, we just change this to trigger function in table child component
  const [fileAdded, setFileAdded] = useState(false); //same here
  const [file, setFile] = useState<File | null>(null);
  const [status, setStatus] = useState<number | null>(null);
  const theme = useContext(ThemeContext);

  const addNewContact = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();    
    setFile(null);
    let formData = new FormData(event.currentTarget);    
    const inputs = event.currentTarget.querySelectorAll(".form-input");    

    let data: any = {};
    inputs.forEach((input) => {      
      data[input.id] = formData.get(input.id);
    });        
    
    await AddNewClientContact(data)
    .then((res) => {
      setContactAdded(prev => !prev);
      setHeaderExpanded(false);
      clearAll();
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const clearAll = () => {
    let form = document.getElementById("new-contact-form");
    let inputs = form?.querySelectorAll<HTMLInputElement>("input");
    setFile(null)

    inputs?.forEach((input) => {
      input.value = "";
    });
  }

  const selectFile = () => {        
    var input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx, .xls, .csv";
    input.multiple = false;

    input.onchange = (_) => {
      let files = input.files;   
      if (files) {
        setFile(files[0])
      }     
    };

    input.click();
  }

  const uploadFile = async () => {
    if (file) {
      setStatus(1);
      await UploadContactFile(file)
      .then((res: any) => {        
        if (res == 200) {
          setStatus(2);
          setFileAdded(prev => !prev);
        }
      })
      .catch((err) => {
        setStatus(3);
      })
      
      setFile(null);
      setTimeout(() => {
        document.getElementById(styles.statusNotif)?.classList.add(styles.fadeOut);
      }, 2000)
      setTimeout(() => {
        setStatus(null)
      }, 2500)
    }
  }

  const handleExpand = () => {
    if (headerExpanded) setFile(null);
    setHeaderExpanded(prev => !prev);
  }

  const message = () => {
    if (status) {
      if (status == 1) {
        return (
          <div id={styles.statusNotif}>
            <span className={cx(styles.loadingSpinner, theme.darkMode && styles.darkMode)}></span>
            <p style={{ margin: 0 }}>Uploading Contacts</p>
          </div>          
        )
      }
      else if (status == 2) {
        return (
          <div id={styles.statusNotif}>
            <Check color="#40B73A" style={{ marginRight: '10px' }} />
            <p style={{ margin: 0 }}>Contacts Uploaded</p>
          </div>          
        )
      }
      else if (status == 3) {
        return (
          <div id={styles.statusNotif}>
            <X color="#CD1503" style={{ marginRight: '10px' }} />
            <p style={{ margin: 0 }}>Could Not Upload File</p>
          </div>          
        )
      }
    }
  }

  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>      
      {message()}         
      <div className={cx(styles.header, theme.darkMode && styles.darkMode, headerExpanded && styles.expanded)}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <h1>Contacts</h1>
          <button 
            onClick={() => handleExpand()} 
            className={cx(styles.addNewButton, theme.darkMode && styles.darkMode)}
            style={{ border: `solid 2px ${headerExpanded ? "#9C0E0E" : "#d0d0d0"}`, color: theme.darkMode ? "#fff" : "#000" }}
          >
              {headerExpanded ? "Cancel" : "Add New"}
          </button>
        </div>
        <div className={cx(styles.formContainer, theme.darkMode && styles.darkMode, headerExpanded && styles.expanded)}>
          <Form addNewContact={addNewContact} clearAll={clearAll} file={file} selectFile={selectFile} uploadFile={uploadFile}/>
        </div>        
      </div>
      <ContactTable collapsed={headerExpanded} newContact={contactAdded} newFile={fileAdded} />      
    </div>
  )
}

export default ContactView
