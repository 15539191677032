import React, { useEffect, useState } from 'react'
import styles from "./OnboardingId.module.scss";
import { UpdateOnboardingTable } from '../../services/authServices';

const TableTitle = (props: any) => {
  const [title, setTitle] = useState(props.title);

  const updateTitle = async (e: any) => {
    setTitle(e.currentTarget.value);

    await UpdateOnboardingTable(e.currentTarget.value, 'Title', props.itemId);
  }

  useEffect(() => {
    var tableTitle = document.getElementById(`table-title-${props.itemId}`);

    const handleFocus = () => {
        if (tableTitle) {            
            tableTitle.style.border = `solid 1px ${props.color}`;
        }
    };
  
    const handleMouseOver = () => {
        console.log("hello")
        if (tableTitle) {            
            tableTitle.style.border = `solid 1px ${props.color}`;
        }
    };
  
    const handleMouseLeave = () => {
        if (tableTitle) {            
            tableTitle.style.border = 'none';
        }
    };
  
    const handleBlur = () => {
        if (tableTitle) {
            tableTitle.style.border = 'none';
        }
    };

    tableTitle?.addEventListener("focus", handleFocus);
    tableTitle?.addEventListener("mouseover", handleMouseOver);
    tableTitle?.addEventListener("mouseleave", handleMouseLeave);
    tableTitle?.addEventListener("blur", handleBlur);

    return () => {
        tableTitle?.removeEventListener("focus", handleFocus);
        tableTitle?.removeEventListener("mouseover", handleMouseOver);
        tableTitle?.removeEventListener("mouseleave", handleMouseLeave);
        tableTitle?.removeEventListener("blur", handleBlur);
      };
  }, [])

  return (
    <input 
        value={title}
        onChange={(e) => updateTitle(e)}
        className={styles.tableTitle}
        style={{ color: props.color }}
        id={`table-title-${props.itemId}`}
    />
  )
}

export default TableTitle
