import React, { useEffect, useState, useContext } from 'react'
import styles from "./OnboardingId.module.scss";
import { GetStatuses } from '../../services/authServices';
import { ThemeContext } from '../../contexts/ThemeContext';
import { UpdateOnboardingItem } from '../../services/authServices'
import cx from 'classnames';
import { Menu } from "@mui/material";

interface Props {
  status: string | null,
  type: string,
  color: string | null,
  itemId: number
}

const Status: React.FC<Props> = ({ status, type, color, itemId }) => {
  const [statuses, setStatuses] = useState([]);  
  const [currentStatus, setCurrentStatus] = useState({ status, color });
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [openMenu, setOpenMenu] = useState(false);
  const theme = useContext(ThemeContext);

  useEffect(() => {
    const getStatuses = async () => {
        var res: any = await GetStatuses("dbo.OnboardingStatuses");        
        setStatuses(res);
    }

    getStatuses()
  }, []);

  const handleMenu = (e: any) => {
    if (openMenu) {
        setOpenMenu(false);
        setAnchorEl(null)
    }
    else {
        setOpenMenu(true);
        setAnchorEl(e.currentTarget)
    }
  }

  const updateStatus = async (status: any) => {
    var newStatus = { status: status.Status, color: status.Color };
    setCurrentStatus(newStatus);
    
    await UpdateOnboardingItem(status.Status, `${type}`, itemId);
    await UpdateOnboardingItem(status.Color, `${type}Color`, itemId);
  }

  return (
    <div 
      onClick={(e) => handleMenu(e)} 
      className={cx(styles.statusCell, theme.darkMode && styles.darkMode)} 
      style={{ width: '13%', color: '#FFFFFF', backgroundColor: currentStatus.color == null ? `${theme.darkMode ? "#707070" : "#E3E3E3" }` : currentStatus.color }}
    >
      <p>{currentStatus.status}</p>      
    <Menu
      open={openMenu}
      anchorEl={anchorEl}
      onClose={handleMenu}
      sx={{
        "& .MuiPaper-root": {                                                                                               
          backgroundColor: theme.darkMode ? "#323130;" : "#FFFFFF",
          padding: "10px 20px",             
          borderRadius: "5px",   
          width: '310px',          
        },
    }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap' }}>
        {
            statuses.length > 0 && (
                statuses.filter((item: any) => item.Category == type).map((status: any) => {
                    return (
                        <div
                          style={{ 
                            height: '35px', 
                            width: '150px', 
                            color: "#FFFFFF",                             
                            backgroundColor: status.Color, 
                            margin: '0 0 10px 0',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',                           
                          }}
                          id={styles.newStatusSticker}
                          onClick={() => updateStatus(status)}
                        >
                        <p style={{ fontSize: '0.75rem', margin: 0, fontWeight: 'bold' }}>{status.Status}</p>
                        </div>
                    )
                })
            )
        }
        <div 
          style={{ 
            height: '35px', 
            width: '150px', 
            color: "#FFFFFF",                             
            backgroundColor: `${theme.darkMode ? "#707070" : "#E3E3E3" }`,
            margin: '0 0 10px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}

          id={styles.newStatusSticker}
          >
        </div>
      </div>
    </Menu>
    </div> 
  )
}

export default Status
