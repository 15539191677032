import React, { useState, useRef, useContext, useEffect } from "react";
import styles from "./TextCell.module.scss";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  UpdateProject,
  UpdateProjectSubitem,
} from "../../../services/authServices";
import { Drawer } from "@mui/material";
import CommentWhite from "../../../assets/media/comment-white.png";
import CommentBlack from "../../../assets/media/comment-black.png";
import xIconWhite from "../../../assets/media/x-icon.png";
import xIconBlack from "../../../assets/media/x-icon-black.png";
import cx from "classnames";
import { AddComment } from "../../../services/authServices";

interface TextCellProps {
  isTitle: boolean;
  subitemId: number | null;
  projectId: number | null;
  chartId: any;
  column: string;
  value: string;  
  subitemCount: null | number;
  comments: any | null;
  updateTextValue?: any;
}

const TextCell: React.FC<TextCellProps> = ({
  comments,
  isTitle,
  subitemId,
  projectId,
  chartId,
  column,
  value,
  subitemCount,
  updateTextValue,
}) => {
  const [editing, setEditing] = useState(false);
  const [valueString, setValueString] = useState(value);
  const [openInfo, setOpenInfo] = useState(false);
  const [infoDisplay, setInfoDisplay] = useState("comments");
  const [commentList, setCommentList] = useState(comments);
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext);

  const handleEnterKeyUp = (e: any) => {    
    if (e.key == "Enter") {
      if (valueString !== "") {
        var activeElement = document.activeElement as HTMLElement | null;

        if (activeElement) activeElement.blur();
        var lowerCaseColumn = column.charAt(0).toLocaleLowerCase() + column.slice(1); 

        setValueString(e.currentTarget.value);
        if (subitemId != null) {
          updateTextValue(projectId, lowerCaseColumn, e.currentTarget.value, subitemId);
          UpdateProjectSubitem(
            subitemId,
            projectId,
            "Title",
            e.currentTarget.value,
          );
        } else {
          updateTextValue(projectId, lowerCaseColumn, e.currentTarget.value, subitemId);
          UpdateProject(projectId, chartId, column, e.currentTarget.value);
        }
      }
    }
  };

  const handleOnBlur = () => {
    if (valueString !== "") {
      var lowerCaseColumn = column.charAt(0).toLocaleLowerCase() + column.slice(1); 
      if (subitemId != null) {
        updateTextValue(projectId, lowerCaseColumn, valueString, subitemId);
        UpdateProjectSubitem(subitemId, projectId, "Title", valueString);
      } else {        
        updateTextValue(projectId, lowerCaseColumn, valueString, subitemId);
        UpdateProject(projectId, chartId, column, valueString);
      }
    }
  };

  const handleEdit = () => {
    if (!editing) setEditing(true);
  };

  const addCommentEnterKey = (e: any) => {
    if (e.key == "Enter") {
      if (commentList !== null) {
        setCommentList([
          ...commentList,
          {
            comment: e.currentTarget.value,
            commenter: `${auth.user[1]} ${auth.user[2]}`,
            commenterPhoto: auth.user[6],
            date: new Date(),
          },
        ]);
      }
      else {
        setCommentList([{
          comment: e.currentTarget.value,
          commenter: `${auth.user[1]} ${auth.user[2]}`,
          commenterPhoto: auth.user[6],
          date: new Date(),
        }])
      }
      
      AddComment(
        e.currentTarget.value,
        `${auth.user[1]} ${auth.user[2]}`,
        auth.user[6],
        new Date(),
        projectId,
        chartId,
        "dbo.ProjectComments"
      );

      const userInput = document.getElementById(
        "commentInput",
      ) as HTMLInputElement;
      userInput.value = "";
    }
  };

  const updateDate = (e: any) => {
    setValueString(e)
    updateTextValue(projectId, "startDate", e, subitemId);
    UpdateProject(projectId, chartId, column, e);
  } 

  return (
    <div className={cx(styles.container)} onClick={handleEdit}>
      {
        column == "StartDate" ? (
          <input 
          lang="en-GB" 
          type="date" 
          value={valueString} 
          className={cx(styles.dateInput, theme.darkMode && styles.darkMode)}
          onChange={(e) => updateDate(e.currentTarget.value)}
        />
        ) : (          
          <input
            style={{ color: value == "New Subitem" ? "gray" : undefined }}
            onBlur={handleOnBlur}
            onKeyUp={(e) => handleEnterKeyUp(e)}            
            value={valueString}
            onChange={(e) => setValueString(e.currentTarget.value)}
            className={cx(styles.input, theme.darkMode && styles.darkMode, subitemId && styles.subitemInput)}
            placeholder={ value == '' && isTitle ? "New Project" : ''} 
            type="text"
          />          
          )
        }
      {subitemCount != null && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: theme.darkMode ? "#5A5A5A" : "#B6B6B6",
            color: "#FFFFFF",
            width: "15px",
            padding: "5px 0",
            borderRadius: "4px",
          }}
        >
          <p style={{ fontSize: "0.75rem" }}>{subitemCount}</p>
        </div>
      )}
      {isTitle && (
        <div className={styles.comment} onClick={() => setOpenInfo(true)}>
          <img
            src={theme.darkMode ? CommentWhite : CommentBlack}
            style={{ height: "25px", width: "25px" }}
          />
          {
            commentList !== null && commentList.length > 0 && (
              <div className={cx(styles.commentNotif, theme.darkMode && styles.darkMode)}>{commentList.length}</div>
            )
          }
        </div>
      )}
      <Drawer
        anchor="right"
        open={openInfo}
        onClose={() => setOpenInfo(false)}
        PaperProps={{
          sx: {
            width: "40vw",
            backgroundColor: theme.darkMode ? "#323130" : "#FFFFFF",
            color: theme.darkMode ? "#FFFFFF" : "#000000",
          },
        }}
      >
        <div style={{ height: "100%", width: "100%", position: "relative" }}>
          <img
            src={theme.darkMode ? xIconWhite : xIconBlack}
            className={styles.xIcon}
            alt="x"
            onClick={() => setOpenInfo(false)}
          />
          <img className={styles.pfp} src={auth.user[6]} alt="pfp" />
          <div className={styles.commentHeader}>
            <p style={{ fontFamily: "PoppinsBold", fontSize: "1.5rem" }}>
              {value}
            </p>
            <div className={styles.tabs}>
              <p onClick={() => setInfoDisplay("comments")}>Comments</p>
              <p onClick={() => setInfoDisplay("files")}>Files</p>
            </div>
          </div>
          {infoDisplay == "comments" && (
            <div className={styles.commentSection}>
              <p>Add a comment or update</p>
              <input
                id="commentInput"
                style={{ color: "inherit" }}
                onKeyUp={(e) => addCommentEnterKey(e)}
              />
              <div>
                {commentList !== null &&
                  commentList.length > 0 &&
                  commentList
                    .sort(
                      (a: any, b: any) =>
                        new Date(b.date).getTime() - new Date(a.date).getTime(),
                    )
                    .map((comment: any) => {
                      let currentDate = new Date();
                      let commentDate = new Date(comment.date);

                      var timeElapsed =
                        currentDate.getTime() - commentDate.getTime();

                      var timeMessage = "";
                      if (Math.floor(timeElapsed / 3600000) < 24) {
                        timeMessage = "Today";
                      }
                      else if (Math.floor(timeElapsed / 3600000) > 24 && Math.floor(timeElapsed / 3600000) < 48) {
                        timeMessage = "Yesterday";
                      }
                      else {
                        timeMessage = `${commentDate.toLocaleString("default", { month: "short" })} ${commentDate.getDay()}`;
                      }

                      return (
                        <div className={styles.commentBox}>
                          <img src={comment.commenterPhoto} />
                          <div>
                            <p style={{ fontSize: "0.85rem" }}>
                              {comment.commenter}
                            </p>
                            <p
                              style={{
                                color: theme.darkMode ? "#DBDBDB" : "#000000",
                                marginBottom: "5px",
                              }}
                            >
                              {comment.comment}
                            </p>
                            <p
                              style={{ fontSize: "0.70rem", color: "#A2A2A2" }}
                            >
                              {timeMessage}
                            </p>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default TextCell;
