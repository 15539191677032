import React from "react";
import styles from "./NotFound.module.scss";

const NotFoundView = () => {
  return (
    <div className={styles.container}>
      <div className={styles.leftBox}>
        <h1>404</h1>
      </div>
      <div className={styles.rightBox}>
        <p>
          Sorry, the page you're looking for
          <br /> does not exist.
        </p>
        <a href="/dashboard">Back to home</a>
      </div>
    </div>
  );
};

export default NotFoundView;
