import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PhoneInput from "react-phone-input-2";
import Microsoft from "../../assets/media/microsoft-logo.png";
import "./AccessForm.scss";

interface AccessFormProps {
  sendRequest: Function;
}

interface Values {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  company: string;  
}

const AccessForm = (props: AccessFormProps) => {
  const [errors, setErrors] = useState("");  

  const handleSubmit = (values: Values) => {    
    setErrors("");

    if (Object.values(values).includes("")) {
      setErrors("Please fill out all fields");
    }
    else {
      console.log(values.password.length)
      if (values.password !== values.confirmPassword) {
        setErrors("Passwords do not match or are blank");
      } 
      else {
        if (values.password.length < 7) {
          setErrors("Password must be at least 7 characters")          
        }
        else {
          props.sendRequest(values);
        }
      }
    }

  };

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          confirmPassword: "",
          company: "",          
        }}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ values, handleChange }) => (
          <Form className="form-container">
            <div className="name-info">
              <li className="input-and-label" id="name-input">
                <p className="access-label">First name</p>
                <input
                  className="input"
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                />
              </li>
              <li className="input-and-label" id="name-input">
                <p className="access-label">Last name</p>
                <input
                  className="input"
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                />
              </li>
            </div>
            <li className="input-and-label">
              <p className="access-label">Email</p>
              <input
                className="input"
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
              />
            </li>
            <div className="name-info">
              <li className="input-and-label" id="name-input">
                <p className="access-label">Desired password</p>
                <input
                  className="input"
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                />
              </li>
              <li className="input-and-label" id="name-input">
                <p className="access-label">Confirm password</p>
                <input
                  className="input"
                  type="password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                />
              </li>
            </div>
            <li
              className="input-and-label"
              id="company-input"
              style={{ marginBottom: errors == "" ? "40px" : "20px" }}
            >
              <p className="access-label">Company</p>
              <input
                className="input"
                type="text"
                name="company"
                value={values.company}
                onChange={handleChange}
              />
            </li>
            <div style={{ display: errors ? "block" : "none" }}>
              <p
                style={{
                  marginTop: 0,
                  marginBottom: "20px",
                  color: "rgb(203, 10, 10)",
                }}
              >
                {errors}
              </p>
            </div>
            <button type="submit" className="submit-button">
              Submit
            </button>
            <p className="sign-in-prompt">
              Already have an account?{" "}
              <strong>
                <a href="/" id="back-link">
                  Sign In
                </a>
              </strong>
            </p>
          </Form>
        )}
      </Formik>
      {/* <div>
                <div className="microsoft-sign-in">
                    <img src={Microsoft} alt="microsoft-logo"/>
                    <p>Continue with Microsoft</p>
                </div>
            </div> */}
    </>
  );
};

export default AccessForm;
