import React, { useState, useEffect, useContext } from 'react'
import styles from "./Calendar.module.scss"
import ChevronWhite from "../../assets/media/chevron-white.png";
import ChevronBlack from "../../assets/media/chevron-black.png";
import cx from 'classnames'
import { title } from 'process'
import { flexbox } from '@mui/system'
import { ThemeContext } from '../../contexts/ThemeContext';
import { EmployeeContext } from '../../contexts/EmployeeContext';

interface Props {
    active: string,
    requests: any,    
}

const Calendar: React.FC<Props> = ({ active, requests }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());  
  const [daysOfMonth, setDaysOfMonth] = useState<Date[]>();
  const [bankHolidayDates, setBankHolidayDates] = useState<Date[]>();
  const [bankHolidays, setBankHolidays] = useState([]);
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const year = new Date().getFullYear();
  const theme = useContext(ThemeContext);
  const employees = useContext(EmployeeContext).employees;

  const getBankHolidays = async () => {
    var holidays: any = [];
    await fetch("https://www.gov.uk/bank-holidays.json")
    .then(res => res.json())
    .then(data => {
        holidays = [...data['england-and-wales'].events];
    })

    holidays = holidays.filter((item: any) => new Date(item.date).getFullYear() == year);    
        
    setBankHolidayDates(holidays.map((item: any) => new Date(item.date)))
    setBankHolidays(holidays);
  }

//   useEffect(() => {
//     console.log(bankHolidays);
//   }, [bankHolidays])

  useEffect(() => {
    getBankHolidays();
  }, [])

  useEffect(() => {
    const date = new Date(year, currentMonth, 1);

    var days = [];
    while (date.getMonth() == currentMonth) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }   

    setDaysOfMonth(days);

  }, [currentMonth])

  return (
    <>
      <div className={cx(styles.monthsCollapsed, active == "calendar" && styles.inactive)}>
        {
            months.map((month, index) => {                
                var requestInstances = requests.filter((x: any) => new Date(x.StartDate).getMonth() == index);
                var days = [];
                var date = new Date(year, index, 1)
                while (date.getMonth() == index) {                    
                    days.push(new Date(date));
                    date.setDate(date.getDate() + 1);
                }                
                
                var blankDays = days[0].getDay();                

                return (
                    <>
                        <p style={{ marginBottom: "7px" }}>{month}</p>
                        <div className={styles.monthContainer}>
                          {
                            Array.from(Array(blankDays)).map(item => {
                                return (
                                    <div className={styles.day}></div>
                                )
                            })
                          }
                          {
                            days.map((day, index) => {
                                var dayString = `${day.getFullYear()}-${day.getMonth() + 1 < 10 ? "0" : ""}${day.getMonth() + 1}-${day.getDate() < 10 ? 0 : ""}${day.getDate()}`                                        
                                var holiday: any = bankHolidays.find((item: any) => item.date == dayString);                            

                                if (holiday != undefined) {
                                    return (
                                        <div className={styles.day} style={{ borderRadius: '40px' }}>
                                            <p style={{ color: "#FFFFFF", backgroundColor: "#FF9348", padding: "2px 4px", borderRadius: '50px' }}>{day.getDate()}</p>
                                        </div>
                                    )
                                }
                                else {
                                    var currentDate = new Date();
                                    var today = false;
                                    if (currentDate.getDate() == day.getDate() && currentDate.getMonth() == day.getMonth()) today = true;

                                    if (today) {
                                        return (
                                            <div className={styles.day}>
                                                <p style={{ color: "#FFFFFF", backgroundColor: "#00C430", padding: "2px 4px", borderRadius: '50px' }}>{day.getDate()}</p>
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div className={styles.day} style={{ }}>
                                                <p>{day.getDate()}</p>
                                            </div>
                                        )
                                    }
                                }
                            })
                          }
                        </div>
                        <div className={styles.userDisplay}>
                        {
                            requestInstances.length > 0 && Array.from(new Set(requestInstances.map((x: any) => x.UserPhoto))).map((item: any) => {                                
                                
                                return (
                                    <img src={item} className={styles.userPhoto} />                                    
                                )
                            })
                        }
                        </div>
                    </>
                )
            })
        }
      </div>
      <div className={cx(styles.monthEnlarged, active == "table" && styles.inactive)}>
        <div className={styles.monthChanger}>
            {
                currentMonth !== 0 && (
                    <img onClick={() => setCurrentMonth(prev => prev - 1)} src={theme.darkMode ? ChevronWhite : ChevronBlack} alt="chev" style={{ transform: 'rotate(180deg)'}} />
                )
            }
            <p className={styles.monthTitle}>{new Date(2024, currentMonth, 1).toLocaleDateString("default", { month: "long" })}</p>
            {
                currentMonth !== 11 && (
                    <img onClick={() => setCurrentMonth(prev => prev + 1)} src={theme.darkMode ? ChevronWhite : ChevronBlack} alt="chev"/>
                )
            }
        </div>
        <div className={styles.weekDays}>
            <div>
                <p>Sunday</p>
            </div>
            <div>
                <p>Monday</p>
            </div>
            <div>
                <p>Tuesday</p>
            </div>
            <div>
                <p>Wednesday</p>
            </div>
            <div>
                <p>Thursday</p>
            </div>
            <div>
                <p>Friday</p>
            </div>
            <div>
                <p>Saturday</p>
            </div>
        </div>
        <div className={styles.monthContainer}>
            {
                daysOfMonth && Array.from(Array(daysOfMonth[0].getDay())).map(item => {
                    return (
                        <div className={styles.dayLarge}></div>
                    )
                })
            }
            {
                bankHolidayDates && daysOfMonth && daysOfMonth.map((day: Date, index: number) => {     
                    var dayString = `${day.getFullYear()}-${day.getMonth() + 1 < 10 ? "0" : ""}${day.getMonth() + 1}-${day.getDate() < 10 ? 0 : ""}${day.getDate()}`;
                    var holiday: any = bankHolidays.find((item: any) => item.date == dayString);                    

                    var test = requests.filter((item: any) => {   
                        let startDate = new Date(item.StartDate);
                        startDate.setHours(0, 0, 0, 0);

                        let endDate = new Date(item.EndDate);
                        endDate.setHours(0, 0, 0, 0);

                        let physicalEnd = new Date(item.PhysicalEnd);
                        physicalEnd.setHours(0, 0, 0, 0);

                        let currDay = new Date(day);
                        currDay.setHours(0, 0, 0, 0);
                        
                        if ((currDay.getTime() >= startDate.getTime() && currDay.getTime() <= endDate.getTime()) || 
                            (currDay.getTime() >= startDate.getTime() && currDay.getTime() <= physicalEnd.getTime())) {
                            return item;
                        }
                    });                                                 

                    test.sort((a: any, b: any) => new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime());                                   
                    
                    if (test.length > 0) {
                        return (
                            <div className={styles.dayLarge}>
                                <p style={{ margin: '3px 0 3px 3px' }}>{day.getDate()}</p>
                                {
                                    test.map((request: any) => {        
                                        var startDate = new Date(request.StartDate);
                                        var endDate = new Date(request.EndDate);
                                        startDate.setHours(0, 0, 0, 0);
                                        endDate.setHours(0, 0, 0, 0);                                           
                                        var name = employees.find((x: any) => x.id == request.UserId).name;  
                                        
                                        console.log(request)
                                        
                                        if (startDate.getTime() == day.getTime()) {
                                            return (
                                                <div className={styles.vacationLineStart} style={{ borderColor: `${request.Color}`, borderWidth: request.Status == "Approved" ? 0 : "2px 0 2px 2px", borderStyle: "solid", backgroundColor: request.Status == "Approved" ? request.Color : 'transparent', borderRadius: "50px 0 0 50px", margin: '0 0 2px 10px', }}>
                                                    <p style={{ color: request.Status == "Approved" ? "#FFFFFF" : "#cacaca", }}>{name}</p>
                                                </div>
                                            )
                                        }
                                        if (endDate.getTime() == day.getTime()) {
                                            return (
                                                <div className={styles.vacationLineEnd} style={{ borderColor: `${request.Color}`, borderWidth: request.Status == "Approved" ? 0 : "2px 2px 2px 0", borderStyle: "solid", backgroundColor: request.Status == "Approved" ? request.Color : 'transparent', borderRadius: "0 50px 50px 0", }}>                                                    
                                                </div>
                                            )
                                        }
                                        else if (new Date(request.PhysicalEnd).getTime() >= day.getTime() && new Date(request.EndDate).getTime() < day.getTime()) {
                                            return (
                                                <div style={{ width: '100%', backgroundColor: 'transparent', height: '20px', marginBottom: '2px', display: 'flex', justifyContent: 'flex-end' }}>                                                    
                                                </div>
                                            )
                                        }
                                        else if (new Date(request.StartDate).getTime() < day.getTime() && new Date(request.EndDate).getTime() > day.getTime()) {
                                            return (
                                                <div className={styles.vacationLineMiddle} style={{ width: '100%', borderColor: `${request.Color}`, borderWidth: request.Status == "Approved" ? 0 : "2px 0 2px 0", borderStyle: "solid", backgroundColor: request.Status == "Approved" ? request.Color : 'transparent', }}>                                                    
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        )
                    }

                    else if (holiday !== undefined) {                            
                        
                        return (
                            <div className={styles.dayLarge} id={styles.bankHoliday}>                                
                                <p style={{ margin: '3px 0 0 3px' }}>{day.getDate()}</p>
                                <p className={styles.dateInfo}>{holiday.title}</p>
                            </div>
                        )
                    }                    
                    else {
                        return (
                            <div className={styles.dayLarge}>                                
                                <p style={{ margin: '3px 0 0 3px' }}>{day.getDate()}</p>
                            </div>
                        )
                    }
                })
            }
            {
                daysOfMonth && Array.from(Array(6 - daysOfMonth[daysOfMonth.length - 1].getDay())).map(item => {
                    return (
                        <div className={styles.dayLarge}></div>
                    )
                })
            }
        </div>
        <div className={styles.key} style={{ flexWrap: 'wrap'}}>
            <div className={styles.keyItem} style={{ flexWrap: 'nowrap' }}>
                <div style={{ marginRight: "5px", height: '10px', width: '10px', borderRadius: '50px', backgroundColor: '#FF9348' }}></div>
                <p style={{ fontSize: '0.65rem', margin: '2px' }}>Bank Holiday</p>
            </div>
            <div className={styles.keyItem}>
                <div style={{ marginRight: "5px", height: '10px', width: '10px', borderRadius: '50px', backgroundColor: '#810FCF' }}></div>
                <p style={{ fontSize: '0.65rem', margin: '2px' }}>Philippe Dominois</p>
            </div>
            <div className={styles.keyItem}>
                <div style={{ marginRight: "5px", height: '10px', width: '10px', borderRadius: '50px', backgroundColor: '#0B868E' }}></div>
                <p style={{ fontSize: '0.65rem', margin: '2px' }}>Tatjana Slykova</p>
            </div>
            <div className={styles.keyItem}>
                <div style={{ marginRight: "5px", height: '10px', width: '10px', borderRadius: '50px', backgroundColor: '#59DF92' }}></div>
                <p style={{ fontSize: '0.65rem', margin: '2px' }}>Jayden Chen</p>
            </div>
            <div className={styles.keyItem}>
                <div style={{ marginRight: "5px", height: '10px', width: '10px', borderRadius: '50px', backgroundColor: '#75B4FF' }}></div>
                <p style={{ fontSize: '0.65rem', margin: '2px' }}>Ford Burgess</p>
            </div>
            <div className={styles.keyItem}>
                <div style={{ marginRight: "5px", height: '10px', width: '10px', borderRadius: '50px', backgroundColor: '#FF7046' }}></div>
                <p style={{ fontSize: '0.65rem', margin: '2px' }}>Alberto Mezzalira</p>
            </div>
            <div className={styles.keyItem}>
                <div style={{ marginRight: "5px", height: '10px', width: '10px', borderRadius: '50px', backgroundColor: '#FF6AA9' }}></div>
                <p style={{ fontSize: '0.65rem', margin: '2px' }}>Tetianna Chervonna</p>
            </div>
            <div className={styles.keyItem}>
                <div style={{ marginRight: "5px", height: '10px', width: '10px', borderRadius: '50px', backgroundColor: '#577590' }}></div>
                <p style={{ fontSize: '0.65rem', margin: '2px' }}>Doris Yu</p>
            </div>
            <div className={styles.keyItem}>
                <div style={{ marginRight: "5px", height: '10px', width: '10px', borderRadius: '50px', backgroundColor: '#0527D2' }}></div>
                <p style={{ fontSize: '0.65rem', margin: '2px' }}>Sue Clarke</p>
            </div>
        </div>
      </div>
    </>
  )
}

export default Calendar
