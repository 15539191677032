import React from "react";
import DashboardView from "../features/dashboard";

const Dashboard = () => {
  return (
    <>
      <DashboardView />
    </>
  );
};

export default Dashboard;
