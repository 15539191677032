import React, { useContext, useEffect, useState, useRef } from 'react'
import styles from "./BenchmarkGraph.module.scss"
import { GetBenchmarkData } from '../../services/authServices';
import { ThemeContext } from '../../contexts/ThemeContext';
import { BarChart } from '@mui/x-charts/BarChart';
import Chart from 'chart.js/auto';
import { BarController, BarElement } from 'chart.js/auto';
import cx from 'classnames'

const BenchmarkGraph = () => {
  const [avgByCountry, setAvgByCountry] = useState<any>([]);
  const theme = useContext(ThemeContext);
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart<"bar"> | null>(null);

  const getData = async () => {
    var res: any = await GetBenchmarkData("dbo.AvbData");
    
    var averages: any = {};
    res.forEach((item: any) => {        
        if (averages[item[10]]) {
            averages[item[10]].AVB += item[15];
            averages[item[10]].Count += 1;
        }
        else {
            averages[item[10]] = {
                AVB: item[15],
                Count: 1,
            }
        }
    })

    var test: any = Object.entries(averages).map((([key, value]: any) => {
        return { data: (value.AVB / value.Count * 100), color: 'rgb(241, 193, 2)', country: key }
    }))    

    setAvgByCountry(test);
  }

  useEffect(() => {
    getData()
  }, [])  

  useEffect(() => {
    if (avgByCountry.length > 0 && chartRef.current) {
        const ctx = chartRef.current.getContext('2d');  
        
        var sorted = avgByCountry.sort((a: any, b: any) => b.data - a.data);

        var test = {
            labels: sorted.slice(0, 17).map((item: any) => item.country),
            datasets: [{
                label: "AVB By Country",
                data: sorted.slice(0, 17).map((item: any) => item.data),
                // barThickness: 50,
                backgroundColor: theme.darkMode ? 'rgba(249, 209, 45, 0.5)' : 'rgba(0, 66, 104, 0.5)',                
                borderColor: theme.darkMode ? 'rgba(249, 209, 45, 1)' : 'rgba(0, 66, 104, 1)',                
                borderWidth: 1
            }]
        }
        
        if (ctx) {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }

            chartInstanceRef.current = new Chart(ctx, {
                type: 'bar',
                data: test,
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            })
        }
    }
  }, [avgByCountry, theme])

  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>
        <h1 className={styles.title}>Benchmarks</h1>
        <div className={cx(styles.chartContainer, theme.darkMode && styles.darkMode)}>
            {
                avgByCountry.length > 0 && (                
                    <canvas ref={chartRef} className={cx(styles.canvas, theme.darkMode && styles.darkMode)} width="700" height="300"/>
                )
            }
        </div>
    </div>
  )
}

export default BenchmarkGraph
