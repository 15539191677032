import React from 'react'
import VacationBoardView from '../features/vacationBoard'

const VacationBoard = () => {
  return (
    <VacationBoardView />
  )
}

export default VacationBoard
