import React from 'react'
import TrackingDemoView from '../features/trackingDemo'

const TrackingDemo = () => {
  return (
    <TrackingDemoView />
  )
}

export default TrackingDemo
