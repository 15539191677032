import React, { useState, useContext } from 'react'
import styles from "./OnboardingId.module.scss";
import cx from 'classnames'
import { ThemeContext } from '../../contexts/ThemeContext';
import { UpdateOnboardingItem } from '../../services/authServices';

interface Props {
    title: string,
    itemId: number
}

const Title: React.FC<Props> = ({ title, itemId }) => {
  const [value, setValue] = useState(title);
  const theme = useContext(ThemeContext);

  const handleTitle = async (e: any) => {
    setValue(e.currentTarget.value);

    await UpdateOnboardingItem(e.currentTarget.value, "Title", itemId);
  }

  return (
    <input value={value} onChange={(e) => handleTitle(e)} className={cx(styles.titleInput, theme.darkMode && styles.darkMode)} />
  )
}

export default Title
