import axios from "axios";
import { read } from "node:fs";
var jwt = require("jsonwebtoken");

var url: string = "https://abintus-app-api-linux.azurewebsites.net/";
// var url: string = "http://localhost:4242/";

export const GetAuthToken = () => {
  const cookies = document.cookie.split(";");

  var auth = null;
  var raw = null;

  raw = localStorage.getItem("authorization");

  
  if (raw !== null) {
    auth = jwt.decode(raw);
  }
    
  return { auth, raw };
};

var rawToken = GetAuthToken().raw;
var decodedToken = GetAuthToken().auth;

export const Login = (email: string, password: string, context: any) =>
  new Promise(async (resolve, reject) => {
    var formData = new FormData();

    formData.append('Email', email);
    formData.append('Password', password);

    await fetch(url + "solutions/login", {
      method: 'POST',
      // credentials: 'include',
      body: formData
    })
    .then(async (res: any) => {      
      if (!res.ok) {        
        if (res.status == 403) {
          reject(403);
        } else if (res.status == 401) {
          reject(401);
        } else {
          reject("Error, please try again later");
        }
      }
      else {  
        let data = await res.json();                     
        let rawToken = data["Authorization"].split(" ")[1];      
        var accessToken = jwt.decode(rawToken);     
        context.setUser(accessToken.User);
        context.setIsAuthenticated(true);
        localStorage.setItem(
          "authorization",
          rawToken
        )
        if (localStorage.getItem("theme") == null) {
          localStorage.setItem("theme", "light");
        }

        resolve("Success")
      }
    })    
  });

  export const GetNewToken = async () => {
    
    await fetch(url + "solutions/refresh", {
      method: 'POST',
      credentials: 'include'
    })
    .then((res: any) => res.json())
    .then((data) => {
      let token = data.accessToken;
      localStorage.setItem("authorization", token);      
    })
    .catch((err) => {
      Logout()
    })
  }

export const Logout = () => {
  document.cookie = "refreshToken" + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
  
  for (const key in localStorage) {
    if (localStorage.hasOwnProperty(key) && key !== "theme") {
      localStorage.removeItem(key);
    }
  }  
};

export const SendAccessRequest = (
  email: string,
  name: string,
  company: string,
  password: string,
) => {
  var payload = {
    Email: email,
    Name: name,
    Company: company,
    Password: password,
  };

  axios
    .post(url + "solutions/request-access", payload)
    .catch((err) => console.log(err));
};

export const SendVerificationEmail = (email: string) => {
  var payload = {
    Email: email,
  };

  axios
    .post(url + "solutions/send-verification-email", payload)
    .catch((err) => console.log(err));
};

export const ConfirmVerificationCode = (
  code: string,
  context: any,
  location: string,
) =>
  new Promise(async (resolve, reject) => {
    var email = location.includes("forgot-password")
      ? context
      : context.user[3];
    axios
      .get(url + "solutions/confirm-verification-code", {
        headers: {
          Email: email,
          Code: code,
        },
      })
      .then((res) => {
        if (!location.includes("forgot-password")) {
          context.setIsValidated(true);
          // document.cookie = "validated=true"
          localStorage.setItem("validated", "true");
        }
        resolve("success");
      })
      .catch((err) => {
        reject("bad request");
      });
  });

export const DeleteVerificationCode = (code: string) => {
  axios
    .post(url + "solutions/delete-verification-code", {
      headers: {
        Code: code,
      },
    })
    .catch((err) => {
      console.log(err);
    });
};

export const UpdatePersonalInfo = (values: Object) =>
  new Promise(async (resolve, reject) => {
    var payload = {
      info: values,
    };

    var token = GetAuthToken().raw;

    axios
      .post(url + "solutions/update-info", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        localStorage.removeItem("authorization");
        var token = res.headers.authorization.split(" ")[1];        
        localStorage.setItem("authorization", token);
        window.location.reload();
      })
      .then((res) => {
        resolve("success");
      })
      .catch(() => {
        reject("bad request");
      });
  });

export const ChangePassword = (newPassword: string, email: string) =>
  new Promise(async (resolve, reject) => {
    var payload = {
      NewPassword: newPassword,
      Email: email,
    };

    var token = GetAuthToken().raw;

    axios
      .post(url + "solutions/change-password", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        resolve("success");
      })
      .catch((err) => {                
        reject(err);
      });
  });

export const GetAllUsers = () =>
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      },
    };

    axios
      .get(url + "solutions/get-all-users", config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });

export const UpdatePhoto = (file: any, id: number) =>
  new Promise(async (resolve, reject) => {
    var formData = new FormData();
    formData.append("file", file);
    formData.append("type", file.type);
    formData.append("fileName", file.name);
    formData.append("id", id.toString());

    var token = GetAuthToken().raw;

    await fetch(url + "solutions/update-photo", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then(async (res) => {
        var data = await res.json();        
        localStorage.removeItem("authorization");    
        let rawToken = data["Authorization"].split(" ")[1];      
        var accessToken = jwt.decode(rawToken);
        
        localStorage.setItem("authorization", rawToken);
        window.location.reload();
      })
      .catch(() => {
        reject("Please try again later");
      });
  });

export const GetBenchmarks = async () => {
  var headers: any = [];
  var benchmarks: any = [];

  await axios
    .get(url + "benchmarks/avb/all-column-names")
    .then((res) => {
      headers = [...res.data];
      console.log(headers);
    })
    .catch((err) => {
      console.log(err);
    });

  await axios
    .get(url + "benchmarks")
    .then((res) => {
      benchmarks = [...res.data];
    })
    .catch((err) => {
      console.log(err);
    });

  return { headers, benchmarks };
};

export const GetSpecificColumns = async (table: string) => {
  var columns: any = [];

  await axios
    .get(url + `benchmarks/avb/column-names?table=${table}`, {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      },
    })
    .then((res) => {
      columns = [...res.data];
    })
    .catch((err) => {
      console.log(err);
    });

  return columns;
};

export const AddBenchmarks = (values: Array<string>) =>
  new Promise(async (resolve, reject) => {
    var payload = {
      GeneralInfo: values,
    };

    await axios
      .post(url + "benchmark/add", payload, {
        headers: {
          Authorization: `Bearer ${rawToken}`,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });

export const GetBenchmarkData = (
  db: string,
) => 
  new Promise(async (resolve, reject) => {       

    await axios.get(url + "solutions/benchmarks/get-all-data", {
      headers: {
        Authorization: `Bearer ${rawToken}`,
        db: db,
      }
    })
    .then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    })
  })

export const AddBenchmarkValues = (
  table: string,
  columnNames: string,
  columnValues: string,
) =>
  new Promise(async (resolve, reject) => {
    var payload = {
      Table: table,
      ColumnNames: columnNames,
      ColumnValues: columnValues,
    };

    await axios
      .post(url + "benchmark/add/avb-values", payload, {
        headers: {
          Authorization: `Bearer ${rawToken}`,
        },
      })
      .then((res) => {
        resolve(200);
      })
      .catch((err) => {
        reject(500);
      });
  });

// Media Price & Quality
export const Automation = (
  file: File,
  userId: number,
  name: string,
  photo: string,
  demo: boolean,
) =>
  new Promise(async (resolve, reject) => {
    var formData = new FormData();
    formData.append("file", file);

    try {
      const res = await fetch(url + "solutions/automation/file-upload", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${rawToken}`,
          UserId: `${userId}`,
          Name: name,
          Photo: photo,
          Demo: demo.toString(),
        },
        body: formData,
      });

      if (!res.ok) {
        let data = await res.json();
        reject({ status: res.status, message: data.message });
      } else {
        let data = await res.json();
        resolve(data.url);
      }
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });

export const GetRecentAutomationRuns = (type: string) =>
  new Promise(async (resolve, reject) => {
    await axios
      .get(url + "solutions/recent-automation-runs", {
        headers: {
          Authorization: `Bearer ${rawToken}`,
          Type: type,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });

export const GetRecentRunsAllTables = (demo: boolean) =>
  new Promise(async (resolve, reject) => {
    await axios
      .get(url + "solutions/recent-automation-runs/all-tables", {
        headers: {
          Authorization: `Bearer ${rawToken}`,
          Demo: demo.toString(),
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });

export const DeleteAutomationRun = (item: any, demo: boolean) =>
  new Promise(async (resolve, reject) => {
    // console.log(item);

    await axios
      .delete(url + "solutions/delete-automation-run", {
        headers: {
          Authorization: `Bearer ${rawToken}`,
          AutomationId: item.Id,
          AutomationType: item.Type,
          Demo: demo.toString(),
        },
      })
      .then((res) => {
        resolve(res.data);
      });
  });

export const ChangeInputTemplate = (file: File, type: string) =>
  new Promise(async (resolve, reject) => {
    var formData = new FormData();
    formData.append("file", file);

    await fetch(url + "solutions/automation/change-input-template", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${rawToken}`,
        Type: type,
      },
      body: formData,
    })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        console.log(err);
      });
});

export const ChangePitchTemplate = (file: File, type: string) =>
  new Promise(async (resolve, reject) => {
    var formData = new FormData();
    formData.append("file", file);

    await fetch(url + "solutions/automation/change-pitch-template", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${rawToken}`,
        Type: type,
      },
      body: formData,
    })
      .then()
      .catch((err) => {
        console.log(err);
      });
});

export const ChangeNamingConvention = (file: File, automation: string) =>
  new Promise(async(resolve, reject) => {
    var formData = new FormData();
    formData.append("file", file);

    await fetch(url + "solutions/automation/change-naming-convention", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${rawToken}`,
        Type: automation,
      },
      body: formData,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
      });
  })

export const GetInputTemplate = (type: string) =>
  new Promise(async (resolve, reject) => {
    await axios
      .get(url + "solutions/automation/get-template", {
        headers: {
          Authorization: `Bearer ${rawToken}`,
          Type: type,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        console.log("get input template error");
        reject(err);
      });
  });

export const GetFullReport = (db: string) =>
  new Promise(async (resolve, reject) => {
    await axios
      .get(url + "solutions/automation/get-full-report", {
        headers: {
          Authorization: `Bearer ${rawToken}`,
          Database: db,
        },
      })
      .then((res) => {
        resolve(res.data);        
      })
      .catch((err) => {
        console.log(err);
      });
  });

export const DownloadInputTemplate = (type: string) =>
  new Promise(async (resolve, reject) => {
    await axios
      .get(url + "solutions/automation/get-input-template", {
        headers: {
          Authorization: `Bearer ${rawToken}`,
          Type: type
        }
      })
      .then((res => {
        resolve(res.data);
      }))
      .catch((err) => {
        console.log(err);
      })
  })

export const AutomationAll = (
  file: File | FileList,
  userId: number,
  name: string,
  photo: string,
  demo: boolean,
  ext: string,
  // country: string = "",
  subType: string
) =>
  new Promise(async (resolve, reject) => {
    var formData = new FormData();
    if (file instanceof File) {
      formData.append("file", file);
    } else if (file instanceof FileList) {
      for (var i = 0; i <= file.length - 1; i++) {
        formData.append(`file${i}`, file[i]);
      }
    }
    
  try {
    var res = await fetch(url + "solutions/automation/" + ext, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${rawToken}`,
        UserId: `${userId}`,
        Name: name,
        Photo: photo,
        Demo: demo.toString(),
        Type: subType
        // Country: country,
      },
      body: formData,
    });

    console.log("hello")

    if (!res.ok) {
      let data = await res.json();
      
      reject({ status: res.status, message: data.message });
    } else {
      let data = await res.json();
      resolve(data.url);
    }
  } catch (err) {    
    reject(err);    
  }
});

export const PitchManagementAutomation = (
  input: File,
  type: string,
  userId: number,
  name: string,
  photo: string,
) =>
  new Promise(async (resolve, reject) => {
    var formData = new FormData();
    formData.append("input", input);
    // formData.append("indices", indices);

    try {
      var res = await fetch(url + "solutions/automation/pitch-management", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${rawToken}`,
          UserId: `${userId}`,
          Name: name,
          Type: type,
          UserPhoto: photo,
        },
        body: formData,
      });

      if (!res.ok) {
        reject(res.status);
      } else {
        let data = await res.json();
        resolve(data.url);
      }
    } catch (err) {
      console.log(err);
    }
  });

  export const GetContractData = () =>
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      },
    };

    axios
      .get(url + `solutions/contracts/calculations`, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });

export const GetContracts = async () => {
  var contracts: any = [];
  var contractUrls: any = [];

  try {
    await axios
      .get(url + "solutions/contracts", {
        headers: {
          Authorization: `Bearer ${rawToken}`,
        },
      })
      .then((res) => {
        contracts = [...res.data];
      });
    await fetch(url + "solutions/contracts/url/list", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${rawToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        data.map((contract: any) => {
          for (var i = 0; i <= contracts.length - 1; i++) {
            if (contracts[i].ContractId == contract[0]) {
              if (contracts[i].ContractUrl) {
                contracts[i].ContractUrl.push(contract);
              } else {
                contracts[i].ContractUrl = [];
                var links: any = [];
                var names = contract[3].split(",");
                names.forEach((name: any) => {
                  var link = contract[1];
                  if (link != "") {
                    links.push(link.replace(/[^/]+(?=\?)/g, name));
                    contracts[i].ContractUrl.push([
                      contract[0],
                      link.replace(/[^/]+(?=\?)/g, name),
                      contract[2],
                      name,
                      contract[4],
                    ]);
                  } else {
                    console.log(link);
                  }
                });
                break;
              }
            }
          }
        });
        contracts.forEach((contract: any) => {
          if (!contract.ContractUrl) contract["ContractUrl"] = "N/A";
        });
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (err) {
    console.log(err);
  }

  return contracts;
};

export const AddContract = (contract: any) =>
  new Promise(async (resolve, reject) => {
    var evaluationInfo: Record<string, any> = {};
    Object.entries(contract).forEach(([key, value]) => {
      if (key.includes("Impr") || key.includes("Comment")) {
        evaluationInfo[key] = value;
        delete contract[key];
      }
    });

    var payload = {
      contract: contract,
    };

    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      },
    };

    var id;
    axios
      .post(url + "solutions/contract/add", payload, config)
      .then(async (res) => {
        resolve(res);
        
        id = res.data;
        var newPayload = {
          evaluation: evaluationInfo,
          id: id,
        };

        if (Object.keys(evaluationInfo).length > 0) {
          await axios
            .post(url + "solutions/contract/add-evaluation", newPayload, config)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status == 403) {
          reject(403);
        } else if (err.response.status == 401) {
          reject(401);
        }
      });
  });

export const AddContractFiles = (files: Array<File>, id: number) =>
  new Promise(async (resolve, reject) => {
    var formData = new FormData();

    for (var i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }

    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
        Id: id,
      },
    };

    axios
      .post(url + "solutions/contract/add-file", formData, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status == 403) {
          reject(403);
        } else if (err.response.status == 401) {
          reject(401);
        }
      });
  });

export const EditContract = (contract: any) =>
  new Promise(async (resolve, reject) => {
    var payload = {
      contract: contract,
    };

    console.log(contract);

    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      },
    };

    axios
      .post(url + "solutions/contract/edit", payload, config)
      .then(async (res) => {
        resolve(res);
        await axios
          .post(url + "solutions/contract/edit-evaluation", payload, config)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        if (err.response.status == 403) {
          reject(403);
        } else if (err.response.status == 401) {
          reject(401);
        }
      });
  });

export const EditContractDocuments = (
  id: number,
  files: Array<File>,
  urls: Array<string>,
) =>
  new Promise(async (resolve, reject) => {
    var formData = new FormData();

    for (var i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }

    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
        Id: id,
        Urls: urls,
      },
    };

    axios
      .post(url + "solutions/contract/edit-files", formData, config)
      .then((res) => {
        console.log(res);
      });
  });

export const DeleteContractDocuments = (files: Array<any>) =>
  new Promise(async (resolve, reject) => {
    var payload = {
      documents: files,
    };

    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      },
    };

    axios
      .post(url + "solutions/contract/delete-files", payload, config)
      .then((res) => {
        console.log(res);
      });
  });

export const GetEvaluationKPIs = (id: number) =>
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
        Id: id,
      },
    };

    axios
      .get(url + "contract/evaluation", config)
      .then((res) => {
        resolve(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  });

export const GetTaskCharts = () =>
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      },
    };

    axios
      .get(url + "solutions/get-task-charts", config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });

export const ArchiveDeleteTaskCharts = (action: string, id: number) => 
  new Promise(async (resolve, reject) => {
  var config = {
    headers: {
      Authorization: `Bearer ${rawToken}`,
    },
  };

  var payload = {
    action: action,
    id: id,
  };

  axios
    .post(url + "solutions/chart/archive-delete-chart", payload, config)
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
})

export const GetSpecificChart = (id: Number) =>
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      },
    };

    axios
      .get(url + `solutions/get-chart/${id}`, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });

export const GetUserProjects = (user: string) => 
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
        user: user,
      },
    }    

    axios
    .get(url + `solutions/get-user-projects`, config)
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      console.log(err);
    });    
  })

export const GetChartProjects = (id: Number | null) =>
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      },
    };

    axios
      .get(url + `solutions/get-chart-projects/${id}`, config)
      .then((res) => {        
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });

export const GetProjectSubitems = (id: Number) =>
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      },
    };

    axios
      .get(url + `solutions/get-project-subitems/${id}`, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });

export const CreateTaskChart = (
  title: string,
  author: string,
  date: string,
  authorPhoto: string,
  copyChart: number | null = null,
) =>
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      },
    };

    var payload = {
      title: title,
      author: author,
      date: date,
      authorPhoto: authorPhoto,
      copyChart: copyChart,
    };

    axios
      .post(url + "solutions/create-task-chart", payload, config)
      .then((res) => {        
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
      });
  });

export const AddProject = (project: any) =>
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      },
    };

    var payload = {
      project: project,
    };

    axios.post(url + "solutions/add-project", payload, config).then((res) => {
      resolve(res);
    });
  });

export const UpdateStatusSticker = (
  id: number,
  column: string,
  value: string,
) =>
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      },
    };
    
    var payload = {
      id: id,
      column: column,
      value: value,
    };

    axios
      .post(url + "solutions/update-status-sticker", payload, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
      });
  });

export const UpdateProject = (
  projectId: number | null,
  chartId: number,
  column: string,
  value: string,
) =>
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      },
    };
    
    var payload = {
      projectId: projectId,
      chartId: chartId,
      column: column,
      value: value,
    };

    axios
      .post(url + "solutions/update-project", payload, config)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  });

export const AddProjectSubitem = (subitem: any) =>
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      },
    };

    var payload = {
      subitem: subitem,
    };

    axios.post(url + "solutions/add-subitem", payload, config).then((res) => {
      resolve(res);
    });
  });

export const UpdateProjectSubitem = (
  id: any,
  projectId: any,
  column: any,
  value: any,
) =>
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      },
    };

    var payload = {
      subitemId: id,
      projectId: projectId,
      column: column,
      value: value,
    };

    axios
      .post(url + "solutions/update-project-subitem", payload, config)
      .then((res) => {
        resolve(res);
      });
  });

export const AddComment = (
  comment: string,
  user: string,
  userPhoto: string,
  time: Date,
  projId: number | null,
  chartId: number | null,
  db: string,
) =>
  new Promise(async (resolve, reject) => {
    var payload = {
      Comment: comment,
      User: user,
      UserPhoto: userPhoto,
      Time: time,
      ProjId: projId,
      ChartId: chartId,
      Database: db
    };    

    axios
      .post(url + "solutions/chart/add-comment", payload, {
        headers: {
          Authorization: `Bearer ${rawToken}`,
        },
      })
      .then((res) => {
        resolve(res);
      });
  });

export const GetComments = (chartId: string | null, db: string) =>
  new Promise(async (resolve, reject) => {
    
    axios
      .get(url + "solutions/chart/get-comments", {
        headers: {
          Authorization: `Bearer ${rawToken}`,
          ChartId: chartId,
          Database: db
        },
      })
      .then((res) => {
        resolve(res.data);
      });
  });

export const DeleteProject = (ids: number[], type: string) =>
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
        ProjectIds: ids.join(" "),
        Type: type,
      },
    };

    axios.delete(url + "solutions/delete-project", config).then((res) => {
      resolve(res);
    });
  });

  export const DeleteRequest = (ids: number[]) =>
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
        RequestIds: ids.join(" "),        
      },
    };

    axios.delete(url + "solutions/delete-vacation-request", config).then((res) => {
      resolve(res);
    });
  });


export const UserEvent = (event: string | null, auth: any) =>
  new Promise(async (resolve, reject) => {
    var payload = {
      Event: event,
      Auth: {
        id: auth.user[0],
        name: auth.user[1] + auth.user[2],
      },
    };

    axios
      .post(url + "solutions/user-event", payload, {
        headers: {
          Authorization: `Bearer ${rawToken}`,
        },
      })
      .then((res) => {
        resolve(res);
      });
  });

export const GetRecentActivity = (auth: any) =>
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
        UserId: auth.user[0],
      },
    };

    axios
      .get(url + "solutions/get-recent-activity", config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });

export const GetHistoricalData = (type: string) =>
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
        Type: type,
      },
    };

    axios
      .get(url + "solutions/get-historical-data", config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });

  export const GetLeadCharts = () =>
  new Promise(async (resolve, reject) => {
    await axios
      .get(url + "solutions/get-lead-charts", {
        headers: {
          Authorization: `Bearer ${rawToken}`,          
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
});

  export const GetAllLeads = () =>
  new Promise(async (resolve, reject) => {
    await axios
      .get(url + "solutions/get-leads", {
        headers: {
          Authorization: `Bearer ${rawToken}`,          
        },
      })
      .then((res) => {        
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
});

export const CreateNewLeadChart = (user: string, userPhoto: string) =>
new Promise(async (resolve, reject) => {
  const payload = {
    User: user,
    Photo: userPhoto
  }

  await axios
    .post(url + "solutions/create-new-lead-chart", payload, {
      headers: {
        Authorization: `Bearer ${rawToken}`,          
      },
    })
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      console.log(err);
      reject(err);
    });
});

export const UpdateLeads = (id: number, column: string, value: string | number | Date | null) => {
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      },
    };

    var payload = {
      id: id,
      column: column,
      value: value
    };

    axios
      .post(url + "solutions/update-leads", payload, config)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  });
}

  export const AddLead = (category: string | undefined, orderId: number) =>
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,        
      },
    };

    var payload = {
      category: category,
      orderId: orderId
    };

    axios.post(url + "solutions/add-new-lead", payload, config)
    .then((res) => {
      resolve(res);
    });
  });

  export const DeleteLead = (ids: number[]) => 
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
        Ids: ids.join(" "),
      }
    }

    axios.delete(url + "solutions/delete-lead", config)
    .then((res => {
      resolve(res);
    }))
  })

  export const UpdateLeadChartTitle = (title: string, id: number | undefined) => 
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,       
      }
    }

    var payload = {
      title: title,
      id: id,
    }

    axios.post(url + "solutions/update-chart-title", payload, config)
    .then((res => {
      resolve(res);
    }))
  })

  export const GetVacationRequests = () => 
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      }
    }

    axios.get(url + "solutions/get-vacation-requests", config)
    .then((res) => {
      resolve(res.data);
    })
  })

  export const GetVacationTables = () => 
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      }
    }

    axios.get(url + "solutions/get-vacation-tables", config)
    .then((res) => {
      resolve(res.data);
    })
  })

  export const NewVacationRequestAndTable = (data: any, type: string) => 
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      }
    }

    var payload = {
      data: data,
      type: type,
    }

    axios.post(url + "solutions/new-vacation-request-and-table", payload, config)
    .then((res: any) => {
      resolve(res);
    });
  })

  export const NewHolidayTable = () => 
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      }
    }

    axios.post(url + "solutions/new-holiday-table", config)
    .then((res: any) => {
      resolve(res.data);
    });
  })

  export const UpdateVacationTable = (id: number, column: string, value: any, db: string, name: string | null = null) => 
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      }
    }

    var payload = {
      id: id,
      column: column,
      value: value,
      db: db,
      name: name
    }

    axios.post(url + "solutions/update-vacation-table", payload, config)
    .then((res: any) => {
      resolve(res.data);
    });
  })

  export const GetStatuses = (db: string) => 
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
        db: db,
      }
    }

    axios.get(url + "solutions/get-statuses", config)
    .then((res) => {
      resolve(res.data);
    })
  })

  export const AddNewStatus = (status: string, category: string, color: string) => 
  new Promise(async (resolve, reject) => {
    var config = {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      }
    }

    var payload = {
      status: status,
      category: category,
      color: color
    }

    axios.post(url + "solutions/add-new-status", payload, config)
    .then((res) => {
      resolve(res.data);
    })
  })

export const GetOnboardingChart = (
  id: string | null = null,
  table: string,
) => 
  new Promise(async (resolve, reject) => {       

    await axios.get(url + "solutions/get-onboarding-chart", {
      headers: {
        Authorization: `Bearer ${rawToken}`,
        id: id,
        table: table
      }
    })
    .then(res => {      
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    })
  })

export const CreateOnboardingTableAndChart = (
  title: string,
  chartId: string | null,
  color: string | null,
  chartToCopy: number | null
) => 
  new Promise(async (resolve, reject) => {
    var payload = {
      title: title,
      chartId: chartId,
      color: color,
      copyChart: chartToCopy
    }

    await axios.post(url + "solutions/create-onboarding-table-and-chart", payload, {
      headers: {
        Authorization: `Bearer ${rawToken}`,
      }
    })
    .then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    })
  })

  export const ChangeOnboardingOrder = async (data: any) => {
    let payload = {
      data: data
    }

    await axios.post(url + "solutions/change-onboarding-order", payload, {
      headers: {
        Authorization: `Bearer ${rawToken}`
      }
    })

  }

  export const CreateOnboardingItem = (
    title: string,
    chartId: string,
    tableId: string,
  ) => 
    new Promise(async (resolve, reject) => {
      var payload = {
        title: title,
        chartId: chartId,
        tableId: tableId
      }
  
      await axios.post(url + "solutions/create-onboarding-item", payload, {
        headers: {
          Authorization: `Bearer ${rawToken}`,
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      })
    })
  
  export const DeleteOnboardingItems = (
    ids: string | number,
    table: string | null = null
  ) => 
    new Promise(async (resolve, reject) => {      
      await axios.delete(url + "solutions/delete-onboarding-items", {
        headers: {
          Authorization: `Bearer ${rawToken}`,
          Ids: ids,
          table: table
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err);
      })
    })

  export const UpdateOnboardingItem = (
    value: string | null,
    column: string,
    id: number
  ) => 
    new Promise(async (resolve, reject) => {
      var payload = {
        value: value,
        column: column,
        id: id
      }      

      await axios.post(url + "solutions/update-onboarding-item", payload, {
        headers: {
          Authorization: `Bearer ${rawToken}`,
        }
      })
      .then(res => {
        resolve(res)
      })
    })

  export const UploadItemFiles = (
    files: Array<File> | string, // string or array, because sometimes links to files are added instead of the files themselves
    id: number,
    type: string,
    category: number,
    linkFileName: string | null = null,
    db: string,
    tableId: number | null = null
  ) =>
    new Promise(async (resolve, reject) => {
      var formData = new FormData();

      if (typeof files == 'string') {
        formData.append("file", files);
      }
      else { // have to use typeof bc typescript will complain if I try to use the type variable
        files.forEach((file: File, i: number) => {
          formData.append(`file-${i + 1}`, file);
        })
      }

      await fetch(url + "solutions/upload-item-files", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${rawToken}`,
          Id: id.toString(),
          Type: type,
          Category: category.toString(),
          linkFileName: linkFileName ? linkFileName : "",
          DB: db,
          TableId: tableId == null ? "null" : tableId.toString()
        },
        body: formData
      })
      .then((res: any) => {
        resolve(res)
      })
      .catch((err) => {
        reject("Error")
      })
    })
  

  export const DeleteItemFiles = (ids: Array<number>, db: string) => 
    new Promise(async (resolve, reject) => {        
      var idsToString = ids.join(' ');

      await axios
        .delete(url + "solutions/delete-item-files", {
          headers: {
            Authorization: `Bearer ${rawToken}`,
            Files: idsToString,
            db: db     
          },
        })
        .then((res) => {      
          resolve(res);
        });
  })

  export const UpdateOnboardingTable = (
    value: string | null,
    column: string,
    id: number
  ) => 
    new Promise(async (resolve, reject) => {
      var payload = {
        value: value,
        column: column,
        id: id
      }

      await axios.post(url + "solutions/update-onboarding-table", payload, {
        headers: {
          Authorization: `Bearer ${rawToken}`,
        }
      })
      .then(res => {        
        resolve(res)
      })
    })
/*
    // Working on the assumption this works until proven otherwise!
    export const DeleteOnboardingTable = (id: number) => {
      console.log("Trying to delete a table");
      axios.delete(url + "solutions/delete-onboarding-table", {
        headers: {
          ID: id,
        },
        })
      .catch((err) => {
        console.log(err);
      });
    };
*/
  export const DeleteOnboardingChart = (id: number) =>
    new Promise(async (resolve, reject) => {
      var config = {
        headers: {
          Authorization: `Bearer ${rawToken}`,
          ChartId: id
        },
      };

      axios.delete(url + "solutions/delete-onboarding-chart", config)
      .then((res) => {
        resolve(res);
      });
    });

  export const ChangeUserRole = (roleId: number, userId: number) => 
    new Promise(async (resolve, reject) => {
      var config = {
        headers: {
          Authorization: `Bearer ${rawToken}`,
        }
      }

      var payload = {
        UserId: userId,
        RoleId: roleId,
      }

      axios.post(url + "solutions/change-user-role", payload, config)
      .then((res: any) => {
        resolve(res);
      })
    })

  export const DeleteUser = (userId: number) =>
    new Promise(async (resolve, reject) => {
      var config = {
        headers: {
          Authorization: `Bearer ${rawToken}`,
          UserId: userId
        },
      };

      axios.delete(url + "solutions/delete-user", config)
      .then((res) => {
        resolve(res);
      });
    });

  export const HandleNewRequest = (role: number, userId: number) =>
    new Promise(async (resolve, reject) => {
      var payload = {
        role: role,
        userId: userId
      };
  
      await axios
        .post(url + "solutions/handle-new-request", payload, {
          headers: {
            Authorization: `Bearer ${rawToken}`,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
  });

  export const GetAllowedPages = (    
  ) => 
    new Promise(async (resolve, reject) => {       
  
      await axios.get(url + "solutions/get-allowed-pages", {
        headers: {
          Authorization: `Bearer ${rawToken}`,          
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      })
    })

  export const ChangeUserAccess = (allowedPages: any) => 
    new Promise(async (resolve, reject) => {
      let jsonData = JSON.stringify(allowedPages)
    
      var payload = {
        AllowedPages: jsonData
      }

      await axios.post(url + "solutions/change-user-access", payload, {
        headers: {
          Authorization: `Bearer ${rawToken}`,
        }
      })
      .then((res) => {
        resolve(res)
      })
    })

  export const GetProcessTables = (    
    ) => 
      new Promise(async (resolve, reject) => {       
    
        await axios.get(url + "solutions/get-process-tables", {
          headers: {
            Authorization: `Bearer ${rawToken}`,          
          }
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        })
    })

  export const NewProcessTable = (title: any, createdBy: number) => 
    new Promise(async (resolve, reject) => {
      var payload = {
        title: title,
        createdBy: createdBy
      }

      await axios.post(url + "solutions/new-process-table", payload, {
        headers: {
          Authorization: `Bearer ${rawToken}`,
        }
      })
      .then((res) => {
        resolve(res)
      })
    })
  
  export const AddProcessInfo = (type: string) => {
    new Promise(async (resolve, reject) => {
      var payload = {
        type: type,       
      }

      await axios.post(url + "solutions/add-process-info", payload, {
        headers: {
          Authorization: `Bearer ${rawToken}`,
        }
      })
      .then((res) => {
        resolve(res)
      })
    })
  }

  export const AddProcessItems = (tableId: number, itemId: number | null, title: string, type: string, step: number) => {
    return new Promise(async (resolve, reject) => {
      var payload = {
        tableId: tableId,
        itemId: itemId,
        title: title,
        type: type,
        step: step
      }

      await axios.post(url + "solutions/add-process-items", payload, {
        headers: {
          Authorization: `Bearer ${rawToken}`,
        }
      })
      .then((res) => {
        resolve(res)
      })
    })
  }

  export const DeleteProcessItem = (id: number | Array<number>, type: string) =>
    new Promise(async (resolve, reject) => {      
      var ids = typeof id == 'number' ? id : id.join(" ");      

      await axios
        .delete(url + "solutions/delete-process-item", {
          headers: {
            Authorization: `Bearer ${rawToken}`,
            Type: type,
            Id: ids
          },
        })
        .then((res) => {
          resolve(res);
        });
    });
  
  export const ChangeProcessOrder = (type: string, id: number, step: number) => {
    return new Promise(async (resolve, reject) => {
      let payload = {
        Type: type,
        Id: id,
        Step: step
      }

      await axios.post(url + "solutions/change-process-order", payload, {
        headers: {
          Authorization: `Bearer ${rawToken}`,          
        }
      })
      .then((res) => {
        resolve(res)
      })
    })
  }

  export const AddNewClientContact = (clientData: any) => {
    return new Promise(async (resolve, reject) => {
      let payload = clientData;

      await axios.post(url + "solutions/new-client-contact", payload, {
        headers: {
          Authorization: `Bearer ${rawToken}`
        }
      })
      .then((res: any) => {
        resolve(res)
      })
      .catch(err => {
        console.log(err);
        reject(err);
      })
    })
  }

  export const GetUserAbilities = (page: number) => {
    return new Promise(async (resolve, reject) => {
      await axios.get(url + "solutions/get-user-abilities", {
        headers: {
          Authorization: `Bearer ${rawToken}`,
          Page: page
        }
      })
      .then((res: any) => {
        resolve(res)
      })
      .catch(err => {
        console.log(err);
        reject(err);
      })
    })
  }

  export const GetContacts = () => {
    return new Promise(async (resolve, reject) => {
      await axios.get(url + "solutions/get-contacts", {
        headers: {
          Authorization: `Bearer ${rawToken}`,          
        }
      })
      .then((res: any) => {
        resolve(res.data)
      })
      .catch(err => {
        console.log(err);
        reject(err);
      })
    })
  }

  export const UploadContactFile = (file: File) => {
    return new Promise(async (resolve, reject) => {
      var formData = new FormData();
      formData.append("file", file);
  
      try {
        const res = await fetch(url + "solutions/upload-contact-file", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${rawToken}`,            
          },
          body: formData,
        });
  
        if (!res.ok) {
          reject(500);
        } else {          
          resolve(200);
        }
      } catch (err) {
        console.log(err);
        reject(err);
      }
    })
  }

  export const DeleteContacts = (ids: Array<number>) =>
    new Promise(async (resolve, reject) => {      
  
      await axios
        .delete(url + "solutions/delete-contacts", {
          headers: {
            Authorization: `Bearer ${rawToken}`,
            Ids: ids.join(", ")
          },
        })
        .then((res) => {
          resolve(res.data);
        });
    });

