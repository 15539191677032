import React, { useContext, useState, useEffect, useRef } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import { GetContracts } from "../../services/authServices";
import styles from "./Database.module.scss";
import { ChevronDown } from "react-feather";
import cx from "classnames";
import columns from "./Columns";
import { Filter, Minimize2 } from "react-feather";
import ContractsTable from "./ContractsTable.js";
import ExpandWhite from "../../assets/media/expand-white.png";
import ExpandBlack from "../../assets/media/expand-black.png";
import XIcon from "../../assets/media/x-icon.png";

const ContractDatabaseView = () => {
  const [unfilteredRows, setUnfilteredRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [average, setAverage] = useState(null);
  const [averageGrade, setAverageGrade] = useState(null);
  const [filterMenu, setFilterMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState({
    AnnualNetSpend: {
      min: -Infinity,
      max: Infinity,
    },
    MaxFees: {
      min: -Infinity,
      max: Infinity,
    },
    ContractScore: {
      min: -Infinity,
      max: Infinity,
    },
    DateOfReview: {
      months: [],
      years: [],
    },
    StartDate: {
      months: [],
      years: [],
    },
  });
  const [loading, setLoading] = useState(false);
  var theme = useContext(ThemeContext);

  const getContracts = async () => {
    setLoading(true);
    var test = [];
    await GetContracts().then((res) => {
      test = [...res];
    });

    var contractScoreSum = 0;
    test.forEach((row) => {
      contractScoreSum += row.ContractScore;
      Object.entries(row).forEach(([key, value]) => {
        var column = columns[key];
        if (column) {
          if (column.filter) {
            if (!column.filter.includes(value)) {
              column.filter = [...column["filter"], value];
            }
          } else {
            column["filter"] = [value];
          }
        }
      });
    });

    var averageCalculation = Math.round(contractScoreSum / test.length);
    var avg;
    if (averageCalculation < 39) {
      avg = "Poor";
    } else if (averageCalculation < 59) {
      avg = "Average";
    } else if (averageCalculation < 80) {
      avg = "Strong";
    }
    setAverage(averageCalculation);
    setAverageGrade(avg);
    setUnfilteredRows(test);
    setRows(test);
    setLoading(false);
  };

  useEffect(() => {
    getContracts();
  }, []);

  // useEffect(() => {
  //     console.log(filters.DateOfReview)
  // }, [filters])

  const handleFilterChange = (filterName, filterValue, minOrMax) => {
    if (filters[filterName]) {
      if (filterName == "DateOfReview" || filterName == "StartDate") {
        var type = typeof filterValue == "number" ? "years" : "months";

        if (!filters[filterName][type].includes(filterValue)) {
          setFilters((prev) => ({
            ...prev,
            [filterName]: {
              ...prev[filterName],
              [type]: [...prev[filterName][type], filterValue],
            },
          }));
        } else {
          // console.log(filtersfilterName.years)
          var removed =
            filters[filterName][type].filter((x) => x !== filterValue) || [];
          setFilters((prev) => ({
            ...prev,
            [filterName]: {
              ...prev[filterName],
              [type]: removed,
            },
          }));
        }
      } else if (
        filterName == "AnnualNetSpend" ||
        filterName == "MaxFees" ||
        filterName == "ContractScore"
      ) {
        var def = minOrMax == "max" ? Infinity : -Infinity;

        setFilters((prev) => ({
          ...prev,
          [filterName]: {
            ...prev[filterName],
            [minOrMax]: filterValue != "" ? parseInt(filterValue) : def,
          },
        }));
      } else if (filters[filterName].includes(filterValue)) {
        var deleted = filters[filterName].filter((item) => {
          return item !== filterValue;
        });
        if (!deleted.length) {
          const newFilters = { ...filters };
          delete newFilters[filterName];
          setFilters(newFilters);
        } else {
          setFilters((prev) => ({
            ...prev,
            [filterName]: deleted,
          }));
        }
      } else {
        setFilters((prev) => ({
          ...prev,
          [filterName]: [...filters[filterName], filterValue],
        }));
      }
    } else {
      setFilters((prev) => ({
        ...prev,
        [filterName]: [filterValue],
      }));
    }
  };

  const applyFilters = (data) => {
    var filteredData = data;

    Object.entries(filters).forEach(([key, value]) => {
      filteredData = filteredData.filter((row) => {
        if (key == "DateOfReview" || key == "StartDate") {
          var newDate = new Date(row[key]);
          var month = newDate.toLocaleDateString("default", { month: "short" });
          var year = newDate.getFullYear();

          if (
            (value.years.includes(year) || !value.years.length) &&
            (value.months.includes(month) || !value.months.length)
          ) {
            return row[key];
          }
        } else if (
          key == "AnnualNetSpend" ||
          key == "MaxFees" ||
          key == "ContractScore"
        ) {
          return row[key] >= value.min && row[key] <= value.max;
        } else {
          return value.includes(row[key]);
        }
      });
    });

    return filteredData;
  };

  useEffect(() => {
    if (unfilteredRows.length) {
      var filteredRows = applyFilters(unfilteredRows);
      setRows(filteredRows);
    }
  }, [filters]);

  const handleBackdropClick = (e) => {
    if (e.target == e.currentTarget) {
      setExpanded(false);
    }
  };

  const handleMenu = (e) => {
    setFilterMenu((prev) => !prev);
    if (anchorEl == null) {
      setAnchorEl(e.currentTarget);
    }
  };

  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>
      <h1>Contract Database</h1>
      {averageGrade !== null ? (
        <div className={cx(styles.metrics, theme.darkMode && styles.darkMode)}>
          <div
            className={cx(styles.metricCard, theme.darkMode && styles.darkMode)}
          >
            <p>Number of Contracts</p>
            <h1>{rows.length}</h1>
          </div>
          <div
            className={cx(styles.metricCard, theme.darkMode && styles.darkMode)}
          >
            <p>Total Global Benchmark</p>
            <h1>{average}%</h1>
          </div>
          <div
            className={cx(styles.metricCard, theme.darkMode && styles.darkMode)}
          >
            <p>Average Contract Grade</p>
            <h1>{averageGrade}</h1>
          </div>
        </div>
      ) : (
        <div
          className={cx(styles.skeletonCard, theme.darkMode && styles.darkMode)}
          style={{ height: "130px", marginBottom: '30px' }}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "3px",
          alignItems: "flex-end",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            width: "70%",
          }}
        >
          {Object.keys(filters).length !== 0 && (
            <>
              <p style={{ margin: "0 0 3px 0", fontSize: "0.75rem" }}>
                Filters:&nbsp;
              </p>
              {Object.entries(filters).map(([key, value]) => {
                if (key == "StartDate" || key == "DateOfReview") {
                  if (value.months.length || value.years.length)
                    return (
                      <div
                        className={cx(
                          styles.filterDisplay,
                          theme.darkMode && styles.darkMode,
                        )}
                      >
                        <p>{key.match(/[A-Z][a-z]+/g).join(" ")}:&nbsp;</p>
                        {value.months.length > 0 && (
                          <>
                            <p>Months -</p>
                            {value.months.map((item, index) => {
                              return (
                                <div
                                  className={styles.filterName}
                                  onClick={() => handleFilterChange(key, item)}
                                >
                                  <p>{item}&nbsp;</p>
                                  <p className={styles.xIcon}>X</p>
                                </div>
                              );
                            })}
                          </>
                        )}
                        {value.years.length > 0 && (
                          <>
                            <p>Years -</p>
                            {value.years.map((item, index) => {
                              return (
                                <div
                                  className={styles.filterName}
                                  onClick={() => handleFilterChange(key, item)}
                                >
                                  <p>{item}&nbsp;</p>
                                  <p className={styles.xIcon}>X</p>
                                </div>
                              );
                            })}
                          </>
                        )}
                      </div>
                    );
                } else if (
                  key == "AnnualNetSpend" ||
                  key == "MaxFees" ||
                  key == "ContractScore"
                ) {
                  if (value.min > -Infinity || value.max < Infinity) {
                    return (
                      <div
                        className={cx(
                          styles.filterDisplay,
                          theme.darkMode && styles.darkMode,
                        )}
                      >
                        <p>{key.match(/[A-Z][a-z]+/g).join(" ")}:&nbsp;</p>
                        {value.min > -Infinity && (
                          <div
                            className={styles.filterName}
                            onClick={() => handleFilterChange(key, "", "min")}
                          >
                            <p>Min - €{value.min.toLocaleString()}&nbsp;</p>
                            <p className={styles.xIcon}>X</p>
                          </div>
                        )}
                        {value.max < Infinity && (
                          <div
                            className={styles.filterName}
                            onClick={() => handleFilterChange(key, "", "max")}
                          >
                            <p>Max - €{value.max.toLocaleString()}&nbsp;</p>
                            <p className={styles.xIcon}>X</p>
                          </div>
                        )}
                      </div>
                    );
                  }
                } else {
                  return (
                    <div
                      className={cx(
                        styles.filterDisplay,
                        theme.darkMode && styles.darkMode,
                      )}
                    >
                      <p>{key.match(/[A-Z][a-z]+/g).join(" ")}:&nbsp;</p>
                      {value.map((item, index) => {
                        return (
                          <div
                            className={styles.filterName}
                            onClick={() => handleFilterChange(key, item)}
                          >
                            <p>{item}&nbsp;</p>
                            <p className={styles.xIcon}>X</p>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              })}
            </>
          )}
        </div>
        <img
          src={theme.darkMode ? ExpandWhite : ExpandBlack}
          style={{ height: "20px", width: "20px" }}
          onClick={() => setExpanded((prev) => !prev)}
        />
      </div>
      {loading ? (
        <div
          className={cx(styles.skeletonCard, theme.darkMode && styles.darkMode)}
          style={{ minHeight: "80vh" }}
        />
      ) : (
        <ContractsTable
          filters={filters}
          handleFilterChange={handleFilterChange}
          columns={columns}
          rows={rows}
          height="80vh"
          padding="10px"
        />
      )}
      <div
        style={{ display: expanded ? "flex" : "none" }}
        className={styles.expandedTable}
        onClick={handleBackdropClick}
      >
        <div
          style={{
            display: "flex",
            width: "95vw",
            justifyContent: "flex-end",
            marginBottom: "3px",
            color: theme.darkMode ? "#FFFFFF" : "#FFFFFF",
          }}
        >
          <Filter className={styles.icon} onClick={(e) => handleMenu(e)} />
          <Minimize2
            className={styles.icon}
            style={{ marginLeft: "10px" }}
            onClick={() => setExpanded((prev) => !prev)}
          />
        </div>
        <ContractsTable
          filters={filters}
          handleFilterChange={handleFilterChange}
          columns={columns}
          rows={rows}
          width="95vw"
          height="90vh"
          padding="5px"
        />
      </div>
    </div>
  );
};

export default ContractDatabaseView;
