import React, { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import styles from "./MPQView.module.scss";
import cx from "classnames";

const MPQView = () => {
  const theme = useContext(ThemeContext);

  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h1 style={{ margin: 0, fontFamily: "PoppinsLight" }}>
            MPQ Benchmarks
          </h1>
          <a href="/dashboard/automations/?automation=mpq-automation">
            <button
              className={cx(
                styles.automationButton,
                theme.darkMode && styles.darkMode,
              )}
            >
              Automation
            </button>
          </a>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default MPQView;
