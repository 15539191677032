import React, { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { ThemeContext } from "../../contexts/ThemeContext";
import styles from "./AccountSettings.module.scss";
import PersonalForm from "./PersonalForm";
import SecurityForm from "./SecurityForm";
import ChangePicture from "../../common/components/ChangePicture/ChangePicture";
import cx from "classnames";

const AccountSettingsView = () => {
  const [open, setOpen] = useState(false);
  const context = useContext(AuthContext);
  const themeContext = useContext(ThemeContext);

  var initials =
    context.user[1].split("").shift() + context.user[2].split("").shift();

  var role = "";
  switch (context.user[7]) {
    case 0:
      role = "You're not supposed to be here";
      break;
    case 1:
      role = "User";
      break;
    case 2:
      role = "Admin";
      break;
  }

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div
          className={cx(
            styles.topCard,
            themeContext.darkMode && styles.darkMode,
          )}
        >
          {context.user[6] !== null ? (
            <div
              className={styles.pfp}
              onClick={() => setOpen(true)}
              style={{
                backgroundImage: `url(${context.user[6]})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            ></div>
          ) : (
            <div
              onClick={() => setOpen(true)}
              className={styles.pfp}
              style={{ backgroundColor: "#F2D680" }}
            >
              <h1>{initials}</h1>
            </div>
          )}
          <div className={styles.pfpText}>
            <h1>
              {context.user[1]} {context.user[2]}
            </h1>
            <h3 style={{ color: "#00C1FF", margin: 0 }}>{role}</h3>
          </div>
          <button onClick={() => setOpen(true)} className={styles.updateButton}>
            Update photo
          </button>
        </div>
        <ChangePicture open={open} handleClose={() => setOpen(false)} />
        <div
          className={cx(
            styles.bottomCard,
            themeContext.darkMode && styles.darkMode,
          )}
        >
          <PersonalForm />
          <SecurityForm />
        </div>
      </div>
    </div>
  );
};

export default AccountSettingsView;
