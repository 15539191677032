import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Dialog } from "@mui/material";
import styles from "./AddAVBForm.module.scss";
import { countries, agencies, budgets } from "./Info";
import {
  AddBenchmarks,
  GetSpecificColumns,
  AddBenchmarkValues,
} from "../../services/authServices";
import CircleLogo from "../../assets/media/circle-logo.png";

const AddAVBForm = () => {
  const [displayError, setDisplayError] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);

  const submitInfo = async (values: any) => {
    var generalInfo = [];
    var keys = Object.keys(values);

    for (var i = 0; i <= 8; i++) {
      if (values[keys[i]] == "Yes") {
        values[keys[i]] = 1;
      }
      if (values[keys[i]] == "No") {
        values[keys[i]] = 0;
      }

      generalInfo.push(values[keys[i]]);
    }

    var id: any = -1;
    await AddBenchmarks(generalInfo).then((res) => {
      id = res;
    });

    var values: any = {
      AvbTV: [
        values.totalTv,
        values.openTv,
        values.payTv,
        values.networkTv,
        values.cableTv,
        values.snlTv,
        values.sportTv,
        values.localTv,
        values.openAztecaTv,
        values.openTelevisaTv,
        values.tvFreeSpace,
      ],
      AvbPrint: [
        values.totalPrint,
        values.newspapers,
        values.magazines,
        values.printFreeSpace,
      ],
      AvbRadio: [values.radio, values.digitalRadio, values.radioFreeSpace],
      AvbOOH: [
        values.ooh,
        values.dooh,
        values.productionCost,
        values.oohFreeSpace,
      ],
      AvbCinema: [values.cinema, values.cinemaFreeSpace],
      AvbOnline: [
        values.totalOnline,
        values.display,
        values.video,
        values.search,
        values.social,
        values.mobileBuy,
        values.pbu,
        values.specialAAEE,
        values.specificMobileData,
        values.programmaticPmp,
        values.skyWeb,
        values.programmaticDeals,
        values.programmaticGuaranteed,
        values.youtube,
        values.displayFreeSpace,
        values.videoFreeSpace,
        values.socialFreeSpace,
      ],
    };

    Object.entries(values).forEach(async (entry) => {
      const [table, avbs] = entry;
      var columnNames = "BenchmarkId,";
      var columnValues = id;
      await GetSpecificColumns(table).then(
        (res) => (columnNames += res.join(",")),
      );
      (avbs as any[]).forEach((avb: any) => {
        columnValues += avb == "" ? ",null" : `,${avb}`;
      });

      var errors = false;
      await AddBenchmarkValues(table, columnNames, columnValues)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          errors = true;
        });

      if (!errors) setDisplaySuccess(true);
      else if (errors) setDisplayError(true);
    });
  };

  const handleClose = () => {
    window.location.reload();
  };

  return (
    <>
      <Dialog
        open={displaySuccess}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "10px",
            backgroundColor: "#FFFFFF",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
            height: "25vh",
            width: "45vw",
            display: "flex",
            paddingRight: 0,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "30px",
            position: "relative",
          },
        }}
      >
        <div className={styles.topBar}>
          <img src={CircleLogo} className={styles.popupLogo} />
        </div>
        <div style={{ width: "70%" }}>
          <h2 className={styles.confirmationMessage}>
            Your AVB Benchmark has been successfully added
          </h2>
        </div>
        <button onClick={() => handleClose()} className={styles.closeButton}>
          Close
        </button>
      </Dialog>
      <div className={styles.header}>
        <h2>Add new AVB benchmark</h2>
      </div>
      <Formik
        initialValues={{
          contractId: "",
          contractName: "",
          advertiser: "",
          agency: "N/A",
          agencyGroup: "",
          avbYear: "",
          annualBudget: "",
          prepared: "No",
          country: "Various",
          totalTv: "",
          openTv: "",
          payTv: "",
          networkTv: "",
          cableTv: "",
          snlTv: "",
          sportTv: "",
          localTv: "",
          openAztecaTv: "",
          openTelevisaTv: "",
          tvFreeSpace: "",
          totalPrint: "",
          newspapers: "",
          magazines: "",
          printFreeSpace: "",
          radio: "",
          digitalRadio: "",
          radioFreeSpace: "",
          ooh: "",
          dooh: "",
          productionCost: "",
          oohFreeSpace: "",
          cinema: "",
          cinemaFreeSpace: "",
          totalOnline: "",
          display: "",
          video: "",
          search: "",
          social: "",
          mobileBuy: "",
          pbu: "",
          specialAAEE: "",
          specificMobileData: "",
          programmaticPmp: "",
          skyWeb: "",
          programmaticDeals: "",
          programmaticGuaranteed: "",
          youtube: "",
          displayFreeSpace: "",
          videoFreeSpace: "",
          socialFreeSpace: "",
        }}
        onSubmit={(values) => submitInfo(values)}
      >
        {({ values, handleChange }) => (
          <Form style={{ width: "100%", padding: "0 20px" }}>
            <li className="input-and-label" style={{ width: "47.5%" }}>
              <label htmlFor="contractId">Contract ID</label>
              <input
                className={styles.input}
                type="text"
                name="contractId"
                value={values.contractId}
                onChange={handleChange}
              />
            </li>
            <h2>General Info</h2>
            <div className={styles.firstDiv}>
              <li className="input-and-label" style={{ width: "47.5%" }}>
                <label htmlFor="contractName">Contract name</label>
                <input
                  className={styles.input}
                  type="text"
                  name="contractName"
                  value={values.contractName}
                  onChange={handleChange}
                />
              </li>
              <li className="input-and-label" style={{ width: "47.5%" }}>
                <label htmlFor="advertiser">Advertiser</label>
                <input
                  className={styles.input}
                  type="text"
                  name="advertiser"
                  value={values.advertiser}
                  onChange={handleChange}
                />
              </li>
              <li className="input-and-label" style={{ width: "47.5%" }}>
                <label htmlFor="agency">Agency</label>
                <select
                  className={styles.input}
                  name="agency"
                  value={values.agency}
                  onChange={handleChange}
                >
                  <option>N/A</option>
                  {agencies.map((agency) => {
                    return <option key={agency}>{agency}</option>;
                  })}
                </select>
              </li>
              <li className="input-and-label" style={{ width: "47.5%" }}>
                <label htmlFor="agency">Agency group</label>
                <input
                  className={styles.input}
                  type="text"
                  name="agencyGroup"
                  value={values.agencyGroup}
                  onChange={handleChange}
                />
              </li>
              <li className="input-and-label" style={{ width: "47.5%" }}>
                <label htmlFor="avbYear">AVB Year</label>
                <input
                  className={styles.input}
                  type="number"
                  name="avbYear"
                  value={values.avbYear}
                  onChange={handleChange}
                />
              </li>
              <li className="input-and-label" style={{ width: "47.5%" }}>
                <label htmlFor="annualBudget">Annual Media Budget</label>
                <select
                  className={styles.input}
                  name="annualBudget"
                  value={values.annualBudget}
                  onChange={handleChange}
                >
                  {budgets.map((budget) => {
                    return <option key={budget}>{budget}</option>;
                  })}
                </select>
              </li>
              <li className="input-and-label" style={{ width: "47.5%" }}>
                <label htmlFor="prepared">Prepared By Abintus</label>
                <select
                  className={styles.input}
                  style={{ height: "40px" }}
                  name="prepared"
                  value={values.prepared}
                  onChange={handleChange}
                >
                  <option>No</option>
                  <option>Yes</option>
                </select>
              </li>
              <li className="input-and-label" style={{ width: "47.5%" }}>
                <label htmlFor="country">Country</label>
                <select
                  className={styles.input}
                  style={{ height: "40px" }}
                  name="country"
                  value={values.country}
                  onChange={handleChange}
                >
                  {countries.map((country) => {
                    return <option key={country}>{country}</option>;
                  })}
                </select>
              </li>
            </div>
            <p>
              <strong>AVB Section</strong> - Leave fields blank if not
              applicable
            </p>
            <h2>TV AVBs</h2>
            <div
              className={styles.firstDiv}
              style={{ justifyContent: "flex-start" }}
            >
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="totalTv">Total TV %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="totalTv"
                  value={values.totalTv}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="openTv">Open TV %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="openTv"
                  value={values.openTv}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="payTv">Pay TV %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="payTv"
                  value={values.payTv}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="networkTv">Network TV %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="networkTv"
                  value={values.networkTv}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="cableTv">Cable TV %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="cableTv"
                  value={values.cableTv}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="snlTv">SNL TV %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="snlTv"
                  value={values.snlTv}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="sportTv">Sport TV %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="sportTv"
                  value={values.sportTv}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="localTv">Local TV %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="localTv"
                  value={values.localTv}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="openAztecaTv">Open Azteca TV %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="openAztecaTv"
                  value={values.openAztecaTv}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="openTelevisaTv">Open Televisa TV %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="openTelevisaTv"
                  value={values.openTelevisaTv}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="tvFreeSpace">TV Free Space %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="tvFreeSpace"
                  value={values.tvFreeSpace}
                  onChange={handleChange}
                />
              </li>
            </div>
            <h2>Print AVBs</h2>
            <div className={styles.firstDiv}>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="totalPrint">Total Print %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="totalPrint"
                  value={values.totalPrint}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="newspapers">Newspapers %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="newspapers"
                  value={values.newspapers}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="magazines">Magazines %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="magazines"
                  value={values.magazines}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="printFreeSpace">Print Free Space %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="printFreeSpace"
                  value={values.printFreeSpace}
                  onChange={handleChange}
                />
              </li>
            </div>
            <h2>Radio AVBs</h2>
            <div className={styles.firstDiv}>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="radio">Radio %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="radio"
                  value={values.radio}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="digitalRadio">Digital Radio %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="digitalRadio"
                  value={values.digitalRadio}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="radioFreeSpace">Radio Free Space %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="radioFreeSpace"
                  value={values.radioFreeSpace}
                  onChange={handleChange}
                />
              </li>
            </div>
            <h2>OOH AVBs</h2>
            <div className={styles.firstDiv}>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="ooh">OOH %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="ooh"
                  value={values.ooh}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="dooh">DOOH %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="dooh"
                  value={values.dooh}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="productionCost">Production Cost %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="productionCost"
                  value={values.productionCost}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="oohFreeSpace">OOH Free Space %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="oohFreeSpace"
                  value={values.oohFreeSpace}
                  onChange={handleChange}
                />
              </li>
            </div>
            <h2>Cinema AVBs</h2>
            <div
              className={styles.firstDiv}
              style={{ justifyContent: "flex-start" }}
            >
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="cinema">Cinema %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="cinema"
                  value={values.cinema}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="cinemaFreeSpace">Cinema Free Space %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="cinemaFreeSpace"
                  value={values.cinemaFreeSpace}
                  onChange={handleChange}
                />
              </li>
            </div>
            <h2>Online AVBs</h2>
            <div
              className={styles.firstDiv}
              style={{ marginBottom: "20px", justifyContent: "flex-start" }}
            >
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="totalOnline">Total Online %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="totalOnline"
                  value={values.totalOnline}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="display">Display %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="display"
                  value={values.display}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="video">Video %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="video"
                  value={values.video}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="search">Search %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="search"
                  value={values.search}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="social">Social %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="social"
                  value={values.social}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="mobileBuy">Mobile Buy %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="mobileBuy"
                  value={values.mobileBuy}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="pbu">PBU %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="pbu"
                  value={values.pbu}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="specialAAEE">Special AAEE %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="specialAAEE"
                  value={values.specialAAEE}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="specific">Specific Mobile Data %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="specific"
                  value={values.specificMobileData}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="programmaticPmp">Programmatic PMP %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="programmaticPmp"
                  value={values.programmaticPmp}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="skyWeb">Sky Web %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="skyWeb"
                  value={values.skyWeb}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="programmaticDeals">Programmatic Deals %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="programmaticDeals"
                  value={values.programmaticDeals}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="programmaticGuaranteed">
                  Programmatic Guaranteed %
                </label>
                <input
                  className={styles.input}
                  type="number"
                  name="programmaticGuaranteed"
                  value={values.programmaticGuaranteed}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="youtube">Youtube %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="youtube"
                  value={values.youtube}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="displayFreeSpace">Display Free Space %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="displayFreeSpace"
                  value={values.displayFreeSpace}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="videoFreeSpace">Video Free Space %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="videoFreeSpace"
                  value={values.videoFreeSpace}
                  onChange={handleChange}
                />
              </li>
              <li
                className="input-and-label"
                style={{ width: "31%", marginRight: "22px" }}
              >
                <label htmlFor="socialFreeSpace">Social Free Space %</label>
                <input
                  className={styles.input}
                  type="number"
                  name="socialFreeSpace"
                  value={values.socialFreeSpace}
                  onChange={handleChange}
                />
              </li>
            </div>
            <button className={styles.submitButton}>Submit benchmark</button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddAVBForm;
