import React, { useEffect, useState, useContext } from "react";
import Navbar from "../../common/layouts/Navbar";
import Sidebar from "../../common/layouts/Sidebar";
import styles from "./Dashboard.module.scss";
import { GetBenchmarks } from "../../services/authServices";
import ExcelViewer from "../../common/components/RecentOutput/RecentOutput";
import { AuthContext } from "../../contexts/AuthContext";
import { ThemeContext } from "../../contexts/ThemeContext";
import RecentActivity from "./RecentActivity";
import AutomationInfo from "./AutomationInfo";
import ContractInfo from "./ContractInfo";
import BenchmarkGraph from "./BenchmarkGraph";
import TaskInfo from "./TaskInfo";
import cx from "classnames";

const DashboardView = () => {
  const [active, setActive] = useState("General");
  const auth = useContext(AuthContext).user;
  const theme = useContext(ThemeContext);

  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>
      <div className={styles.topBar} style={{ width: '100%' }}>
        <h1 className={styles.title}>{auth[1]}'s Dashboard</h1>        
          <div className={styles.sliderDiv}>
            <div className={cx(styles.slider, active == "Pitch" && styles.pitch, active == "Other" && styles.other)}><p>{active}</p></div>
            <div className={styles.option} onClick={() => setActive("General")}>
              <p>General</p>
            </div>
            <div className={styles.option} onClick={() => setActive("Pitch")}>
              <p>Pitch</p>
            </div>
            <div className={styles.option} onClick={() => setActive("Other")}>
              <p>Other</p>
            </div>
        </div>        
      </div>
      <div className={cx(styles.content)} style={{ display: active == "General" ? "flex" : "none" }}>
        <ContractInfo />
        <BenchmarkGraph />
        <AutomationInfo />
        <TaskInfo />        
      </div>
      <div className={styles.content} style={{ display: active == "Pitch" ? "flex" : "none" }}>
        <p>Pitch Section</p>
      </div>
      <div className={styles.content} style={{ display: active == "Other" ? "flex" : "none" }}>
        <p>Other Section</p>
      </div>
    </div>
  );
};

export default DashboardView;
