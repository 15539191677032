import React from 'react'
import ProcessesView from '../features/processes'

const Processes = () => {
  return (
    <ProcessesView />
  )
}

export default Processes
