import React, { useEffect, useState } from 'react'
import styles from "./styles/Table.module.scss";

interface FilterMenuProps {
    values: any,
    column: string,
    handleFilters: Function,
    filters: any
}

const FilterMenu: React.FC<FilterMenuProps> = ({ values, column, filters, handleFilters }) => {  
  const [filteredValues, setFilteredValues] = useState(values)

  const filterValues = (value: string) => {
    if (value == "") {
        setFilteredValues(values);
    }
    else {
        let newValues = filteredValues.filter((val: string) => val.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
        setFilteredValues(newValues);
    }
  }  

  useEffect(() => {
    console.log(values, column)
  }, [])

  return (
    <div>
        <div>
            <input onChange={(e) => filterValues(e.currentTarget.value)} className={styles.filterSearch} placeholder='Search'/>
        </div>
        <div style={{ maxHeight: "300px", overflowY: 'scroll', paddingTop: "5px" }}>
            {
                filteredValues.map((value: any) => {
                    
                    return (
                        <div style={{ display: 'flex', alignItems: "center" }} key={value}>
                            <input 
                              onChange={() => handleFilters(value, column)} 
                              type="checkbox" 
                              checked={filters[column].includes(value)}
                              style={{ height: '15px', width: '15px', marginRight: '10px' }} 
                            />
                            <p style={{fontSize: "0.85rem" }}>{value}</p>                                  
                        </div>
                    )
                })
            }
        </div>
    </div>
  )
}

export default FilterMenu
