import React, { useContext, useState } from "react";
import styles from "./VerifyEmail.module.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { style } from "@mui/system";
import { AuthContext } from "../../contexts/AuthContext";
import {
  ConfirmVerificationCode,
  DeleteVerificationCode,
} from "../../services/authServices";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const VerifyEmailView = () => {
  const [errors, setErrors] = useState(false);
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const url = useLocation().pathname;

  const ConfirmCode = (code: string) => {
    setErrors(false);
    ConfirmVerificationCode(code, context, url)
      .then((res) => {
        DeleteVerificationCode(code);
        navigate("/dashboard");
      })
      .catch((res) => {
        setErrors(true);
      });
  };

  const initials =
    context.user[1].split("").shift() + context.user[2].split("").shift();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.formCard}>
          <div className={styles.headerInfo}>
            <div>
              <h1>Hello {context.user[1]}</h1>
              <p>
                Enter the code below that we sent to <br />
                <strong style={{ color: "#025373bf" }}>
                  {context.user[3]}
                </strong>
              </p>
            </div>
            {context.user[6] !== null ? (
              <img
                src={context.user[6]}
                style={{
                  borderRadius: "50px",
                  height: "100px",
                  width: "100px",
                }}
              />
            ) : (
              <div
                style={{
                  color: "#FFFFFF",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100px",
                  width: "100px",
                  borderRadius: 50,
                  backgroundColor: "#F2D680",
                }}
              >
                <h1>{initials}</h1>
              </div>
            )}
          </div>
          <svg width="100%" height="5px" id="divider">
            <line
              x1="0"
              y1="0"
              x2="100%"
              stroke="#000000"
              strokeWidth="3.5px"
            />
          </svg>
          <Formik
            initialValues={{ code: "" }}
            onSubmit={(values) => ConfirmCode(values.code)}
          >
            {({ values, handleChange, isSubmitting, handleSubmit }) => (
              <Form className="form-container">
                <li className="input-and-label">
                  <label htmlFor="code">Enter code</label>
                  <Field
                    className="input"
                    type="text"
                    name="code"
                    value={values.code}
                    onChange={handleChange}
                  />
                </li>
                <p
                  style={{
                    marginTop: "-20px",
                    color: "rgb(203, 10, 10)",
                    display: errors ? "block" : "none",
                  }}
                >
                  Incorrect code, please try again.
                </p>
                <button type="submit" className={styles.submitButton}>
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailView;
