import React, { useContext } from 'react'
import styles from "./styles/TrackingDemo.module.scss"
import { ThemeContext } from '../../contexts/ThemeContext'
import cx from 'classnames'

const TrackingDemoView = () => {
  const theme = useContext(ThemeContext); 
  
  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>
      <h1>Tracking Demo</h1>
        <div style={{ margin: "0 auto", height: "90%", width: "100%", position: 'relative' }}>
          <iframe 
            title="Stage Entertainment Media Performance Dashboards_20240808"
            className={styles.powerbiDash}
            width="1125"
            height="650.25"
            src="https://app.powerbi.com/view?r=eyJrIjoiM2IzNjhiMjItODc0Yy00MWVhLWFiMDgtMDUwZDhlM2M2ZDVjIiwidCI6IjBlYWI3NTgzLTk0NzEtNDI2ZS05MWEyLWVmMDc2ZmYyZGU4NyJ9"
          />
          <div className={styles.blocker}></div>
          <div className={styles.blocker2}></div>
        </div>      
    </div>
  )
}

export default TrackingDemoView
