import React, { useState, useContext, useEffect } from "react";
import { Menu } from "@mui/material";
import styles from "./PhotoCell.module.scss";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { UpdateProject } from "../../../services/authServices";
import { UpdateProjectSubitem } from "../../../services/authServices";
import AnonPhoto from "../../../assets/media/anon.jpeg";
import cx from "classnames";
import { X } from "react-feather"

const PhotoCell = (props: any) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [photo, setPhoto] = useState(props.photo);
  const [associatedPeople, setAssociatedPeople] = useState(props.person)
  const theme = useContext(ThemeContext);

  const handleMenu = (e: any) => {
    if (anchorEl == null) {
      setAnchorEl(e.currentTarget);
    }
    setOpen((prev) => !prev);
  };

  const update = (person: any) => {    
    var current = associatedPeople;    

    if (current == '') {
      current += `${person.name}`
    }
    else {
      current += `, ${person.name}`
    }        
    
    if (props.value == "Owner") {      
      setAssociatedPeople(current.trim());
      props.updateUsers(current, props.projectId, "owner", props.subitemId)
      UpdateProjectSubitem(props.subitemId, props.projectId, "Owner", current);
    }
    else {
      setAssociatedPeople(current.trim());
      UpdateProject(props.projectId, props.chartId, props.value, current);
  
      props.updateUsers(current, props.projectId, props.value)
    }

    setOpen(false);
  };

  const deletePerson = (person: string) => {
    var arr = associatedPeople.split(",");
    var newPeople = arr.filter((x: any) => x !== person);        
    setAssociatedPeople(newPeople.join(","));

    if (props.value == "Owner") {
      props.updateUsers(newPeople.join(","), props.projectId, "owner", props.subitemId)
      UpdateProjectSubitem(props.subitemId, props.projectId, "Owner", newPeople);
    }
    else {
      props.updateUsers(newPeople.join(","), props.projectId, props.value)
      UpdateProject(props.projectId, props.chartId, props.value, newPeople);
    }
  }  

  return (
    <>
      <Menu
        open={open}
        onClose={(e) => handleMenu(e)}
        anchorEl={anchorEl}
        sx={{
          "& .MuiPaper-root": {
            // height: "px",
            // width: "400px",
            backgroundColor: "#FFFFFF",
            padding: "10px 20px",            
          },
        }}
      >
        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
          <p style={{ marginRight: '10px' }}>Current:</p>
            {
              props.people !== undefined && associatedPeople !== '' && associatedPeople.split(",").map((person: any) => { 
                var personInfo = props.people.find((x: any) => x.name == person.trim());                

                return (
                  <div className={styles.currentPerson} onClick={() => deletePerson(person)}>
                    {
                      personInfo == undefined ? (
                        <img
                          src={AnonPhoto}
                          alt="img"
                          className={styles.photo}
                          style={{ marginRight: '10px' }}
                        />
                      ) : (
                        personInfo.photo == null ? (
                          <div className={styles.nameCircle} style={{ height: '30px', width: '30px' }}>
                            <p>{personInfo.name.split(" ")[0].slice(0,1)} {personInfo.name.split(" ")[1].slice(0,1)}</p>
                          </div>
                        ) : (
                          <img
                            src={personInfo.photo}
                            alt="img"
                            className={styles.photo}
                            style={{ marginRight: '10px' }}
                          />                          
                        )                        
                      )
                    }                    
                    <X style={{ color: '#CB0A0A' }} className={styles.deletePerson}/>
                  </div>
                )
              })
            }
        </div>
        <ul style={{ display: 'flex'}}>
          {props.people != undefined && props.people.map((person: any) => {                
            if (!associatedPeople.includes(person.name)) {              
              return (
                <li
                  className={styles.personList}
                  onClick={() => update(person)}
                  key={person.id}
                >
                  {
                    person.photo ? (
                      <img
                        src={person.photo}
                        alt="image"
                        className={styles.personListImage}
                      />
                    ) : (
                      <div className={styles.nameCircle}>
                        <p>{person.name.split(" ")[0].slice(0,1)} {person.name.split(" ")[1].slice(0,1)}</p>
                      </div>
                    )
                  }
                  {/* <p>{person.name}</p> */}
                </li>
              );
            }
          })}
        </ul>
      </Menu>
      <div
        className={cx(styles.container, theme.darkMode && styles.darkMode)}
        onClick={(e) => handleMenu(e)}
        style={{ minWidth: '145px' }}
      >
        {
          props.people !== undefined && (
            associatedPeople !== '' ? (
              associatedPeople.split(",").map((person: any) => { 
                var personInfo = props.people.find((x: any) => x.name == person.trim());                

                return (              
                  personInfo == undefined ? (
                    <img
                      src={AnonPhoto}
                      alt="img"
                      className={styles.photo}                      
                    />
                  ) : (
                    personInfo.photo == null ? (
                      <div className={styles.nameCircle} style={{ height: '30px', width: '30px', marginRight: '-12px' }}>
                        <p>{personInfo.name.split(" ")[0].slice(0,1)} {personInfo.name.split(" ")[1].slice(0,1)}</p>
                      </div>
                    ) : (
                      <img
                        src={personInfo.photo}
                        alt="img"
                        className={styles.photo}                        
                      />                          
                    )                        
                  )                
                )
              })
            ) : (
              <img
                src={AnonPhoto}
                alt="img"
                className={styles.photo}                      
              />
            )
          )
        }        
      </div>
    </>
  );
};

export default PhotoCell;
