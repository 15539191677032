import React, { useState, useContext } from "react";
import styles from "./Navbar.module.scss";
import { searchData } from "../helpers/searchData";
import Logo from "../../assets/media/abintus-logo-white.png";
import Sun from "../../assets/media/sun.png";
import Moon from "../../assets/media/moon.png";
import Search from "../../assets/media/search-glass.png";
import { Menu } from "@mui/material";
import { AuthContext } from "../../contexts/AuthContext";
import { Logout } from "../../services/authServices";
import { useNavigate } from "react-router-dom";
import ChangePicture from "../components/ChangePicture/ChangePicture.js";
import Switch from "@mui/material/Switch";
import { ThemeContext } from "../../contexts/ThemeContext";
import cx from "classnames";

interface NavbarProps {
  // onMobileNavOpen: () => void;
}

interface Item {
  title: string;
  href: string;
}

const Navbar: React.FC<NavbarProps> = ({}) => {
  const [open, setOpen] = React.useState<null | HTMLElement>(null);
  const [openChangePhoto, setOpenChangePhoto] = useState(false);
  const [displaySearchResults, setDisplaySearchResults] = useState(false);
  const [searchResults, setSearchResults] = useState<Item[]>([]);
  const [mouseInResults, setMouseInResults] = useState(false);
  const isMenuVisible = Boolean(open);
  const test = useContext(AuthContext);
  // This is not a good way to do this and should not be permanent - this is because the app errors out when context is deleted
  const context =
    test.user !== null ? test : { user: ["", "", "", "", "", "", "", ""] };
  const themeContext: any = useContext(ThemeContext);
  const navigate = useNavigate();
  var initials =
    context.user[1].split("").shift() + context.user[2].split("").shift();

  const changeTheme = () => {
    var currentTheme = localStorage.getItem("theme");
    localStorage.setItem("theme", currentTheme == "light" ? "dark" : "light");

    themeContext.setDarkMode((prev: any) => !prev);
  };

  var role = "";
  switch (context.user[7]) {
    case 0:
      role = "You're not supposed to be here";
      break;
    case 1:
      role = "User";
      break;
    case 2:
      role = "Admin";
      break;
  }

  const pfp = (photoUrl: string, sizing: string) => {
    if (photoUrl == "" || photoUrl == null) {
      return (
        <button
          className={styles.pfp}
          onClick={openMenu}
          style={{ marginLeft: "10px", border: "none" }}
        >
          <p>{initials}</p>
        </button>
      );
    } else {
      return (
        <button
          className={styles.pfp}
          onClick={openMenu}
          style={{
            marginLeft: "10px",
            border: "none",
            backgroundColor: "transparent",
          }}
        >
          <img
            src={photoUrl}
            style={{
              height: `${sizing}`,
              width: `${sizing}`,
              borderRadius: "50px",
              objectFit: 'cover'
            }}
          />
        </button>
      );
    }
  };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget);
  };

  const closeMenu = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    closeMenu();
    setTimeout(() => {
      navigate("/");
      Logout();
    }, 400);
  };

  const levenshteinDistance = (str1: string, str2: string) => {
    let track = Array(str2.length + 1)
      .fill(null)
      .map(() => Array(str1.length + 1).fill(null));

    for (var i = 0; i <= str1.length; i++) {
      track[0][i] = i;
    }
    for (var j = 0; j <= str2.length; j++) {
      track[j][0] = j;
    }

    for (let j = 1; j <= str2.length; j += 1) {
      for (let i = 1; i <= str1.length; i += 1) {
        const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
        track[j][i] = Math.min(
          track[j][i - 1] + 1, // deletion
          track[j - 1][i] + 1, // insertion
          track[j - 1][i - 1] + indicator, // substitution
        );
      }
    }
    return track[str2.length][str1.length];
  };

  const handlOnBlur = () => {
    if (!mouseInResults) setDisplaySearchResults(false);
  };

  const handleSearch = (term: string) => {
    if (term.trim() == "") {
      setSearchResults([]);
      setDisplaySearchResults(false);
    } else {
      let results = searchData.filter((item) => {
        return (
          levenshteinDistance(item.title.toLowerCase(), term.toLowerCase()) <=
            2 || item.title.toLowerCase().includes(term.toLowerCase())
        );
      });
      if (results.length > 0) setDisplaySearchResults(true);
      else setDisplaySearchResults(false);
      setSearchResults(results);
    }
  };

  return (
    <div
      className={cx(styles.container, themeContext.darkMode && styles.darkMode)}
    >
      <div className={styles.navbarLeft}>
        {/* <a href="/"> */}
        <img
          src={Logo}
          alt="logo"
          style={{ height: "50px", marginRight: "10px" }}
        />
        {/* </a> */}        
      </div>
      <div className={styles.searchBarContainer}>
        <img src={Search} className={styles.searchIcon} />
        <input
          placeholder="Search"
          className={cx(
            styles.searchBar,
            themeContext.darkMode && styles.darkMode,
          )}
          onChange={(e) => handleSearch(e.currentTarget.value)}
          onFocus={() => handlOnBlur()}
          onBlur={() => handlOnBlur()}
        />
        <div
          onMouseEnter={() => setMouseInResults(true)}
          onMouseLeave={() => setMouseInResults(false)}
          className={cx(
            styles.resultsContainer,
            themeContext.darkMode && styles.darkMode,
          )}
          style={{ display: displaySearchResults ? "block" : "none" }}
        >
          {
            searchResults.length > 0 &&
              searchResults.map((item, index) => {
                return (
                  <a
                    key={index}
                    href={item.href}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <li>
                      <p style={{ margin: 0 }}>{item.title}</p>
                      <p
                        style={{
                          margin: 0,
                          fontSize: "0.7rem",
                          color: themeContext.darkMode ? "#363636" : "#868686",
                        }}
                      >
                        {item.href}
                      </p>
                    </li>
                  </a>
                );
              })
            //   ) : (
            //     <li>
            //       <p style={{ margin: 0 }}>{}</p>
            //     </li>
            //   )
          }
        </div>
      </div>
      <div className={styles.navbarRight}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <img src={Sun} alt="sun" style={{ height: '15px', width: '15px'}} /> */}
          <Switch
            onChange={changeTheme}
            size="small"
            checked={localStorage.getItem("theme") == "dark"}
          />
          {/* <img src={Moon} alt="moon" style={{ height: '12px', width: '12px'}} /> */}
        </div>
        {pfp(context.user[6], "35px")}
        <div className={styles.hamburgerMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <Menu
        open={isMenuVisible}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 3,
            backgroundColor: themeContext.darkMode ? "#B1CDDE" : "#FFFFFF",
            marginLeft: "-35px",
            marginTop: "35px",
            padding: "10px 30px 10px",
            height: "250px",
            width: "400px",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <div className={styles.popUpMenuInfo}>
          {context.user[6] !== null ? (
            <div
              className={styles.pfp}
              onClick={() => setOpenChangePhoto(true)}
              style={{
                backgroundImage: `url(${context.user[6]})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: "100px",
                width: "100px",
                marginRight: "25px",
              }}
            ></div>
          ) : (
            <div
              className={styles.pfp}
              onClick={() => setOpenChangePhoto(true)}
              style={{ height: "100px", width: "100px", marginRight: "25px" }}
            >
              <h1>{initials}</h1>
            </div>
          )}
          <ChangePicture
            open={openChangePhoto}
            handleClose={() => setOpenChangePhoto(false)}
          />
          <div>
            <h1 style={{ margin: "0 0 5px 0" }}>
              {context.user[1]} {context.user[2]}
            </h1>
            <p style={{ margin: "0 0 5px 0" }}>{context.user[3]}</p>
            <p style={{ color: "#4281a3", margin: 0 }}>{role}</p>
          </div>
        </div>
        <div className={styles.userActions}>
          <a href="/dashboard/account-settings">
            <div>
              <p>Account Settings</p>
            </div>
          </a>
          <a onClick={handleLogout}>
            <div>
              <p>Log out</p>
            </div>
          </a>
        </div>
      </Menu>
    </div>
  );
};

export default Navbar;
