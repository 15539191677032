export const colors = {
    Status : {
        "Trying to Connect": "#999999",
        "ON HOLD": "#FF7BD0",
        "Email Sent": "#70D6D1",
        "LOST": "#E8697E",
        "Dormant": "#797E93",
        "WON": "#369970",
        "Awaiting Brief": "#D5C667",
        "Prepare Proposal": "#F2C230",
        "Cancelled": "#C95C76",
        "RFP Received": "#B57DE3",
        "Connected": "#349ECD",
        "Done Webinar": "#5C5C5C",
        "Flowing": "#34D391",
        "Awaiting Feedback": "#FDBC64",
        "" : "#C4C4C4"
    },
    Location : {
        "LinkedIn" : "#349ECD",
        "Meeting": "#F27294",
        "Video Call": "#70D6D1",
        "Email": "#B57DE3",
        "Not Answering": "#5C5C5C",
        "Call Scheduled": "#369970",
        "" : "#C4C4C4"
    },
    Proposal : {
        "Sent" : "#3BACD9",
        "Negotiation" : "#F2B263",
        "Approved" : "#36D98D",
        "Draft Sent" : "#F2AEEE",
        "Stuck" : "#F27294",
        "" : "#C4C4C4"
    }
}

export const countries = [
    "AF-Afghanistan",
    "AL-Albania",
    "DZ-Algeria",
    "AD-Andorra",
    "AO-Angola",
    "AG-Antigua and Barbuda",
    "AR-Argentina",
    "AM-Armenia",
    "AU-Australia",
    "AT-Austria",
    "AZ-Azerbaijan",
    "BS-Bahamas",
    "BH-Bahrain",
    "BD-Bangladesh",
    "BB-Barbados",
    "BY-Belarus",
    "BE-Belgium",
    "BZ-Belize",
    "BJ-Benin",
    "BT-Bhutan",
    "BO-Bolivia",
    "BA-Bosnia and Herzegovina",
    "BW-Botswana",
    "BR-Brazil",
    "BN-Brunei Darussalam",
    "BG-Bulgaria",
    "BF-Burkina Faso",
    "BI-Burundi",
    "CV-Cabo Verde",
    "KH-Cambodia",
    "CM-Cameroon",
    "CA-Canada",
    "CF-Central African Republic",
    "TD-Chad",
    "CL-Chile",
    "CN-China",
    "CO-Colombia",
    "KM-Comoros",
    "CG-Congo",
    "CD-Democratic Republic of the Congo",
    "CR-Costa Rica",
    "HR-Croatia",
    "CU-Cuba",
    "CY-Cyprus",
    "CZ-Czech Republic",
    "DK-Denmark",
    "DJ-Djibouti",
    "DM-Dominica",
    "DO-Dominican Republic",
    "EC-Ecuador",
    "EG-Egypt",
    "SV-El Salvador",
    "GQ-Equatorial Guinea",
    "ER-Eritrea",
    "EE-Estonia",
    "ET-Ethiopia",
    "FJ-Fiji",
    "FI-Finland",
    "FR-France",
    "GA-Gabon",
    "GM-Gambia",
    "GE-Georgia",
    "DE-Germany",
    "GH-Ghana",
    "GR-Greece",
    "GD-Grenada",
    "GT-Guatemala",
    "GN-Guinea",
    "GW-Guinea-Bissau",
    "GY-Guyana",
    "HT-Haiti",
    "HN-Honduras",
    "HU-Hungary",
    "IS-Iceland",
    "IN-India",
    "ID-Indonesia",
    "IR-Iran",
    "IQ-Iraq",
    "IE-Ireland",
    "IL-Israel",
    "IT-Italy",
    "CI-Côte d'Ivoire",
    "JM-Jamaica",
    "JP-Japan",
    "JO-Jordan",
    "KZ-Kazakhstan",
    "KE-Kenya",
    "KI-Kiribati",
    "KW-Kuwait",
    "KG-Kyrgyzstan",
    "LA-Lao People's Democratic Republic",
    "LV-Latvia",
    "LB-Lebanon",
    "LS-Lesotho",
    "LR-Liberia",
    "LY-Libya",
    "LI-Liechtenstein",
    "LT-Lithuania",
    "LU-Luxembourg",
    "MK-North Macedonia",
    "MG-Madagascar",
    "MW-Malawi",
    "MY-Malaysia",
    "MV-Maldives",
    "ML-Mali",
    "MT-Malta",
    "MH-Marshall Islands",
    "MR-Mauritania",
    "MU-Mauritius",
    "MX-Mexico",
    "FM-Federated States of Micronesia",
    "MD-Moldova",
    "MC-Monaco",
    "MN-Mongolia",
    "ME-Montenegro",
    "MA-Morocco",
    "MZ-Mozambique",
    "MM-Myanmar",
    "NA-Namibia",
    "NR-Nauru",
    "NP-Nepal",
    "NL-Netherlands",
    "NZ-New Zealand",
    "NI-Nicaragua",
    "NE-Niger",
    "NG-Nigeria",
    "KP-Democratic People's Republic of Korea",
    "NO-Norway",
    "OM-Oman",
    "PK-Pakistan",
    "PW-Palau",
    "PA-Panama",
    "PG-Papua New Guinea",
    "PY-Paraguay",
    "PE-Peru",
    "PH-Philippines",
    "PL-Poland",
    "PT-Portugal",
    "QA-Qatar",
    "KR-Republic of Korea",
    "MD-Republic of Moldova",
    "RO-Romania",
    "RU-Russian Federation",
    "RW-Rwanda",
    "KN-Saint Kitts and Nevis",
    "LC-Saint Lucia",
    "VC-Saint Vincent and the Grenadines",
    "WS-Samoa",
    "SM-San Marino",
    "ST-Sao Tome and Principe",
    "SA-Saudi Arabia",
    "SN-Senegal",
    "RS-Serbia",
    "SC-Seychelles",
    "SL-Sierra Leone",
    "SG-Singapore",
    "SK-Slovakia",
    "SI-Slovenia",
    "SB-Solomon Islands",
    "SO-Somalia",
    "ZA-South Africa",
    "KR-South Korea",
    "SS-South Sudan",
    "ES-Spain",
    "LK-Sri Lanka",
    "SD-Sudan",
    "SR-Suriname",
    "SZ-Swaziland",
    "SE-Sweden",
    "CH-Switzerland",
    "SY-Syrian Arab Republic",
    "TJ-Tajikistan",
    "TW-Taiwan",
    "TH-Thailand",
    "TL-Timor-Leste",
    "TG-Togo",
    "TO-Tonga",
    "TT-Trinidad and Tobago",
    "TN-Tunisia",
    "TR-Turkey",
    "TM-Turkmenistan",
    "TV-Tuvalu",
    "UG-Uganda",
    "UA-Ukraine",
    "AE-United Arab Emirates",
    "GB-United Kingdom",
    "TZ-United Republic of Tanzania",
    "US-United States of America",
    "UY-Uruguay",
    "UZ-Uzbekistan",
    "VU-Vanuatu",
    "VE-Venezuela",
    "VN-Viet Nam",
    "YE-Yemen",
    "ZM-Zambia",
    "ZW-Zimbabwe"
];
