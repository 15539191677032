import React from "react";
import AccountSettingsView from "../features/accountSettings";

const AccountSettings = () => {
  return (
    <div>
      <AccountSettingsView />
    </div>
  );
};

export default AccountSettings;
