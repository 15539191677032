export const todaysDate = () => {
  const date = new Date();
  let currentHour = date.getHours();
  let currentMinute = date.getMinutes();
  let currentDay = String(date.getDate()).padStart(2, "0");
  let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
  let currentYear = date.getFullYear();
  currentMinute = currentMinute < 10 ? `0${currentMinute}` : currentMinute;
  let currentDate = `${currentDay}-${currentMonth}-${currentYear} ${currentHour}:${currentMinute}`;

  return currentDate;
};
