import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from "./AutomationInfo.module.scss";
import { ThemeContext } from '../../contexts/ThemeContext';
import { GetRecentRunsAllTables } from '../../services/authServices';
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import Chart from 'chart.js/auto';
import cx from 'classnames';

interface Colors {
    [key: string]: string;
}

const AutomationInfo = () => {  
  const [autoInfo, setAutoInfo] = useState<any | null>({
    Stage: [],
    AVB: [],
    MPQ: [],
    Pitch: [],
    Unilever: [],
  });  
  const [recentRuns, setRecentRuns] = useState<any | null>([]);
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart<"pie"> | null>(null);
  const theme = useContext(ThemeContext);

  const getAutomations = async () => {
    const res: any = await GetRecentRunsAllTables(false);
    
    var stage: any = [];
    var avb: any = [];
    var mpq: any = [];
    var pitch: any = [];
    var unilever: any = [];
    var mostRecent: any = [];

    res.sort((a: any, b: any) => new Date(a).getTime() - new Date(b).getTime()).forEach((item: any, index: number) => {
        if (index < 4) mostRecent.push(item);

        switch (item[7]) {
            case "AVB":
                avb.push(item);
                break;
            case "MPQ":
                mpq.push(item);
                break;
            case "Pitch":
                pitch.push(item);
                break;
            case "Stage":
                stage.push(item);
                break;
            case "COM":
                unilever.push(item);
                break;
        }
    });

    setRecentRuns(mostRecent);
    setAutoInfo({
        Stage: stage,
        AVB: avb,
        MPQ: mpq,
        Pitch: pitch,
        Unilever: unilever
    })
  }

  var colors: Colors = {
    Stage: "rgba(27, 147, 13, 0.5)",
    AVB: "rgba(255, 162, 9, 0.5)",
    MPQ: "rgba(0, 174, 237, 0.5)",
    Pitch: "rgba(65, 48, 171, 0.5)",
    Unilever: "rgba(241, 193, 2, 0.5)",
  }

  var borderColors: Colors = {
    Stage: "rgba(27, 147, 13, 1)",
    AVB: "rgba(255, 162, 9, 1)",
    MPQ: "rgba(0, 174, 237, 1)",
    Pitch: "rgba(65, 48, 171, 1)",
    Unilever: "rgba(241, 193, 2, 1)",
  }

  useEffect(() => {
    getAutomations();
  }, [])  

  useEffect(() => {
    if (autoInfo.Stage.length && chartRef.current) {
        const ctx = chartRef.current.getContext('2d');

        const test = {            
            datasets: [{
                label: 'N/A',
                data: [autoInfo.Stage.length, autoInfo.AVB.length, autoInfo.MPQ.length, autoInfo.Pitch.length, autoInfo.Unilever.length],                
                backgroundColor: [
                    "rgba(27, 147, 13, 0.5)",
                    "rgba(255, 162, 9, 0.5)",
                    "rgba(0, 174, 237, 0.5)",
                    "rgba(65, 48, 171, 0.5)",
                    "rgba(241, 193, 2, 0.5)",
                ],
                borderColor: [
                    "rgba(27, 147, 13, 1)",
                    "rgba(255, 162, 9, 1)",
                    "rgba(0, 174, 237, 1)",
                    "rgba(65, 48, 171, 1)",
                    "rgba(241, 193, 2, 1)",
                ],
                radius: 60
            }]
        }

        if (ctx) {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
            else {
                chartInstanceRef.current = new Chart(ctx, {
                    type: 'pie',
                    data: test,                    
                })
                
                var canvas = document.querySelector('canvas');                

                // if (canvas !== null) {
                //     canvas.height = 100;
                //     canvas.width = 50;
                // }

            }
        }

    }
  }, [autoInfo.Stage.length])

  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>
        <h1 className={styles.title}>Automation</h1>        
        
            <canvas ref={chartRef} height="100" width="100" className={styles.canvas} />                        
            <div className={styles.info}> 
                <div className={styles.autoNames}>
                    {
                        Object.keys(autoInfo).map((key: any) => {
                            return (
                                <div key={key} style={{ display: "flex", alignItems: "center", margin: '0 10px 10px 0' }}>
                                    <div style={{ height: '10px', width: "10px", backgroundColor: `${colors[key]}`, border: `solid 1px ${borderColors[key]}`, marginRight: '5px' }}></div>
                                    <p style={{ fontSize: '0.75rem', margin: 0 }}>- {key} ({autoInfo[key].length})</p>
                                </div>
                            )
                        })
                    }    
                </div>  
            </div>
        
        <div className={cx(styles.recent)}>
            <p style={{ margin: "0 0 0px 10px" }}>Recent</p>    
            <div style={{ width: "100%" }}>
                <div className={styles.recentRun}>
                    <div><p style={{ margin: 0, fontSize: '0.8rem' }}>User</p></div>
                    <div><p style={{ margin: 0, fontSize: '0.8rem' }}>Type</p></div>
                    <div><p style={{ margin: 0, fontSize: '0.8rem', }}>Rows</p></div>
                    <div><p style={{ margin: 0, fontSize: '0.8rem', textAlign: 'center' }}>File</p></div>
                </div>
                {
                    recentRuns.length > 0 && recentRuns.map((item: any, index: number) => {  
                        var color = "transparent";
                        if (index % 2 == 0) {
                            if (theme.darkMode) color = "#252423";
                            else color = "#EEEEEE"
                        }

                        return (
                            <div className={styles.recentRun} key={item} style={{ backgroundColor: `${color}` }}>
                                <div style={{ display: 'flex', alignItems: 'center'}}><img src={item[6]} alt="pfp" className={styles.pfp} /></div>
                                <div><p style={{ margin: 0, fontSize: '0.8rem' }}>{item[7]}</p></div>
                                <div><p style={{ margin: 0, fontSize: '0.8rem' }}>{item[8] == null ? "N/A" : item[8]}</p></div>
                                <a href={item[2]}><button className={cx(styles.fileButton, theme.darkMode && styles.darkMode)}>View</button></a>
                            </div>
                        )
                    })
                }
            </div>
        </div>        
    </div>
  )
}

export default AutomationInfo
