import React from 'react'
import AccountsView from '../features/accounts'

const Accounts = () => {
  return (
    <AccountsView />
  )
}

export default Accounts
