export const palette = [
    '#FF0000', // Red
    '#FFA500', // Orange
    '#FFE14D', // Yellow
    '#6CDE6C', // Lime
    '#008000', // Green
    '#40E0D0', // Turquoise
    '#00FFFF', // Cyan
    '#0000FF', // Blue
    '#4B0082', // Indigo
    '#800080', // Purple
    '#FF00FF', // Magenta
    '#FF8DA1'  // Pink
];
