import React, { ChangeEvent, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./ForgotPassword.module.scss";
import CircleLogo from "../../assets/media/circle-logo.png";
import {
  SendVerificationEmail,
  ConfirmVerificationCode,
  DeleteVerificationCode,
  ChangePassword,
} from "../../services/authServices";
import cx from "classnames";

const ForgotPasswordView = () => {
  const [display, setDisplay] = useState(1);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState("");
  const url = useLocation().pathname;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const handleNewPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async () => {
    if (email.trim() == "") {
      setErrors("Please provide your email.");
    } else {
      setErrors("");
      await SendVerificationEmail(email);
      setDisplay(2);
    }
  };

  const handleCodeSubmit = async () => {
    setIsSubmitting(true);
    await ConfirmVerificationCode(code, email, url)
      .then((res) => {
        setErrors("");
        DeleteVerificationCode(code);
        setDisplay(3);
        setIsSubmitting(false);
      })
      .catch((res) => {
        setIsSubmitting(false);
        setErrors("Incorrect code, please try again");
      });
  };

  const handlePasswordSubmit = async () => {
    setErrors("");
    if (confirmPassword !== newPassword) {
      setErrors("Passwords must match");
    } else {
      if (confirmPassword.length < 7) {
        setErrors("Password must be seven characters or more");
      } else {
        await ChangePassword(confirmPassword, email);
        setDisplay(4);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div
          style={{ display: display == 1 ? "block" : "none", width: "100%" }}
        >
          <div className={styles.header}>
            <img
              src={CircleLogo}
              className={cx(styles.circleLogo, isSubmitting && styles.rotate)}
              alt="logo"
            />
            <div>
              <h1>Feeling forgetful?</h1>
              <p>
                Enter your email below, and we'll send you a code to verify your
                email before you change your password
              </p>
            </div>
          </div>
          <svg width="100%" height="5px" id="divider">
            <line
              x1="0"
              y1="0"
              x2="100%"
              stroke="#000000"
              strokeWidth="3.5px"
            />
          </svg>
          <input
            onChange={handleInputChange}
            className={styles.forgotPassInput}
            placeholder="user@example.com"
            type="email"
            name="email"
            style={{
              width: "100%",
              boxSizing: "border-box",
              marginTop: "30px",
              padding: "12px 10px",
            }}
          />
          <p
            style={{
              textAlign: "center",
              marginTop: "-20px",
              color: "rgb(203, 10, 10)",
              display: errors ? "block" : "none",
            }}
          >
            {errors}
          </p>
          <button
            onClick={handleSubmit}
            type="submit"
            className="submit-button"
          >
            Send code
          </button>
        </div>
        <div style={{ display: display == 2 ? "block" : "none" }}>
          <div className={styles.header}>
            <img
              src={CircleLogo}
              className={cx(styles.circleLogo, isSubmitting && styles.rotate)}
              alt="logo"
            />
            <div>
              <h1>Email verification</h1>
              <p>
                Please enter the code we sent to your email
                <br />
                so we know that it's you
              </p>
            </div>
          </div>
          <svg width="100%" height="5px" id="divider">
            <line
              x1="0"
              y1="0"
              x2="100%"
              stroke="#000000"
              strokeWidth="3.5px"
            />
          </svg>
          <input
            onChange={handleCodeChange}
            className={styles.forgotPassInput}
            placeholder="Code"
            type="text"
            name="code"
            style={{
              width: "100%",
              boxSizing: "border-box",
              marginTop: "30px",
              padding: "12px 10px",
            }}
          />
          <p
            style={{
              textAlign: "center",
              marginTop: "-20px",
              color: "rgb(203, 10, 10)",
              display: errors ? "block" : "none",
            }}
          >
            {errors}
          </p>
          <button
            onClick={handleCodeSubmit}
            type="submit"
            className="submit-button"
          >
            Submit
          </button>
        </div>
        <div style={{ display: display == 3 ? "block" : "none" }}>
          <div className={styles.header}>
            <img
              src={CircleLogo}
              className={cx(styles.circleLogo, isSubmitting && styles.rotate)}
              alt="logo"
            />
            <div>
              <h1>Reset Password</h1>
              <p>
                Thank you for confirming your email.
                <br />
                You can now reset your password
              </p>
            </div>
          </div>
          <svg width="100%" height="5px" id="divider">
            <line
              x1="0"
              y1="0"
              x2="100%"
              stroke="#000000"
              strokeWidth="3.5px"
            />
          </svg>
          <li className="input-and-label" style={{ marginTop: "30px" }}>
            <label htmlFor="newPassword" style={{ marginBottom: 0 }}>
              New password
            </label>
            <input
              onChange={handleNewPasswordChange}
              className={styles.forgotPassInput}
              type="password"
              name="newPassword"
              style={{
                width: "100%",
                boxSizing: "border-box",
                padding: "12px 10px",
                marginBottom: "20px",
              }}
            />
          </li>
          <li className="input-and-label">
            <label htmlFor="confirmPassword" style={{ marginBottom: 0 }}>
              Confirm password
            </label>
            <input
              onChange={handleConfirmPasswordChange}
              className={styles.forgotPassInput}
              type="password"
              name="confirmPassword"
              style={{
                width: "100%",
                boxSizing: "border-box",
                padding: "12px 10px",
                marginBottom: "20px",
              }}
            />
          </li>
          <p
            style={{
              textAlign: "center",
              marginTop: "-20px",
              color: "rgb(203, 10, 10)",
              display: errors ? "block" : "none",
            }}
          >
            {errors}
          </p>
          <button
            onClick={handlePasswordSubmit}
            type="submit"
            className="submit-button"
          >
            Submit
          </button>
        </div>
        <div
          className={styles.confirmMessage}
          style={{ display: display == 4 ? "block" : "none" }}
        >
          <h1>Password successfully reset</h1>
          <p>Your password has been reset and you can now log in.</p>
          <a href="/">
            <button type="submit" className="submit-button">
              Back to login
            </button>
          </a>
        </div>
      </div>
      <p className={styles.bottomMessage}>
        Remembered your password?{" "}
        <strong>
          <a className={styles.link} href="/">
            Back to login
          </a>
        </strong>
      </p>
    </div>
  );
};

export default ForgotPasswordView;
