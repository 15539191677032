import React from "react";
import VerifyEmailView from "../features/verifyEmail";

const VerifyEmail = () => {
  return (
    <>
      <VerifyEmailView />
    </>
  );
};
export default VerifyEmail;
