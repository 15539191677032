import React, { useState } from "react";
import styles from "./AddBenchmarks.module.scss";
import AddAVBForm from "./AddAVBForm";
import cx from "classnames";
import { act } from "react-dom/test-utils";

const AddBenchmarksView = () => {
  const [activeForm, setActiveForm] = useState("AVB");

  const toggleFormAVB = () => {
    setActiveForm("AVB");
  };

  const toggleFormTC = () => {
    setActiveForm("TC");
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        overflowY: "scroll",
        paddingBottom: "40px",
        boxSizing: "border-box",
      }}
    >
      <div className={styles.container}>
        <div className={styles.toggle}>
          <div
            className={styles.toggleButton}
            onClick={() => toggleFormAVB()}
            style={{
              zIndex: activeForm == "AVB" ? 999 : 0,
              backgroundColor: activeForm == "AVB" ? "#FFFFFF" : "#F4F4F4",
            }}
          >
            AVB
          </div>
          <div
            className={styles.toggleButton}
            onClick={() => toggleFormTC()}
            style={{
              zIndex: activeForm == "TC" ? 999 : 0,
              backgroundColor: activeForm == "TC" ? "#FFFFFF" : "#F4F4F4",
            }}
          >
            TC
          </div>
        </div>
        <div className={styles.card}>
          {activeForm == "AVB" ? (
            <AddAVBForm />
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1>Add TC</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddBenchmarksView;
