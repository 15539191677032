import React, { useContext, useState, useEffect } from "react";
import styles from "./PieChart.module.scss";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { GetHistoricalData } from "../../../services/authServices";
import cx from "classnames";

interface Props {
  type: string;
}

const CountryPieChart = (props: Props) => {
  const [countryData, setCountryData] = useState<any | null>(null);
  const theme = useContext(ThemeContext);

  const getData = async () => {    
    var data: any = await GetHistoricalData(props.type);

    var countryCount: Record<string, number> = {};
    data.slice(0).forEach((item: any) => {
      var country = "";
      if (props.type.includes("mpq")) country = item[5];
      else if (props.type.includes("stage")) country = item[2];      

      if (country == "The UK" || country == "The US")
        country = country.split(" ")[1];
      else if (country == "Netherlands") country = "NL";

      if (countryCount[country]) {
        countryCount[country] += 1;
      } else {
        countryCount[country] = 1;
      }
    });
    var array: any = [];
    // 8AC8A4
    let colors: any = [];
    if (props.type.includes("mpq")) {
      colors = [
        "#025373",
        "#75B8BF",
        "#D0E5F2",
        "#F2E5BD",
        "#F2D680",
        "#F98F6E",
        "#CB532D",
      ];
    } else {
      colors = ["#013220", "#014421", "#355E3B", "#8A9A5B", "#BCB88A"];
    }
    let other = 0;
    Object.entries(countryCount)
      .sort((a, b) => b[1] - a[1])
      .forEach(([key, value], index) => {
        let percentage =
          Math.round(((value / data.length) * 100 + Number.EPSILON) * 10) / 10;
        if (Object.entries(countryCount).length > 5 && percentage < 2) {
          other += percentage;
        } else {
          array.push({
            id: key,
            value: percentage,
            country: `${key} - ${percentage}%`,
            color: colors[index],
          });
        }
      });
    if (other > 0) {
      array.push({
        id: "Other",
        value: other,
        country: `Other - ${other}%`,
        color: "gray",
      });
    }

    setCountryData(array);
  };

  useEffect(() => {
    getData();
  }, [props.type]);

  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>
      <h1 className={styles.title}>
        {props.type.split("-")[0].toLocaleUpperCase()} By Country
      </h1>
      {countryData && (
        <PieChart
          series={[
            {
              data: [...countryData],
            },
          ]}
          width={350}
          height={250}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: "white",
              fontWeight: "bold",
            },
          }}
        />
      )}
      <div className={styles.countryList}>
        {countryData &&
          countryData.map((item: any) => {
            return (
              <div
                key={item.country}
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0 0 10px 0",
                }}
              >
                <div
                  style={{
                    height: "10px",
                    width: "10px",
                    backgroundColor: `${item.color}`,
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    marginRight: "10px",
                  }}
                ></div>
                <p>{item.country}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CountryPieChart;
