import React, { useContext, useEffect, useState, useRef, } from 'react'
import { GetAllLeads, AddLead, DeleteLead, GetLeadCharts, UpdateLeadChartTitle, GetComments, UpdateLeads } from '../../services/authServices'
import styles from "./LeadManagement.module.scss"
import { ThemeContext } from '../../contexts/ThemeContext'
import cx from 'classnames'
import Table from '../../common/components/LeadManagement/Table'
import SearchWhite from "../../assets/media/search-white.png";
import SearchBlack from "../../assets/media/search-black.png";
import PersonWhite from "../../assets/media/user-white.png";
import PersonBlack from "../../assets/media/user-black.png";
import LocationWhite from "../../assets/media/location-white.png";
import LocationBlack from "../../assets/media/location-black.png";
import ChevronDown from "../../assets/media/chevron-down-white.png";
import WhiteTrash from "../../assets/media/white-trash.png";
import BlackTrash from "../../assets/media/trash-black.png";
import { Menu } from "@mui/material";
import { useParams } from "react-router-dom";
import { X } from "react-feather";

interface Lead {
  Id: 1,
  KeyClient: string,
  Company: string,
  Country: string,
  Services: string[],
  Status: string,
  LastContact: string | Date | null,
  ContactLocation: string,
  Proposal: string | null,
  Confidence: number,
  DealSize: number,
  RevenueForecast: number,
  Description: string,
  Category: string,
  ChartId: number,
  OrderId: number,
}

interface Chart {
  Id: number,
  Title: string,
  Creator: string,
}

interface LeadState {
  "Cold": Lead[],
  "Warm": Lead[],
  "Hot": Lead[],
  "Won": Lead[]
}

const LeadManagementView = () => {  
  const [chart, setChart] = useState<Chart>();
  const [unfilteredLeads, setUnfilteredLeads] = useState<LeadState>({
    "Cold": [],
    "Warm": [],
    "Hot": [],
    "Won": [],
  })
  const [leads, setLeads] = useState<LeadState>({
    "Cold": [],
    "Warm": [],
    "Hot": [],
    "Won": [],
  })
  const [anchorElNewItem, setAnchorElNewItem] = useState(null);
  const selectRef = useRef<HTMLSelectElement>(null);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [dragged, setDragged] = useState<number | null>(null);
  const [draggedOver, setDraggedOver] = useState<number | null>(null);
  const [tableDragOver, setTableDragOver] = useState<string | null>(null);
  const [movedElement, setMovedElement] = useState<any>();
  const params = useParams();
  const theme = useContext(ThemeContext);


  const getLeads = async () => {
    var charts: any = await GetLeadCharts();  

    charts.forEach((chart: any) => {
      var test: Chart | undefined;
      if (chart.Id == params.chartId) {
        test = {
          Id: chart.Id,
          Title: chart.Title,
          Creator: chart.Creator,
        }
              
        setChart(test);
      } 
    })

    
  var data: any = await GetAllLeads();

  // if (chart !== undefined) {
  //   var comments: any = await GetComments(chart?.Id.toString(), "dbo.LeadManagementComments"); 

  //   console.log(comments)
  // }
  var coldLeads: Lead[] = [];
  var warmLeads: Lead[] = [];
  var hotLeads: Lead[] = [];
  var wonLeads: Lead[] = [];
  
  var chartId = params.chartId;    
    data.filter((lead: Lead) => lead.ChartId.toString() == chartId).sort((a: Lead, b: Lead) => a.OrderId - b.OrderId).forEach((lead: any) => {      
      var newLead = {
        Id: lead.Id,
        KeyClient: lead.KeyClient,
        Company: lead.Company,
        Country: lead.Country,
        Services: lead.Services.split(",").filter((item: string) => item != ""),
        Status: lead.Status,
        LastContact: lead.LastContact,
        ContactLocation: lead.ContactLocation,
        DealSize: lead.DealSize,
        Proposal: lead.Proposal,
        Confidence: lead.Confidence,
        RevenueForecast: lead.RevenueForecast,
        Description: lead.Description,
        Category: lead.Category,
        ChartId: lead.ChartId,
        OrderId: lead.OrderId,
      }
  
      if (lead.Category == "Cold") coldLeads.push(newLead);
      if (lead.Category == "Warm") warmLeads.push(newLead);
      if (lead.Category == "Hot") hotLeads.push(newLead);
      if (lead.Category == "Won") wonLeads.push(newLead);
    })    
  
    setUnfilteredLeads({
      "Cold": coldLeads,
      "Warm": warmLeads,
      "Hot": hotLeads,
      "Won": wonLeads,
    });

    setLeads({
      "Cold": coldLeads,
      "Warm": warmLeads,
      "Hot": hotLeads,
      "Won": wonLeads,
    })
  }

  useEffect(() => {
    getLeads()
  }, [])

  const handleMenu = (e: any) => {
    if (anchorElNewItem == null) {
      setAnchorElNewItem(e.currentTarget);
    }
    else {
      setAnchorElNewItem(null)
    }
  }

  const handleCreate = async () => {
    var category = selectRef.current?.value;
    var combinedLeads = [...unfilteredLeads['Cold'], ...unfilteredLeads['Warm'], ...unfilteredLeads['Hot'], ...unfilteredLeads['Won']]
    var orderId = combinedLeads.length ? combinedLeads[combinedLeads.length - 1].OrderId : 0;

    if (category !== undefined) {
      var newItem = {
        Id: null,
        KeyClient: "",
        Company: "",
        Country: "GB-United Kingdom",
        Services: [],
        Status: "",
        LastContact: new Date(),
        ContactLocation: "",
        Proposal: "",
        Confidence: 0,
        RevenueForecast: 0,
        DealSize: 0,
        Description: "Status",
        Category: category,
        OrderId: orderId
      }
      
      await AddLead(category, orderId).then((res: any) => {
        newItem.Id = res.data.id;
        newItem.Services = [];        
        
        setLeads(prev => ({
          ...prev,
          [category as keyof LeadState] : [...prev[category as keyof LeadState], newItem],
        }))
      });
    }
    
  }

  const handleSelect = (id: number) => {
    if (selectedItems.includes(id)) {      
      let newArr = selectedItems.filter(item => item !== id);
      setSelectedItems(newArr);
    }
    else {
      let newArr = [...selectedItems, id];
      setSelectedItems(newArr)
    }
  }

  const deleteItems = async () => {
    const newHot = leads["Hot"].filter(item => !selectedItems.includes(item.Id))
    const newWarm = leads["Warm"].filter(item => !selectedItems.includes(item.Id))
    const newCold = leads["Cold"].filter(item => !selectedItems.includes(item.Id))
    const newWon = leads["Won"].filter(item => !selectedItems.includes(item.Id))

    setUnfilteredLeads({
      "Hot" : newHot,
      "Warm" : newWarm,
      "Cold" : newCold,
      "Won" : newWon,
    })

    setLeads({
      "Hot" : newHot,
      "Warm" : newWarm,
      "Cold" : newCold,
      "Won" : newWon,
    });

    await DeleteLead(selectedItems);

    setSelectedItems([]);
  }

  const handleSearchFilter = (input: string) => {
    
    if (input == "") {
      setLeads(unfilteredLeads);
    }
    else {
      var newCold = unfilteredLeads["Cold"].filter(item => item.KeyClient.toLocaleLowerCase().includes(input.toLocaleLowerCase()) || item.Company.toLocaleLowerCase().includes(input.toLocaleLowerCase()));
      var newHot = unfilteredLeads["Hot"].filter(item => item.KeyClient.toLocaleLowerCase().includes(input.toLocaleLowerCase()) || item.Company.toLocaleLowerCase().includes(input.toLocaleLowerCase()));
      var newWarm = unfilteredLeads["Warm"].filter(item => item.KeyClient.toLocaleLowerCase().includes(input.toLocaleLowerCase()) || item.Company.toLocaleLowerCase().includes(input.toLocaleLowerCase()));
      var newWon = unfilteredLeads["Won"].filter(item => item.KeyClient.toLocaleLowerCase().includes(input.toLocaleLowerCase()) || item.Company.toLocaleLowerCase().includes(input.toLocaleLowerCase()));
  
      setLeads(prev => ({
        ...prev,
        "Cold" : newCold,
        "Hot" : newHot,
        "Warm" : newWarm,
        "Won" : newWon
      }))
    }
  }

  const updateChartTitle = async (e: any) => {
    let title = e.currentTarget.value;
    
    setChart(prev => {
      if (prev) {
        return {
          ...prev,
          Title: title
        }
      }
    })

    await UpdateLeadChartTitle(title, chart?.Id);
  }

  const handleDrag = (id: number) => {
    setDragged(id)
  }

  const handleDragOver = (id: number) => {
    setDraggedOver(id);
  }

  const handleDragDrop = () => {
    var leadsCombined = [...leads['Cold'], ...leads['Warm'], ...leads['Hot'], ...leads['Won']]
    var movedProject = leadsCombined.find((x) => x.Id == dragged);
    var adjacentProject = leadsCombined.find((x) => x.Id == draggedOver);    
      
    var newLeads = leadsCombined.map((lead: Lead) => {
      if (adjacentProject) {
        if (lead.Id == dragged) {
          UpdateLeads(dragged, 'OrderId', adjacentProject.OrderId + 1)
          UpdateLeads(dragged, 'Category', tableDragOver)

          var newLead: Lead = lead;          
          newLead.OrderId = adjacentProject.OrderId + 1;          
          newLead.Category = tableDragOver !== null ? tableDragOver : newLead.Category

          return newLead
        }
        else if (lead.OrderId > adjacentProject.OrderId) {
          let newLead: Lead = lead;
          newLead.OrderId = newLead.OrderId + 1;
          UpdateLeads(lead.Id, 'OrderId', newLead.OrderId + 1)
          
          return newLead   
        }
        else {
          return lead
        }
      }
      else {
        return lead
      }
    })    

    var sortedLeads = newLeads.sort((a: any, b: any) => a.OrderId - b.OrderId);    
    var leadsCategories: any = {
      'Cold' : sortedLeads.filter((x: any) => x.Category == "Cold"),
      'Warm' : sortedLeads.filter((x: any) => x.Category == "Warm"),
      'Hot' : sortedLeads.filter((x: any) => x.Category == "Hot"),
      'Won' : sortedLeads.filter((x: any) => x.Category == "Won"),
    }


    setLeads(leadsCategories)
    setUnfilteredLeads(unfilteredLeads)
    setDragged(null)
    setDraggedOver(null)
    setMovedElement(dragged)
  }

  return (
    <div className={cx(styles.wrapper, theme.darkMode && styles.darkMode)}>
      <div className={cx(styles.titleInfo, theme.darkMode && styles.darkMode)}>      
        {
          chart != undefined && (
            <div style={{ marginBottom: '15px' }}>
              <input onChange={(e) => updateChartTitle(e)} value={chart?.Title} className={cx(styles.chartTitleInput, theme.darkMode && styles.darkMode)} placeholder={chart?.Title}/>
              <p style={{ margin: 0}}>Created by {chart?.Creator}</p>
            </div>
          )
        }    
        <div className={styles.toolbar}>
          <button onClick={(e) => handleMenu(e)} className={styles.newItemButton}>New Item <img src={ChevronDown} alt="chev" style={{ marginLeft: '10px', height: '20px', width: '20px' }} /></button>
          <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
            <img src={theme.darkMode ? SearchWhite : SearchBlack} style={{ height: '20px', width: "20px", marginRight: '7px'}} alt="search" />
            <input onChange={(e) => handleSearchFilter(e.currentTarget.value)} className={cx(styles.searchInput, theme.darkMode && styles.darkMode)} placeholder="Search" />
          </div>
          {/* <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
            <img src={theme.darkMode ? PersonWhite : PersonBlack} style={{ height: '20px', width: "20px", marginRight: '7px'}} alt="search" />
            <p>Person</p>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={theme.darkMode ? LocationWhite : LocationBlack} style={{ height: '20px', width: "20px", marginRight: '7px'}} alt="search" />
            <p>Country</p>
          </div> */}
        </div> 
      </div>
      <div style={{ paddingLeft: '40px' }}>
        {
          chart && (
            <>
              <div onDragOver={() => setTableDragOver('Cold')}>
                <Table movedElement={movedElement} draggedOver={draggedOver} dragged={dragged} handleDragOver={handleDragOver} handleDrag={handleDrag} handleDragDrop={handleDragDrop} category={"Cold"} categoryColor={"#048ABF"} leads={leads["Cold"]} handleSelect={handleSelect} selectedItems={selectedItems} chartId={chart?.Id}/>
              </div>
              <div onDragOver={() => setTableDragOver('Warm')}>
                <Table movedElement={movedElement} draggedOver={draggedOver} dragged={dragged} handleDragOver={handleDragOver} handleDrag={handleDrag} handleDragDrop={handleDragDrop} category={"Warm"} categoryColor={"#F2522E"} leads={leads["Warm"]}handleSelect={handleSelect}  selectedItems={selectedItems} chartId={chart?.Id}/>
              </div>
              <div onDragOver={() => setTableDragOver('Hot')}>
                <Table movedElement={movedElement} draggedOver={draggedOver} dragged={dragged} handleDragOver={handleDragOver} handleDrag={handleDrag} handleDragDrop={handleDragDrop} category={"Hot"} categoryColor={"#E2435C"} leads={leads["Hot"]} handleSelect={handleSelect} selectedItems={selectedItems} chartId={chart?.Id}/>
              </div>
              <div onDragOver={() => setTableDragOver('Won')}>
                <Table movedElement={movedElement} draggedOver={draggedOver} dragged={dragged} handleDragOver={handleDragOver} handleDrag={handleDrag} handleDragDrop={handleDragDrop} category={"Won"} categoryColor={"#03733F"} leads={leads["Won"]} handleSelect={handleSelect} selectedItems={selectedItems} chartId={chart?.Id}/>
              </div>
            </>
          )
        }
      </div>
      <Menu
        open={anchorElNewItem !== null} // putting all menu components down here
        anchorEl={anchorElNewItem}
        onClose={(e) => handleMenu(e)}
        sx={{
          "& .MuiPaper-root": {
            height: "50px",
            width: "250px",                                      
            backgroundColor: "#075CF1",   
            color: "#FFFFFF",
            padding: "0 10px 20px 10px" 
          },
        }}
      >
        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between'}}>
          <div>
            <p style={{ margin: "0 0 5px 0", fontSize: "0.9rem" }}>Category</p>
            <select className={styles.selectMenu} ref={selectRef}>
              <option>Not Interested</option>
              <option>Cold</option>
              <option>Warm</option>
              <option>Hot</option>
              <option>Won</option>
              <option>Lost</option>
            </select>
          </div>
          <button onClick={() => handleCreate()} className={styles.addButton}>Create</button>
        </div>
      </Menu>
      <div 
        className={cx(styles.popupMenu, theme.darkMode && styles.darkMode)}
        style={{ display: selectedItems.length > 0 ? "block" : "none" }}
      >
        <div className={styles.popupContainer}>
          <div
            className={styles.optionDisplay}
            style={{
              borderRight: `solid 1px ${theme.darkMode ? "#D0E5F2" : "#dadada"}`,
              marginRight: "110px",
            }}
          >            
            <h1 style={{ fontFamily: "PoppinsLight", margin: "-7px" }}>
              {selectedItems.length}
            </h1>
            <p style={{ margin: 0 }}>Selected</p>
          </div>
          <div
            className={styles.optionDisplay}
            onClick={() => deleteItems()}
          >
            <img
              src={theme.darkMode ? WhiteTrash : BlackTrash}
              style={{ height: "30px", width: "30px", marginBottom: "5px" }}
            />
            <p style={{ margin: 0 }}>Delete</p>
          </div>        
          <div
            onClick={() => setSelectedItems([])}
            className={styles.optionDisplay}
            style={{ position: "absolute", right: 0 }}
          >
            <X style={{ height: "30px", width: "30px", marginBottom: "2px" }} />
            <p style={{ margin: 0 }}>Close</p>
          </div>              
        </div>
      </div>
    </div>
  )
}

export default LeadManagementView
