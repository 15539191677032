import React, { useContext, useEffect, useState } from "react";
import { Dialog, Menu } from "@mui/material";
import { ThemeContext } from "../../contexts/ThemeContext";
import { AuthContext } from "../../contexts/AuthContext";
import {
  GetFullReport,
  ChangeInputTemplate,
  ChangeNamingConvention,
  DownloadInputTemplate,
} from "../../services/authServices";
import styles from "./Options.module.scss";
import {
  UploadCloud,
  ArrowLeft,
  Download,
  Upload,
  FileText,
} from "react-feather";
import CircleLogo from "../../assets/media/circle-logo.png";
import BlackTriangle from "../../assets/media/black-triangle.png";
import BlueTriangle from "../../assets/media/blue-triangle.png";
import XLIcon from "../../assets/media/xl-icon.png";
import XLIconLight from "../../assets/media/xl-icon-light.png";
import UploadWhite from "../../assets/media/upload-white.png";
import UploadBlack from "../../assets/media/upload-black.png";
import cx from "classnames";

const Options = (props: any) => {
  const [newTemplateFile, setNewTemplateFile] = useState<File | undefined>(
    undefined,
  );  
  const [open, setOpen] = useState(false);
  const [templateDialog, setTemplateDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [reportLoading, setReportLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext);

  const handleMenu = (e: any) => {
    setOpen((prev) => !prev);
    if (anchorEl == null) {
      setAnchorEl(e.currentTarget);
    }
  };

  const selectNewTemplate = (e: any) => {
    var input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx, .xls, .csv";
    input.click();
    input.onchange = (_) => {
      let file = input.files?.[0];
      setNewTemplateFile(file);
    };
    setTemplateDialog(true);
  };

  const downloadFullReport = async () => {
    setReportLoading(true);
    setOpen(false);
    
    try {
      var database =
        props.automation == "avb-automation"
          ? `dbo.AvbData${props.demo ? "Demo" : ""}`
          : `dbo.MediaPriceQualityData${props.demo ? "Demo" : ""}`;
      var report: any = await GetFullReport(database);
      const a = document.createElement("a");
      a.href = report;
      setReportLoading(false);
      a.download =
        props.automation == "avb-automation"
          ? "AVB Full Report"
          : "Media Price & Quality Full Report.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (err) {
      console.log(err);
    }
  };

  const updateTemplate = async () => {
    if (newTemplateFile) {
      if (props.automation == "comvergence-automation" || props.automation == "stage-automation") {
        setLoading(true);
        await ChangeNamingConvention(newTemplateFile, props.automation)
        .then((res: any) => {         
          if (res.status == 200) {
            setLoading(false);
            setSuccess(true);
          }
        })
      }
      else {
        await ChangeInputTemplate(newTemplateFile, "Media Price Quality")
        .then(res => {
          console.log(res);
        })
      }
    }
  };  

  const downloadTemplate = async () => {
    await DownloadInputTemplate(props.automation)
    .then((res: any) => {
      const a = document.createElement("a");
      a.href = res;
      a.download = props.automation.includes("pitch") ? `${props.automation}-template.docx` : `${props.automation}-template.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    })
  }

  return (
    <>
      <div
        className={cx(styles.reportLoading, reportLoading && styles.visible)}
      >
        <div
          className={styles.loadingDot}
          style={{
            backgroundColor:
              props.automation == "avb-automation" ? "#FFA209" : "#5ad3ff",
          }}
        ></div>
        <div
          className={styles.loadingDot}
          style={{
            backgroundColor:
              props.automation == "avb-automation" ? "#FFA209" : "#5ad3ff",
          }}
        ></div>
        <div
          className={styles.loadingDot}
          style={{
            backgroundColor:
              props.automation == "avb-automation" ? "#FFA209" : "#5ad3ff",
          }}
        ></div>
      </div>
      <div
        className={cx(styles.options, theme.darkMode && styles.darkMode)}
        onClick={(e) => handleMenu(e)}
      >
        <h3>Options</h3>
        <img src={theme.darkMode ? BlueTriangle : BlackTriangle} />
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => handleMenu(e)}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: theme.darkMode ? "#3b3a39" : "#FFFFFF",
            color: theme.darkMode ? "#FFFFFF" : "#000000",
            padding: "4px 15px 0px 10px",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <ul className={styles.optionsList}>
          {props.automation == "mpq-automation" && auth.user[7] >= 2 && (
            <>
              <li onClick={(e) => selectNewTemplate(e)}>
                <Upload style={{ marginRight: "10px" }} />
                <p>Replace Current Input Template</p>
              </li>
            </>
          )}
          {
            (props.automation == "avb-automation" || props.automation == "mpq-automation") && (
              <>
                <li onClick={downloadTemplate}>                  
                  <Download style={{ marginRight: "10px" }} />
                  <p>Download Input Template</p>                  
                </li>
                {
                  props.automation != "pitch-automation" && (
                    <li onClick={downloadFullReport}>
                      <FileText style={{ marginRight: "10px" }} />
                      <p>Download Full Report</p>
                    </li>
                  )
                }
              </>
            )
          }
          {
            (props.automation == "comvergence-automation" || props.automation == "stage-automation") && (
              <li onClick={selectNewTemplate}>
                <img src={theme.darkMode ? UploadWhite : UploadBlack} style={{ height: '20px', width: "20px", marginRight: '15px' }} alt="upload"/>
                <p style={{ fontSize: '0.9rem' }}>Upload New Naming Convention</p>
              </li>
            )
          }
        </ul>
      </Menu>
      <Dialog
        open={templateDialog}
        sx={{
          "& .MuiPaper-root": {            
            backgroundColor: theme.darkMode ? "#3b3a39" : "#FFFFFF",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",            
            color: theme.darkMode ? "#FFFFFF" : "#000000",
            height: "40vh",
            width: "60vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            overflowX: "hidden",
            textAlign: "center",
            padding: "40px 10px",
            boxSizing: 'border-box',            
            // fontFamily: "PoppinsLight"
          },
        }}
      > 
      {
        (!loading && !success) && (
          <>
            <h1 style={{ margin: "0 0 30px 0", fontFamily: "PoppinsBold"}}>Confirm</h1>
            <div className={styles.dialogInfo}>
              {newTemplateFile && (
                <div style={{ marginBottom: "20px" }}>
                  <img
                    src={theme.darkMode ? XLIconLight : XLIcon}
                    style={{ height: "30px", width: "30px" }}
                    />
                  <h5 style={{ fontSize: "0.9rem", margin: 0 }}>
                    {newTemplateFile.name}
                  </h5>
                </div>
              )}
              {
                (props.automation == "stage-automation" || props.automation == "comvergence-automation") ? (
                  <p style={{ marginBottom: "40px" }}>You are replacing the Naming Convention/Variables file for this automation.</p>
                ) : (
                  <p style={{ marginBottom: "40px" }}>
                    You are replacing the template file that employees can use to create
                    their inputs for this automation.
                  </p>
                )
              }
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className={cx(styles.dialogButton, theme.darkMode && styles.darkMode)}
                  onClick={updateTemplate}
                  style={{ backgroundColor: "#000000", color: "#FFFFFF" }}
                >
                  Confirm
                </button>
                <button
                  className={cx(styles.dialogButton, theme.darkMode && styles.darkMode)}
                  onClick={() => setTemplateDialog(false)}
                  style={{
                    backgroundColor: theme.darkMode ? "transparent" : "#FFFFFF",
                    border: `solid 1px ${theme.darkMode ? "#cacaca" : "#d0d0d0"}`,                
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </>
        ) 
      }
      {
        loading && (          
           <div className={styles.loadingContainer}>
             <h3>Loading . . .</h3>
             <div
               className={styles.loadingBar}
               style={{ backgroundColor: "#cacaca" }}
             >
               <div
                 className={styles.movingDiv1}
                 style={{ backgroundColor: "#000000", opacity: 0.25 }}
               ></div>
               <div
                 className={styles.movingDiv2}
                 style={{ backgroundColor: "#000000", opacity: 0.1 }}
               ></div>
               <div
                 className={styles.movingDiv3}
                 style={{ backgroundColor: "#FFFFFF", opacity: 0.35 }}
               ></div>
             </div>
           </div>
        )        
      }
      {
        success && (
          <div style={{ alignItems: "center" }}>
            <h1 style={{ fontFamily: "PoppinsBold", fontSize: "1.6rem", marginBottom: '40px' }}>Document successfully updated</h1>
            <p style={{ marginBottom: "90px" }}>The Naming Convention/Variables file has been successfully updated.</p>
            <button
              className={cx(styles.dialogButton, theme.darkMode && styles.darkMode)}
              onClick={() => {
                setOpen(false);
                setTemplateDialog(false);
              }}
              style={{ backgroundColor: "#000000" }}
            >
              Close
            </button>
          </div>
        )
      }
      </Dialog>
    </>
  );
};

export default Options;
