import React, { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { ThemeContext } from "../../contexts/ThemeContext";
import styles from "./AccountSettings.module.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Dialog } from "@mui/material";
import Arrow from "../../assets/media/arrow.png";
import { ArrowRight } from "react-feather";
import { UpdatePersonalInfo } from "../../services/authServices";
import cx from "classnames";

const PersonalForm = () => {
  const context = useContext(AuthContext);
  const theme = useContext(ThemeContext);

  interface Values {
    [key: string]: any;
  }

  const updateInfo = (values: Values) => {
    for (const key in values) {
      if (values[key] == "") {
        switch (key) {
          case "firstName":
            values[key] = context.user[1];
            break;
          case "lastName":
            values[key] = context.user[2];
            break;
          case "email":
            values[key] = context.user[3];
            break;
          case "phoneNumber":
            values[key] = context.user[5];
            break;
          case "company":
            values[key] = context.user[6];
            break;
        }
      }
    }
    values["id"] = context.user[0];

    UpdatePersonalInfo(values);
  };

  return (
    <div className={styles.personalInfo} id="personal-info">
      <div className={cx(styles.titleDiv, theme.darkMode && styles.darkMode)}>
        <h2>Update personal info</h2>
        <div style={{ display: "flex", alignItems: "center" }}>
          <a href="#security-info" style={{ marginRight: "5px" }}>
            Change password
          </a>
          {/* <img src={Arrow} style={{ height: "20px", width: "20px", marginTop: "2px"}}/> */}
          <ArrowRight />
        </div>
      </div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          company: "",
          phoneNumber: "",
        }}
        onSubmit={(values) => updateInfo(values)}
      >
        {({ values, handleChange }) => (
          <Form
            className={cx(
              styles.formContainer,
              theme.darkMode && styles.darkMode,
            )}
          >
            <div className="name-info">
              <li className="input-and-label" id={styles.halfInput}>
                <label htmlFor="firstName">First name</label>
                <input
                  placeholder={context.user[1]}
                  className={styles.input}
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                />
              </li>
              <li className="input-and-label" id={styles.halfInput}>
                <label htmlFor="lastName">Last name</label>
                <input
                  placeholder={context.user[2]}
                  className={styles.input}
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                />
              </li>
            </div>
            <div className="name-info">
              <li className="input-and-label" id={styles.halfInput}>
                <label htmlFor="email">Email</label>
                <input
                  placeholder={context.user[3]}
                  className={styles.input}
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
              </li>
              <li className="input-and-label" id={styles.halfInput}>
                <label htmlFor="company">Company</label>
                <input
                  placeholder={context.user[6]}
                  className={styles.input}
                  type="text"
                  name="company"
                  value={values.company}
                  onChange={handleChange}
                />
              </li>
            </div>
            <li className="input-and-label">
              <label htmlFor="phoneNumber">Phone number</label>
              <input
                placeholder={context.user[5]}
                className={styles.input}
                type="text"
                name="phoneNumber"
                value={values.phoneNumber}
                onChange={handleChange}
              />
            </li>
            <button type="submit" className="submit-button">
              Update
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalForm;
