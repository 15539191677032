import React from 'react'
import ContactView from '../features/contacts'

const Contacts = () => {
  return (
    <ContactView />
  )
}

export default Contacts
