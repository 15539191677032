import React, { useContext } from "react";
import styles from "./ContractEvaluation.module.scss";
import { ThemeContext } from "../../contexts/ThemeContext";
import cx from "classnames";

const ContractEvaluation = ({ contract, updateValues }) => {
  var theme = useContext(ThemeContext);

  //   console.log(contract.SignedByBothParties)

  return (
    <ul>
      <li className={styles.evaluationItem}>
        <div className={styles.dropdownContainer}>
          <label>Signed By Both Parties</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.SignedByBothParties
                ? contract.SignedByBothParties
                : "N/A"
            }
            onChange={(e) => updateValues(e, "SignedByBothParties")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div className={styles.dropdownContainer}>
          <label>Needs Improvement?</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.SignedByBothPartiesImpr
                ? contract.SignedByBothPartiesImpr
                : "N/A"
            }
            onChange={(e) => updateValues(e, "SignedByBothPartiesImpr")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div
          className={cx(
            styles.commentContainer,
            theme.darkMode && styles.darkMode,
          )}
        >
          <label>Comments</label>
          <input
            type="text"
            value={
              contract.SignedByBothPartiesComment
                ? contract.SignedByBothPartiesComment
                : ""
            }
            onChange={(e) => updateValues(e, "SignedByBothPartiesComment")}
          />
        </div>
      </li>
      <li className={styles.evaluationItem}>
        <div className={styles.dropdownContainer}>
          <label>Definitions</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={contract.Definitions ? contract.Definitions : "N/A"}
            onChange={(e) => updateValues(e, "Definitions")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div className={styles.dropdownContainer}>
          <label>Needs Improvement</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={contract.DefinitionsImpr ? contract.DefinitionsImpr : "N/A"}
            onChange={(e) => updateValues(e, "DefinitionsImpr")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div
          className={cx(
            styles.commentContainer,
            theme.darkMode && styles.darkMode,
          )}
        >
          <label>Comments</label>
          <input
            type="text"
            value={
              contract.DefinitionsComment ? contract.DefinitionsComment : ""
            }
            onChange={(e) => updateValues(e, "DefinitionsComment")}
          />
        </div>
      </li>
      <li className={styles.evaluationItem}>
        <div className={styles.dropdownContainer}>
          <label>Services Described</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.ServicesDescribed ? contract.ServicesDescribed : "N/A"
            }
            onChange={(e) => updateValues(e, "ServicesDescribed")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div className={styles.dropdownContainer}>
          <label>Needs Improvement</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.ServicesDescribedImpr
                ? contract.ServicesDescribedImpr
                : "N/A"
            }
            onChange={(e) => updateValues(e, "ServicesDescribedImpr")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div
          className={cx(
            styles.commentContainer,
            theme.darkMode && styles.darkMode,
          )}
        >
          <label>Comments</label>
          <input
            type="text"
            value={
              contract.ServicesDescribedComment
                ? contract.ServicesDescribedComment
                : ""
            }
            onChange={(e) => updateValues(e, "ServicesDescribedComment")}
          />
        </div>
      </li>
      <li className={styles.evaluationItem}>
        <div className={styles.dropdownContainer}>
          <label>Reporting Clause</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={contract.ReportingClause ? contract.ReportingClause : "N/A"}
            onChange={(e) => updateValues(e, "ReportingClause")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div className={styles.dropdownContainer}>
          <label>Needs Improvement?</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.ReportingClauseImpr
                ? contract.ReportingClauseImpr
                : "N/A"
            }
            onChange={(e) => updateValues(e, "ReportingClauseImpr")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div
          className={cx(
            styles.commentContainer,
            theme.darkMode && styles.darkMode,
          )}
        >
          <label>Comments</label>
          <input
            type="text"
            value={
              contract.ReportingClauseComment
                ? contract.ReportingClauseComment
                : ""
            }
            onChange={(e) => updateValues(e, "ReportingClauseComment")}
          />
        </div>
      </li>
      <li className={styles.evaluationItem}>
        <div className={styles.dropdownContainer}>
          <label>Agent Only</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={contract.AgentOnly ? contract.AgentOnly : "N/A"}
            onChange={(e) => updateValues(e, "AgentOnly")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div className={styles.dropdownContainer}>
          <label>Needs Improvement?</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={contract.AgentOnlyImpr ? contract.AgentOnlyImpr : "N/A"}
            onChange={(e) => updateValues(e, "AgentOnlyImpr")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div
          className={cx(
            styles.commentContainer,
            theme.darkMode && styles.darkMode,
          )}
        >
          <label>Comments</label>
          <input
            type="text"
            value={contract.AgentOnlyComment ? contract.AgentOnlyComment : ""}
            onChange={(e) => updateValues(e, "AgentOnlyComment")}
          />
        </div>
      </li>
      <li className={styles.evaluationItem}>
        <div className={styles.dropdownContainer}>
          <label>Authority Clause</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={contract.AuthorityClause ? contract.AuthorityClause : "N/A"}
            onChange={(e) => updateValues(e, "AuthorityClause")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div className={styles.dropdownContainer}>
          <label>Needs Improvement</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.AuthorityClauseImpr
                ? contract.AuthorityClauseImpr
                : "N/A"
            }
            onChange={(e) => updateValues(e, "AuthorityClauseImpr")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div
          className={cx(
            styles.commentContainer,
            theme.darkMode && styles.darkMode,
          )}
        >
          <label>Comments</label>
          <input
            type="text"
            value={
              contract.AuthorityClauseComment
                ? contract.AuthorityClauseComment
                : ""
            }
            onChange={(e) => updateValues(e, "AuthorityClauseComment")}
          />
        </div>
      </li>
      <li className={styles.evaluationItem}>
        <div className={styles.dropdownContainer}>
          <label>Exclusivity Reciprocal</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.ExclusivityReciprocal
                ? contract.ExclusivityReciprocal
                : "N/A"
            }
            onChange={(e) => updateValues(e, "ExclusivityReciprocal")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div className={styles.dropdownContainer}>
          <label>Needs Improvement?</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.ExclusivityReciprocalImpr
                ? contract.ExclusivityReciprocalImpr
                : "N/A"
            }
            onChange={(e) => updateValues(e, "ExclusivityReciprocalImpr")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div
          className={cx(
            styles.commentContainer,
            theme.darkMode && styles.darkMode,
          )}
        >
          <label>Comments</label>
          <input
            type="text"
            value={
              contract.ExclusivityReciprocalComment
                ? contract.ExclusivityReciprocalComment
                : ""
            }
            onChange={(e) => updateValues(e, "ExclusivityReciprocalComment")}
          />
        </div>
      </li>
      <li className={styles.evaluationItem}>
        <div className={styles.dropdownContainer}>
          <label>Key Individuals</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={contract.KeyIndividuals ? contract.KeyIndividuals : "N/A"}
            onChange={(e) => updateValues(e, "KeyIndividuals")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div className={styles.dropdownContainer}>
          <label>Needs Improvement?</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.KeyIndividualsImpr ? contract.KeyIndividualsImpr : "N/A"
            }
            onChange={(e) => updateValues(e, "KeyIndividualsImpr")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div
          className={cx(
            styles.commentContainer,
            theme.darkMode && styles.darkMode,
          )}
        >
          <label>Comments</label>
          <input
            type="text"
            value={
              contract.KeyIndividualsComment
                ? contract.KeyIndividualsComment
                : ""
            }
            onChange={(e) => updateValues(e, "KeyIndividualsComment")}
          />
        </div>
      </li>
      <li className={styles.evaluationItem}>
        <div className={styles.dropdownContainer}>
          <label>Transparent Remuneration</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.TransparentRemuneration
                ? contract.TransparentRemuneration
                : "N/A"
            }
            onChange={(e) => updateValues(e, "TransparentRemuneration")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div className={styles.dropdownContainer}>
          <label>Needs Improvement?</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.TransparentRemunerationImpr
                ? contract.TransparentRemunerationImpr
                : "N/A"
            }
            onChange={(e) => updateValues(e, "TransparentRemunerationImpr")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div
          className={cx(
            styles.commentContainer,
            theme.darkMode && styles.darkMode,
          )}
        >
          <label>Comments</label>
          <input
            type="text"
            value={
              contract.TransparentRemunerationComment
                ? contract.TransparentRemunerationComment
                : ""
            }
            onChange={(e) => updateValues(e, "TransparentRemunerationComment")}
          />
        </div>
      </li>
      <li className={styles.evaluationItem}>
        <div className={styles.dropdownContainer}>
          <label>PRF Included</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={contract.PRFIncluded ? contract.PRFIncluded : "N/A"}
            onChange={(e) => updateValues(e, "PRFIncluded")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div className={styles.dropdownContainer}>
          <label>Needs Improvement?</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={contract.PRFIncludedImpr ? contract.PRFIncludedImpr : "N/A"}
            onChange={(e) => updateValues(e, "PRFIncludedImpr")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div
          className={cx(
            styles.commentContainer,
            theme.darkMode && styles.darkMode,
          )}
        >
          <label>Comments</label>
          <input
            type="text"
            value={
              contract.PRFIncludedComment ? contract.PRFIncludedComment : ""
            }
            onChange={(e) => updateValues(e, "PRFIncludedComment")}
          />
        </div>
      </li>
      <li className={styles.evaluationItem}>
        <div className={styles.dropdownContainer}>
          <label>PRF With Malus</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={contract.PRFWithMalus ? contract.PRFWithMalus : "N/A"}
            onChange={(e) => updateValues(e, "PRFWithMalus")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div className={styles.dropdownContainer}>
          <label>Needs Improvement?</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.PRFWithMalusImpr ? contract.PRFWithMalusImpr : "N/A"
            }
            onChange={(e) => updateValues(e, "PRFWithMalusImpr")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div
          className={cx(
            styles.commentContainer,
            theme.darkMode && styles.darkMode,
          )}
        >
          <label>Comments</label>
          <input
            type="text"
            value={
              contract.PRFWithMalusComment ? contract.PRFWithMalusComment : ""
            }
            onChange={(e) => updateValues(e, "PRFWithMalusComment")}
          />
        </div>
      </li>
      <li className={styles.evaluationItem}>
        <div className={styles.dropdownContainer}>
          <label>PRF Methodology</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={contract.PRFMethodology ? contract.PRFMethodology : "N/A"}
            onChange={(e) => updateValues(e, "PRFMethodology")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div className={styles.dropdownContainer}>
          <label>Needs Improvement?</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.PRFMethodologyImpr ? contract.PRFMethodologyImpr : "N/A"
            }
            onChange={(e) => updateValues(e, "PRFMethodologyImpr")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div
          className={cx(
            styles.commentContainer,
            theme.darkMode && styles.darkMode,
          )}
        >
          <label>Comments</label>
          <input
            type="text"
            value={
              contract.PRFMethodologyComment
                ? contract.PRFMethodologyComment
                : ""
            }
            onChange={(e) => updateValues(e, "PRFMethodologyComment")}
          />
        </div>
      </li>
      <li className={styles.evaluationItem}>
        <div className={styles.dropdownContainer}>
          <label>Agency Commitments</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.AgencyCommitments ? contract.AgencyCommitments : "N/A"
            }
            onChange={(e) => updateValues(e, "AgencyCommitments")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div className={styles.dropdownContainer}>
          <label>Needs Improvement?</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.AgencyCommitmentsImpr
                ? contract.AgencyCommitmentsImpr
                : "N/A"
            }
            onChange={(e) => updateValues(e, "AgencyCommitmentsImpr")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div
          className={cx(
            styles.commentContainer,
            theme.darkMode && styles.darkMode,
          )}
          value={
            contract.AgencyCommitmentsComment
              ? contract.AgencyCommitmentsComment
              : ""
          }
          onChange={(e) => updateValues(e, "AgencyCommitmentsComment")}
        >
          <label>Comments</label>
          <input
            type="text"
            value={
              contract.AgencyCommitmentsComment
                ? contract.AgencyCommitmentsComment
                : ""
            }
            onChange={(e) => updateValues(e, "AgencyCommitmentsComment")}
          />
        </div>
      </li>
      <li className={styles.evaluationItem}>
        <div className={styles.dropdownContainer}>
          <label>Audit Rights</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={contract.AuditRights ? contract.AuditRights : "N/A"}
            onChange={(e) => updateValues(e, "AuditRights")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div className={styles.dropdownContainer}>
          <label>Needs Improvement?</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={contract.AuditRightsImpr ? contract.AuditRightsImpr : "N/A"}
            onChange={(e) => updateValues(e, "AuditRightsImpr")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div
          className={cx(
            styles.commentContainer,
            theme.darkMode && styles.darkMode,
          )}
        >
          <label>Comments</label>
          <input
            type="text"
            value={
              contract.AuditRightsComment ? contract.AuditRightsComment : ""
            }
            onChange={(e) => updateValues(e, "AuditRightsComment")}
          />
        </div>
      </li>
      <li className={styles.evaluationItem}>
        <div className={styles.dropdownContainer}>
          <label>Rebates Clauses</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={contract.RebatesClauses ? contract.RebatesClauses : "N/A"}
            onChange={(e) => updateValues(e, "RebatesClauses")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div className={styles.dropdownContainer}>
          <label>Needs Improvement?</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.RebatesClausesImpr ? contract.RebatesClausesImpr : "N/A"
            }
            onChange={(e) => updateValues(e, "RebatesClausesImpr")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div
          className={cx(
            styles.commentContainer,
            theme.darkMode && styles.darkMode,
          )}
        >
          <label>Comments</label>
          <input
            type="text"
            value={
              contract.RebatesClausesComment
                ? contract.RebatesClausesComment
                : ""
            }
            onChange={(e) => updateValues(e, "RebatesClausesComment")}
          />
        </div>
      </li>
      <li className={styles.evaluationItem}>
        <div className={styles.dropdownContainer}>
          <label>EPD Clauses</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={contract.EPDClause ? contract.EPDClause : "N/A"}
            onChange={(e) => updateValues(e, "EPDClause")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div className={styles.dropdownContainer}>
          <label>Needs Improvement?</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={contract.EPDClauseImpr ? contract.EPDClauseImpr : "N/A"}
            onChange={(e) => updateValues(e, "EPDClauseImpr")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div
          className={cx(
            styles.commentContainer,
            theme.darkMode && styles.darkMode,
          )}
        >
          <label>Comments</label>
          <input
            type="text"
            value={contract.EPDClausesComment ? contract.EPDClausesComment : ""}
            onChange={(e) => updateValues(e, "EPDClausesComment")}
          />
        </div>
      </li>
      <li className={styles.evaluationItem}>
        <div className={styles.dropdownContainer}>
          <label>Unbilled Media Clause</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.UnbilledMediaClause
                ? contract.UnbilledMediaClause
                : "N/A"
            }
            onChange={(e) => updateValues(e, "UnbilledMediaClause")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div className={styles.dropdownContainer}>
          <label>Needs Improvement?</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.UnbilledMediaClauseImpr
                ? contract.UnbilledMediaClauseImpr
                : "N/A"
            }
            onChange={(e) => updateValues(e, "UnbilledMediaClauseImpr")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div
          className={cx(
            styles.commentContainer,
            theme.darkMode && styles.darkMode,
          )}
        >
          <label>Comments</label>
          <input
            type="text"
            value={
              contract.UnbilledMediaClauseComment
                ? contract.UnbilledMediaClauseComment
                : ""
            }
            onChange={(e) => updateValues(e, "UnbilledMediaClauseComment")}
          />
        </div>
      </li>
      <li className={styles.evaluationItem}>
        <div className={styles.dropdownContainer}>
          <label>Competitive Payment Terms</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.CompetitivePaymentTerms
                ? contract.CompetitivePaymentTerms
                : "N/A"
            }
            onChange={(e) => updateValues(e, "CompetitivePaymentTerms")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div className={styles.dropdownContainer}>
          <label>Needs Improvement?</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.CompetitivePaymentTermsImpr
                ? contract.CompetitivePaymentTermsImpr
                : "N/A"
            }
            onChange={(e) => updateValues(e, "CompetitivePaymentTermsImpr")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div
          className={cx(
            styles.commentContainer,
            theme.darkMode && styles.darkMode,
          )}
        >
          <label>Comments</label>
          <input
            type="text"
            value={contract.AgentOnlyComment ? contract.AgentOnlyComment : ""}
            onChange={(e) => updateValues(e, "AgentOnlyComment")}
          />
        </div>
      </li>
      <li className={styles.evaluationItem}>
        <div className={styles.dropdownContainer}>
          <label>Standard Termination Clause</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.StandardTerminationClause
                ? contract.StandardTerminationClause
                : "N/A"
            }
            onChange={(e) => updateValues(e, "StandardTerminationClause")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div className={styles.dropdownContainer}>
          <label>Needs Improvement?</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.StandardTerminationClauseImpr
                ? contract.StandardTerminationClauseImpr
                : "N/A"
            }
            onChange={(e) => updateValues(e, "StandardTerminationClauseImpr")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div
          className={cx(
            styles.commentContainer,
            theme.darkMode && styles.darkMode,
          )}
        >
          <label>Comments</label>
          <input
            type="text"
            value={
              contract.StandardTerminationClauseComment
                ? contract.StandardTerminationClauseComment
                : ""
            }
            onChange={(e) =>
              updateValues(e, "StandardTerminationClauseComment")
            }
          />
        </div>
      </li>
      <li className={styles.evaluationItem}>
        <div className={styles.dropdownContainer}>
          <label>Standard Subcontracting Clause</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.StandardSubcontractingClause
                ? contract.StandardSubcontractingClause
                : "N/A"
            }
            onChange={(e) => updateValues(e, "StandardSubcontractingClause")}
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div className={styles.dropdownContainer}>
          <label>Needs Improvement?</label>
          <select
            className={cx(
              styles.evaluationSelect,
              theme.darkMode && styles.darkMode,
            )}
            value={
              contract.StandardSubcontractingClauseImpr
                ? contract.StandardSubcontractingClauseImpr
                : "N/A"
            }
            onChange={(e) =>
              updateValues(e, "StandardSubcontractingClauseImpr")
            }
          >
            <option>Yes</option>
            <option>No</option>
            <option>N/A</option>
          </select>
        </div>
        <div
          className={cx(
            styles.commentContainer,
            theme.darkMode && styles.darkMode,
          )}
        >
          <label>Comments</label>
          <input
            type="text"
            value={
              contract.StandardSubcontractingClauseComment
                ? contract.StandardSubcontractingClauseComment
                : ""
            }
            onChange={(e) =>
              updateValues(e, "StandardSubcontractingClauseComment")
            }
          />
        </div>
      </li>
    </ul>
  );
};

export default ContractEvaluation;
