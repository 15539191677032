import React from 'react'
import OnboardingId from '../features/onboarding/OnboardingId'

const OnboardingView = () => {
  return (
    <OnboardingId />
  )
}

export default OnboardingView
