import React, { useContext, useEffect, useState, } from 'react'
import { EmployeeContext } from '../../contexts/EmployeeContext';
import styles from "./OnboardingId.module.scss";
import AnonPhoto from "../../assets/media/anon.jpeg";
import { Menu } from "@mui/material";
import { UpdateOnboardingItem } from '../../services/authServices';
import { ThemeContext } from '../../contexts/ThemeContext';
import cx from 'classnames'

interface Props {
  person: string | null,
  itemId: number
}

const Person: React.FC<Props> = ({ person, itemId }) => {
  const [associatedEmployees, setAssociatedEmployees] = useState<string | null>(person);
  const [personMenuAnchorEl, setPersonMenuAnchorEl] = useState<any>(null);
  const [openMenu, setOpenMenu] = useState(false);
  const employees = useContext(EmployeeContext).employees;
  const theme = useContext(ThemeContext)

  const handleMenu = (e: any) => {
    if (openMenu) {        
        setOpenMenu(false)
    }
    else {
        setOpenMenu(true);
        setPersonMenuAnchorEl(e.currentTarget)
    }
  }

  const updateEmployees = async (id: any) => {
    var newEmployees;
    if (associatedEmployees == null) {
        newEmployees = id.toString();
        setAssociatedEmployees(newEmployees);
    }
    else {
        newEmployees = associatedEmployees + `,${id}`;
        setAssociatedEmployees(newEmployees)
    }

    await UpdateOnboardingItem(newEmployees, "Person", itemId)
  }

  const deleteEmployee = async (id: number) => {
    var newIds = associatedEmployees?.split(",").map(Number).filter(x => x !== id);
    if (newIds) {
        if (newIds.length == 0) {
            setAssociatedEmployees(null);
        }
        else {
            setAssociatedEmployees(newIds?.join(','))
        }
    }
  }

  return (
    <div onClick={(e) => handleMenu(e)} id={styles.personCell} className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ width: '10%' }}>
      {
        associatedEmployees == null ? (
            <img src={AnonPhoto} style={{ height: '25px', width: '25px', borderRadius: '50px' }} />
        ) : (
            // <div className={styles.associatedEmployeeImageList}>
            <>
              {
                employees.map((employee: any) => {
                    if (associatedEmployees.split(",").map(Number).includes(employee.id)) {
                        return (
                            <img src={employee.photo} style={{ height: '25px', width: '25px', borderRadius: '50px', objectFit: 'cover' }} />
                        )
                    }
                })
              }
              </>
            // </div>
        )
      }
      <Menu
        open={openMenu}
        anchorEl={personMenuAnchorEl}
        onClose={(e) => handleMenu(e)}
        sx={{
            "& .MuiPaper-root": {
              // height: "px",
              // width: "400px",
              backgroundColor: "#FFFFFF",
              padding: "10px 20px",            
            },
        }}
      >
        {
            associatedEmployees != null && (
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px' }}>
                  <p style={{ marginRight: '10px' }}>Current:</p>
                  {
                    employees.map((employee: any) => {
                        if (associatedEmployees.split(",").map(Number).includes(employee.id)) {
                            return (
                                <div className={styles.deleteEmployee} onClick={() => deleteEmployee(employee.id)}>
                                    <img src={employee.photo} style={{ height: '35px', width: '35px', marginRight: '10px', borderRadius: '50px', objectFit: 'cover' }} />
                                    <p>X</p>
                                </div>
                            )
                        }
                    })
                  }
                </div>
            )
        }
        <div className={styles.employeeImageList}>
            {
                employees.map((person: any) => {
                    return (
                        <img 
                          src={person.photo} 
                          style={{ height: '35px', width: '35px', borderRadius: '50px', objectFit: 'cover' }} 
                          onClick={() => updateEmployees(person.id)}
                        />
                    )
                })
            }
        </div>
      </Menu>
    </div> 
  )
}

export default Person
