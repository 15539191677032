import React, { useContext, useEffect, useState, useRef } from 'react'
import { ThemeContext } from '../../contexts/ThemeContext'
import { AuthContext } from '../../contexts/AuthContext'
import styles from "./AccountsView.module.scss"
import cx from 'classnames'
import { Menu, Dialog } from "@mui/material"
import { ChangeUserRole, DeleteUser, HandleNewRequest, ChangeUserAccess } from '../../services/authServices'

interface EmployeeProps {
  name: string,
  email: any,
  role: string,    
  photo: string | null,
  id: number,
  company: string,  
}

const EmployeeItem: React.FC<EmployeeProps> = ({ name, email, role, photo, id, company }) => {
  const [expanded, setExpanded] = useState(false);
  const [roleMenu, setRoleMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userRole, setUserRole] = useState(role);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [requestDialog, setRequestDialog] = useState(false);
  const [accessDialog, setAccessDialog] = useState(false);
  const [requestAction, setRequestAction] = useState<number | null>(null);  
  const theme = useContext(ThemeContext);
  const authContext = useContext(AuthContext);  
  const accessDialogRef = useRef<HTMLDivElement>(null)

  const handleExpand = (e: any) => { 
    if (!expanded) {
      setExpanded(true);
      setTimeout(() => document.getElementById(`extra-info-${id}`)?.classList.add(styles.expanded), 200)      
    }
    else {
        if (e.target.id !== "option-button" && !accessDialogRef.current?.contains(e.target)) {
            document.getElementById(`extra-info-${id}`)?.classList.remove(styles.expanded)            
            setExpanded(false)
        }
    }
  }

  const handleMenu = (e: any) => {    
    if (!roleMenu) {
        setRoleMenu(true)
        setAnchorEl(e.target)
    }
    else {        
        setRoleMenu(false)
    }
  }

  const changeRole = async (roleId: number) => {
    setAnchorEl(null)
    setRoleMenu(false)
    await ChangeUserRole(roleId, id)
    .then((res: any) => {
        if (res.status == 200) {
          if (roleId == 2) setUserRole("Admin")
          else setUserRole("User")
        }
    })
  }

  const handleDialog = () => {
    if (!deleteDialog) {
        setDeleteDialog(true)
    }
    else {
        setDeleteDialog(false)
    }
  }

  const deleteUser = async () => {
    await DeleteUser(id)
    .then((res: any) => {
      if (res.status == 200) {
          window.location.reload()
      }
    })
  }

  const handleRequestDialog = (action: number | null) => {
    if (!requestDialog) {
        setRequestAction(action)
        setRequestDialog(true);
    }
    else {
        setRequestDialog(false);
        setRequestAction(null);
    }
  }

  const handleNewRequest = async (action: number | null, userId: number) => {
    if (action !== null) {
        await HandleNewRequest(action, id)
        .then((res: any) => {            
          if (res == 'OK') {
            window.location.reload()
          }
        })
    }
  }   

  const renderRequestDialog = () => {
    let header;
    let subHeader;
    if (requestAction == 2) {
        header = `Give ${name} Admin Access?`
        subHeader = `${name} will have all the privileges of an Admin, regardless of whether they are a member of Abintus.`
    }
    else if (requestAction == 1) {
        header = `Give ${name} Access?`
        subHeader = `${name} will be able to access most features of Abintus Solutions.`
    }
    else if (requestAction == 0) {
        header = `Deny ${name}'s Request?`
        subHeader = `${name}'s info and account will be deleted. They will have to make a new request if they need access to Abintus Solutions.`
    }
    
    return (
        <>
            <h1 style={{ margin: "0 0 20px 0" }}>{header}</h1>
            <p style={{ margin: "0 0 40px 0" }}>{subHeader}</p>
            <div>
                <button onClick={() => handleNewRequest(requestAction, id)} style={{ backgroundColor: "#000000", color: "#FFFFFF" }} className={styles.dialogButton} >Confirm</button>
                <button onClick={() => handleRequestDialog(null)} style={{ border: 'solid 1px #cacaca', backgroundColor: 'transparent', color: "#000000" }} className={styles.dialogButton} >Cancel</button>
            </div>
        </>
    )    
  }

  return (
    <div className={cx(styles.employeeItem, theme.darkMode && styles.darkMode, expanded && styles.expanded)} onClick={(e) => handleExpand(e)}>
        <div className={cx(styles.preliminaryInfo, expanded && styles.expanded)} onClick={(e) => handleExpand(e)}>
            {
                photo == null ? (
                    <div className={cx(styles.initialsDiv, expanded && styles.expanded)}>{name.split(' ')[0].charAt(0)}{name.split(' ')[1].charAt(0)}</div>
                ) : (
                    <img src={photo} alt="profile-photo"/>
                )
            }
            <div>
                <p>{name}</p>
                <p style={{ color: theme.darkMode ? "#F1C102" : "#4281A3", fontSize: '0.75rem' }}>{userRole}</p>
            </div>
        </div>
        <div className={styles.expandedItems} id={`extra-info-${id}`}>
            <div className={styles.extraInfo}>
                <p><strong>Email</strong> {email}</p>
                <p><strong>Joined</strong> June 2023</p>
                <p><strong>Company</strong> {company}</p>
                <p><strong>Role</strong> {role}</p>
            </div>
            
                {
                    role !== "Pending Request" ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <button id="option-button" onClick={(e) => handleMenu(e)} style={{ backgroundColor: theme.darkMode ? "#252423" : "#024863", color: "#FFFFFF" }}>Change Role</button>
                          <button id="option-button" onClick={() => handleDialog()} style={{ backgroundColor: "#9C0E0E", color: "#FFFFFF" }}>Delete User</button>                        
                        </div>
                    ) : (
                        <>
                          <button id="option-button" onClick={() => handleRequestDialog(2)} style={{ backgroundColor: "#388734", border: "solid 1px #388734", color: "#FFFFFF" }}>Grant Access as Admin</button>
                          <button id="option-button" onClick={() => handleRequestDialog(1)} style={{ backgroundColor: "transparent", border: "solid 1px #388734", color: theme.darkMode ? "#FFFFFF" : "#000000", }}>Grant Access as User</button>
                          <button id="option-button" onClick={() => handleRequestDialog(0)} style={{ backgroundColor: "#9C0E0E", border: "solid 1px #9C0E0E", color: "#FFFFFF" }}>Deny Access</button>
                        </>
                    )
                }
            
            <Menu
                open={roleMenu}
                onClose={handleMenu}                
                anchorEl={anchorEl}
                sx={{
                  "& .MuiPaper-root": {
                    width: '250px',
                    padding: "5px 15px",
                    fontSize: '0.85rem',
                  }
                }}
            >
                <p style={{ fontWeight: 'bold' }}>Change Role</p>
                <ul className={styles.roleList}>
                    <li onClick={() => changeRole(2)}><p>Administrator <strong style={{ color: "#4281A3" }}>{ role == "Admin" && "Selected"}</strong></p></li>
                    <li onClick={() => changeRole(1)}><p>User (No Admin priveleges) <strong style={{ color: "#4281A3" }}>{ role == "User" && "Selected"}</strong></p></li>
                </ul>
            </Menu>
            <Dialog
              open={deleteDialog}
              onClose={handleDialog}
              sx={{
                "& .MuiPaper-root": {
                  // height: "50vh",
                  width: "35vw",
                  padding: "30px 20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  boxSizing: "border-box",
                  textAlign: 'center'
                },
              }} 
            >
                <h1 style={{ margin: "0 0 20px 0" }}>Delete {name}'s Account?</h1>
                <p style={{ margin: "0 0 40px 0" }}>This action cannot be undone.</p>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <button onClick={() => deleteUser()} style={{ backgroundColor: "#9C0E0E", color: "#FFFFFF" }} className={styles.dialogButton} >Delete</button>
                    <button onClick={() => handleDialog()} style={{ border: 'solid 1px #cacaca', backgroundColor: 'transparent', color: "#000000" }} className={styles.dialogButton} >Cancel</button>
                </div>
            </Dialog>
            <Dialog
              open={requestDialog}
              onClose={() => handleRequestDialog(null)}
              sx={{
                "& .MuiPaper-root": {
                  // height: "50vh",
                  width: "40vw",
                  padding: "30px 20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  boxSizing: "border-box",
                  textAlign: 'center'
                },
              }} 
            >
              {renderRequestDialog()}
            </Dialog>            
        </div>
    </div>
  )
}

export default EmployeeItem
