import React, { useContext, useEffect, useState, useRef } from "react";
import styles from "./StatusCell.module.scss";
import { Menu } from "@mui/material";
import { UpdateProject, UpdateProjectSubitem, GetStatuses, UpdateStatusSticker } from "../../../services/authServices";
import { ThemeContext } from "../../../contexts/ThemeContext";
import EditWhite from "../../../assets/media/edit-white.png";
import EditBlack from "../../../assets/media/edit-black.png";
import cx from "classnames";
import { FlexboxGrid } from "rsuite";

const StatusCell = (props: any) => {
  const [open, setOpen] = useState(false);  
  const [anchorEl, setAnchorEl] = useState(null);
  const [column, setColumn] = useState(props.column);
  const [status, setStatus] = useState<string>(props.value);
  const [displayColorMenu, setDisplayColorMenu] = useState(false);
  const [editing, setEditing] = useState(false);
  const [subAnchorEl, setSubAnchorEl] = useState(null);
  const [openSubmenu, setOpenSubmenu] = useState<number | null>(null);
  const newStickerVal = useRef<any>(null);
  const theme = useContext(ThemeContext);
  var staticColors = [
    "#14E0FF", "#11DAC9", "#12E1A3", "#15E87B", "#10DA46", "#11CD1B", "#2DBF0F", "#5AE703", "#94EA12",
    "#BAE011", "#F1EA15", "#FFC414", "#FF9214", "#FF5F14", "#FF2C14", "#FF142C", "#FF145F", "#FF1492",
    "#FF14C4", "#FF14F7", "#D414FF", "#A114FF", "#6E14FF", "#3B14FF"
  ]
  const [newStickerColor, setNewStickerColor] = useState(staticColors[Math.floor(Math.random() * 24)])

  const handleMenu = (e: any) => {
    if (anchorEl == null) {
      setAnchorEl(e.currentTarget);
    }
    setOpen((prev) => !prev);
  };

  const update = (key: any) => {
    setStatus(key);    

    props.updateStatus(props.projectId, props.column, key, props.subitemId)
    if (props.subitemId !== null) {
      UpdateProjectSubitem(props.subitemId, props.projectId, props.column, key);
    } else {
      UpdateProject(props.projectId, props.chartId, `${props.column}`, key);
    }
    setOpen(false);
  };

  const addStatus = async () => {
    const value: any = newStickerVal.current!.value;
    var column = props.column == "Priority" ? "Priority" : "Status";

    newStickerVal.current.value = ""

    props.newStatus(value, column, newStickerColor);
  }

  const updateStatusSticker = async (value: string, id: number, column: string) => {
    if (column == 'Color') {
      var colorDiv = document.getElementById(`edit-sticker-color-${id}`);

      if (colorDiv) {
        colorDiv.style.backgroundColor = value;
      }
    }
    await UpdateStatusSticker(id, column, value)
    .then((res) => {
      
    })

    props.getChart();
  }

  const handleSubMenu = (e: any, id: number) => {
    if (subAnchorEl !== null) {
      setOpenSubmenu(null);
      setSubAnchorEl(null);
    }
    else {
      setOpenSubmenu(id);
      setSubAnchorEl(e.currentTarget);
    }
  }

  return (
    <>
      {
        props.statuses !== undefined && props.statuses.length > 0 && (
          <>
            <div
              className={cx(styles.container, theme.darkMode && styles.darkMode)}
              style={{
                backgroundColor: status == '' ? theme.darkMode ? '#707070' : '#E3E3E3' : props.statuses.find((x: any) => x.Status == status).Color,
                minWidth: props.width,
              }}
              onClick={(e) => handleMenu(e)}
            >
              <p>{status}</p>
            </div>
            <Menu
              open={open}
              onClose={(e) => handleMenu(e)}
              anchorEl={anchorEl}
              sx={{
                "& .MuiPaper-root": {
                  // height: "350px",
                  width: "480px",                                    
                  backgroundColor: theme.darkMode ? "#252423" : "#FFFFFF",
                  padding: "20px",
                },
              }}
            >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                marginBottom: '10px'
              }}
            >
            {
              props.column == "Priority"
                ? props.statuses.filter((x: any) => x.Category == "Priority").map((status: any) => {
                  return (
                    <div
                      key={status.Id}
                      style={{ backgroundColor: status.Color }}
                      className={cx(
                        styles.colorDiv,
                        theme.darkMode && styles.darkMode,
                      )}
                      onClick={() => update(status.Status)}
                    >
                      <p style={{ fontSize: '0.85rem', fontWeight: '700'}}>{status.Status}</p>
                    </div>
                  );
                })
                : props.statuses.filter((x: any) => x.Category == "Status").map((status: any) => {
                    var blankColor = theme.darkMode ? "#707070" : "#E3E3E3"
                    
                    return editing ? (
                      <div className={cx(styles.editDiv, theme.darkMode && styles.darkMode)}>
                        <div id={`edit-sticker-color-${status.Id}`} onClick={(e) => handleSubMenu(e, status.Id)} className={styles.newColorDiv} style={{ height: '30px', width: '30px', marginRight: "7px", backgroundColor: `${status.Color}`}}></div>
                        <Menu
                          open={openSubmenu == status.Id}
                          anchorEl={subAnchorEl}
                          onClose={(e) => handleSubMenu(e, status.Id)}
                          sx={{
                            "& .MuiPaper-root": {
                              backgroundColor: theme.darkMode ? "#252423" : "#FFFFFF",
                              width: "300px",
                              padding: "20px",                              
                            },
                          }}
                        >
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>                          
                            {
                              staticColors.map((color) => {
                                return (
                                  <div 
                                  onClick={() => updateStatusSticker(color, status.Id, "Color")}
                                  className={styles.newColorDiv} 
                                  style={{ backgroundColor: `${color}`, margin: '3px 6px 3px 0'}}                                
                                  >                  
                                  </div>              
                                )
                              })
                            }
                          </div>
                        </Menu>
                        <input placeholder={status.Status} onChange={(e) => updateStatusSticker(e.currentTarget.value, status.Id, "Status")}/>
                      </div>
                    ) : (
                      <div
                        key={status.Id}
                        style={{ backgroundColor: status.Status == "" ? blankColor : status.Color }}
                        className={cx(
                          styles.colorDiv,
                          theme.darkMode && styles.darkMode,
                        )}
                        onClick={() => update(status.Status)}
                      >
                        <p style={{ fontSize: '0.85rem', fontWeight: '700'}}>{status.Status}</p>
                      </div>
                    );
                  })}                
              </div>
              <div style={{ marginBottom: '20px', width: '100%', textAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div onClick={() => setDisplayColorMenu(prev => !prev)} className={styles.newColorDiv} style={{ backgroundColor: newStickerColor }}></div>
                <input ref={newStickerVal} type="text" placeholder="New Sticker" className={styles.newSticker} style={{ color: theme.darkMode ? "#FFFFFF" : "#000000" }} />
                <button onClick={() => addStatus()} className={styles.newStickerButton}>Add</button>
              </div>
              <div style={{ display: displayColorMenu ? 'flex' : 'none', justifyContent: 'flex-start', flexWrap: 'wrap'}}>
                {
                  staticColors.map((color) => {
                    return (
                      <div 
                      className={styles.newColorDiv} 
                      style={{ backgroundColor: `${color}`, margin: '3px 6px 3px 0'}}
                      onClick={() => {
                        setNewStickerColor(color);
                        setDisplayColorMenu(false);
                      }} 
                      >                  
                      </div>              
                    )
                  })
                }
              </div>     
              <div onClick={() => setEditing(prev => !prev)} className={cx(styles.editButton, theme.darkMode && styles.darkMode)}>
                <img src={theme.darkMode ? EditWhite : EditBlack} alt="edit" />
                <p>Edit Labels</p>  
              </div>   
            </Menu>
          </>
        )
      }      
    </>
  );
};

export default StatusCell;
