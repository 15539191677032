import React, { useState, createContext, useContext, useEffect } from "react";
import { act } from "react-dom/test-utils";
import { GetAllUsers } from "../services/authServices";

type EmployeeContextType = {
    employees: any
}

type EmployeeContextProviderType = {
    children: React.ReactNode;
};

const EmployeeContext = createContext({} as EmployeeContextType);

const EmployeeProvider = ({ children }: EmployeeContextProviderType) => {  
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const getEmployees = async () => {
        try {
            const res: any = await GetAllUsers();
            var activeEmployees = res.filter((user: any) => user.role !== 0);
            setEmployees(activeEmployees);
        }
        catch (err) {
            console.log(err);
        }
    }
    
    getEmployees();
  }, [])


  return (
    <EmployeeContext.Provider value={{ employees }}>
      {children}
    </EmployeeContext.Provider>
  )
}

export { EmployeeContext, EmployeeProvider }
