import React, { useState, useEffect, useContext, useRef } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';
import { GetContacts, DeleteContacts } from '../../services/authServices';
import styles from "./styles/Table.module.scss";
import cx from "classnames";
import {
    Table,
    TableContainer,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Menu,
    Dialog
  } from "@mui/material";
import DotMenuWhite from '../../assets/media/dots-vertical-white.png'
import DotMenuBlack from '../../assets/media/dots-vertical-black.png'
import CopyIcon from '../../assets/media/copy-icon.png';
import CopyIconWhite from '../../assets/media/copy-icon-white.png';
import FilterMenu from './FilterMenu';

interface TableProps {
  collapsed: boolean,
  newContact: boolean,
  newFile: boolean
}

interface Filters {
  Name: Array<string>
  Title:  Array<string>,
  ProvidedDate: Array<string>,
  AgencyGroup: Array<string>,
  Agency: Array<string>,
  Country: Array<string>,
  Email: Array<string>,
  Phone: Array<string>,
}

const ContactTable: React.FC<TableProps> = ({ collapsed, newContact, newFile }) => {
  const [baseContacts, setBaseContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const theme = useContext(ThemeContext);
  const [openMenu, setOpenMenu] = useState("");
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [toDelete, setToDelete] = useState<Array<number>>([]);
  const [filters, setFilters] = useState<Filters>({
    Name: [],
    Title: [],
    ProvidedDate: [],
    AgencyGroup: [],
    Agency: [],
    Country: [],
    Email: [],
    Phone: [],
  })
  const [filterVals, setFilterVals] = useState<Filters>({
    Name: [],    
    Title: [],
    ProvidedDate: [],
    AgencyGroup: [],
    Agency: [],
    Country: [],
    Email: [],
    Phone: [],
  })

  const columnKeyMap: { [key: string]: keyof Filters } = {
    "Name": "Name",
    "Provided Date": "ProvidedDate",
    "Title": "Title",
    "Agency Group": "AgencyGroup",
    "Agency": "Agency",
    "Country": "Country",
    "Email": "Email",
    "Phone": "Phone"
  };  

  const COLUMNS = ["Name", "Provided Date", "Title", "Agency Group", "Agency", "Country", "Email", "Phone", "Notes"]

  useEffect(() => {
    const getContacts = async () => {
      let newContacts: any = await GetContacts();

      let newFilterVals: Filters = {
        Name: [],
        Title: [],
        ProvidedDate: [],
        AgencyGroup: [],
        Agency: [],
        Country: [],
        Email: [],
        Phone: [],
      }

      const isKeyOfFilters = (key: string): key is keyof Filters => {
        return key in newFilterVals;
      };

      newContacts.forEach((contact: any) => {
        Object.keys(contact).forEach((key: string) => {
          if (key == "PhoneNumber") {
            if (!newFilterVals.Phone.includes(contact.PhoneNumber) && contact.PhoneNumber !== "") {
              newFilterVals.Phone.push(contact.PhoneNumber)
            }
          }
          else if (isKeyOfFilters(key)) {
            if (!newFilterVals[key as keyof Filters].includes(contact[key]) && contact[key] !== "") {
              newFilterVals[key].push(contact[key])
            }
          }
        })
      })
        // newFilterVals.Name.push(contact.Name)
        // newFilterVals.Title.push(contact.Title)
        // newFilterVals.ProvidedDate.push(contact.ProvidedDate)
        // newFilterVals.AgencyGroup.push(contact.AgencyGroup)
        // newFilterVals.Agency.push(contact.Agency)
        // newFilterVals.Country.push(contact.Country)
        // newFilterVals.Email.push(contact.Email)
        // newFilterVals.Phone.push(contact.PhoneNumber)

      setFilterVals(newFilterVals);
      setBaseContacts(newContacts);
      setFilteredContacts(newContacts)
    }
    getContacts();
  }, [newContact, newFile])  

  useEffect(() => {
    console.log(filterVals)
  }, [filterVals])

  const bgHelper = (index: number) => {
    if (index % 2 == 0) {
      return theme.darkMode ? "#252423" : "#EEEEEE"
    }
    else {
      return theme.darkMode ? "#2d2d2d" : "#FFF";
    }
  }

  const handleFilterMenu = (e: any, column: string) => {        
    if (openMenu == "") {      
      setOpenMenu(column);
      setMenuAnchorEl(e.currentTarget);
    }
  }

  const copyToClipboard = (column: string) => {
    let result = filteredContacts.map((contract: any) => contract[column]);
    let joined = result.join(" ");    
    
    navigator.clipboard.writeText(joined)
    .then(() => alert("Items Copied"));
  }

  const handleFilters = async (val: string, col: keyof Filters) => {    
    let newArr = filters[col].includes(val) ? filters[col].filter((value: string) => value !== val) : [...filters[col], val]

    let newFilters = {
      ...filters,
      [col] : newArr
    }
        
    setFilters(newFilters);      
  }

  const applyFilters = (unfilteredRows: any) => {        
    let test: any = [];

    test = unfilteredRows.filter((row: any) => {
      return (
        (filters.Name.length == 0 || filters.Name.includes(row.Name)) &&
        (filters.Title.length == 0 || filters.Title.includes(row.Title)) &&
        (filters.ProvidedDate.length == 0 || filters.ProvidedDate.includes(row.ProvidedDate)) &&
        (filters.AgencyGroup.length == 0 || filters.AgencyGroup.includes(row.AgencyGroup)) &&
        (filters.Agency.length == 0 || filters.Agency.includes(row.Agency)) &&
        (filters.Country.length == 0 || filters.Country.includes(row.Country)) &&
        (filters.Email.length == 0 || filters.Email.includes(row.Email)) &&
        (filters.Phone.length == 0 || filters.Phone.includes(row.Phone))
      )
    })

    setFilteredContacts(test)
  } 
  
  useEffect(() => {
    if (baseContacts.length > 0) {
      let newContacts = applyFilters(baseContacts)
    }
  }, [filters]) 

  const handleDeleteItems = (id: number | null) => {
    if (id != null) {
      if (!toDelete.includes(id)) {
        setToDelete([...toDelete, id])
      }
      else {
        setToDelete(toDelete.filter((item: number) => item !== id));
      }
    }
  }

  const deleteRows = async () => {
    if (toDelete.length > 0) {
      await DeleteContacts(toDelete);
      let newRows = baseContacts.filter((contact: any) => !toDelete.includes(contact.Id));
      setBaseContacts(newRows);
      setFilteredContacts(newRows);
      setToDelete([])
    }
  }

  const selectAllContacts = () => {
    let allIds = filteredContacts.map((contact: any) => contact.Id);
    setToDelete(allIds)
  }  

  const checkActiveFilters = (filt: Filters) => {
    return Object.values(filt).filter((arr: any) => arr.length > 0);
  }

  const clearFilters = () => {
    setFilters({
      Name: [],
      Title: [],
      ProvidedDate: [],
      AgencyGroup: [],
      Agency: [],
      Country: [],
      Email: [],
      Phone: [],
    })
  }

  return (
    <>
      {
        (checkActiveFilters(filters).length > 0) && (
          <p className={styles.clearFilters} onClick={() => clearFilters()}>Clear Filters X</p>
        )
      } 
      <TableContainer style={{ height: collapsed ? '41%' : '87%' }} className={cx(styles.tableContainer, theme.darkMode && styles.darkMode)}>
        <Table sx={{ borderCollapse: 'separate' }}> 
          <TableHead> 
            <TableRow style={{ backgroundColor: `${theme.darkMode ? "#2d2d2d" : "#fff" }`, position: "sticky", top: 0, zIndex: 200 }} id={styles.header}>
              {
                COLUMNS.map((column) => {
                  let key = columnKeyMap[column]
                  let uniqueVals: any = [];
                  filteredContacts.map((contract: any) => {
                    if (!uniqueVals.includes(contract[column.replace(/\s/g, "")])) { 
                      uniqueVals.push(contract[column.replace(/\s/g, "")])
                    }
                  })

                  if (column == "Name") {
                    return (
                      <TableCell                        
                        key={column} 
                        style={{ padding: '10px 16px', fontWeight: 'bold', borderBottom: `solid 1px ${ theme.darkMode ? '#525150' : '#E0E0E0' }`, borderRight: `solid 1px ${theme.darkMode ? "#525150" : "#E0E0E0"}` }} 
                        className={styles.cell} 
                        id={styles.firstCell}
                      >
                        <div className={styles.flexDiv}>
                          <p>{column}</p>                          
                          <img src={theme.darkMode ? DotMenuWhite : DotMenuBlack} onClick={(e) => handleFilterMenu(e, column)} />
                          <img src={theme.darkMode ? CopyIconWhite : CopyIcon} style={{ height: '17px', width: '17px' }} onClick={() => copyToClipboard(column)} />
                          {
                            filters.Name.length > 0 && (
                              <div className={styles.filterNotif}><p>{filters.Name.length}</p></div>
                            )
                          }
                          <Menu
                            open={openMenu == column}
                            anchorEl={menuAnchorEl}
                            onClose={() => setOpenMenu("")}
                            sx={{
                              "& .MuiPaper-root": {
                                padding: "10px 20px"
                              }
                            }}
                          >
                            <FilterMenu values={uniqueVals} column={column} filters={filters} handleFilters={handleFilters} />
                          </Menu>
                        </div>
                      </TableCell>
                    )
                  }
                  else {
                    return (
                      <TableCell key={column} style={{ padding: '10px 16px', fontWeight: 'bold', borderBottom: `solid 1px ${ theme.darkMode ? '#525150' : '#E0E0E0' }` }} className={styles.cell}>
                        <div className={styles.flexDiv}>
                          <p>{column}</p>
                          { column !== "Notes" && ( 
                            <>                                       
                              <img src={theme.darkMode ? DotMenuWhite : DotMenuBlack} onClick={(e) => handleFilterMenu(e, column)} />
                              <img src={theme.darkMode ? CopyIconWhite : CopyIcon} style={{ height: '17px', width: '17px' }} onClick={() => copyToClipboard(column)} />
                              {
                                filters[key].length > 0 && (
                                  <div className={styles.filterNotif}><p>{filters[key].length}</p></div>
                                )
                              }
                              <Menu
                                open={openMenu == column}
                                anchorEl={menuAnchorEl}
                                onClose={() => setOpenMenu("")}
                                sx={{
                                  "& .MuiPaper-root": {
                                    padding: "10px 20px",                                    
                                  }
                                }}
                              >
                                <FilterMenu values={uniqueVals} column={column.replace(/\s/g,'')} filters={filters} handleFilters={handleFilters}/>                              
                              </Menu>
                            </>
                          )} 
                        </div>
                      </TableCell>
                    )
                  }
                })
              }            
            </TableRow>    
          </TableHead>      
          <TableBody>
            {
              filteredContacts.length > 0 && (
                  filteredContacts.map((contact: any, i) => {
                    let color = bgHelper(i);

                    return (
                      <TableRow 
                        style={{ backgroundColor: color }} 
                        className={cx(styles.tableRow, theme.darkMode && styles.darkMode)} 
                        key={contact.Id}                        
                      >
                        <TableCell 
                          style={{ borderBottom: `solid 1px ${ theme.darkMode ? '#525150' : '#E0E0E0' }`, borderRight: `solid 1px ${theme.darkMode ? "#525150" : "#E0E0E0"}` }} 
                          className={styles.cell} 
                          id={styles.firstCell}                          
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input checked={toDelete.includes(contact.Id)} onChange={() => handleDeleteItems(contact.Id)} type="checkbox" style={{ height: '15px', width: '15px', marginRight: '10px'}} />
                            <p>{contact.Name}</p>                            
                          </div>
                        </TableCell>
                        <TableCell style={{ borderBottom: `solid 1px ${ theme.darkMode ? '#525150' : '#E0E0E0' }` }} className={styles.cell}><p>{contact.ProvidedDate}</p></TableCell>
                        <TableCell style={{ borderBottom: `solid 1px ${ theme.darkMode ? '#525150' : '#E0E0E0' }` }} className={styles.cell}><p>{contact.Title}</p></TableCell>
                        <TableCell style={{ borderBottom: `solid 1px ${ theme.darkMode ? '#525150' : '#E0E0E0' }` }} className={styles.cell}><p>{contact.AgencyGroup}</p></TableCell>
                        <TableCell style={{ borderBottom: `solid 1px ${ theme.darkMode ? '#525150' : '#E0E0E0' }` }} className={styles.cell}><p>{contact.Agency}</p></TableCell>
                        <TableCell style={{ borderBottom: `solid 1px ${ theme.darkMode ? '#525150' : '#E0E0E0' }` }} className={styles.cell}><p>{contact.Country}</p></TableCell>
                        <TableCell style={{ borderBottom: `solid 1px ${ theme.darkMode ? '#525150' : '#E0E0E0' }` }} className={styles.cell}><p>{contact.Email}</p></TableCell>
                        <TableCell style={{ borderBottom: `solid 1px ${ theme.darkMode ? '#525150' : '#E0E0E0' }` }} className={styles.cell}><p>{contact.PhoneNumber}</p></TableCell>
                        <TableCell style={{ borderBottom: `solid 1px ${ theme.darkMode ? '#525150' : '#E0E0E0' }` }} className={styles.cell}><p>{contact.Notes}</p></TableCell>
                      </TableRow>
                    )
                })                      
              )
            }
          </TableBody>
        </Table>      
      </TableContainer>
      {
        toDelete.length > 0 && (
          <div className={styles.deletePopup}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '30px' }}>
              <h2 style={{ fontSize: '2rem', margin: 0 }}>{toDelete.length}</h2>
              <p style={{ fontSize: '1rem', margin: 0 }}>Selected</p>
            </div>
            <button onClick={() => deleteRows()} style={{ backgroundColor: "#9C0E0E", color: "#fff" }}>Delete</button>
            <button onClick={() => selectAllContacts()} style={{ backgroundColor: "transparent", color: "#000", border: "solid 2px #efefef" }}>Select All</button>
            <button onClick={() => setToDelete([])}>Cancel</button>
          </div>
        )
      }
    </>
  )
}

export default ContactTable
