import React, { useContext, useEffect, useState } from 'react'
import styles from "./OnboardingId.module.scss"
import { ThemeContext } from '../../contexts/ThemeContext';
import cx from 'classnames'
import { DatePicker, Stack, CustomProvider } from 'rsuite';
import { UpdateOnboardingItem } from '../../services/authServices';

interface Props {
  date: string | null,
  itemId: number
}

const StartDate: React.FC<Props> = ({ date, itemId }) => {
  const [currentDate, setCurrentDate] = useState<string | null>(
    date ? date : null
  );
  const theme = useContext(ThemeContext);
  const months = ["Jan", "Feb", "Mar", "Apr", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const updateDate = async (e: any) => {
    let val = e.currentTarget.value;
    var newDate;
    if (val == "") {
      newDate = null;
    }
    else {      
      newDate = val;
    }    
    
    setCurrentDate(val);
    await UpdateOnboardingItem(newDate, "StartDate", itemId);
  }

  return (
    <div className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ width: '12%' }}>
      <input type="date" className={styles.dateInput} value={currentDate == null ? "" : currentDate} onChange={(e) => updateDate(e)}/>
      {/* <CustomProvider theme={theme.darkMode ? "dark" : "light"}>
        <DatePicker 
          defaultValue={currentDate} 
          placement="bottomEnd" 
          format="MMM dd, yyyy" 
          size="xs" 
          appearance="subtle"    
          onChange={(e) => updateDate(e)}      
        />
      </CustomProvider>        */}
    </div>
  )
}

export default StartDate
