import React, { useState, useEffect, useContext } from 'react'
import styles from "./ContractInfo.module.scss";
import { GetContracts } from "../../services/authServices";
import { ThemeContext } from '../../contexts/ThemeContext';
import cx from 'classnames'

const ContractInfo = () => {
  const [contracts, setContracts] = useState([]);
  const [numAgencies, setNumAgencies] = useState<string[]>([]);
  const [numCountries, setNumCountries] = useState<string[]>([]);
  const [totalScore, setTotalScore] = useState<number>(0);
  const [abintusTotal, setAbintusTotal] = useState<number>(0);
  const theme = useContext(ThemeContext);

  const getContracts = async () => {
    var res = await GetContracts();
    setContracts(res);
    
    var contractArr = [];
    var agencies: string[] = [];
    var countries: string[] = [];
    var total: number = 0;
    var abintusTotal: number = 0;
    res.forEach((item: any) => {
        contractArr.push(item);
        if (!agencies.includes(item.Agency)) agencies.push(item.Agency);
        if (!countries.includes(item.Country)) countries.push(item.Country);
        if (item.PreparedByAbintus == "Yes") abintusTotal += item.ContractScore;
        total += item.ContractScore;
    })

    setNumAgencies(agencies);
    setNumCountries(countries);
    setTotalScore(total);
    setAbintusTotal(abintusTotal);    
  }

  useEffect(() => {
    getContracts();
  }, [])

  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>
      <h1 className={styles.title}>Contracts</h1>
      <div className={styles.info}>
        <div className={cx(styles.infoBox, theme.darkMode && styles.darkMode)}>
            <p># of Contracts</p>
            <h1>{contracts?.length}</h1>
        </div>
        <div className={cx(styles.infoBox, theme.darkMode && styles.darkMode)}>
            <p># of Countries</p>
            <h1>{numCountries?.length}</h1>
        </div>
        <div className={cx(styles.infoBox, theme.darkMode && styles.darkMode)}>
            <p># of Agencies</p>
            <h1>{numAgencies?.length}</h1>
        </div>
        <div className={cx(styles.infoBox, theme.darkMode && styles.darkMode)}>
            <p>Average</p>
            <h1>{Math.round(totalScore / contracts.length)}<span style={{ fontSize: '1rem'}}> %</span></h1>
        </div>
        <div className={cx(styles.infoBox, theme.darkMode && styles.darkMode)} style={{ border: "none" }}>
            <p>Abintus Average</p>
            <h1>{Math.round(abintusTotal / contracts.filter((x: any) => x.PreparedByAbintus == "Yes").length)}<span style={{ fontSize: '1rem'}}> %</span></h1>
        </div>
      </div>
      <div className={styles.barGraphs}>
        <div style={{ width: '45%'}}>
            <p>Prepared By Abintus</p>
            <div style={{ width: '100%', height: '15px', display: 'flex' }}>
                <div 
                    style={{ 
                        paddingLeft: "5px",
                        height: '100%', 
                        backgroundColor: theme.darkMode ? 'rgb(241, 193, 2)' : 'rgba(0, 66, 104, 0.5)',
                        width: `${(contracts.filter((x: any) => x.PreparedByAbintus == "Yes").length / contracts.length) * 100}%`
                    }}
                ><p style={{ color: '#FFFFFF' }}>Y {Math.round((contracts.filter((x: any) => x.PreparedByAbintus == "Yes").length / contracts.length) * 100)}%</p></div>
                <div 
                    style={{ 
                        paddingLeft: "5px",
                        height: '100%', 
                        backgroundColor: theme.darkMode ? 'rgb(135, 135, 135)' : '#A0A0A0', 
                        width: `${(contracts.filter((x: any) => x.PreparedByAbintus == "No").length / contracts.length) * 100}%`
                    }}
                ><p style={{ color: '#FFFFFF' }}>N {Math.round((contracts.filter((x: any) => x.PreparedByAbintus == "No").length / contracts.length) * 100)}%</p></div>
            </div>
        </div>
        <div style={{ width: '45%' }}>
            <p>Auto Renew</p>
            <div style={{ width: '100%', height: '15px', display: 'flex' }}>
                <div 
                    style={{ 
                        paddingLeft: "5px",
                        height: '100%', 
                        backgroundColor: theme.darkMode ? 'rgb(241, 193, 2)' : 'rgba(0, 66, 104, 0.5)', 
                        width: `${(contracts.filter((x: any) => x.AutoRenew == "Yes").length / contracts.length) * 100}%`
                    }}
                ><p style={{ color: '#FFFFFF', }}>Y {Math.round((contracts.filter((x: any) => x.AutoRenew == "Yes").length / contracts.length) * 100)}%</p></div>
                <div 
                    style={{ 
                        paddingLeft: "5px",
                        height: '100%', 
                        backgroundColor: theme.darkMode ? 'rgb(135, 135, 135)' : '#A0A0A0', 
                        width: `${(contracts.filter((x: any) => x.AutoRenew == "No").length / contracts.length) * 100}%`
                    }}
                ><p style={{ color: '#FFFFFF' }}>N {Math.round((contracts.filter((x: any) => x.AutoRenew == "No").length / contracts.length) * 100)}%</p></div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default ContractInfo
