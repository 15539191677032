import React, { useState, memo, useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import styles from "./Layout.module.scss";
import cx from "classnames";

const DashboardLayout = memo(({ children }: any) => {
  const [openMobile, setOpenMobile] = useState(false);
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <div
        className={cx(
          styles.container,
          isMenuCollapsed && styles.collapsed,
          themeContext.darkMode && styles.darkMode,
        )}
        // style={{ paddingLeft: isMenuCollapsed ? "4vw" : "16vw" }}
      >
        <Navbar />
        {children}
        <Sidebar
          collapseMenu={() => setIsMenuCollapsed((prev) => !prev)}
          openMobile={openMobile}
          closeMobile={() => setOpenMobile(false)}
        />
      </div>
    </>
  );
});

export default DashboardLayout;
