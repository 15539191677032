import React, { useState, useContext, useEffect, useRef } from 'react'
import styles from "./VacationBoard.module.scss"
import { ChevronRight } from "react-feather";
import cx from 'classnames'
import { ThemeContext } from '../../contexts/ThemeContext';
import { Menu } from "@mui/material";
import { AuthContext } from '../../contexts/AuthContext';
import { NewVacationRequestAndTable, UpdateVacationTable } from '../../services/authServices';
import { palette } from './Colors';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';

interface Props {
  requestItems: any | null,
  users: any | null,
  active: string,
  tableTitle: string,
  tableColor: string,
  tableId: number,
  selectedItems: any,
  handleSelected: any,
  userFilter: any
}

const VacationTable: React.FC<Props> = ({ userFilter, selectedItems, handleSelected, tableId, tableTitle, requestItems, users, tableColor, active }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [title, setTitle] = useState(tableTitle);
  const [color, setColor] = useState(tableColor);
  const [unfilteredRequests, setUnfilteredRequests] = useState(requestItems)
  const [requests, setRequests] = useState(requestItems);
  const [openUserMenu, setOpenUserMenu] = useState<number | null>(null);
  const [openDatePicker, setOpenDatePicker] = useState<number | null>(null);
  const [openNotes, setOpenNotes] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElColorMenu, setAnchorElColorMenu] = useState<any>(null);
  const [anchorElDatePicker, setAnchorElDatePicker] = useState<any>(null);
  const [anchorElNotes, setAnchorElNotes] = useState<any>(null);  
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext);
  const colorAnchorRef = useRef(null);

  const handleMenu = (e: any, id: number) => {
    if (anchorEl == null) {
      setAnchorEl(e.currentTarget);
      setOpenUserMenu(id);
    }
    else {
      setAnchorEl(null);
      setOpenUserMenu(null)
    }
  }

  const handleDatePicker = (e: any, id: number) => {
    if (openDatePicker == null) {
      setOpenDatePicker(id);
    }
    else {
      setOpenDatePicker(null)
    }
  }

  const handleNotes = (e: any, id: number) => {
    if (anchorElNotes == null) {
      setAnchorElNotes(e.currentTarget);
      setOpenNotes(id);
    }
    else {
      if (e.target.tagName !== "TEXTAREA") {
        setAnchorElNotes(null);
        setOpenNotes(null)
      }
    }
  }

  const handleColorMenu = (e: any) => {
    if (anchorElColorMenu == null) {
      setAnchorElColorMenu(colorAnchorRef.current);
    }
    else {
      setAnchorElColorMenu(null);
    }
  }

  const newRequest = async () => {
    const request = {
      Id: null,
      TableId: tableId,
      UserId: auth.user[0],
      StartDate: new Date(),
      EndDate: new Date(),
      Notes: "",
      Status: "Requested",
      UserPhoto: auth.user[6]
    }

    await NewVacationRequestAndTable(request, "Request")
    .then((res: any) => {      
      request.Id = res.data.id;
    })

    setRequests((prev: any) => [...prev, request])
  }

  const handleTitle = async (e: any) => {
    setTitle(e.currentTarget.value);

    await UpdateVacationTable(tableId, "Title", e.currentTarget.value, "dbo.VacationTables");
  }

  const handleColor = async (color: string) => {
    setAnchorElColorMenu(null);
    setColor(color);
    await UpdateVacationTable(tableId, "Color", color, "dbo.VacationTables");
  }

  const resizeInput = () => {
    const input = document.getElementById(`title-input-${tableId}`);    

    if (input !== null) {
      if (title.length < 5) {
        input.style.width = '50px';
      }
      else {
        const titleWidth = input.scrollWidth;
        input.style.width = titleWidth + 'px';
      }
    }
  }

  useEffect(() => {
    if (userFilter !== null) {
      var filteredRequests = unfilteredRequests.filter((request: any) => request.UserId == userFilter.id);
      setRequests(filteredRequests)
    }
    else {
      setRequests(unfilteredRequests)
    }
  }, [userFilter])

  useEffect(() => {
    resizeInput()
  }, [title])

  const updateRequests = async (id: number, value: any, column: string) => {

    var newRequests = requests.map((item: any) => {      
      if (item.Id == id) {
        item[column] = value;
      }

      return item;
    })    

    setAnchorElColorMenu(null);
    setOpenUserMenu(null);
    setRequests(newRequests);

    await UpdateVacationTable(id, column, value, "dbo.VacationRequests");
  }

  const handleDates = async (e: any, id: number, user: string) => {
    let startDate: any = null;
    let endDate: any = null
    if (e !== null) {
      startDate = `${e[0].getFullYear()}-${(e[0].getMonth() + 1).toString().padStart(2, '0')}-${e[0].getDate().toString().padStart(2, '0')}`;
      endDate = `${e[1].getFullYear()}-${(e[1].getMonth() + 1).toString().padStart(2, '0')}-${e[1].getDate().toString().padStart(2, '0')}`;    
    }

    var newRequests = requests.map((item: any) => {
      if (item.Id == id) {
        item.StartDate = startDate;
        item.EndDate = endDate;
      }
      return item;
    })
    
    setRequests(newRequests);
    setOpenDatePicker(null);

    await UpdateVacationTable(id, "Dates", `${startDate} ${endDate}`, "dbo.VacationRequests", user);
  }

  const calculateDaysOff = (person: any) => {    
    console.log("hello")
    var userRequests = requests.filter((item: any) => item.UserId == person.id);
    if (userRequests.length > 0) {
      let days = 0;
      userRequests.forEach((request: any) => {
        let currentDate = new Date(request.StartDate);
        let stopDate = new Date(request.EndDate)
        while (currentDate <= stopDate) {                  
          if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) { // if day is not on weekend            
            days++;
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }   
      });
      return days;
      
    }
    else {
      return 0
    } 
  }

  return (
    <>
      <div className={cx(styles.tableTitle, active == "table" && styles.active)}>
        <div onClick={(e) => handleColorMenu(e)} className={styles.colorIcon} style={{ backgroundColor: `${color}`}}></div>        
        <input id={`title-input-${tableId}`} ref={colorAnchorRef} onChange={(e) => handleTitle(e)} value={title} className={styles.titleInput} style={{ color: color }}/>        
        <ChevronRight className={cx(styles.chevron, collapsed && styles.collapsed)} style={{ color: color }} />
        <Menu
          open={anchorElColorMenu !== null}
          onClose={(e) => handleColorMenu(e)}
          anchorEl={anchorElColorMenu}
          sx={{
            "& .MuiPaper-root": {              
              width: '120px',                      
              backgroundColor: "#FFFFFF",              
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',              
            },
          }}
        >
          <div style={{ boxSizing: 'border-box', paddingLeft: '2px', display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', height: '100%', width: '100%' }}>
            {
              palette.map(color => {
                return (
                  <div onClick={() => handleColor(color)} className={styles.colorDiv} style={{ backgroundColor: color }}></div>
                  )
                })
            }
          </div>
        </Menu>
      </div>
      <div className={cx(styles.chart, theme.darkMode && styles.darkMode)} style={{  borderLeft: `solid 2px ${color}`, borderRight: `solid 2px ${color}` }}>        
        <div style={{ width: '100%' }}>
          <div className={styles.chartTopBar}>
              <div className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ position: "sticky", left: 0, width: active == "table" ? "40%" : "50%", }}>
                <p>Employee</p>
              </div>
              <div className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ width: active == "table" ? "20%" : "50%" }}>
                  <p>Dates</p>
              </div>
              {
                active == "table" && (
                  <>
                    <div className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ width: '20%' }}>
                        <p># of Working Days Off</p>
                    </div>
                    <div className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ width: '20%' }}>
                        <p>Notes</p>
                    </div>              
                  </>
                )
              }
          </div>
          {
            users && requests.map((item: any) => {
              var startDateObj = item.StartDate == null ? new Date() : new Date(item.StartDate); // create date object with date string that is given
              var endDateObj = item.EndDate == null ? new Date() : new Date(item.EndDate);
              var startDate = item.StartDate == null ? " " : `${startDateObj.toLocaleString("default", { month: "short" })} ${startDateObj.getDate()}`; // format into MM dd string
              var endDate = item.EndDate == null ? " " :  `${endDateObj.toLocaleString("default", { month: "short" })} ${endDateObj.getDate()}`;
              var pfp = users.filter((user: any) => user.id == item.UserId)[0].photo;
              var name = users.filter((user: any) => user.id == item.UserId)[0].name;

              var dayCount = item.StartDate == null ? [] : [startDateObj];              
              var currentDate = new Date(startDateObj);
              var stopDate = endDateObj;                        
              
              if (item.StartDate !== null) {              
                while (currentDate < stopDate) {                  
                  if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) { // if day is not on weekend
                    dayCount.push(new Date(currentDate));
                  }
                  currentDate.setDate(currentDate.getDate() + 1);
                }              
              }              

              return (
                <div key={item.Id} className={cx(styles.row, theme.darkMode && styles.darkMode)}>
                  <div id={styles.titleCell} className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ width: active == "table" ? "40%" : "50%", }}>
                    <input onClick={() => handleSelected(item.Id)} type="checkbox" style={{ marginRight: active == "table" ? "80px" : "20px" }} checked={selectedItems.includes(item.Id)} />
                    <div className={styles.nameImageContainer} onClick={(e) => handleMenu(e, item.Id)}>
                      <img src={pfp} alt="pfp" className={styles.pfp} />
                      <p>{name}</p>
                    </div>
                  </div>
                  <Menu
                    open={openUserMenu == item.Id}
                    onClose={(e) => handleMenu(e, item.Id)}
                    anchorEl={anchorEl}
                    sx={{
                      "& .MuiPaper-root": {
                        height: "350px",  
                        width: '250px',                      
                        backgroundColor: "#FFFFFF",
                        padding: "20px",
                        boxSizing: 'border-box',
                        // boxShadow: 'none'
                      },
                    }}
                  >
                    <ul>
                      { 
                        users.map((person: any) => {
                          return (
                            <li className={styles.userListItem} key={person.id} onClick={() => updateRequests(item.Id, person.id, "UserId")}>
                              <img
                                src={person.photo}
                                alt="image"
                                className={styles.pfp}
                              />
                              <p>{person.name}</p>
                            </li>
                          );
                      })}
                    </ul>
                  </Menu>
                  <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ width: active == "table" ? "20%" : "50%", position: "relative"}}>
                    <div onClick={(e) => handleDatePicker(e, item.Id)} className={cx(styles.dateDisplay, item.Status == "Approved" && styles.approved, item.Status == "Denied" && styles.denied)}><p>{startDate} - {endDate}</p></div>
                    <div className={cx(styles.datePickerContainer, theme.darkMode && styles.darkMode)}
                      style={{ display: openDatePicker == item.Id ? "block" : "none", width: '250px' }}
                      // open={openDatePicker == item.Id}
                      // onClose={(e) => handleDatePicker(e, item.Id)}
                      // anchorEl={anchorElDatePicker}
                      // sx={{
                      //   "& .MuiPaper-root": {
                      //     padding: '5px 10px',                                   
                      //     backgroundColor: theme.darkMode ? "#252423" : "#FFFFFF",                                    
                      //   },
                      // }}
                    >
                      <DateRangePicker 
                        size="sm" 
                        placeholder="Select Dates" 
                        format="dd-MM-yyyy"                     
                        onChange={(e) => handleDates(e, item.Id, name)}
                        value={[startDateObj, endDateObj]}                        
                      />
                    </div>
                  </div>
                  {
                    active == "table" && (
                      <>
                        <div onClick={(e) => handleNotes(e, item.Id)} className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ width: '20%' }}>
                          <p>{dayCount.length}</p>                          
                        </div>
                        <div onClick={(e) => handleNotes(e, item.Id)} className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ display: 'flex', justifyContent: 'flex-start', width: '20%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', textAlign: 'left', paddingLeft: "10px" }}>
                          <p>{item.Notes}</p>
                          <Menu
                            open={openNotes == item.Id}
                            anchorEl={anchorElNotes}
                            onClose={(e) => handleNotes(e, item.Id)}
                            sx={{
                              "& .MuiPaper-root": {
                                padding: '5px 10px',                                   
                                backgroundColor: theme.darkMode ? "#252423" : "#FFFFFF",                                    
                              },
                            }}
                          >
                            <textarea value={item.Notes} className={cx(styles.textArea, theme.darkMode && styles.darkMode)} onChange={(e) => updateRequests(item.Id, e.currentTarget.value, "Notes")} />
                          </Menu>
                        </div>                    
                      </>
                    )
                  }
                </div>
              )
            })
          }
          {
            userFilter !== null && active == "table" && (
              <div className={cx(styles.row, theme.darkMode && styles.darkMode)}>
                <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ borderRight: "none", borderLeft: 'none', width: "40%", position: "relative"}}></div>
                <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ borderRight: "none", borderLeft: 'none', width: "20%", position: "relative"}}></div>
                <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ borderRight: "none", borderLeft: 'none', width: "20%", position: "relative"}}><p>{calculateDaysOff(userFilter)}</p></div>
                <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ borderRight: "none", borderLeft: 'none', width: "20%", position: "relative"}}></div>
              </div>
            )
          }
          <div className={cx(styles.newDiv, theme.darkMode && styles.darkMode)} style={{ width: "100%", boxSizing: 'border-box'}} onClick={newRequest}>
            <p>+ New Item</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default VacationTable
