import React, { useContext, useState, useEffect } from 'react'
import { ThemeContext } from '../../contexts/ThemeContext'
import styles from "./PitchReference.module.scss"
import cx from "classnames"
import { Dialog } from "@mui/material";
import { ChangeInputTemplate, GetInputTemplate } from '../../services/authServices';

const PitchReference = () => {
  const theme = useContext(ThemeContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [templateType, setTemplateType] = useState<string | null>(null);
  const [inputFile, setInputFile] = useState<File | null>(null);
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [templateLocations, setTemplateLocations] = useState({
    "Baseline": {
      date: "",
      url: ""
    },
    "Scenario 1": {
      date: "",
      url: ""
    },
    "Scenario 2": {
      date: "",
      url: ""
    },
    "Instructions": {
      date: "",
      url: ""
    },
  })

  const getTemplates = async () => {
    await GetInputTemplate("pitch")
    .then((res: any) => {
      const updatedLocations: any = { ...templateLocations }
      res.forEach((item: any) => {
        updatedLocations[item[0]] = {
          date: item[2],
          url: item[1]
        }
      })
      setTemplateLocations(updatedLocations);
    })
  }

  useEffect(() => {
    getTemplates()
  }, [])

  const handleReplace = (type: string) => {
    setTemplateType(type);
    setOpenDialog(true)
  }

  const handleFile = () => {
    var input = document.createElement("input");
    input.type = "file";

    input.accept = ".xlsx, .xls, .csv, .docx";
    input.onchange = (_) => {
      let files = input.files?.[0];
      if (files) {
        setInputFile(files);
      }
    };  
    input.click();
  }

  const handleCancel = () => {
    setOpenDialog(false);
    setInputFile(null);
  }

  const updateTemplate = async () => {
    if (inputFile && templateType) {
      await ChangeInputTemplate(inputFile, templateType)
      .then((res: any) => {
        if (res.status == 200) {
          setSuccessMessage(true);
          setInputFile(null);
        }
      })
    }
  }
  
  const handleEnd = () => {    
    setTemplateType(null);
    setOpenDialog(false);  
    setSuccessMessage(false);  
  }

  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>
      <div className={cx(styles.header, theme.darkMode && styles.darkMode)}>
        <p>Reference</p>        
      </div>
      <div className={cx(styles.list, theme.darkMode && styles.darkMode)}>        
        <ul>
          <li>
            <p>Baseline Template</p>
            <div className={cx(styles.buttonContainer, theme.darkMode && styles.darkMode)}>
            {
              templateLocations["Baseline"].url.length > 0 && (
                <a href={templateLocations["Baseline"].url} className={cx(styles.button, theme.darkMode && styles.darkMode)}>Download</a> 
              )
            }
              <button onClick={() => handleReplace("Baseline")} className={cx(styles.button, theme.darkMode && styles.darkMode)} style={{ color: theme.darkMode ? "#FFFFFF" : "#000000", marginLeft: "7px" }}>Replace</button>   
            </div>            
          </li>
          <li>
            <p>Scenario 1</p>
            <div className={cx(styles.buttonContainer, theme.darkMode && styles.darkMode)}>
              {
                templateLocations["Scenario 1"].url.length > 0 && (
                  <a href={templateLocations["Scenario 1"].url} className={cx(styles.button, theme.darkMode && styles.darkMode)}>Download</a> 
                )
              }
              <button onClick={() => handleReplace("Scenario 1")} className={cx(styles.button, theme.darkMode && styles.darkMode)} style={{ color: theme.darkMode ? "#FFFFFF" : "#000000", marginLeft: "7px" }}>Replace</button>
            </div>
          </li>
          <li>
            <p>Scenario 2 Template</p>
            <div className={cx(styles.buttonContainer, theme.darkMode && styles.darkMode)}>
              {
                templateLocations["Scenario 2"].url.length > 0 && (
                  <a href={templateLocations["Scenario 2"].url} className={cx(styles.button, theme.darkMode && styles.darkMode)}>Download</a> 
                )
              }
              <button onClick={() => handleReplace("Scenario 2")} className={cx(styles.button, theme.darkMode && styles.darkMode)} style={{ color: theme.darkMode ? "#FFFFFF" : "#000000", marginLeft: "7px" }}>Replace</button>
            </div>
          </li>
          <li>
            <p>Instructions</p>
            <div className={cx(styles.buttonContainer, theme.darkMode && styles.darkMode)}>
              {
                templateLocations["Instructions"].url.length > 0 && (
                  <a href={templateLocations["Instructions"].url} className={cx(styles.button, theme.darkMode && styles.darkMode)}>Download</a> 
                )
              }
              <button onClick={() => handleReplace("Instructions")} className={cx(styles.button, theme.darkMode && styles.darkMode)} style={{ color: theme.darkMode ? "#FFFFFF" : "#000000", marginLeft: "7px" }}>Replace</button>
            </div>
          </li>
        </ul>
      </div>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        sx={{
          "& .MuiPaper-root": {
            // height: "50vh",
            width: "50vw",
            padding: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            boxSizing: "border-box",
            textAlign: 'center'
          },
        }}    
      >
        {
          successMessage ? (
            <>
              <h1 className={styles.dialogTitle}>{templateType} Template Successfully Updated</h1>
              <button onClick={() => handleEnd()} style={{ backgroundColor: "#000000" }} className={styles.dialogButton}>Close</button>
            </>
          ) : (
            <>
              <h1 className={styles.dialogTitle}>Replace {templateType} Template?</h1>  
              <div style={{ display: "flex", marginBottom: "60px" }}>
                <input
                  readOnly
                  style={{ width: "150px", marginRight: "8px", borderRadius: "6px", border: "solid 1px #cacaca" }}
                  value={
                    inputFile !== undefined ? inputFile?.name : ""
                  }          
                />
                <button className={styles.selectFile} onClick={() => handleFile()}>{inputFile !== null ? "Change" : "Select File"}</button>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <button onClick={inputFile ? () => updateTemplate() : undefined} style={{ backgroundColor: inputFile ? "#000000" : "#E3E3E3" }} className={styles.dialogButton}>Confirm</button>
                <button style={{ backgroundColor: "rgb(144, 9, 9)" }} onClick={() => handleCancel()} className={styles.dialogButton}>Cancel</button>
              </div>
            </>
          )
        }
        
      </Dialog>        
    </div>
  )
}

export default PitchReference
