import React, { useState, useContext } from "react";
import { Dialog } from "@mui/material";
import CircleLogo from "../../../assets/media/circle-logo.png";
import styles from "./ChangePicture.module.scss";
import { AuthContext } from "../../../contexts/AuthContext";
import cx from "classnames";
import { UpdatePhoto } from "../../../services/authServices";

const ChangePicture = (props) => {
  const [photo, setPhoto] = useState(null);
  const context = useContext(AuthContext);
  const initials =
    context.user[1].split("").shift() + context.user[2].split("").shift();

  const close = () => {
    props.handleClose();
    setPhoto(null);
  };

  const upload = () => {
    if (photo !== null) {
      UpdatePhoto(photo, context.user[0]);
    }
  };

  function FileDialog() {
    const selectFile = (e) => {
      var input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.onchange = (_) => {
        let files = input.files[0];
        setPhoto(files);
      };
      input.click();
    };

    if (photo == null) {
      return (
        <div
          onClick={(e) => selectFile(e)}
          className={styles.fileDialog}
          style={{
            backgroundImage:
              context.user[6] !== null && `url(${context.user[6]})`,
            backgroundColor: "#F2D680",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className={styles.overlay}></div>
          <h2 style={{ display: context.user[6] !== null && "none" }}>
            {initials}
          </h2>
          <h3>Upload photo</h3>
        </div>
      );
    } else {
      const url = URL.createObjectURL(photo);
      return (
        <div
          onClick={(e) => selectFile(e)}
          className={styles.fileDialog}
          style={{
            backgroundImage: `url(${url})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className={styles.overlay}></div>
          <h3>Upload photo</h3>
        </div>
      );
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={close}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "10px",
          backgroundColor: "#FFFFFF",
          padding: "30px 40px",
          boxSizing: "border-box",          
          position: "relative",
          height: "65vh",
          width: "50vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: 'center',
          overflowX: "hidden",
        },
      }}
    >      
      {/* <div className={styles.content}> */}
        <h1 style={{ margin: "0 0 30px" }}>Update your photo</h1>
        <FileDialog />
        <button
          onClick={upload}
          className={cx(styles.button, photo !== null && styles.selected)}
          style={{
            padding: "10px 0 10px 0",
            color: "#FFFFFF",
            backgroundColor: photo !== null ? "#000000" : "#d0d0d0",
          }}
        >
          Confirm
        </button>
        <button className={styles.button} id={styles.cancel} onClick={close}>
          Cancel
        </button>
      {/* </div> */}
    </Dialog>
  );
};

export default ChangePicture;
