import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import styles from "./MPQData.module.scss";
import cx from "classnames";
import { GetHistoricalData } from "../../services/authServices";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import CountryPieChart from "../../common/components/CountryPieChart/CountryPieChart";
import Heatmap from "../../common/components/Heatmap/Heatmap";

interface Agencies {
  [key: string]: any;
}

const MPQData = () => {
  const [countryData, setCountryData] = useState<any | null>(null);
  const [clientData, setClientData] = useState<any | null>(null);
  const [agencyData, setAgencyData] = useState<any | null>(null);
  const [heatmapExpanded, setHeatmapExpanded] = useState(false);
  const theme = useContext(ThemeContext);

  const resizeHeatmap = () => {
    setHeatmapExpanded((prev) => !prev);
  };

  const getData = async () => {
    var data: any = await GetHistoricalData("mpq");

    console.log(data);

    var countryCount: Record<string, number> = {};
    var clientCount: any = {};
    var agencies: Agencies = {};
    data.forEach((item: any) => {
      var country = item[5];
      // console.log(item);

      if (item[5] == "The UK" || item[5] == "The US")
        country = item[5].split(" ")[1];
      else if (item[5] == "Netherlands") country = "NL";

      if (countryCount[country]) {
        countryCount[country] += 1;
      } else {
        countryCount[country] = 1;
      }

      if (!agencies[item[4]]) {
        agencies[item[4]] = 1;
      } else {
        agencies[item[4]]++;
      }

      if (clientCount[item[3]]) {
        var current = clientCount[item[3]];
        if (current[item[4]]) {
          current[item[4]]++;
        } else {
          current[item[4]] = 1;
        }
      } else {
        clientCount[item[3]] = {
          [item[4]]: 1,
        };
      }
    });

    var sortedAgenciesArray = Object.keys(agencies).sort();
    var sortedAgenciesObj: Agencies = {};

    sortedAgenciesArray.forEach((agency: string) => {
      sortedAgenciesObj[agency] = agencies[agency];
    });

    var sortedClientsArray = Object.keys(clientCount).sort();
    var sortedClientsObj: Agencies = {};

    sortedClientsArray.forEach((client: string) => {
      sortedClientsObj[client] = clientCount[client];
    });

    setClientData(sortedClientsObj);
    setAgencyData(sortedAgenciesObj);
    var array: any = [];
    // 8AC8A4
    let colors = [
      "#025373",
      "#75B8BF",
      "#D0E5F2",
      "#F2E5BD",
      "#F2D680",
      "#F98F6E",
      "#CB532D",
    ];
    let other = 0;
    Object.entries(countryCount)
      .sort((a, b) => b[1] - a[1])
      .forEach(([key, value], index) => {
        let percentage =
          Math.round(((value / data.length) * 100 + Number.EPSILON) * 10) / 10;
        if (Object.entries(countryCount).length > 5 && percentage < 2) {
          other += percentage;
        } else {
          array.push({
            id: key,
            value: percentage,
            country: `${key} - ${percentage}%`,
            color: colors[index],
          });
        }
      });
    array.push({
      id: "Other",
      value: other,
      country: `Other - ${other}%`,
      color: "gray",
    });

    setCountryData(array);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleBackdropClick = () => {
    if (heatmapExpanded) setHeatmapExpanded(false);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <CountryPieChart type="mpq" />
        <div
          onClick={() => handleBackdropClick()}
          className={cx(
            styles.agencyData,
            heatmapExpanded && styles.expanded,
            theme.darkMode && !heatmapExpanded && styles.darkMode,
          )}
        >
          {clientData && (
            <Heatmap
              expanded={heatmapExpanded}
              resizeHeatmap={resizeHeatmap}
              clientAgencyData={clientData}
              agencyList={agencyData}
            />
          )}
        </div>
      </div>
      <p style={{ margin: 0, fontSize: "0.75rem" }}>* Data taken from 20,000 most recent entries</p>
    </>
  );
};

export default MPQData;
