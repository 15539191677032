import React, { useState, useEffect, useContext, useRef } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';
import styles from "./styles/ContactsIndex.module.scss";
import cx from "classnames";

interface Props {
    addNewContact: any,
    clearAll: any,
    file: File | null,
    selectFile: Function,
    uploadFile: Function
}

const Form: React.FC<Props> = ({ addNewContact, clearAll, file, selectFile, uploadFile }) => {  
  const theme = useContext(ThemeContext);

  const AGENCY_GROUPS = [
    "GroupM", "Dentsu", "Havas Media Network", "Independent", "IPG Mediabrands", "Local Planet", "Mediabrands", 
    "Mediaplus", "Omnicom Media (OMG)", "Publicis Media", "Stagwell Brand"
  ]

  const AGENCIES = ["Mindshare", "Essence", "m/SIX", "OMD", "DentsuX", "Publicis Media", "Havas Media", "Initiative", "Crossmedia", "Mediaplus", "Pilot Media", "Starcom", "Vizeum", "Nayoki Mediaplus", "Mediacom", "Hearts & Science", "UM", "Carat", "Nova Espressão", "Havas Media Group", "Havas Creative", "MG OMD", "Zenith", "Dentsu", "Wavemaker", "GroupM", "PHD", "Mediahub", "Goodstuff", "The7Stars", "iProspect", "LoveSugarScience", "IPG Mediabrands", "Re:Media", "Bizkit Havas", "Omnicom Media Group", "Arena Media", "Hello Media", "Inspired", "MediaBrands", "Convertal OÜ", "Reprise", "MediaHouse", "The&Partnership", "Lion Communications", "Dagmar", "Mediabroker", "SIA MMS Communication", "Mediaschneider (Zurich)", "mmm", "Mediatonic", "eQumedia", "Dentsu International", "UPartner Media", "Spark Foundry", "Total Media", "VCCP", "Bicycle", "Publicis Media Group", "Msix&Partners", "Time & Space", "Cairnsoneil", "Media Experts", "Touché", "Mediahub Worldwide Asia Pacific Pty Ltd", "EssenceMediacom", "Blue449", "Segmenta (Bosch)", "Lemundo", "Territory", "cossett", "Deep Media", "Tubix", "PIA Media", "BuddyBrand", "Socially Powerful", "team5pm", "Ever Nine", "data driven rebels"];

  return (
    <form onSubmit={addNewContact} id="new-contact-form" className={cx(styles.form, theme.darkMode && styles.darkMode)}>
        <h2 style={{ fontSize: '1.1rem', margin: '0 0 10px' }}>New Contact Form</h2>
        <div className={styles.formRow}>
            <div style={{ width: '32%', boxSizing: 'border-box' }}>
                <p>Data Provided</p>
                <input type="text" className="form-input" id="DataProvided" name="DataProvided"/>                
            </div>
            <div style={{ width: '32%', boxSizing: 'border-box' }}>
                <p>Name</p>
                <input type="text" className="form-input" id="ClientName" name="ClientName"/>
            </div>
            <div style={{ width: '32%', boxSizing: 'border-box' }}>
                <p>Title</p>
                <input type="text" className="form-input" id="ClientTitle" name="ClientTitle"/>
            </div>
        </div>
        <div className={styles.formRow}>
            <div style={{ width: '49%', boxSizing: 'border-box' }}>
                <p>Agency Group</p>
                <input type="text" className="form-input" id="AgencyGroup" list="agency-groups" name="AgencyGroup"/>    
                <datalist id="agency-groups">
                    { AGENCY_GROUPS.map((agencyGroup: string) => <option key={agencyGroup} value={agencyGroup}>{agencyGroup}</option>) }
                </datalist>            
            </div>
            <div style={{ width: '49%', boxSizing: 'border-box' }}>
                <p>Agency</p>
                <input type="text" className="form-input" id="Agency" list="agency" name="Agency"/>    
                <datalist id="agency">
                    { AGENCIES.map((agency: string) => <option key={agency} value={agency}>{agency}</option>) }
                </datalist>            
            </div>
        </div>
        <div className={styles.formRow}>
            <div style={{ width: '32%', boxSizing: 'border-box' }}>
                <p>Country</p>
                <input type="text" className="form-input" id="ClientCountry" name="ClientCountry"/>
            </div>
            <div style={{ width: '32%', boxSizing: 'border-box' }}>
                <p>Email</p>
                <input type="email" className="form-input" id="ClientEmail" name="ClientEmail"/>
            </div>
            <div style={{ width: '32%', boxSizing: 'border-box' }}>
                <p>Phone</p>
                <input type="text" className="form-input" id="ClientPhone" name="ClientPhone"/>
            </div>
        </div>
        <div className={styles.formRow}>
            <div style={{ width: '100%', boxSizing: 'border-box' }}>
                <p>Notes</p>
                <textarea className="form-input" id="ClientNotes" style={{ height: '50px' }} name="ClientNotes" />
            </div>
        </div>
        <div className={styles.formRow} style={{ alignItems: 'center' }}>
            <div>
                <button 
                    style={{ marginRight: '10px', backgroundColor: "#2C45E5", color: "#FFF" }} 
                    className={cx(styles.formButton, theme.darkMode && styles.darkMode)}
                    type="submit"
                >
                    Submit
                </button>
                <button 
                    style={{ backgroundColor: theme.darkMode ? "#3B3A39" : "#cacaca", color: theme.darkMode ? "#fff" : "#000" }}
                    className={cx(styles.formButton, theme.darkMode && styles.darkMode)}
                    onClick={() => clearAll()}   
                    type="button"               
                >
                    Clear All
                </button>
            </div>
            <div>
            {
                file ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <button 
                            style={{ marginRight: '10px', backgroundColor: "#47A6EB", color: "#FFF"}}
                            className={styles.formButton}
                            type="button"
                            onClick={() => uploadFile()}
                        >
                            Upload
                        </button>
                        <p>{file.name}</p>
                    </div>
                ) : (
                    <p onClick={() => selectFile()} className={cx(styles.uploadLink)}>Upload contacts in bulk with an Excel file</p>
                )
            }
            </div>
        </div>
    </form>
  )
}

export default Form
