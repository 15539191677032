import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { ArchiveDeleteTaskCharts } from "../../../services/authServices";
import styles from "./TaskTile.module.scss";
import Folder from "../../../assets/media/folder.png"
import FolderBlack from "../../../assets/media/folder-dark.png"
import WhiteTrash from "../../../assets/media/white-trash.png";
import TrashBlack from "../../../assets/media/trash-black.png";
import { Dialog } from "@mui/material";
import cx from "classnames";

const TaskTile = (props: any) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [action, setAction] = useState<string | null>(null);
  const theme = useContext(ThemeContext);

  const archiveDialog = (action: string) => {
    setOpenDialog(true)
    setAction(action)
  }

  const chartAction = async () => {
    if (action) {
      await ArchiveDeleteTaskCharts(action, props.id);
      setOpenDialog(false);

      props.getAllCharts();
    }
  }

  return (
    <div className={styles.outerContainer}>
      <a href={props.href} style={{ textDecoration: "none" }}>
        <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>          
          <div className={styles.tileInfo}>
            <div>
              <h2>{props.title}</h2>
              <p>Created by: {props.author}</p>
            </div>
            <img src={props.authorPhoto} alt="author" />
          </div>
        </div>
      </a>
      <div className={cx(styles.options, theme.darkMode && styles.darkMode)}>
        <img src={theme.darkMode ? Folder : FolderBlack} className={styles.archiveButton} onClick={() => archiveDialog('Archive')}/>
        <img src={theme.darkMode ? WhiteTrash : TrashBlack} className={styles.archiveButton} onClick={() => archiveDialog('Delete')}/>
      </div>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        sx={{
          "& .MuiPaper-root": {
            // height: "50vh",
            width: "35vw",
            padding: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            boxSizing: "border-box",
            textAlign: 'center'
          },
        }}    
      >
        <h1 className={styles.dialogTitle}>{action == 'Archive' ? 'Archive' : 'Delete'} Item?</h1>
        {
          action == 'Archive' ? (
            <p style={{ marginBottom: '40px' }}>This item and all its projects/subitems will be hidden until made visible again.</p>
          ) : (
            <p style={{ marginBottom: '40px' }}>You will permanently delete this item and all its projects/subitems.</p>
          )
        }
        <div style={{ display: 'flex', justifyContent: "space-between", width: '100%' }}>
          <button onClick={() => chartAction()} type="submit" className={cx(styles.submitButton, action == 'Delete' && styles.delete)}>Confirm</button>
          <button onClick={() => setOpenDialog(false)} type="submit" className={styles.submitButton} id={styles.cancelButton}>Cancel</button>
        </div>
      </Dialog>
    </div>
  );
};

export default TaskTile;
