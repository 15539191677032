import React, { useEffect, useState, useContext } from "react";
import { ThemeContext } from "../../../contexts/ThemeContext";
import styles from "./Heatmap.module.scss";
import cx from "classnames";
import {
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Menu,
  Checkbox,
} from "@mui/material";
import { Maximize2, Minimize2 } from "react-feather";

interface HeatmapProps {
  clientAgencyData: { [key: string]: { [key: string]: any } };
  agencyList: string[];
  expanded: boolean;
  resizeHeatmap: Function;
}

interface ClientAgencyItem {
  agency: string;
}

const Heatmap: React.FC<HeatmapProps> = ({
  clientAgencyData,
  agencyList,
  expanded,
  resizeHeatmap,
}) => {
  const [agencies, setAgencies] = useState([]);
  var theme = useContext(ThemeContext);

  useEffect(() => {
    var array: any = [];

    if (clientAgencyData) {
      Object.values(clientAgencyData).forEach((value: any) => {
        if (!array.includes(value.agency)) array.push(value.agency);
      });
    }

    setAgencies(array);
  }, []);

  const boxColor = (value: number) => {
    if (value == 0) return "";
    if (value > 0 && value <= 150) return "#8bd5ee";
    if (value > 150 && value <= 300) return "#8dc8e8";
    if (value > 300 && value <= 450) return "#0d9ddb";
    if (value > 450 && value <= 600) return "#0077c8";
    if (value > 600 && value <= 750) return "#00629b";
    if (value > 750 && value <= 1050) return "#004f71";
    if (value > 1050 && value <= 1500) return "#004658";
  };

  return (
    <div
      className={cx(
        styles.heatMapContainer,
        expanded && styles.expanded,
        theme.darkMode && styles.darkMode,
      )}
    >
      <TableContainer>
        {expanded ? (
          <Minimize2
            className={styles.maximize}
            onClick={() => resizeHeatmap()}
          />
        ) : (
          <Maximize2
            className={styles.maximize}
            onClick={() => resizeHeatmap()}
          />
        )}
        <Table>
          <div style={{ position: "sticky", top: 0, zIndex: 999 }}>
            <div
              style={{
                display: "flex",
                backgroundColor: theme.darkMode ? "none" : "#FFFFFF",
                opacity: 1,
                background: theme.darkMode ? "#3B3A39" : "#FFFFFF",
              }}
            >
              <div
                className={cx(styles.cell, expanded && styles.expanded)}
                style={{
                  height: "25px",
                  position: "sticky",
                  left: 0,
                  backgroundColor: theme.darkMode ? "#3B3A39" : "#FFFFFF",
                }}
              >
                {/* <p style={{ fontSize: '0.5rem' }}>Agency/Client</p> */}
              </div>
              <div
                className={cx(styles.cell, expanded && styles.expanded)}
                style={{
                  height: "25px",
                  backgroundColor: theme.darkMode ? "#3B3A39" : "#FFFFFF",
                }}
              >
                <p>
                  <strong>Total</strong>
                </p>
              </div>
              {Object.entries(clientAgencyData).map(([key, value]) => {
                // console.log({ key, value });
                return (
                  <div className={cx(styles.cell, expanded && styles.expanded)} key={key}>
                    <p style={{ margin: 0 }}>
                      {key.length > 10 ? `${key.slice(0, 10)}.` : key}
                    </p>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                display: "flex",
                backgroundColor: theme.darkMode ? "none" : "#FFFFFF",
                opacity: 1,
                background: theme.darkMode ? "#3B3A39" : "#FFFFFF",
              }}
            >
              <div
                className={cx(styles.cell, expanded && styles.expanded)}
                style={{
                  position: "sticky",
                  left: 0,
                  background: theme.darkMode ? "#3B3A39" : "#FFFFFF",
                }}
              >
                <p>
                  <strong>Total</strong>
                </p>
              </div>
              <div
                className={cx(styles.cell, expanded && styles.expanded)}
                style={{
                  // position: 'sticky',
                  // left: 0,
                  background: theme.darkMode ? "#3B3A39" : "#FFFFFF",
                }}
              >
                <p
                  style={{
                    margin: 0,
                    fontSize: expanded ? "0.85rem" : "0.7rem",
                    color: "#00C1FF",
                  }}
                >
                  <strong>
                    {Object.values(agencyList).reduce(
                      (acc, val) => acc + Number(val),
                      0,
                    )}
                  </strong>
                </p>
              </div>
              {Object.entries(clientAgencyData).map(([key, value]) => {
                var total = Object.values(value).reduce(
                  (acc, val) => acc + val,
                  0,
                );

                return (
                  <div className={cx(styles.cell, expanded && styles.expanded)}>
                    <p
                      style={{
                        margin: 0,
                        fontSize: "0.70rem",
                        color: "#00C1FF",
                      }}
                    >
                      <strong>{total}</strong>
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <TableBody>
            {Object.entries(agencyList).map(([agencyKey, agencyValue]) => {
              return (
                <TableRow style={{ display: "flex" }}>
                  <div
                    className={cx(styles.cell, expanded && styles.expanded)}
                    style={{
                      position: "sticky",
                      left: 0,
                      background: theme.darkMode ? "#3B3A39" : "#FFFFFF",
                    }}
                  >
                    <p>{agencyKey}</p>
                  </div>
                  <div
                    className={cx(styles.cell, expanded && styles.expanded)}
                    style={{
                      background: theme.darkMode ? "#3B3A39" : "#FFFFFF",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontSize: "0.7rem",
                        color: theme.darkMode ? "#00C1FF" : "#00C1FF",
                      }}
                    >
                      <strong>{agencyValue}</strong>
                    </p>
                  </div>
                  {Object.entries(clientAgencyData).map(([key, value]) => {
                    var number = 0;
                    var color = "#000000";

                    if (value[agencyKey]) {
                      number = value[agencyKey];
                    }

                    if (number > 300) {
                      color = "#FFFFFF";
                    } else if (number > 0 && number <= 300) {
                      color = "#000000";
                    } else {
                      if (theme.darkMode) {
                        color = "#FFFFFF";
                      } else {
                        color = "#000000";
                      }
                    }

                    return (
                      <div
                        className={cx(styles.cell, expanded && styles.expanded)}
                        style={{ backgroundColor: boxColor(number) }}
                      >
                        <p style={{ color: `${color}` }}>{number}</p>
                      </div>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Heatmap;
