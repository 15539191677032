import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../contexts/ThemeContext';
import { EmployeeContext } from '../../contexts/EmployeeContext';
import styles from "./OnboardingIndex.module.scss"
import cx from "classnames"
import { Dialog, Menu } from "@mui/material";
import BlackTrash from "../../assets/media/trash-black.png"
import { CreateOnboardingTableAndChart, GetOnboardingChart, DeleteOnboardingChart } from '../../services/authServices';
import AnonPhoto from "../../assets/media/anon.jpeg";

interface Chart {
  Id: number,
  Employee: string,
  Items: any,
}

const OnboardingView = () => {
  const [charts, setCharts] = useState<Array<Chart> | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [newChartName, setNewChartName] = useState("");
  const [copyChart, setCopyChart] = useState(false);
  const [chartToCopy, setChartToCopy] = useState<number | null>(null);
  const [openMenu, setOpenMenu] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const theme = useContext(ThemeContext);
  const employees = useContext(EmployeeContext).employees;
  
  const getCharts = async () => {
    try {
      const chartsRes: any = await GetOnboardingChart(null, "dbo.OnboardingCharts");
      const itemsRes: any = await GetOnboardingChart(null, "dbo.OnboardingItems");      
  
      var chartsAndItems = chartsRes.map((chart: any) => {
        return {
          ...chart,          
          Items: itemsRes.filter((item: any) => item.ChartId == chart.Id)
        }
      })      
      setCharts(chartsAndItems);
    }
    catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getCharts()
  }, []);

  const handleNewChartName = (e: any) => {
    setNewChartName(e.currentTarget.value);
  }

  const createNewChart = async () => {
    if (newChartName !== "") {
      await CreateOnboardingTableAndChart(newChartName, null, null, chartToCopy)
      .then((res: any) => {
        if (res == "OK") {
          window.location.reload();
        }
      })
    }
  }

  const handleMenuClose = () => {
    setCopyChart(false);
    setChartToCopy(null);
    setOpenDialog(false);
  }

  useEffect(() => {
    console.log(chartToCopy)
  }, [chartToCopy])

  function handleMenu(e: any, id: number) {
    if (anchorEl == null) {
      setAnchorEl(e.currentTarget)
      setOpenMenu(id)
    }
    else {
      setOpenMenu(null)
      setAnchorEl(null)
    }
  }

  const deleteTable = async (id: number) => {    
    await DeleteOnboardingChart(id)
    .then((res: any) => {
      if (res.status == 200) {
        if (charts) {
          let newCharts = charts?.filter((chart: Chart) => chart.Id !== id);
          setCharts(newCharts)
        }
      }
    })
  }  

  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>
      <div className={cx(styles.topSubContainer, theme.darkMode && styles.darkMode)}>
        <h1>Employee Onboarding</h1>
        <button onClick={() => setOpenDialog(true)}>Create New</button>
      </div>
      <div className={cx(styles.table, theme.darkMode && styles.darkMode)}>
        <div className={styles.header}>
          <div><p>Employee</p></div>
          <div><p>Progress</p></div>
          <div><p>Up Next</p></div>
          <div><p>Date Created</p></div>
          <div style={{ width: '5%' }}></div>        
        </div>
        {
          charts && (
            charts.map((item: any, index: number) => {              
              let color = "";
              var doneItems = item.Items.filter((item: any) => item.Status == "Done");
              var progress = +((doneItems.length / item.Items.length).toFixed(2));
              progress = isNaN(progress) ? 0 : progress           
              let progressLength = 151.5 - (progress * 151.5);
              
              if (progress < 0.20) color = "#FF5D5D";
              else if (progress >= 0.20 && progress < 0.40) color = "#FE7E39";
              else if (progress >= 0.40 && progress < 0.60) color = "#FECE39";
              else if (progress >= 0.60 && progress < 0.90) color = "#9BDA00";
              else if (progress >= 0.90 && progress <= 1) color = "#32DA00";
              

              var upNext = item.Items.find((item: any) => item.Status !== "Done");
              var employee;              
              if (upNext) {
                employee = employees.find((emp: any) => emp.id == parseInt(upNext.Person));                
              }

              var date = new Date(item.DateCreated);
              var month = date.toLocaleString('default', { month: 'short' });
              var formattedDate = `${month} ${date.getDate()}, ${date.getFullYear()}`;              
  
              return (
                <div 
                    className={cx(styles.onboardingItem, theme.darkMode && styles.darkMode)} 
                    style={{ 
                      borderBottom: index !== charts.length - 1 ? `${theme.darkMode ? 'solid 0.5px rgb(82, 81, 80)' : 'solid 1px rgb(224, 224, 224, 1)'}` : 'none',
                      backgroundColor: theme.darkMode ? `${ index % 2 == 0 && '#252423' }` : `${index % 2 == 0 && '#EEEEEE' }` 
                    }}
                  >
                        <a className={styles.link} href={`/dashboard/onboarding/${item.Id}`}>
                          <div><p>{item.Employee}</p></div>
                          <div>                            
                              <svg width="60" height="60" viewBox="-8.5 -8.5 85 85" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{ transform: "rotate(-90deg)" }}>
                                <circle r="24" cx="34" cy="34" fill="transparent" stroke="transparent" stroke-width="5" stroke-dasharray="150.72px" stroke-dashoffset="0"></circle>
                                <circle r="24" cx="34" cy="34" stroke={color} stroke-width="5" stroke-linecap="butt" stroke-dashoffset={`${progressLength}px`} fill="transparent" stroke-dasharray="150.72px"></circle>
                                <text x="17px" y="36px" fill={color} font-size="12px" font-weight="bold" style={{ transform: "rotate(90deg) translate(0px, -64px)"}}>{Math.round(progress * 100)}%</text>
                              </svg>
                          </div>
                              {
                                upNext ? (                                
                                  <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center', padding: '0 20px', boxSizing: 'border-box'}}>
                                    <p>{upNext.Title}</p>
                                    {/* <img src={employee ? employee.photo : AnonPhoto} style={{ height: '35px', width: '35px', borderRadius: '50px', objectFit: 'cover' }}/> */}
                                  </div> 
                                ) : (
                                  <div style={{ textAlign: 'center' }}>
                                    <p>N/A</p>
                                  </div>
                                )
                              }                                                      
                            <div>                          
                                <p>{formattedDate}</p>
                            </div>
                          </a>
                          <div className={styles.dotMenuContainer} onClick={(e) => handleMenu(e, item.Id)}>
                              <div className={styles.dotMenu}></div>
                              <div className={styles.dotMenu}></div>
                              <div className={styles.dotMenu}></div>
                              <Menu
                                  open={openMenu == item.Id}
                                  anchorEl={anchorEl}
                                  onClose={(e) => handleMenu(e, item.Id)}
                                  sx={{
                                    "& .MuiPaper-root": {                                      
                                      backgroundColor: "Menu",
                                      padding: '10px'                                                
                                    },
                                  }}
                                >                                
                                  <div className={styles.deleteDiv} onClick={() => deleteTable(item.Id)}>
                                    <img src={BlackTrash} />
                                    <p style={{ margin: 0 }}>Delete Onboarding Table</p>
                                  </div>
                                </Menu>
                          </div>
                          {/* <div className={styles.fileDiv}>
                              <img src={theme.darkMode ? FileWhite : FileBlack} />
                              <p>{files}</p>
                          </div> */}
                  
                </div>  
              )
            })          
          )
        }        
      </div>
      <Dialog
        open={openDialog}
        onClose={() => handleMenuClose()}
        sx={{
          "& .MuiPaper-root": {
            // height: "50vh",
            width: "50vw",
            padding: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            boxSizing: "border-box",
            textAlign: 'center'
          },
        }}
      >
        <h1 style={{ fontFamily: 'PoppinsBold', marginBottom: '40px' }}>Create New Onboarding Chart</h1>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <label>Employee Name</label>
          <input value={newChartName} onChange={(e) => handleNewChartName(e)} className={styles.newChartInput} style={{ width: '100%', marginBottom: '20px' }}/>
          <div style={{ marginBottom: '40px', width: '100%' }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p>Copy Existing Chart?</p>
              <input type="checkbox" onClick={() => setCopyChart(prev => !prev)} style={{ height: "20px", width: "20px" }} />
            </div>
            {copyChart && (
              <select
                defaultValue="Select"
                className={styles.selectMenu}
                onChange={(e) =>
                  setChartToCopy(parseInt(e.currentTarget.value))
                }
              >
                <option disabled>Select</option>
                {charts?.map((chart: any) => {                    
                  return <option value={chart.Id}>{chart.Employee}</option>;
                })}
              </select>
            )}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <button onClick={createNewChart} className={styles.formButton} style={{ backgroundColor: newChartName !== "" ? "#000000" : "#EFEFEF"}}>Create</button>
            <button onClick={() => handleMenuClose()} className={styles.formButton} style={{ backgroundColor: 'rgb(144, 9, 9)'}}>Cancel</button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default OnboardingView