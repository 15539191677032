import React, { useState, useContext } from "react";
import styles from "./TextCell.module.scss";
import { ThemeContext } from "../../../contexts/ThemeContext";
import cx from "classnames";

const NewSubitemCell = (props: any) => {
  const [value, setValue] = useState("");
  const theme = useContext(ThemeContext);

  const handleEnterKeyUp = (e: any) => {
    if (e.key == "Enter") {
      if (value !== "") {
        props.newSubitem(value, props.projectId);
        setValue("");
      }
    }
  };

  const handleOnBlur = () => {
    if (value !== "") {
      props.newSubitem(value, props.projectId);
      setValue("");
    }
  };

  return (
    <input
      value={value}
      onBlur={handleOnBlur}
      onKeyUp={(e) => handleEnterKeyUp(e)}
      className={cx(styles.subItemCell, theme.darkMode && styles.darkMode)}
      placeholder="Add new item"
      onChange={(e) => setValue(e.currentTarget.value)}
    />
  );
};

export default NewSubitemCell;
