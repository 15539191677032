import React, { useContext, useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";
import styles from "./Automations.module.scss";
import { ThemeContext } from "../../contexts/ThemeContext";
import { AuthContext } from "../../contexts/AuthContext";
import {
  UserEvent,
  Automation,
  PitchManagementAutomation,
  AutomationAll,
} from "../../services/authServices";
import XLIcon from "../../assets/media/xl-icon.png";
import XLIconLight from "../../assets/media/xl-icon-light.png";
import AnonPhoto from "../../assets/media/anon.jpeg";
import ErrorIcon from "../../assets/media/error-icon.png";
import { todaysDate } from "../../common/helpers/todaysDate";
import cx from "classnames";
import Options from "./Options";
import RecentRuns from "./RecentRuns";
import { ChevronRight } from "react-feather";
import Stage from "./Stage";
import MPQData from "./MPQData";
import PitchReference from "./PitchReference";
import CountryPieChart from "../../common/components/CountryPieChart/CountryPieChart";

interface RunNumber {
  MPQ: string | number;
  AVB: string | number;
  Pitch: string | number;
  Stage: string | number;
  Comvergence: string | number;
}

const AllAutomationsView = () => {
  const params = new URLSearchParams(window.location.search);
  const [currentAutomation, setCurrentAutomation] = useState(
    params.get("automation"),
  );
  const [automationColor, setAutomationColor] = useState("");
  const [inputFile, setInputFile] = useState<File | undefined>(undefined);
  const [inputFolder, setInputFolder] = useState<FileList | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState(false);
  const [outputFile, setOutputFile] = useState<string | undefined>(undefined);
  const [badInputError, setBadInputError] = useState<null | string>(null);
  const [subType, setSubType] = useState<string>("Baseline");
  const [comvergenceType, setComvergenceType] = useState<string>("Input Type 1")
  const [runDuration, setRunDuration] = useState<string | null>("");
  const [collapsed, setCollapsed] = useState(false);
  const [netherlands, setNetherlands] = useState(false);
  const demo = useLocation().pathname.includes("demo");
  const [runs, setRuns] = useState<RunNumber>({
    MPQ: 0,
    AVB: 0,
    Pitch: 0,
    Stage: 0,
    Comvergence: 0,
  });
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext);
  const mobile = useMediaQuery("(max-width: 1200px)");


  const changeThemeColor = (e: any = null) => {
    setBadInputError(null);
    let fromUrl = params.get("automation");

    let test2;
    if (e != null) {
      test2 = e;
    } else {
      test2 = fromUrl;
    }

    setCurrentAutomation(test2);
    setInputFile(undefined);
    setOutputFile(undefined);

    switch (test2) {
      case "avb-automation":
        setAutomationColor("#FFA209");
        break;
      case "mpq-automation":
        setAutomationColor("#00aeed");
        break;
      case "pitch-automation":
        setAutomationColor("#4130AB");
        break;
      case "stage-automation":
        setAutomationColor("#1B930D");
        break;
      case "comvergence-automation":
        setAutomationColor("#D6C30E");
        break;
      default:
        setAutomationColor("");
        break;
    }
  };

  const selectFolder = (folder: any) => {
    setInputFolder(folder);
    setInputFile(undefined);
    setBadInputError(null);
    setOutputFile(undefined);
  };

  const selectFile = (type: string) => {
    setInputFolder(undefined);
    setBadInputError(null);
    setOutputFile(undefined);

    var input = document.createElement("input");
    input.type = "file";

    if (type == "folder") {
    } else {
      input.accept = ".xlsx, .xls, .csv";
      input.onchange = (_) => {
        let files = input.files?.[0];
        if (type == "input") setInputFile(files);
      };
    }
    input.click();
  };

  const startNewAutomation = () => {
    setOutputFile(undefined);
    setInputFile(undefined);
    selectFile("input");
  };

  const runAutomation = async () => {
    var action: any = "";
    if (currentAutomation == "avb-automation") action = `AVB Automation`;
    else if (currentAutomation == "mpq-automation") action = "MPQ Automation";
    else if (currentAutomation == "stage-automation")
      action = "Stage Automation";
    else action = "Pitch Automation";

    if (demo) action += " Demo";

    await UserEvent(action, auth);

    if (
      (inputFile != undefined && inputFile != null) ||
      inputFolder != undefined
    ) {
      const startTime: any = new Date();
      setLoading(true);
      if (currentAutomation == "stage-automation") {
        if (netherlands && inputFolder) {
          await AutomationAll(
            inputFolder,
            auth.user[0],
            "Stage Netherlands",
            auth.user[6],
            demo,
            "stage-automation",
            "",
          )
            .then((res: any) => {
              let endTime: any = new Date();
              let milliseconds = Math.abs(endTime - startTime);
              let seconds = (milliseconds / 1000).toFixed(2);
              setRunDuration(seconds);
              setOutputFile(res);
            })
            .catch((err) => {
              console.log(err);
              if (err.status == 422 || err.status == 408) {
                setBadInputError(err.message);
              }
            });
          setLoading(false);
        } else if (inputFile) {
          await AutomationAll(
            inputFile,
            auth.user[0],
            inputFile.name,
            auth.user[6],
            demo,
            "stage-automation",
            "",
          )
            .then((res: any) => {
              let endTime: any = new Date();
              let milliseconds = Math.abs(endTime - startTime);
              let seconds = (milliseconds / 1000).toFixed(2);
              setRunDuration(seconds);
              setOutputFile(res);
            })
            .catch((err) => {
              if (err.status == 400 || err.status == 422) {
                setBadInputError(err.message);
              }
            });
          setLoading(false);
        }
      } else if (currentAutomation == "avb-automation" || currentAutomation == "comvergence-automation") {        
        if (inputFile) {
          await AutomationAll(
            inputFile,
            auth.user[0],
            inputFile.name,
            auth.user[6],
            demo,
            currentAutomation,
            comvergenceType
          )
            .then((res: any) => {
              let endTime: any = new Date();
              let milliseconds = Math.abs(endTime - startTime);
              let seconds = (milliseconds / 1000).toFixed(2);
              // socket.emit("automation_run", { message: "automation begun" })
              setRunDuration(seconds);
              setOutputFile(res);
            })
            .catch((err) => {    
              if (err.status == 422) {
                setBadInputError(err.message);
              }
              else if (err.status == 500) {                
                setBadInputError(err.message)
              }      
              else if (err.status == 400) {
                setBadInputError(err.message)
              }        
            });
          setLoading(false);
        }
      } else if (currentAutomation == "mpq-automation") {
        if (inputFile) {
          await Automation(
            inputFile,
            auth.user[0],
            inputFile.name,
            auth.user[6],
            demo,
          )
            .then((res: any) => {
              let endTime: any = new Date();
              let milliseconds = Math.abs(endTime - startTime);
              let seconds = (milliseconds / 1000).toFixed(2);
              // socket.emit("automation_run", { message: "automation begun" })
              setRunDuration(seconds);
              setOutputFile(res);
            })
            .catch((err) => {
              if (err.status == 422) {
                setBadInputError(err.message);
              }
            });
          setLoading(false);
        }
      } else if (currentAutomation == "pitch-automation") {
        if (inputFile) {
          await PitchManagementAutomation(
            inputFile,
            subType,
            auth.user[0],
            `${auth.user[1]} ${auth.user[2]}`,
            auth.user[6],
          ).then((res: any) => {
            let endTime: any = new Date();
            let milliseconds = Math.abs(endTime - startTime);
            let seconds = (milliseconds / 1000).toFixed(2);
            // socket.emit("automation_run", { message: "automation begun" })
            setOutputFile(res);
            setLoading(false);
            setRunDuration(seconds);
          });
        }
      }
    }
  };

  const handleCollapse = () => {
    setCollapsed((prev: any) => !prev);
  };

  useEffect(() => {
    changeThemeColor();
  }, []);

  const avbGradient = {
    background: "-webkit-linear-gradient(45deg, #FFA209, #FFFFFF)",
    WebkitBackgroundClip: "text",
    color: "transparent",
    fontSize: "2.75rem",
    margin: 0,
  };

  const mpqGradient = {
    background: "-webkit-linear-gradient(45deg, #00aeed, #FFFFFF)",
    WebkitBackgroundClip: "text",
    color: "transparent",
    fontSize: "2.75rem",
    margin: 0,
  };

  const pitchGradient = {
    background: "-webkit-linear-gradient(45deg, #4130AB, #FFFFFF)",
    WebkitBackgroundClip: "text",
    color: "transparent",
    fontSize: "2.75rem",
    margin: 0,
  };

  const stageGradient = {
    background: "-webkit-linear-gradient(45deg, #1B930D, #FFFFFF)",
    WebkitBackgroundClip: "text",
    color: "transparent",
    fontSize: "2.75rem",
    margin: 0,
  };

  const comvergenceGradient = {
    background: "-webkit-linear-gradient(45deg, #D6C30E, #FFFFFF)",
    WebkitBackgroundClip: "text",
    color: "transparent",
    fontSize: "2.75rem",
    margin: 0,
  };

  const setRunAmount = (mpq: any, avb: any, pitch: any, stage: any, com: any) => {
    setRuns({
      MPQ: mpq,
      AVB: avb,
      Pitch: pitch,
      Stage: stage,
      Comvergence: com,
    });
  };

  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>
      <h1 className={styles.title}>Automations {demo && "Demo"}</h1>
      {demo && (
        <p style={{ margin: "0 0 10px 0", fontSize: "0.8rem" }}>
          This page exists solely to demonstrate the capabilities of our
          automation systems
        </p>
      )}
      <div className={styles.content}>
        <div style={{ width: mobile ? "100%" : collapsed ? "8%" : "68%" }}>
          <div
            className={cx(
              styles.automationInterface,
              theme.darkMode && styles.darkMode,
              loading && styles.loading,
            )}
            style={{
              border: currentAutomation
                ? `solid 1px ${automationColor}`
                : "none",
              padding: collapsed ? 0 : "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!collapsed ? (
              <>
                {currentAutomation !== null && (
                  <select
                    defaultValue={
                      currentAutomation == null ? "Select" : currentAutomation
                    }
                    className={cx(
                      styles.automationSelect,
                      theme.darkMode && styles.darkMode,
                    )}
                    onChange={(e) => changeThemeColor(e.currentTarget.value)}
                  >
                    <option disabled>Select</option>
                    <option value="avb-automation">AVB</option>
                    <option value="mpq-automation">
                      Media Price & Quality
                    </option>
                    <option value="pitch-automation">Pitch Management</option>
                    <option value="stage-automation">Stage</option>
                    <option value="comvergence-automation">COMvergence</option>
                  </select>
                )}
                {!loading && currentAutomation == null && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "95%",
                    }}
                  >
                    <p className={styles.selectHeader}>Select an automation</p>
                    <div
                      className={styles.automationTiles}
                      style={{ border: "solid 1px #FFA209" }}
                      onClick={() => changeThemeColor("avb-automation")}
                    >
                      <div className={styles.runCounter}>
                        <p style={avbGradient}>{runs.AVB}</p>
                        <p style={{ margin: 0, color: "#FFA209" }}>runs</p>
                      </div>
                      <p style={{ margin: 0, fontSize: '1.115rem' }}>AVB</p>
                    </div>
                    <div
                      className={styles.automationTiles}
                      style={{ border: "solid 1px #00aeed" }}
                      onClick={() => changeThemeColor("mpq-automation")}
                    >
                      <div className={styles.runCounter}>
                        <p style={mpqGradient}>{runs.MPQ}</p>
                        <p style={{ margin: 0, color: "#00aeed", fontSize: '1.25rem' }}>runs</p>
                      </div>
                      <p style={{ margin: 0, fontSize: '1.05rem' }}>MPQ</p>
                    </div>
                    <div
                      className={styles.automationTiles}
                      style={{ border: "solid 1px #4130AB" }}
                      onClick={() => changeThemeColor("pitch-automation")}
                    >
                      <div className={styles.runCounter}>
                        <p style={pitchGradient}>{runs.Pitch}</p>
                        <p style={{ margin: 0, color: "#4130AB", fontSize: '1.25rem' }}>runs</p>
                      </div>
                      <p style={{ margin: 0, fontSize: '1.05rem' }}>Pitch Management</p>
                    </div>
                    <div
                      className={styles.automationTiles}
                      style={{ border: "solid 1px #1B930D" }}
                      onClick={() => changeThemeColor("stage-automation")}
                    >
                      <div className={styles.runCounter}>
                        <p style={stageGradient}>{runs.Stage}</p>
                        <p style={{ margin: 0, color: "#1B930D", fontSize: '1.25rem' }}>runs</p>
                      </div>
                      <p style={{ margin: 0, fontSize: '1.05rem' }}>Stage Entertainment</p>
                    </div>
                    <div
                      className={styles.automationTiles}
                      style={{ border: "solid 1px #D6C30E" }}
                      onClick={() => changeThemeColor("comvergence-automation")}
                    >
                      <div className={styles.runCounter}>
                        <p style={comvergenceGradient}>{runs.Comvergence}</p>
                        <p style={{ margin: 0, color: "#D6C30E", fontSize: '1.25rem' }}>runs</p>
                      </div>
                      <p style={{ margin: 0, fontSize: '1.05rem' }}>COMvergence Unilever</p>
                    </div>
                  </div>
                )}
                {(
                  (currentAutomation != null && currentAutomation != "pitch-automation")
                ) && (
                  <Options automation={currentAutomation} demo={demo} />
                )}
                {!loading &&
                  !badInputError &&
                  (currentAutomation == "avb-automation" ||
                    currentAutomation == "mpq-automation" ||
                    currentAutomation == "stage-automation") &&
                  !outputFile && (
                    <div className={styles.interfaceSubcontainer}>                     
                      <input
                        readOnly
                        value={inputFile !== undefined ? inputFile.name : ""}
                        type="text"
                        className={cx(
                          styles.inputDocument,
                          theme.darkMode && styles.darkMode,
                        )}
                      />
                      <button
                        onClick={() => selectFile("input")}
                        className={cx(
                          styles.selectInput,
                          theme.darkMode && styles.darkMode,
                        )}
                      >
                        Select Input
                      </button>
                    </div>
                  )}
                {/* {!outputFile &&
                  !loading &&
                  !badInputError &&
                  currentAutomation == "stage-automation" && (
                    <Stage
                      netherlands={netherlands}
                      setNetherlands={setNetherlands}
                      selectFolder={selectFolder}
                      inputFolder={inputFolder}
                      selectFile={selectFile}
                      inputFile={inputFile}
                    />
                  )} */}
                {!loading &&
                  !badInputError &&
                  (currentAutomation == "pitch-automation" || currentAutomation == "comvergence-automation") &&
                  !outputFile && (
                    <div className={styles.pitchAutomationSubcontainer}>
                      <div>
                        {
                            currentAutomation == "pitch-automation" ? (
                                <select
                                defaultValue={"Baseline"}
                                onChange={(e) => setSubType(e.currentTarget.value)}
                                className={cx(
                                    styles.subType,
                                    theme.darkMode && styles.darkMode,
                                )}
                                >
                                <option>Baseline</option>
                                <option>Scenario 1</option>
                                <option>Scenario 2</option>
                                </select>
                            ) : (
                                <select onChange={(e) => setComvergenceType(e.currentTarget.value)} defaultValue={"Input Type 1"} className={cx(styles.subType, theme.darkMode && styles.darkMode)}>
                                    <option>Input Type 1</option>
                                    <option>Input Type 2</option>
                                </select>                                                               
                            )
                        }
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input
                            readOnly
                            style={{ width: "150px" }}
                            value={
                              inputFile !== undefined ? inputFile.name : ""
                            }
                            type="text"
                            className={cx(
                              styles.inputDocument,
                              theme.darkMode && styles.darkMode,
                            )}
                          />
                          <button
                            onClick={() => selectFile("input")}
                            className={cx(
                              styles.selectInput,
                              theme.darkMode && styles.darkMode,
                            )}
                          >
                            Select Input
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                {!loading &&
                  !badInputError &&
                  currentAutomation !== null &&
                  !outputFile && (
                    <button
                      onClick={runAutomation}
                      className={cx(
                        styles.runButton,
                        theme.darkMode && styles.darkMode,
                        inputFolder && styles.enabled,
                        inputFile && styles.enabled,
                      )}
                    >
                      Run Automation
                    </button>
                  )}
                {!loading && !badInputError && outputFile !== undefined && (
                  <div className={styles.resultsDiv}>
                    <img
                      style={{ height: "45px", width: "45px" }}
                      src={theme.darkMode ? XLIconLight : XLIcon}
                      alt="xl"
                    />
                    <p>
                      {todaysDate()} - {currentAutomation}
                    </p>
                    <div style={{ display: "flex" }}>
                      <a href={outputFile}>
                        <button className={styles.downloadButton}>
                          Download Results
                        </button>
                      </a>
                      <button
                        onClick={startNewAutomation}
                        className={styles.runAgainButton}
                      >
                        Run Another File
                      </button>
                    </div>
                  </div>
                )}
                {loading && (
                  <div className={styles.loadingContainer}>
                    <h3>Loading . . .</h3>
                    <div
                      className={styles.loadingBar}
                      style={{ backgroundColor: `${automationColor}` }}
                    >
                      <div
                        className={styles.movingDiv1}
                        style={{ backgroundColor: "#000000", opacity: 0.25 }}
                      ></div>
                      <div
                        className={styles.movingDiv2}
                        style={{ backgroundColor: "#000000", opacity: 0.1 }}
                      ></div>
                      <div
                        className={styles.movingDiv3}
                        style={{ backgroundColor: "#FFFFFF", opacity: 0.35 }}
                      ></div>
                    </div>
                  </div>
                )}
                {loading && inputFile && inputFile?.size * 0.000001 > 2 && (
                  <div className={styles.sizeWarning}>
                    <p>
                      Your file is about{" "}
                      {Math.round(inputFile.size * 0.000001 * 100) / 100} MB, so
                      this may take a bit longer.
                    </p>
                  </div>
                )}
                {badInputError && inputFile && (
                  <div className={styles.errorMessage}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={ErrorIcon}
                        style={{
                          height: "35px",
                          width: "35px",
                          marginRight: "10px",
                        }}
                      />
                      <div>
                        <p style={{ color: "rgb(203, 10, 10)" }}>
                          {badInputError}
                        </p>
                        <p style={{ fontSize: "0.8rem", marginBottom: '20px' }}>
                          If you believe this is a mistake, please contact{" "}
                          <a style={{ color: theme.darkMode ? "#03A0FF" : "#002DF8" }} href="mailto:ford.burgess@abintus.consulting">
                            ford.burgess@abintus.consulting
                          </a>{" "}
                          with your file attached.
                        </p>
                      </div>
                    </div>
                    <div>
                      <button
                        className={cx(
                          styles.redoButton,
                          theme.darkMode && styles.darkMode,
                        )}
                        onClick={(e) => selectFile("input")}
                      >
                        Choose another file
                      </button>
                    </div>
                  </div>
                )}
                {outputFile && runDuration && (
                  <p
                    className={cx(
                      styles.runDuration,
                      theme.darkMode && styles.darkMode,
                    )}
                  >
                    Results in {runDuration} seconds
                  </p>
                )}
              </>
            ) : (
              <div className={styles.chevronContainer} onClick={handleCollapse}>
                <ChevronRight className={styles.expandChevron} />
              </div>
            )}
          </div>
          {!collapsed && currentAutomation?.includes("mpq") && <MPQData />}
          {!collapsed && currentAutomation?.includes("stage") && (
            <CountryPieChart type={"stage"} />
          )}
          {
            !collapsed && currentAutomation?.includes("pitch") && <PitchReference />
          }
        </div>
        <RecentRuns
          demo={demo}
          setRunAmount={setRunAmount}
          collapseMenu={handleCollapse}
          collapsed={collapsed}
        />
      </div>
    </div>
  );
};

export default AllAutomationsView;
