import React, { useContext } from "react";
import HomeView from "../features/home";
import UnfinishedPage from "../common/components/UnfinishedPage";

const HomePage = () => {
  return (
    <>
      <HomeView />
    </>
  );
};
export default HomePage;
