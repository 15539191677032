import React, { useState, useContext, useEffect } from 'react'
import styles from "./ContractFile.module.scss"
import { ThemeContext } from '../../../contexts/ThemeContext';
import cx from 'classnames'

interface ContractFileProps {
    advertiser: string,
    files: any,
    startDate: string,
    id: number
}

const ContractFile: React.FC<ContractFileProps> = ({ advertiser, files, startDate, id }) => {
  const [expanded, setExpanded] = useState(false);
  const [displayFiles, setDisplayFiles] = useState(false);
  const theme = useContext(ThemeContext);
  const expandedHeight = 60 + (files.length * 25);

  const handleExpand = (e: any) => {
    var contractDiv = document.getElementById(`contract-container-${id}`)
    if (!expanded) {
        setExpanded(true)        
        if (contractDiv) {
            contractDiv.style.height = `${expandedHeight}px`;
            contractDiv.style.paddingBottom = '10px'
        }
        setTimeout(() => setDisplayFiles(true), 100)
    }
    else {
        
        if (e.target.id !== 'contract-link') { // if user clicks on link, do not collapse div
            if (contractDiv) {
                contractDiv.style.height = '40px';
            }
            setDisplayFiles(false);
            setExpanded(false);
        }
    }
  }

  return (
    <div onClick={(e) => handleExpand(e)} className={cx(styles.container, theme.darkMode && styles.darkMode)} id={`contract-container-${id}`}>
      <div style={{ marginBottom: expanded ? "20px" : "0" }}>        
        <p style={{ marginBottom: '5px' }}><strong style={{ marginRight: '10px' }}>{advertiser}</strong>{files.length} File{files.length == 1 ? '' : 's'}</p>
        <p style={{ fontSize: '0.75rem' }}>{startDate}</p>        
      </div>
      {
        displayFiles && (
            files.length > 0 ? (
                <div>
                    {
                        files.map((file: any) => {
                            
                            return (
                                <div style={{ display: 'flex', alignItems: 'center', height: '25px' }}>
                                    <a href={file[1]} target="_blank" id="contract-link">{file[3]}</a>
                                </div>
                            )
                        })
                    }
                </div>
            ) : (
                <p>There are no files associated with this contract</p>
            )
        )
      }
    </div>
  )
}

export default ContractFile
