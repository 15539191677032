import React, { useState, useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import { TableCell, Menu } from "@mui/material";
import BlackTriangle from "../../assets/media/black-triangle.png";
import BlueTriangle from "../../assets/media/blue-triangle.png";

const ContractButton = (props) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useContext(ThemeContext);

  const openMenu = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  if (props.link.length > 1) {
    return (
      <>
        <button
          onClick={(e) => openMenu(e)}
          style={{ display: "flex", alignItems: "center" }}
        >
          <p style={{ margin: "0 5px 0 0" }}>View</p>
          <img
            src={theme.darkMode ? BlueTriangle : BlackTriangle}
            style={{ height: "8px", width: "10px" }}
          />
        </button>
        <Menu
          open={open}
          onClose={() => setOpen(false)}
          anchorEl={anchorEl}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: theme.darkMode ? "#002837" : "#FFFFFF",
              color: theme.darkMode ? "#D0E5F2" : "#000000",
            },
          }}
        >
          {props.link.map((doc, i) => {
            return (
              <a
                key={i}
                href={doc[1]}
                target="_blank"
                style={{ color: "inherit" }}
              >
                <p style={{ margin: "15px" }}>{doc[3]}</p>
              </a>
            );
          })}
        </Menu>
      </>
    );
  } else {
    return (
      <a href={props.link[0][1]} target="_blank">
        <button>View</button>
      </a>
    );
  }
};

export default ContractButton;
