import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Home.module.scss";
import CircleLogo from "../../assets/media/circle-logo.png";
import AbintusLogo from "../../assets/media/abintus-logo-white.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AuthContext } from "../../contexts/AuthContext";
import { ThemeContext } from "../../contexts/ThemeContext";
import { Login, SendVerificationEmail } from "../../services/authServices";
import MSLogo from "../../assets/media/ms-logo.png";
import cx from 'classnames'

function HomeView() {
  const [loggingIn, setLoggingIn] = useState(false);
  const [unAuthErrors, setUnAuthErrors] = useState(false);
  const [invalidErrors, setInvalidErrors] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const context = useContext(AuthContext);
  const theme = useContext(ThemeContext)
  const navigate = useNavigate();

  var night = localStorage.getItem("theme") == "dark" ? true : false;

  const handleLogin = (email: string, password: string) => {
    setUnAuthErrors(false);
    setInvalidErrors(false);
    setLoggingIn(true);
    setEmail(email);
    setPassword(password);
    Login(email, password, context)
      .then(() => {
        navigate("/verify-email");
        SendVerificationEmail(email);
      })
      .catch((res) => {
        console.log(res);
        setLoggingIn(false);        
        if (res == 403) {
          setUnAuthErrors(true);
        } else if (res == 401) {
          setInvalidErrors(true);
        }
      });
  };

  return (
    <div className={styles.root}>
      <div className={night ? styles.leftNight : styles.left}>
        <img
          src={AbintusLogo}
          alt="abintus logo"
          className={styles.abintusLogo}
        />
        <p className={styles.privacyPolicy}>
          <a href="https://www.abintus.consulting/privacy-policy">Terms & Conditions</a> | <a href="https://www.abintus.consulting/terms-and-conditions">Privacy Policy</a> ©2024 Abintus Consulting®
        </p>
      </div>
      <div className={night ? styles.rightNight : styles.right}>
        {/* {
          context.user !== null ? (
            <>
              <img src={CircleLogo} className={loggingIn ? styles.circleLogoRotate : styles.circleLogo} alt="logo"/>
              <h1 style={{ marginBottom: "65px" }}>Hello again, {context.user[1]}</h1>
              <a href="/dashboard" style={{ textDecoration: "none" }}>
                <button type="submit" style={{ width: "200px"}} className={night ? styles.submitButtonNight : styles.submitButton}>Go to dashboard</button>
              </a>
            </>
          ) : ( */}
        <>
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={(values) => handleLogin(values.email, values.password)}
          >
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
              <Form className={styles.formContainer}>
                <img
                  src={CircleLogo}
                  className={
                    loggingIn ? styles.circleLogoRotate : styles.circleLogo
                  }
                  alt="logo"
                />
                <h1>Sign In</h1>
                <ul
                  className={night ? styles.inputListNight : styles.inputList}
                >
                  <li>
                    <label>Email</label>
                    <Field
                      className={night ? styles.inputNight : styles.input}
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                  </li>
                  <li
                    id={styles.passwordInput}
                    style={{ marginBottom: "30px" }}
                  >
                    <label>Password</label>
                    <Field
                      className={night ? styles.inputNight : styles.input}
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                    />
                    <a href="/forgot-password">Forgot Password?</a>
                  </li>
                  <div
                    className={styles.errorMessage}
                    style={{
                      display: invalidErrors ? "block" : "none",
                      marginTop: "-20px",
                    }}
                  >
                    <p>Wrong email or password. Please try again.</p>
                  </div>
                  <div
                    className={styles.errorMessage}
                    style={{
                      display: unAuthErrors ? "block" : "none",
                      marginTop: "-20px",
                    }}
                  >
                    <p>Your account must be verified before you can log in.</p>
                  </div>
                  <li style={{ marginBottom: '0px', height: '70px' }}>
                    <button
                      className={
                        night ? styles.submitButtonNight : styles.submitButton
                      }
                      type="submit"
                    >
                      Proceed
                    </button>
                  </li>
                </ul>
              </Form>
            )}
          </Formik>
          {/* <a className={styles.msLink} href="https://abintus-solutions.azurewebsites.net/.auth/login/aad/">
            <button className={cx(styles.msButton, theme.darkMode && styles.darkMode)}>
              <img src={MSLogo} alt="ms-logo" style={{ marginRight: '12px' }} />
              Sign in with Microsoft            
            </button>
          </a> */}
{/*           <svg width="320px" height="5px">
            <line
              x1="0"
              y1="0"
              x2="320px"
              stroke={night ? "#878787" : "#000000"}
              strokeWidth="2px"
            />
          </svg> */}
          <p className={styles.signUp}>
            Don't have an account?{" "}
            <strong>
              <a className={styles.link} href="/request-access">
                Request Access
              </a>
            </strong>
          </p>
        </>
        {/* )
        } */}
      </div>
    </div>
  );
}

export default HomeView;
