import React from 'react'
import OnboardingView from '../features/onboarding'

const OnboardingIndex = () => {
  return (
    <OnboardingView />
  )
}

export default OnboardingIndex
