import React, { useEffect, useState, useContext } from 'react'
import styles from "./ContractDashboard.module.scss"
import { GetContracts, GetContractData } from '../../services/authServices'
import { Dialog } from "@mui/material";
import { ThemeContext } from '../../contexts/ThemeContext';
import cx from 'classnames'
import BlackArrow from '../../assets/media/arrow-left.png';
import WhiteArrow from '../../assets/media/arrow-white.png';
import SVGArrow from '../../assets/media/arrow-svg.svg'
import ContractFile from '../../common/components/ContractFile/ContractFile';


const ContractDashboardView = () => {
  const [loading, setLoading] = useState(true);
  const [contractData, setContractData] = useState({});
  const [contracts, setContracts] = useState([]);
  const [openDialogue, setOpenDialog] = useState("");
  const [specialView, setSpecialView] = useState("")
  const theme = useContext(ThemeContext);

  const getContractData = async () => {    
    
    await GetContractData()
    .then((res: any) => {
      setContractData(res)
    });
    
    var newContracts: any = [];
    setLoading(false);

    await GetContracts()
    .then((res: any) => {
      newContracts = [...res]
    })    
    setContracts(newContracts)
  }

  useEffect(() => {    
    getContractData()
  }, []);

  const handleNewView = (key: string | null) => {
    if (key !== null) {
      document.getElementById("info-container")?.classList.add(styles.fadeOut);    
      setTimeout(() => setSpecialView(key), 200)
    }
    else {
      document.getElementById("contract-view")?.classList.add(styles.fadeOut)
      setTimeout(() => setSpecialView(""), 200)
    }
  }

  const renderContractEntries = () => {
    return (
      <div className={styles.infoContainer} id="info-container">
        {
          Object.entries(contractData).map(([key, value], index) => {
            var val = (value as number) * 100;
            let progressLength = 151.5 - ((value as number) * 151.5);
  
            let color; 
            if (val < 20) color = "#FF5D5D";
            else if (val >= 20 && val < 40) color = "#FE7E39";
            else if (val >= 40 && val < 60) color = "#FECE39";
            else if (val >= 60 && val < 90) color = "#9BDA00";
            else if (val >= 90 && val <= 100) color = "#32DA00";                    
  
            return (
              <div className={cx(styles.contractKPI, theme.darkMode && styles.darkMode)}>
                <p>{key.match(/[A-Z][a-z]+/g)?.join(' ')}</p>
                <svg width="150" height="150" viewBox="-8.5 -8.5 85 85" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{ transform: "rotate(-90deg)" }}>
                  <circle r="24" cx="34" cy="34" fill="transparent" stroke="#cacaca" stroke-width="5" stroke-dasharray="150.72px" stroke-dashoffset="0"></circle>
                  <circle r="24" cx="34" cy="34" stroke={color} stroke-width="5" stroke-linecap="butt" stroke-dashoffset={`${progressLength}px`} fill="transparent" stroke-dasharray="150.72px"></circle>
                  <text x="19px" y="36px" fill={color} font-size="15px" font-weight="bold" style={{ transform: "rotate(90deg) translate(0px, -64px)"}}>{Math.round(val)}%</text>
                </svg>
                <button onClick={() => handleNewView(key)} className={styles.viewContractButton}>View Contracts</button>
              </div>              
            )
          })
        }
      </div>
    )
  }

  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>
      <h1 style={{ fontSize: '2rem', fontWeight: 500, margin: '0 0 50px 0' }}>Contract Dashboard</h1>      
        {
          !loading && (
            specialView.length > 0 ? (
              <div className={styles.contractView} id="contract-view">
                <div>
                  <div style={{ paddingLeft: '10px', marginBottom: '20px' }}>
                    <p style={{ fontSize: '1.15rem', marginBottom: '5px' }}>{specialView.match(/[A-Z][a-z]+/g)?.join(' ')} - <strong>{contracts.filter((contract: any) => contract[specialView] == "Yes").length} Contracts</strong> meet this criteria</p>                    
                    <div className={styles.backDiv} onClick={() => handleNewView(null)}>
                      <img src={theme.darkMode ? WhiteArrow : BlackArrow} style={{ height: '15px', width: '15px' }} alt="arrow" />
                      <p>Back</p>
                    </div>
                  </div>
                </div>
                <div className={styles.contractList}>                  
                  {
                    contracts.map((contract: any) => {
                      if (contract[specialView] == "Yes") {
                        var startDate = new Date(contract.StartDate)
                        var dateString = `${startDate.toLocaleDateString('default', { month: 'short' })} ${startDate.getDate()}, ${startDate.getFullYear()}`                        

                        return (
                          <ContractFile advertiser={contract.Advertiser} startDate={dateString} files={contract.ContractUrl} id={contract.ContractId} />
                        )
                      }
                    })
                  }
                </div>
              </div>
            ) : (
              renderContractEntries()
            )
          )
        }      
      {/* <div className={styles.graphMarkerContainer}>
        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
          <p style={{ margin: 0, color: '#757575', fontSize: '0.75rem', marginLeft: '7px' }}>0%</p>
          <p style={{ margin: 0, color: '#757575', fontSize: '0.75rem', marginLeft: '7px' }}>25%</p>
          <p style={{ margin: 0, color: '#757575', fontSize: '0.75rem', marginLeft: '7px' }}>50%</p>
          <p style={{ margin: 0, color: '#757575', fontSize: '0.75rem', marginLeft: '7px' }}>75%</p>
          <p style={{ margin: 0, color: '#757575', fontSize: '0.75rem', }}>100%</p>
        </div>
        <div style={{ display: 'flex', padding: '0 14px', boxSizing: 'border-box' }}>
          <div className={styles.graphMarker}></div>
          <div className={styles.graphMarker}></div>
          <div className={styles.graphMarker}></div>
          <div className={styles.graphMarker}></div>
        </div>
      </div>
      <div className={styles.contractInfo}>
        {
          !loading && (
            Object.entries(contractData).map(([key, value], index) => {
              var val = (value as number) * 100;              

              let bgColor, borderColor;                       

              if (val < 20) {
                bgColor = "rgba(200, 32, 32, 0.75)";
                borderColor = '#C82020'
              }
              else if (val >= 20 && val < 40) {
                bgColor = 'rgba(255, 112, 1, 0.75)';
                borderColor = "#FF7000";
              }
              else if (val >= 40 && val < 60) {
                borderColor = "#FECE39";
                bgColor = 'rgba(254, 206, 57, 0.75)';
              } 
              else if (val >= 60 && val < 90) {
                borderColor = "#84B901";
                bgColor = 'rgba(132, 185, 1, 0.75)';
              } 
              else if (val >= 90) {
                borderColor = "#35AB12";
                bgColor = "rgba(53, 171, 18, 0.75)";
              }

              if (val <= 20) {
                return (
                  <div style={{ width: '100%', display: 'flex', alignItems: 'center', fontSize: '0.75rem', margin: '20px 0' }}>
                    <div className={styles.contractEntry} style={{ margin: '0 10px 0 0', color: '#000000', width: `${val}%`, backgroundColor: bgColor, border: `solid 2px ${borderColor}`, }}></div>
                    <p>{key.match(/[A-Z][a-z]+/g)?.join(' ')} - <strong style={{ fontStyle: 'italic'}}>{Math.round(val)}%</strong></p>
                  </div>
                )
              }
              else {
                return (
                  <div className={styles.contractEntry} style={{ color: '#000000', width: `${val}%`, backgroundColor: bgColor, border: `solid 2px ${borderColor}` }}>
                    <p>{key.match(/[A-Z][a-z]+/g)?.join(' ')} - <strong style={{ fontStyle: 'italic'}}>{Math.round(val)}%</strong></p>
                  </div>
                )
              }
            })
          )
        }
      </div> */}
    </div>
  )
}

export default ContractDashboardView
