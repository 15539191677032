import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import styles from "./AVB.module.scss";
import { ThemeContext } from "../../contexts/ThemeContext";
import cx from "classnames";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { GetAuthToken } from "../../services/authServices";
import PowerBI from "../../pages/PowerBI";
import * as pbi from "powerbi-client";

const AVBView = () => {
  const theme = useContext(ThemeContext);
  const [accessToken, setAccessToken] = useState(null);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        overflowY: "scroll",
        paddingBottom: "10px",
        boxSizing: "border-box",
      }}
    >
      <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h1 style={{ margin: 0, fontWeight: 500, fontSize: "2rem" }}>
            AVB Benchmarks
          </h1>
          <a href="/dashboard/automations/?automation=avb-automation">
            <button
              className={cx(
                styles.automationButton,
                theme.darkMode && styles.darkMode,
              )}
            >
              Automation
            </button>
          </a>
        </div>
        <div>
          <div className={cx(styles.card, theme.darkMode && styles.darkMode)}>
            <div style={{ margin: "0 auto", height: "100%", width: "100%" }}>
              <iframe
                title="Benchmarks_Dashboard_Az_V4"
                className={styles.powerbiDash}
                width="1140"
                height="541.25"
                src="https://app.powerbi.com/view?r=eyJrIjoiYWIzMWNkYmMtNzA4ZC00YmZkLThmYzctZTE4YTYwZTMzMTQ0IiwidCI6IjBlYWI3NTgzLTk0NzEtNDI2ZS05MWEyLWVmMDc2ZmYyZGU4NyJ9"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AVBView;
