import React, { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { ThemeContext } from "../../contexts/ThemeContext";
import styles from "./AccountSettings.module.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Dialog } from "@mui/material";
import { ArrowLeft } from "react-feather";
import { ChangePassword } from "../../services/authServices";
import CircleLogo from "../../assets/media/circle-logo.png";
import cx from "classnames";

const SecurityForm = () => {
  const context = useContext(AuthContext);
  const theme = useContext(ThemeContext);
  const [badMatchError, setBadMatchError] = useState(false);
  const [shortPassError, setShortPassError] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [serverError, setServerError] = useState(false);

  interface Values {
    newPassword: string;
    confirmPassword: string;
  }

  const updatePassword = (values: Values) => {
    setBadMatchError(false);
    setShortPassError(false);

    if (values.newPassword !== values.confirmPassword) {
      setBadMatchError(true);
    } else {
      if (values.newPassword.length < 7) {
        setShortPassError(true);
      } else {
        ChangePassword(values.newPassword, context.user[3])
          .then(() => {
            setDialogOpen(true);
          })
          .catch((err) => {
            setServerError(true);
            setDialogOpen(true);
          });
      }
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
    window.location.reload();
  };

  return (
    <div className={styles.securityInfo} id="security-info">
      <div className={cx(styles.titleDiv, theme.darkMode && styles.darkMode)}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <img src={ArrowLeft} style={{ height: "20px", width: "20px", marginTop: "2px"}}/> */}
          <ArrowLeft style={{ marginRight: "5px" }} />
          <a href="#personal-info" style={{ marginRight: "5px" }}>
            Personal info
          </a>
        </div>
        <h2>Security settings</h2>
      </div>
      <Formik
        initialValues={{ newPassword: "", confirmPassword: "" }}
        onSubmit={(values) => updatePassword(values)}
      >
        {({ values, handleChange }) => (
          <Form
            className={cx(
              styles.formContainer,
              theme.darkMode && styles.darkMode,
            )}
            id={styles.passwordForm}
          >
            <li className="input-and-label">
              <label htmlFor="newPassword">New password</label>
              <input
                className={styles.input}
                type="password"
                name="newPassword"
                value={values.newPassword}
                onChange={handleChange}
              />
            </li>
            <li className="input-and-label">
              <label htmlFor="confirmPassword">Confirm password</label>
              <input
                className={styles.input}
                type="password"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChange}
              />
            </li>
            <div
              className={styles.errorMessage}
              style={{ display: badMatchError ? "block" : "none" }}
            >
              <p>Passwords do not match or are blank.</p>
            </div>
            <div
              className={styles.errorMessage}
              style={{ display: shortPassError ? "block" : "none" }}
            >
              <p>Password must be 7 characters or longer.</p>
            </div>
            <button type="submit" className="submit-button">
              Update
            </button>
          </Form>
        )}
      </Formik>
      <Dialog
        open={dialogOpen}
        sx={{
          "& .MuiPaper-root": {
            width: "60vw",
            padding: "30px 40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            boxSizing: "border-box",
            textAlign: 'center'
          },
        }}
      >        
        <h1 style={{ margin: "0 0 20px" }}>{serverError ? "Unexpected error" : "Password successfully updated"}</h1>
        <p style={{ fontSize: "1.2rem", margin: "0 0 40px" }}>
          {
            serverError ? 
              "There has been an error with our server, please try again later" :
              "Please be sure to remember this password, as your previous password will no longer work"
          }          
        </p>
        <button onClick={() => handleClose()} className={styles.closeButton}>
          Close
        </button>
      </Dialog>
    </div>
  );
};

export default SecurityForm;
