import React, { useState, useEffect, useContext } from 'react'
import styles from "./VacationBoard.module.scss"
import { ThemeContext } from '../../contexts/ThemeContext'
import { AuthContext } from '../../contexts/AuthContext';
import ChevronDown from "../../assets/media/chevron-down-white.png";
import PersonWhite from "../../assets/media/user-white.png";
import PersonBlack from "../../assets/media/user-black.png";
import ChevronWhite from "../../assets/media/chevron-white.png";
import ChevronBlack from "../../assets/media/chevron-black.png";
import ArrowsWhite from "../../assets/media/arrows-white.png";
import ArrowsBlack from "../../assets/media/arrows-black.png";
import WhiteTrash from "../../assets/media/white-trash.png";
import BlackTrash from "../../assets/media/trash-black.png";
import { X, Check, Slash } from "react-feather";
import VacationTable from './Table';
import Calendar from './Calendar';
import { palette } from './Colors';
import { DeleteRequest, UpdateVacationTable, GetAllUsers, GetVacationRequests, GetVacationTables, NewVacationRequestAndTable } from '../../services/authServices';
import cx from 'classnames'
import { Menu } from "@mui/material";

interface User {
    name: string,
    email: string,
    photo: string,
    id: number
}

interface Table {
    Id: number | null,
    Title: string,
    Color: string,
    Requests: any
}

const VacationBoardView = () => {
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext);
  const [users, setUsers] = useState<User[]>();
  const [tables, setTables] = useState<Table[]>([]);
  const [requests, setRequests] = useState([]);
  const [active, setActive] = useState("table");
  const [userFilter, setUserFilter] = useState<any>(null);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [anchorElUserFilter, setAnchorElUserFilter] = useState(null);

  const getInfo = async () => {
    var res: any = await GetAllUsers();

    var users: User[] = [];
    res.forEach((item: any) => {
        users.push({
            name: item.name,
            email: item.email,
            photo: item.photo,
            id: item.id,
        })
    });   

    var requests: any = [];
    var requestsRes: any = await GetVacationRequests();
    requestsRes.forEach((request: any) => {
        var physicalEnd = new Date(request.EndDate);
        physicalEnd.setDate(physicalEnd.getDate() + (6 - physicalEnd.getDay()));        

        var userColors: any = {
            2: '#75B4FF',
            14: '#FF7046',
            15: '#0B868E',
            16: '#59DF92',
            17: '#FF6AA9',
            19: '#810FCF',
            20: '#577590',
            48: '#0527D2'
        }       

        requests.push({
            Id: request.Id,
            TableId: request.TableId,
            UserId: request.UserId,
            StartDate: request.StartDate == null ? null : request.StartDate.split("T")[0],
            EndDate: request.EndDate == null ? null : request.EndDate.split("T")[0],
            PhysicalEnd: physicalEnd,
            Notes: request.Notes,
            Status: request.Status,
            UserPhoto: users.filter((x: any) => x.id == request.UserId)[0].photo,      
            Color : userColors[request.UserId],      
        })
    })

    // requests.sort((a: any, b: any) => new Date(b.StartDate) - new Date(a.StartDate))

    var tablesRes: any = await GetVacationTables();
    var tables: any = [];
    tablesRes.forEach((item: any) => {
        tables.push({
            Id: item.Id,
            Title: item.Title,
            Color: item.Color,
            Requests: requests.filter((request: any) => request.TableId == item.Id)
        })
    });

    setRequests(requests);
    setTables(tables);
    setUsers(users);
  }

  const newTable = async () => {
    const table = {
        Id: null,
        Title: "New Table",
        Color: palette[Math.floor(Math.random() * 12)],
        Requests: [],
    }

    await NewVacationRequestAndTable(table, "Table")
    .then((res: any) => {
        table.Id = res.data.id;
    });

    setTables((prev: any) => [...prev, table]);    
  }

  useEffect(() => {
    getInfo();
  }, [])

  const handleSelected = (id: number) => {    
    if (selectedItems.includes(id)) {      
        let newArr = selectedItems.filter(item => item !== id);
        setSelectedItems(newArr);
    }
    else {
        let newArr = [...selectedItems, id];
        setSelectedItems(newArr)
    }    
  }

  const deleteItems = async () => {
    await DeleteRequest(selectedItems);
    setTables([]);
    setSelectedItems([]);
    getInfo();
  }

  const updateRequest = async (action: string) => {    

    selectedItems.forEach(async (id) => {
      var req: any = requests.find((x: any) => x.Id == id);
      var info: any = [];
      
      var email: any = '';
      if (req != undefined) { 
        var user = users?.find((x: any) => x.id == req.UserId)
        email = user?.email;
      }

      info = [req.StartDate, req.EndDate, action]      

      await UpdateVacationTable(id, "Status", info.join(" "), "dbo.VacationRequests", email);
    })

    setTables([]);
    setSelectedItems([])
    getInfo();
  }

  const handleUserFilterMenu = (e: any) => {
    if (anchorElUserFilter == null) {
      setAnchorElUserFilter(e.currentTarget)
    }
    else {
      setAnchorElUserFilter(null)
    }
  }

  const handleUserFilter = (user: any) => { 
    if (userFilter == null) {
      setUserFilter(user);
    }
    else {
      setUserFilter(null);
    }
  }

  return (
    <div className={cx(styles.wrapper, theme.darkMode && styles.darkMode)}>        
        <div className={cx(styles.calendarView, active == "calendar" && styles.active, theme.darkMode && styles.darkMode)}>
            <div className={cx(styles.sectionTitle)}>
                {/* <h1>Calendar</h1> */}
                <img src={theme.darkMode ? ChevronWhite : ChevronBlack} className={styles.chevron} alt="chev" onClick={() => setActive("calendar")}/>
            </div>
            <Calendar active={active} requests={requests} />
        </div>
        <div className={cx(styles.tableView, active == "table" && styles.active)}>
            <div className={cx(styles.sectionTitle)}>
                <img src={theme.darkMode ? ChevronWhite : ChevronBlack} className={styles.chevron} alt="chev" onClick={() => setActive("table")}/>
                <h1>Holidays</h1>
            </div>
            <div className={styles.toolBar}>
                <button onClick={newTable} className={styles.newItemButton}>New Table <img src={ChevronDown} alt="chev" style={{ marginLeft: '10px', height: '20px', width: '20px' }} /></button>
                <div className={styles.toolBarItem} style={{ marginRight: '20px' }} onClick={(e) => handleUserFilterMenu(e)}>
                  {
                    userFilter == null ? (
                      <>
                        <img src={theme.darkMode ? PersonWhite : PersonBlack} style={{ height: '15px', width: "15px", marginRight: '7px'}} alt="user" />
                        <p>User</p>                      
                        <Menu
                          open={anchorElUserFilter !== null}
                          anchorEl={anchorElUserFilter}
                          onClose={(e: any) => handleUserFilterMenu(e)}
                          sx={{
                            padding: 0,
                            "& .MuiPaper-root": {
                              maxHeight: "150px",
                              minWidth: "100px",
                              backgroundColor: "#FFFFFF",
                              color: "#000000",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "10px 20px",
                              zIndex: 1500,
                            },
                          }}
                        >
                          <p style={{ margin: 0 }}>Filter by user</p>
                            {
                              users && users.map((user: any) => {
                                return (
                                  <img
                                    key={user.name}
                                    onClick={() => handleUserFilter(user)}
                                    src={user.photo}
                                    className={styles.userFilterPhoto}
                                  />
                                );
                              })
                            }
                        </Menu>
                      </>
                    ) : (
                      <div onClick={(e) => handleUserFilter(e)}>
                        <img src={userFilter.photo} className={styles.userFilterPhoto} style={{ marginLeft: 0, height: '30px', width: '30px' }} alt="user" />
                        <p style={{ fontSize: '0.9rem', marginRight: '10px' }}>{userFilter.name}</p>
                        <X style={{ height: '20px', width: '20px' }}/>
                      </div>
                    )
                  }
                </div>
                {/* <div>
                    <img src={theme.darkMode ? ArrowsWhite : ArrowsBlack} style={{ height: '15px', width: "15px", marginRight: '7px'}} alt="user" />
                    <p>Sort</p>
                </div> */}
            </div>
            {
              tables.length > 0 && (
                  tables.map((item: any) => {
                    return (
                      <VacationTable key={item.Id} userFilter={userFilter} handleSelected={handleSelected} selectedItems={selectedItems} tableId={item.Id} tableTitle={item.Title} requestItems={item.Requests} tableColor={item.Color} users={users} active={active} />
                    )
                  })
              )
            }
            <div className={cx(styles.popupMenu, theme.darkMode && styles.darkMode)}  style={{ display: selectedItems.length > 0 ? "block" : "none" }}>
            <div className={styles.popupContainer}>
          <div
            className={styles.optionDisplay}
            style={{
              borderRight: `solid 1px ${theme.darkMode ? "#D0E5F2" : "#dadada"}`,
              marginRight: "110px",
            }}
          >            
            <h1 style={{ fontFamily: "PoppinsLight", margin: "-7px" }}>
              {selectedItems.length}
            </h1>
            <p style={{ margin: 0 }}>Selected</p>
          </div>
          {
            auth.user[7] == 2 && (
              <>
                <div
                  className={styles.optionDisplay}  
                  onClick={() => updateRequest("Approved")}          
                >
                  <Check style={{ height: "35px", width: "35px", marginBottom: "2px", color: 'green' }} />
                  <p style={{ margin: 0 }}>Approve</p>
                </div>
                <div
                  className={styles.optionDisplay}  
                  onClick={() => updateRequest("Denied")}                    
                >
                  <Slash style={{ height: "35px", width: "35px", marginBottom: "2px", color: '#CB0A0A' }} />
                  <p style={{ margin: 0 }}>Deny</p>
                </div>
              </>
            )
          }
          <div
            className={styles.optionDisplay}
            onClick={() => deleteItems()}
          >
            <img
              src={theme.darkMode ? WhiteTrash : BlackTrash}
              style={{ height: "30px", width: "30px", marginBottom: "5px" }}
            />
            <p style={{ margin: 0 }}>Delete</p>
          </div>        
          <div
            onClick={() => setSelectedItems([])}
            className={styles.optionDisplay}
            style={{ position: "absolute", right: 0 }}
          >
            <X style={{ height: "30px", width: "30px", marginBottom: "2px" }} />
            <p style={{ margin: 0 }}>Close</p>
          </div>              
        </div>
            </div>
        </div>
    </div>
  )
}

export default VacationBoardView
