import React, { useState, createContext, useContext } from "react";

type ThemeContextType = {
  darkMode: boolean;
};

type ThemeContextProviderType = {
  children: React.ReactNode;
};

export const ThemeContext = React.createContext({} as ThemeContextType);

export const ThemeProvider = ({ children }: ThemeContextProviderType) => {
  var theme = localStorage.getItem("theme");
  var test = theme !== null && theme !== "light";

  const [darkMode, setDarkMode] = useState(test);

  const values = {
    darkMode,
    setDarkMode,
  };

  return (
    <ThemeContext.Provider value={values}>{children}</ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
