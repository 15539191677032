import React, { useState, useContext, useEffect } from 'react'
import styles from "./Table.module.scss";
import { UpdateLeads, AddLead, AddComment, GetComments } from '../../../services/authServices';
import { ChevronRight } from "react-feather";
import { ThemeContext } from '../../../contexts/ThemeContext';
import { AuthContext } from '../../../contexts/AuthContext';
import cx from 'classnames'
import { Menu, Drawer } from "@mui/material";
import CommentWhite from "../../../assets/media/comment-white.png";
import CommentBlack from "../../../assets/media/comment-black.png";
import ReactCountryFlag from "react-country-flag"
import WhiteStar from "../../../assets/media/star-white.png"
import BlackStar from "../../../assets/media/star-black.png"
import GoldStar from "../../../assets/media/star-gold.png"
import DatePicker from "react-datepicker";
import xIconWhite from "../../../assets/media/x-icon.png";
import xIconBlack from "../../../assets/media/x-icon-black.png";
import { registerLocale } from  "react-datepicker";
import uk from 'date-fns/locale/es';
import { colors, countries } from './info';
import "./react-datepicker.css";
registerLocale('uk', uk)

interface Props {
    category: string
    leads: any,    
    categoryColor: string,
    handleSelect: any,
    selectedItems: any,
    chartId: any,
    handleDrag: any,
    handleDragOver: any,
    handleDragDrop: any,
    dragged: number | null,
    draggedOver: number | null,
    movedElement: any
}

interface Comment {
    id: number | null,
    leadId: number;
    comment: string;
    commenter: string;
    commenterPhoto: string;
    date: Date;
  }

const Table: React.FC<Props> = ({ category, leads, categoryColor, handleSelect, selectedItems, chartId, handleDrag, handleDragOver, handleDragDrop, dragged, draggedOver, movedElement }) => {
  const [unfilteredLeads, setUnfilteredLeads] = useState(leads);
  const [collapsed, setCollapsed] = useState(false);
  const [allCountries, setAllCountries] = useState(countries);
  const [anchorElStatus, setAnchorElStatus] = useState(null);    
  const [anchorElDescription, setAnchorElDescription] = useState(null);
  const [anchorElLocation, setAnchorElLocation] = useState(null); 
  const [anchorElProposal, setAnchorElProposal] = useState(null); 
  const [anchorElServices, setAnchorElServices] = useState(null); 
  const [servicesMenuOpen, setServicesMenuOpen] = useState(Array(leads.length).fill(false));
  const [statusMenuOpen, setStatusMenuOpen] = useState(Array(leads.length).fill(false));
  const [locationMenuOpen, setLocationMenuOpen] = useState(Array(leads.length).fill(false));
  const [proposalMenuOpen, setProposalMenuOpen] = useState(Array(leads.length).fill(false));  
  const [descriptionOpen, setDescriptionOpen] = useState(Array(leads.length).fill(false));
  const [infoDisplay, setInfoDisplay] = useState("comments");
  const [commentList, setCommentList] = useState<Comment[]>([])
  const [drawerOpen, setDrawerOpen] = useState<number | null>(null);
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext);

  const getComments = async () => {
    var comments: Comment[] = [];

    var commentRes: any = await GetComments(chartId.toString(), "dbo.LeadManagementComments");
    commentRes.forEach((comment: any) => {        
        comments.push({
            id: comment[0],
            leadId: comment[2],
            comment: comment[6],
            commenter: comment[3],
            commenterPhoto: comment[4],
            date: comment[5],
        });
    });
    
    var test = comments.sort((a: any, b: any) => b.id - a.id);    
    setCommentList(test)
  }

  useEffect(() => {
    setUnfilteredLeads(leads);
  }, [leads])

  useEffect(() => {
    getComments();
  }, [])

  const updateTextValue = async (column: string, value: string | Date | null, id: number) => {    
    if (column == "RevenueForecast" || column == "DealSize" || column == "Confidence") {
        if (typeof value == 'string' && !value.match(/[^,\d€]+/)) { // string does not include letters or other punctuation
            let cleanedValue = value.replace(/[,€]+/g, "");  
            let formattedValue = cleanedValue == "" ? 0 : parseInt(cleanedValue);             
            var mapped = unfilteredLeads.map((item: any) => {                
                if (item.Id == id) {
                    item[column] = formattedValue;
                }
        
                return item;
            })
        
            setUnfilteredLeads(mapped);
            await UpdateLeads(id, column, formattedValue);
        }        
    }
    else if (column == "Services") {
        var mapped = unfilteredLeads.map((item: any) => {
            if (item.Id == id) {
                if (item.Services.includes(value)) {
                    item.Services = item.Services.filter((x: any) => x !== value);                    
                }      
                else {                    
                    item.Services.push(value);                    
                }          
                UpdateLeads(id, column, item.Services.join(", "));
            }
            
            return item;
        })
                
        setUnfilteredLeads(mapped);
    }
    else {
        var mapped = unfilteredLeads.map((item: any) => {
            if (item.Id == id) {                
                item[column] = value;
            }
    
            return item;
        })
    
        setUnfilteredLeads(mapped);
        await UpdateLeads(id, column, value);
    }
  }

  const handleDescriptionBox = (e: any, index: number) => {
    if (anchorElDescription == null) {
        setAnchorElDescription(e.currentTarget);
        let newArr = descriptionOpen;
        newArr[index] = true;
        setDescriptionOpen(newArr);
      }
      else {
        if (e.target.tagName !== "TEXTAREA") {
            setAnchorElDescription(null)
            setDescriptionOpen(Array(leads.length).fill(false));
        }
      }
  }

  const handleColorMenuStatus = (e: any, index: number) => {       
    if (anchorElStatus== null) {
        setAnchorElStatus(e.currentTarget);
        let newArr = statusMenuOpen;
        newArr[index] = true;
        setStatusMenuOpen(newArr);
    }
    else {
        setAnchorElStatus(null)
        setStatusMenuOpen(Array(leads.length).fill(false));
    }    
  }

  const handleColorMenuLocation = (e: any, index: number) => {       
    if (anchorElLocation == null) {
        setAnchorElLocation(e.currentTarget);
        let newArr = locationMenuOpen;
        newArr[index] = true;
        setLocationMenuOpen(newArr);
    }
    else {
        setAnchorElLocation(null)
        setLocationMenuOpen(Array(leads.length).fill(false));
    }    
  }

  const handleColorMenuProposal = (e: any, index: number) => {       
    if (anchorElProposal == null) {
        setAnchorElProposal(e.currentTarget);
        let newArr = proposalMenuOpen;
        newArr[index] = true;
        setProposalMenuOpen(newArr);
    }
    else {
        setAnchorElProposal(null)
        setProposalMenuOpen(Array(leads.length).fill(false));
    }    
  }

  const handleServicesMenu = (e: any, index: number) => {          
    if (anchorElServices == null) {
        setAnchorElServices(e.currentTarget);
        let newArr = servicesMenuOpen;
        newArr[index] = true;
        setServicesMenuOpen(newArr);
    }
    else if (!e.target.className.toLowerCase().includes("servicesinput")) {
        setAnchorElServices(null)        
        setServicesMenuOpen(Array(leads.length).fill(false));
    }    
  }

  const newItem = async () => { 
    var orderId = unfilteredLeads.length ? unfilteredLeads[unfilteredLeads.length - 1].OrderId + 1 : 0;
    var newItem = {
        Id: null,
        KeyClient: "",
        Company: "",
        Country: "GB-United Kingdom",
        Services: [],
        Status: "",
        LastContact: new Date(),
        ContactLocation: "",
        Proposal: "",
        DealSize: 0,
        Confidence: 0,
        RevenueForecast: 0,
        Description: "Status",
        Category: category,
        OrderId: orderId
    }    

    await AddLead(category, orderId).then((res: any) => {
        newItem.Id = res.data.id;        
        setUnfilteredLeads([...unfilteredLeads, newItem]);        
      });

    setUnfilteredLeads([...unfilteredLeads, newItem]);
  }

  const handleDrawer = (id: number) => {
    if (drawerOpen == null) setDrawerOpen(id);
    else setDrawerOpen(null);
  }

  const addCommentEnterKey = (e: any, id: number) => {
    if (e.key == "Enter") {
      setCommentList([
        ...commentList,
        {
          id: null,
          leadId: id,
          comment: e.currentTarget.value,
          commenter: `${auth.user[1]} ${auth.user[2]}`,
          commenterPhoto: auth.user[6],
          date: new Date(),
        },
      ])

      AddComment(
        e.currentTarget.value,
        `${auth.user[1]} ${auth.user[2]}`,
        auth.user[6],
        new Date(),
        id,
        chartId,
        "dbo.LeadManagementComments"
      );

      const userInput = document.getElementById(
        "commentInput",
      ) as HTMLInputElement;
      userInput.value = "";
    }
  };

  return (
    <>
        <div className={styles.tableTitle} onClick={() => setCollapsed(prev => !prev)}>
            <h1 style={{ margin: 0, color: `${categoryColor}` }}>{category}</h1>
            <ChevronRight className={cx(styles.chevron, collapsed && styles.collapsed)} style={{ color: `${categoryColor}` }} />
        </div>
        <div className={cx(styles.chart, theme.darkMode && styles.darkMode)} style={{  borderLeft: `solid 2px ${categoryColor}` }}>            
            <div>
                <div className={styles.chartTopBar}>
                    <div className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ position: "sticky", left: 0, minWidth: '425px', }}>
                        <p>Item</p>
                    </div>
                    <div className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ minWidth: '200px', }}>
                        <p>Company</p>
                    </div>
                    <div className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ minWidth: '200px' }}>
                        <p>Country</p>
                    </div>
                    <div className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ minWidth: '220px' }}>
                        <p>Services</p>
                    </div>
                    <div className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ minWidth: '140px' }}>
                        <p>Status</p>
                    </div>
                    <div className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ minWidth: '180px' }}>
                        <p>Last Contact</p>
                    </div>
                    <div className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ minWidth: '150px' }}>
                        <p>Contacted</p>
                    </div>
                    <div className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ minWidth: '200px' }}>
                        <p>Proposal</p>
                    </div>
                    <div className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ minWidth: '220px' }}>
                        <p>Deal Size</p>
                    </div>
                    <div className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ minWidth: '220px' }}>
                        <p>Confidence</p>
                    </div>
                    <div className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ minWidth: '220px' }}>
                        <p>Revenue Forecast</p>
                    </div>
                    <div className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ minWidth: '400px' }}>
                        <p>Status</p>
                    </div>
                    <div className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ minWidth: '50px' }}></div>
                </div>
                {
                unfilteredLeads.length > 0 && !collapsed && unfilteredLeads.map((item: any, index: any) => {                    
                    var countryCode = item.Country.split("-")[0];                    
                    var status: keyof typeof colors.Status = item.Status;                    
                    var contactLocation: keyof typeof colors.Location = item.ContactLocation;
                    var proposal: keyof typeof colors.Proposal = item.Proposal == null ? "" : item.Proposal;                     
                    var stars: any = [];
                    [...Array(5)].forEach((_, i) => {
                        var blankStar = theme.darkMode ? WhiteStar : BlackStar
                        var test = i + 1 > item.Confidence ? blankStar : GoldStar;
                        stars.push(<img 
                            className={styles.star} 
                            key={i} 
                            src={test} 
                            onClick={() => updateTextValue("Confidence", item.Confidence == i + 1 ? (i).toString() : (i + 1).toString(), item.Id)}
                            onMouseOver={(e) => (e.currentTarget.src = GoldStar)} 
                            onMouseLeave={(e) => {
                                if (i + 1 > item.Confidence) {                                
                                    e.currentTarget.src = test
                                }
                            }}
                        />)
                    })                   
                    
                    return (
                        <>
                            <div 
                            className={cx(styles.rowContainer, movedElement == item.Id && styles.pulse,)}
                            style={{ display: "flex", flexDirection: 'row', height: collapsed ? 0 : 'auto' }} 
                            key={item.Id}
                            draggable={true}
                            onDrag={() => handleDrag(item.Id)}
                            onDragOver={(e) => handleDragOver(item.Id)}
                            onDragEnd={() => handleDragDrop()}
                            >
                                {
                                    dragged == item.Id ? (
                                        <div
                                            style={{
                                                height: "45px",
                                                width: "100%",
                                                backgroundColor: theme.darkMode
                                                ? "#191919d4"
                                                : "#f4f4f4",
                                            }}
                                            ></div>
                                    ) : (
                                        <>
                                            <div 
                                                key={item.Id} 
                                                className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} 
                                                style={{ position: "sticky", left: 0, zIndex: 100, minWidth: '425px', justifyContent: 'space-between' }}
                                                // onDrag={() => handleDrag(project.id)}
                                                // onDragOver={() => handleDragOver(project.id)}
                                                // onDragEnd={handleDragDrop}                                
                                            >
                                                <input type="checkbox" onClick={() => handleSelect(item.Id)} checked={selectedItems.includes(item.Id)}/>
                                                <input placeholder="Item / Client" onChange={(e) => updateTextValue("KeyClient", e.currentTarget.value, item.Id)} style={{ minWidth: '80%'}} className={cx(styles.valueInput, theme.darkMode && styles.darkMode )} value={item.KeyClient}  />
                                                <div className={cx(styles.commentIconDiv, theme.darkMode && styles.darkMode)} onClick={() => handleDrawer(item.Id)}>
                                                    <img src={theme.darkMode ? CommentWhite : CommentBlack} alt="comment"/>
                                                    {
                                                        commentList?.filter(comment => comment.leadId == item.Id).length > 0 && (
                                                            <div className={cx(styles.commentNotif, theme.darkMode && styles.darkMode)}>{commentList?.filter(comment => comment.leadId == item.Id).length}</div>
                                                        )
                                                    }
                                                </div>
                                                <Drawer
                                                    anchor="right"
                                                    open={drawerOpen == item.Id}
                                                    onClose={() => handleDrawer(item.Id)}
                                                    PaperProps={{
                                                    sx: {
                                                        width: "40vw",
                                                        backgroundColor: theme.darkMode ? "#323130" : "#FFFFFF",
                                                        color: theme.darkMode ? "#FFFFFF" : "#000000",
                                                        overflow: 'hidden'
                                                    },
                                                    }}
                                                >
                                                    <div style={{ height: "100%", width: "100%", position: "relative" }}>
                                                        <img
                                                            src={theme.darkMode ? xIconWhite : xIconBlack}
                                                            className={styles.xIcon}
                                                            alt="x"
                                                            onClick={() => handleDrawer(item.Id)}
                                                        />
                                                        <img className={styles.pfp} src={auth.user[6]} alt="pfp" />
                                                        <div className={styles.commentHeader}>
                                                            <p style={{ fontFamily: "PoppinsBold", fontSize: "1.5rem", margin: 0, width: '80%' }}>{item.KeyClient}</p>
                                                            <div className={styles.tabs}>
                                                            <p onClick={() => setInfoDisplay("comments")}>Comments</p>
                                                            <p onClick={() => setInfoDisplay("files")}>Files</p>
                                                            </div>
                                                        </div>
                                                        <div style={{ height: '80%', overflowY: 'scroll'}}>
                                                            {infoDisplay == "comments" && (
                                                                <div className={styles.commentSection}>
                                                                    <p>Add a comment or update</p>
                                                                    <input
                                                                        id="commentInput"
                                                                        style={{ color: "inherit" }}
                                                                        onKeyUp={(e) => addCommentEnterKey(e, item.Id)}
                                                                    />
                                                                    <div>
                                                                        {commentList &&
                                                                        commentList.length > 0 &&
                                                                        commentList
                                                                            .filter(comment => comment.leadId == item.Id)
                                                                            .sort(
                                                                            (a: any, b: any) =>
                                                                                new Date(b.date).getTime() - new Date(a.date).getTime(),
                                                                            )
                                                                            .map((comment: any) => {                                                            
                                                                            let currentDate = new Date();
                                                                            let commentDate = new Date(comment.date);
                
                                                                            var timeElapsed =
                                                                                currentDate.getTime() - commentDate.getTime();
                
                                                                            var timeMessage = "";
                                                                            if (Math.floor(timeElapsed / 3600000) < 24) {
                                                                                timeMessage = "Today";
                                                                            }
                                                                            else if (Math.floor(timeElapsed / 3600000) > 24 && Math.floor(timeElapsed / 3600000) < 48) {
                                                                                timeMessage = "Yesterday";
                                                                            }
                                                                            else {
                                                                                timeMessage = `${commentDate.toLocaleString("default", { month: "short" })} ${commentDate.getDay()}`;
                                                                            }
                                                                            
                                                                            return (
                                                                                <div className={styles.commentBox}>
                                                                                    <img src={comment.commenterPhoto} style={{}} />
                                                                                    <div>
                                                                                        <p style={{ fontSize: "0.85rem", }}>{comment.commenter}</p>
                                                                                        <p
                                                                                        style={{
                                                                                            color: theme.darkMode ? "#DBDBDB" : "#000000",
                                                                                            marginBottom: "5px",
                                                                                        }}
                                                                                        >
                                                                                        {comment.comment}
                                                                                        </p>
                                                                                        <p
                                                                                        style={{ fontSize: "0.70rem", color: "#A2A2A2" }}
                                                                                        >
                                                                                        {timeMessage}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                            })}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Drawer>
                                            </div>
                                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '200px', justifyContent: 'center' }} >
                                                <input placeholder="Company" onChange={(e) => updateTextValue("Company", e.currentTarget.value, item.Id)} className={cx(styles.valueInput, theme.darkMode && styles.darkMode )} value={item.Company} />
                                            </div>
                                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '200px' }}>
                                                <ReactCountryFlag countryCode={countryCode} svg style={{ height: '19px', width: '40px' }} />
                                                <select defaultValue={countryCode} className={cx(styles.countrySelect, theme.darkMode && styles.darkMode)} onChange={(e) => updateTextValue("Country", e.currentTarget.value, item.Id)}>
                                                    <option>{item.Country.split("-")[1]}</option>
                                                    {
                                                        allCountries.map((flag: any) => {                                                        
                                                            return (
                                                                <option value={flag}>{flag.split("-")[1]}</option>                                                
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div id={styles.servicesContainer} className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '220px', maxWidth: '220px' }} onClick={(e) => { handleServicesMenu(e, index)}}>
                                                <div className={styles.plus}><p>+</p></div>
                                                {
                                                    item.Services.length > 0 && item.Services.map((service: string) => {
                                                        return (
                                                            <div className={styles.services}>
                                                            <p>{service}</p>
                                                            <p className={styles.x}>X</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <Menu                                  
                                                open={servicesMenuOpen[index]}
                                                anchorEl={anchorElServices}
                                                onClose={(e) => handleServicesMenu(e, index)}
                                                sx={{
                                                    "& .MuiPaper-root": {                                                                          
                                                        padding: "15px 20px",
                                                        backgroundColor: theme.darkMode ? "#252423" : "#FFFFFF",
                                                        color: theme.darkMode ? "#FFFFFF" : "#000000",                               
                                                    },
                                                }}
                                            >
                                                <p style={{ margin: "0 0 5px 0" }}>Add new service</p>
                                                <input onKeyUp={(e) => {
                                                    if (e.key == "Enter") {
                                                        updateTextValue("Services", e.currentTarget.value, item.Id);
                                                        e.currentTarget.value = "";
                                                    } 
                                                }}
                                                className={cx(styles.servicesInput, theme.darkMode && styles.darkMode)}/>
                                                <p style={{ margin: "0 0 10px 0"}}>Current</p>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                {
                                                    item.Services.length > 0 && item.Services.map((service: any) => {
                                                        return (
                                                            <div className={styles.services} id={styles.editService} onClick={() => updateTextValue("Services", service, item.Id)}>
                                                                <p style={{ fontSize: '0.9rem'}}>{service}</p>
                                                                <p className={styles.x}>X</p>
                                                            </div>
                                                        )
                                                    })
                                                }       
                                                </div>                             
                                            </Menu>
                                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '140px', color: "#FFFFFF", backgroundColor: `${colors.Status[status]}` }} onClick={(e) => handleColorMenuStatus(e, index)}>
                                                <p>{item.Status}</p>
                                                <Menu
                                                open={statusMenuOpen[index]}
                                                anchorEl={anchorElStatus}
                                                onClose={(e) => handleColorMenuStatus(e, index)}
                                                sx={{
                                                    "& .MuiPaper-root": {
                                                    height: "275px",
                                                    width: "500px",                                      
                                                    backgroundColor: theme.darkMode ? "#252423" : "#FFFFFF",                                    
                                                    },
                                                }}
                                                >
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', padding: '10px' }}>
                                                        {
                                                            Object.entries(colors.Status).map(([key, value]) => {
                                                                return (
                                                                    <div className={styles.colorElement} style={{ backgroundColor: value }} onClick={() => updateTextValue("Status", key, item.Id)}>
                                                                        <p>{key}</p>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Menu>
                                            </div>
                                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '180px' }}>                              
                                            <DatePicker id={styles.datePicker} selected={item.LastContact} className="datePicker" onChange={(date) => updateTextValue("LastContact", date, item.Id)} />
                                            </div>
                                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '150px', color: "#FFFFFF", backgroundColor: `${colors.Location[contactLocation]}` }}  onClick={(e) => handleColorMenuLocation(e, index)}>
                                                <p>{contactLocation}</p>
                                                <Menu
                                                open={locationMenuOpen[index]}
                                                anchorEl={anchorElLocation}
                                                onClose={(e) => handleColorMenuLocation(e, index)}
                                                sx={{
                                                    "& .MuiPaper-root": {
                                                    height: "275px",
                                                    width: "500px",                                      
                                                    backgroundColor: theme.darkMode ? "#252423" : "#FFFFFF",                                    
                                                    },
                                                }}
                                                >
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', padding: '10px' }}>
                                                        {
                                                            Object.entries(colors.Location).map(([key, value]) => {
                                                                return (
                                                                    <div className={styles.colorElement} style={{ backgroundColor: value }} onClick={() => updateTextValue("ContactLocation", key, item.Id)}>
                                                                        <p>{key}</p>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Menu>
                                            </div>
                                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '200px', backgroundColor: `${colors.Proposal[proposal]}`, color: "#FFFFFF" }} onClick={(e) => handleColorMenuProposal(e, index)}>
                                                <p>{item.Proposal}</p>
                                                <Menu
                                                open={proposalMenuOpen[index]}
                                                anchorEl={anchorElProposal}
                                                onClose={(e) => handleColorMenuProposal(e, index)}
                                                sx={{
                                                    "& .MuiPaper-root": {
                                                    height: "275px",
                                                    width: "500px",                                      
                                                    backgroundColor: theme.darkMode ? "#252423" : "#FFFFFF",                                    
                                                    },
                                                }}
                                                >
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', padding: '10px' }}>
                                                        {
                                                            Object.entries(colors.Proposal).map(([key, value]) => {
                                                                return (
                                                                    <div className={styles.colorElement} style={{ backgroundColor: value }} onClick={() => updateTextValue("Proposal", key, item.Id)}>
                                                                        <p>{key}</p>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Menu>
                                            </div>
                                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '220px' }}>
                                                <input value={`€${item.DealSize.toLocaleString('en-US')}`} className={cx(styles.valueInput, theme.darkMode && styles.darkMode )} type="text" onChange={(e) => updateTextValue("DealSize", e.currentTarget.value, item.Id)}/>                              
                                            </div>
                                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '220px' }}>
                                                {
                                                    stars.map((star: any) => {
                                                        return (
                                                            star
                                                        )
                                                    })
                                                }
                                            </div>                            
                                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '220px' }}>
                                                <input value={`€${item.RevenueForecast.toLocaleString('en-US')}`} className={cx(styles.valueInput, theme.darkMode && styles.darkMode )} type="text" onChange={(e) => updateTextValue("RevenueForecast", e.currentTarget.value, item.Id)}/>                              
                                            </div>
                                            <div id={styles.description} onClick={(e) => handleDescriptionBox(e, index)} className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ justifyContent: 'flex-start', minWidth: '400px', maxWidth: '400px', height: 'auto', textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                <p>{item.Description}</p>
                                                <Menu
                                                open={descriptionOpen[index]}
                                                anchorEl={anchorElDescription}
                                                onClose={(e) => handleDescriptionBox(e, index)}
                                                sx={{
                                                    "& .MuiPaper-root": {
                                                    padding: '5px 10px',                                   
                                                    backgroundColor: theme.darkMode ? "#252423" : "#FFFFFF",                                    
                                                    },
                                                }}
                                                >
                                                    <textarea value={item.Description} className={cx(styles.textArea, theme.darkMode && styles.darkMode)} onChange={(e) => updateTextValue("Description", e.currentTarget.value, item.Id)} />
                                                </Menu>
                                            </div>
                                            <div className={cx(styles.cell, theme.darkMode && styles.darkMode)} style={{ minWidth: '50px' }}></div>
                                        </>
                                    )
                                }
                            </div>
                            <div
                                className={cx(
                                    styles.dropZone,
                                    draggedOver == item.Id &&
                                        dragged !== item.Id &&
                                        styles.active,
                                    )}
                                style={{
                                    backgroundColor: theme.darkMode ? "rgba(25, 25, 25, 0.83)" : "#f4f4f4",
                                }}
                            ></div>
                      </>
                    )
                })
            }      
            {
                !collapsed && (
                    <>
                        <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ borderLeft: 'none' }}></div>
                        <div style={{ display: 'flex' }}>
                            {
                                !collapsed && (
                                    <div 
                                    onClick={() => newItem()}
                                    id={styles.newItemRow} 
                                    className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} 
                                    style={{ borderRight: "none", position: "sticky", left: 0, zIndex: 0, minWidth: '425px', textAlign: 'center', justifyContent: 'flex-start' }}
                                    >
                                        <input type="checkbox" disabled style={{ marginRight: '20px' }}/>
                                        <p style={{ fontSize: '0.9em', color: theme.darkMode ? "#cacaca" : "#999999" }}>+ New Item</p>                   
                                    </div>
                                )
                            }
                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '200px' }}></div>
                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '200px' }}></div>
                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '220px' }}></div>
                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '140px' }}>
                                <div style={{ height: '75%', width: '95%', display: 'flex' }}>
                                    {
                                        unfilteredLeads.length > 0 && Object.entries(colors.Status).map(([key, value]) => {
                                            let condition = (x: string) => x == key;
                                            let total = unfilteredLeads.reduce((acc: any, curr: any) => {
                                                if (condition(curr.Status)) return acc + 1;
                                                else return acc;
                                            }, 0);

                                            let width = total / unfilteredLeads.length * 100;                                                                   

                                            return (
                                                <div style={{ height: "100%", width: `${width}%`, backgroundColor: `${value}`}}></div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '180px' }}></div>
                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '150px' }}>
                                <div style={{ height: '75%', width: '95%', display: 'flex' }}>
                                    {
                                        unfilteredLeads.length > 0 && Object.entries(colors.Location).map(([key, value]) => {
                                            let condition = (x: string) => x == key;
                                            let total = unfilteredLeads.reduce((acc: any, curr: any) => {
                                                if (condition(curr.ContactLocation)) return acc + 1;
                                                else return acc;
                                            }, 0);

                                            let width = total / unfilteredLeads.length * 100;                                                      

                                            return (
                                                <div style={{ height: "100%", width: `${width}%`, backgroundColor: `${value}`}}></div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '200px' }}>
                                <div style={{ height: '75%', width: '95%', display: 'flex' }}>
                                    {
                                        unfilteredLeads.length > 0 && Object.entries(colors.Proposal).map(([key, value]) => {
                                            let condition = (x: string) => x == key;
                                            let total = unfilteredLeads.reduce((acc: any, curr: any) => {
                                                if (condition(curr.Proposal)) return acc + 1;
                                                else return acc;
                                            }, 0);

                                            let width = total / unfilteredLeads.length * 100;                                                      

                                            return (
                                                <div style={{ height: "100%", width: `${width}%`, backgroundColor: `${value}`}}></div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '220px' }}>
                                {
                                    unfilteredLeads.length > 0 && (
                                        <p>€{unfilteredLeads.reduce((acc: any, curr: any) => acc + curr.DealSize, 0).toLocaleString()}</p>
                                    )
                                }
                            </div>
                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '220px', }}> 
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {
                                        unfilteredLeads.reduce((acc: any, curr: any) => acc + curr.Confidence, 0) == 0 ? (
                                            <p>0 / 5</p>
                                        ) : (
                                            <p>{(unfilteredLeads.reduce((acc: any, curr: any) => acc + curr.Confidence, 0) / unfilteredLeads.filter((item: any) => item.Confidence > 0).length).toFixed(1)} / 5</p>
                                        )
                                    }                           
                                </div>                       
                            </div>
                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '220px' }}>
                                {
                                    unfilteredLeads.length > 0 && (
                                        <p>€{unfilteredLeads.reduce((acc: any, curr: any) => acc + curr.RevenueForecast, 0).toLocaleString()}</p>
                                    )
                                }
                            </div>
                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '400px' }}></div>
                            <div className={cx(styles.itemCell, theme.darkMode && styles.darkMode)} style={{ minWidth: '50px' }}></div>
                        </div>
                    </>
                )
            }
            </div>            
        </div>
    </>
  )
}

export default Table
