export const calculateDuration = (date) => {
  let currentDate = new Date();
  let runDate = new Date(date);
  let milliseconds = Math.abs(currentDate - runDate);
  let minutes = Math.floor(milliseconds / 60000);

  let duration = date.split("T")[0];
  if (minutes < 60) {
    if (minutes < 1) {
      duration = `Just now`;
    } else {
      duration = `${minutes}m ago`;
    }
  } else if (minutes >= 60) {
    let test = Math.floor(minutes / 60);

    if (test <= 12) {
      duration = `${test} hr${test >= 2 ? "s" : ""} ago`;
    } else {
      let month = runDate.toLocaleString("default", { month: "short" });
      let day = runDate.getUTCDate();

      duration = `${month} ${day}`;
    }
  }

  return duration;
};
