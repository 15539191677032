import React from "react";
import Cat from "../../assets/media/hang-in-there.png";

const UnfinishedPage = () => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={Cat} style={{ height: "300px", width: "600px" }} />
      <p style={{ fontFamily: "PoppinsLight", fontSize: "1.4rem" }}>
        This page isn't ready yet
      </p>
    </div>
  );
};

export default UnfinishedPage;
