import React, { useState } from "react";
import AccessForm from "./AccessForm";
import styles from "./RequestAccess.module.scss";
import CircleLogo from "../../assets/media/circle-logo.png";
import { SendAccessRequest } from "../../services/authServices";
import cx from "classnames";

const RequestAccessView = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);
  const [isMessageVisible, setIsMessageVisible] = useState(false);

  interface info {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    company: string;
  }

  const SendRequest = (info: info) => {
    setIsSubmitting((prev) => !prev);
    setTimeout(() => {
      setIsRequestSubmitted((prev) => !prev);
      setIsSubmitting((prev) => !prev);
    }, 1000);

    setTimeout(() => {
      setIsMessageVisible((prev) => !prev);
    }, 1200);
    SendAccessRequest(
      info.email,
      info.firstName + " " + info.lastName,
      info.company,
      info.password,
    );
  };

  return (
    <div className={styles.root}>
      <div
        className={cx(
          styles.container,
          isRequestSubmitted && styles.afterRequest,
        )}
      >
        <div
          className={cx(
            styles.formCard,
            isRequestSubmitted && styles.afterRequest,
          )}
        >
          <div
            style={{
              display: isRequestSubmitted ? "none" : "block",
              width: "100%",
            }}
          >
            <div className={styles.headerInfo}>
              <div>
                <h1 style={{ fontSize: '2rem'}}>Request Access</h1>
                <p>
                  Please fill out the form below, and our developers will
                  <br />
                  review your request.
                </p>
              </div>
              <img
                src={CircleLogo}
                className={cx(styles.circleLogo, isSubmitting && styles.rotate)}
                alt="logo"
              />
            </div>
            <svg width="100%" height="5px" id="divider">
              <line
                x1="0"
                y1="0"
                x2="100%"
                stroke="#000000"
                strokeWidth="3.5px"
              />
            </svg>
            <AccessForm sendRequest={SendRequest} />
          </div>
          <div
            className={cx(styles.message, isMessageVisible && styles.visible)}
          >
            <h1>Thank you for your request</h1>
            <p>We will review your request and get back to you shortly.</p>
            <a href="/" style={{ textDecoration: "none" }}>
              <div className={styles.back}>Back to Home</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestAccessView;
