import React, { useContext, useEffect, useState, useRef } from "react";
import { ThemeContext } from "../../../contexts/ThemeContext";
import styles from "./TaskTable.module.scss";
import cx from "classnames";
import { Plus, Trash, Copy, ChevronDown } from "react-feather";
import PhotoCell from "../PhotoCell/PhotoCell";
import StatusCell from "../StatusCell/StatusCell";
import TextCell from "../TextCell/TextCell";
import NewSubitemCell from "../TextCell/NewSubitemCell";
import { GetStatuses, AddNewStatus } from "../../../services/authServices";
import ChevronWhite from "../../../assets/media/chevron-white.png";
import ChevronBlack from "../../../assets/media/chevron-black.png";

interface Props {
  projects: any;
  updateSubitem: any;
  updateUsers: any;
  updateStatus: any;
  updateTextValue: any;
  params: any;
  users: any;
  newSubitem: any;
  newProject: any;
  selectedProjects: any;
  selectedSubitems: any;
  selectProject: any;
  selectSubitem: any,
  deselectSubitems: any,
  status: string;
  subitemDrag: boolean;
  drag: any;
  dragOver: any;
  handleDrag: any;
  handleDragOver: any;  
  handleDragDrop: any;
  movedElement: any;
  getChart: any;
}

const TaskTable: React.FC<Props> = ({
  projects,
  updateSubitem,
  updateUsers,
  updateStatus,
  updateTextValue,
  params,
  users,
  newSubitem,
  newProject,
  selectedProjects,
  selectedSubitems,
  selectProject,
  selectSubitem,
  deselectSubitems,
  status,
  handleDrag,
  handleDragOver,
  handleDragDrop,
  subitemDrag,
  drag,
  dragOver,
  movedElement,
  getChart,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [expandedProjects, setExpandedProjects] = useState<number[]>([]);  
  const [openSelectMenu, setOpenSelectMenu] = useState(false);
  const [newSubitemLoading, setNewSubItemLoading] = useState<boolean>(false);
  const [statusColor, setStatusColor] = useState("");
  const [statuses, setStatuses] = useState<any>([]);  
  const theme = useContext(ThemeContext);

  useEffect(() => {
    switch (status) {
      case "Current":
        setStatusColor("rgb(48, 99, 240)");
        break;
      case "Upcoming":
        setStatusColor("#B80AF5");
        break;
      case "Completed":
        setStatusColor("#27B02F");
        break;
    }
  });  

  const expandProject = (id: number) => {    
    if (!expandedProjects.includes(id)) {
      setExpandedProjects([...expandedProjects, id]);
    }
    else {
      let newArr = expandedProjects.filter(x => x !== id);
      setExpandedProjects(newArr)
    }
    if (!expandedProjects.includes(id)) deselectSubitems();
  };

  const getStatuses = async () => {
    const res: any = await GetStatuses("dbo.Statuses");        

    setStatuses(res);
  }

  useEffect(() => {
    getStatuses()    
  }, [])

  const newStatus = async (value: string, column: string, color: string) => {
    if (statuses) {
      var newStatus = [...statuses, { Id: null, Status: value, Category: column, Color: color}];
      setStatuses(newStatus)
    }

    await AddNewStatus(value, column, color);
    getStatuses();    
  }

  const handleExpand = () => {
    setCollapsed(prev => !prev)
  }

  const updateChart = () => {    
    getChart();
  }

  return (
    <>
      <div
        className={cx(styles.tableTitle, collapsed && styles.collapsed)}
        onClick={() => setCollapsed((prev) => !prev)}
      >
        <h1 style={{ color: statusColor }}>{status}</h1>
        <ChevronDown
          style={{ color: statusColor }}
          className={cx(styles.collapseChevron, collapsed && styles.collapsed)}
        />
      </div>
      <div
        style={{ borderLeft: `solid 2px ${statusColor}` }}
        className={cx(styles.chart, theme.darkMode && styles.darkMode)}
      >
        <div className={styles.chartTopBar}>
          <div
            className={cx(styles.cell, theme.darkMode && styles.darkMode)}
            style={{
              minWidth: "500px",
              position: "sticky",
              left: 0,
              zIndex: 999,
            }}
          >
            <p style={{ paddingLeft: "25px" }}>Project</p>
          </div>
          <div
            className={cx(styles.cell, theme.darkMode && styles.darkMode)}
            style={{ minWidth: "145px" }}
          >
            <p>Sponsor</p>
          </div>
          <div
            className={cx(styles.cell, theme.darkMode && styles.darkMode)}
            style={{ minWidth: "145px" }}
          >
            <p>Manager</p>
          </div>
          <div
            className={cx(styles.cell, theme.darkMode && styles.darkMode)}
            style={{ minWidth: "200px" }}
          >
            <p>Priority</p>
          </div>
          <div
            className={cx(styles.cell, theme.darkMode && styles.darkMode)}
            style={{ minWidth: "200px" }}
          >
            <p>Status</p>
          </div>
          <div
            className={cx(styles.cell, theme.darkMode && styles.darkMode)}
            style={{ minWidth: "215px" }}
          >
            <p>Key Client</p>
          </div>
          <div
            className={cx(styles.cell, theme.darkMode && styles.darkMode)}
            style={{ minWidth: "180px" }}
          >
            <p>Start Date</p>
          </div>
          <div
            className={cx(styles.cell, theme.darkMode && styles.darkMode)}
            style={{ minWidth: "50px" }}
          ></div>
        </div>
        <div style={{ height: collapsed ? 0 : "auto" }}>
          {projects.map((project: any, index: any) => {
            // var dateReformatted = `${project.startDate.split("-")[2]}-${project.startDate.split("-")[1]}-${project.startDate.split("-")[0]}`;
            // console.log(project)
            return (
              <>
                <div
                  key={project.id}
                  className={cx(
                    styles.projectContainer,
                    drag == project.id && styles.dragged,
                    movedElement?.id == project.id && styles.pulse,
                  )}
                  onDrag={() => {
                    if (!expandedProjects.includes(project.id) && !subitemDrag) handleDrag(project.id) 
                  }}
                  onDragOver={() => {
                    if (!expandedProjects.includes(project.id) && !subitemDrag) handleDragOver(project.id)
                  }}
                  onDragEnd={() => {
                    if (!expandedProjects.includes(project.id) && !subitemDrag) handleDragDrop(index)
                  }}
                  draggable={!expandedProjects.includes(project.id)}
                >
                  {drag == project.id ? (
                    <div
                      style={{
                        height: "45px",
                        width: "100%",
                        backgroundColor: theme.darkMode
                          ? "#191919d4"
                          : "#f4f4f4",
                      }}
                    ></div>
                  ) : (
                    <>
                      <div style={{ display: "flex" }}>
                        <div
                          className={cx(
                            styles.projectCell,
                            theme.darkMode && styles.darkMode,
                          )}
                          style={{
                            minWidth: "500px",
                            maxWidth: "500px",
                            position: "sticky",
                            left: 0,
                            zIndex: 100,
                          }}
                        >
                          <input
                            checked={selectedProjects.includes(project.id)}
                            type="checkbox"
                            style={{ height: "20px", width: "20px" }}
                            onChange={(e) => selectProject(e, project.id)}
                          />
                          <img
                            src={theme.darkMode ? ChevronWhite : ChevronBlack}
                            onClick={() => expandProject(project.id)}
                            className={cx(
                              styles.projectExpandChevron,
                              expandedProjects.includes(project.id) && styles.active,
                            )}
                          />
                          <TextCell
                            isTitle={true}
                            comments={project.comments}
                            subitemCount={project.subItems.length}                            
                            value={project.title}
                            subitemId={null}
                            chartId={params.chartId}
                            projectId={project.id}
                            column="Title"     
                            updateTextValue={updateTextValue}                       
                          />
                        </div>
                        <PhotoCell
                          subitemId={null}
                          updateUsers={updateUsers}
                          person={project.sponsor}
                          photo={project.sponsorPhoto}
                          people={users}
                          value={"Sponsor"}
                          chartId={params.chartId}
                          projectId={project.id}
                        />
                        <PhotoCell
                          subitemId={null}
                          updateUsers={updateUsers}
                          person={project.manager}
                          photo={project.managerPhoto}
                          people={users}
                          value={"Manager"}
                          chartId={params.chartId}
                          projectId={project.id}
                        />
                        <StatusCell
                          subitemId={null}
                          width={"200px"}
                          column={"Priority"}
                          updateStatus={updateStatus}
                          value={project.priority}
                          chartId={params.chartId}
                          projectId={project.id}
                          statuses={statuses}
                          newStatus={newStatus}
                          getChart={updateChart}
                        />
                        <StatusCell
                          subitemId={null}
                          width={"200px"}
                          column={"Status"}
                          updateStatus={updateStatus}
                          value={project.status}
                          chartId={params.chartId}
                          projectId={project.id}
                          statuses={statuses}
                          newStatus={newStatus}
                          getChart={updateChart}
                        />
                        <div
                          className={cx(
                            styles.projectCell,
                            theme.darkMode && styles.darkMode,
                          )}
                          style={{ minWidth: "215px", maxWidth: "215px" }}
                        >
                          <TextCell
                            comments={null}
                            isTitle={false}
                            subitemCount={null}
                            value={project.keyClient}
                            chartId={params.chartId}
                            projectId={project.id}
                            subitemId={null}
                            column="KeyClient"   
                            updateTextValue={updateTextValue}                         
                          />
                        </div>
                        <div
                          className={cx(
                            styles.projectCell,
                            theme.darkMode && styles.darkMode,
                          )}
                          style={{ minWidth: "180px" }}
                        >
                          <TextCell
                            comments={null}
                            isTitle={false}
                            subitemCount={null}
                            value={project.startDate}
                            chartId={params.chartId}
                            projectId={project.id}
                            subitemId={null}
                            column="StartDate"
                            updateTextValue={updateTextValue}
                          />
                          {/* <input 
                            lang="en-GB" 
                            type="date" 
                            value={dateReformatted} 
                            className={cx(styles.dateInput, theme.darkMode && styles.darkMode)}
                          /> */}
                        </div>
                        <div
                          className={cx(
                            styles.projectCell,
                            theme.darkMode && styles.darkMode,
                          )}
                          style={{ minWidth: "50px" }}
                        ></div>
                      </div>
                      <div
                        className={cx(
                          styles.subItemDisplay,
                          expandedProjects.includes(project.id) && styles.expanded,
                          theme.darkMode && styles.darkMode,
                        )}
                      >
                        <div>
                          <div
                            className={cx(
                              styles.subItemRow,
                              expandedProjects.includes(project.id) && styles.expanded,
                            )}
                            onDragOver={() => handleDragOver(-1)}
                          >
                            <div
                              className={styles.bufferDiv}
                              style={{
                                backgroundColor: theme.darkMode
                                  ? "#252423"
                                  : "#F4F4F4",
                              }}
                            ></div>                            
                            <div
                              className={cx(
                                styles.cell,
                                theme.darkMode && styles.darkMode,
                              )}
                              style={{
                                borderRadius: "8px 0 0 0",
                                minWidth: "450px",
                                maxWidth: "450px",
                                position: "sticky",
                                left: "50px",
                                zIndex: 999,
                              }}
                            >
                              <p style={{ paddingLeft: "25px" }}>Subitem</p>
                            </div>
                            <div
                              className={cx(
                                styles.cell,
                                theme.darkMode && styles.darkMode,
                              )}
                              style={{ minWidth: "145px" }}
                            >
                              <p>Owner</p>
                            </div>
                            <div
                              className={cx(
                                styles.cell,
                                theme.darkMode && styles.darkMode,
                              )}
                              style={{ minWidth: "185px" }}
                            >
                              <p>Monday</p>
                            </div>
                            <div
                              className={cx(
                                styles.cell,
                                theme.darkMode && styles.darkMode,
                              )}
                              style={{ minWidth: "185px" }}
                            >
                              <p>Tuesday</p>
                            </div>
                            <div
                              className={cx(
                                styles.cell,
                                theme.darkMode && styles.darkMode,
                              )}
                              style={{ minWidth: "185px" }}
                            >
                              <p>Wednesday</p>
                            </div>
                            <div
                              className={cx(
                                styles.cell,
                                theme.darkMode && styles.darkMode,
                              )}
                              style={{ minWidth: "185px" }}
                            >
                              <p>Thursday</p>
                            </div>
                            <div
                              className={cx(
                                styles.cell,
                                theme.darkMode && styles.darkMode,
                              )}
                              style={{ minWidth: "185px" }}
                            >
                              <p>Friday</p>
                            </div>
                            <div
                              className={cx(
                                styles.cell,
                                theme.darkMode && styles.darkMode,
                              )}
                              style={{ minWidth: "70px" }}
                            ></div>
                          </div>
                          <div
                            className={cx(
                              styles.dropZoneSubitem,
                              dragOver == -1 &&
                              drag !== -1 &&
                              styles.active,
                              )}
                              style={{
                                backgroundColor: theme.darkMode ? "rgba(25, 25, 25, 0.83)" : "#f4f4f4",
                                display: 'flex'
                              }}
                            >                                        
                              <div style={{ minWidth: '50px', height: '100%'}}></div>
                              <div
                                className={cx(
                                  styles.dropZoneTest,
                                  dragOver == -1 &&
                                  drag !== -1 &&
                                  styles.active,
                                )}
                              ></div>
                            </div> 
                          {project.subItems.sort((a: any, b: any) => a.orderId - b.orderId).map((item: any, i: any) => {                            
                            return (
                              <>
                              <div
                                className={
                                  cx(
                                    styles.subItemRow,
                                    movedElement?.id == item.id && styles.pulse,  
                                  )}
                                style={{
                                  display: expandedProjects.includes(project.id)
                                    ? "flex"
                                    : "none",
                                }}
                                key={item.id}
                                draggable
                                onDrag={() => handleDrag(item.id, true)}
                                onDragOver={() => handleDragOver(item.id)}
                                onDragEnd={() => handleDragDrop(item.id)}                               
                              >
                                <div
                                  className={styles.bufferDiv}
                                  style={{
                                    backgroundColor: theme.darkMode
                                      ? "#252423"
                                      : "#f4f4f4",
                                  }}
                                ></div>
                                {
                                  drag == item.id ? (
                                    <div
                                      style={{
                                        height: "45px",
                                        width: "100%",
                                        backgroundColor: theme.darkMode
                                          ? "#191919d4"
                                          : "#f4f4f4",
                                      }}
                                    ></div>
                                  ) : (   
                                    <>
                                      <div
                                        className={cx(
                                          styles.projectCell,
                                          theme.darkMode && styles.darkMode,
                                        )}
                                        style={{
                                          minWidth: "450px",
                                          maxWidth: "450px",
                                          position: "sticky",
                                          left: "50px",
                                          zIndex: 999,
                                        }}
                                      >
                                        <input
                                          checked={selectedSubitems.includes(item.id)}
                                          type="checkbox"
                                          style={{ height: "20px", width: "20px" }}
                                          onChange={() => selectSubitem(item.id)}
                                        />
                                        <TextCell                                    
                                          updateTextValue={updateTextValue}
                                          comments={null}
                                          isTitle={false}
                                          subitemCount={null}                                    
                                          value={item.title}
                                          subitemId={item.id}
                                          chartId={params.chartId}
                                          projectId={project.id}
                                          column="Title"                                    
                                        />
                                      </div>
                                      <PhotoCell
                                        updateUsers={updateUsers}
                                        subitemId={item.id}
                                        person={item.owner}
                                        photo={item.ownerPhoto}
                                        people={users}
                                        value={"Owner"}
                                        chartId={params.chartId}
                                        projectId={project.id}
                                      />
                                      <StatusCell
                                        subitemId={item.id}
                                        updateStatus={updateStatus}
                                        width={"185px"}
                                        column={"Day1"}
                                        value={item.day1}
                                        chartId={params.chartId}
                                        projectId={project.id}
                                        statuses={statuses}
                                        newStatus={newStatus}
                                        getChart={updateChart}
                                      />
                                      <StatusCell
                                        subitemId={item.id}
                                        updateStatus={updateStatus}
                                        width={"185px"}
                                        column={"Day2"}
                                        value={item.day2}
                                        chartId={params.chartId}
                                        projectId={project.id}
                                        statuses={statuses}
                                        newStatus={newStatus}
                                        getChart={updateChart}
                                      />
                                      <StatusCell
                                        subitemId={item.id}
                                        updateStatus={updateStatus}
                                        width={"185px"}
                                        column={"Day3"}
                                        value={item.day3}
                                        chartId={params.chartId}
                                        projectId={project.id}
                                        statuses={statuses}
                                        newStatus={newStatus}
                                        getChart={updateChart}
                                      />
                                      <StatusCell
                                        subitemId={item.id}
                                        updateStatus={updateStatus}
                                        width={"185px"}
                                        column={"Day4"}
                                        value={item.day4}
                                        chartId={params.chartId}
                                        projectId={project.id}
                                        statuses={statuses}
                                        newStatus={newStatus}
                                        getChart={updateChart}
                                      />
                                      <StatusCell
                                        subitemId={item.id}
                                        updateStatus={updateStatus}
                                        width={"185px"}
                                        column={"Day5"}
                                        value={item.day5}
                                        chartId={params.chartId}
                                        projectId={project.id}
                                        statuses={statuses}
                                        newStatus={newStatus}
                                        getChart={updateChart}
                                      />
                                      <div
                                        className={cx(
                                          styles.cell,
                                          theme.darkMode && styles.darkMode,
                                        )}
                                        style={{ minWidth: "70px" }}
                                      ></div>                                      
                                    </>
                                  )
                                }                                
                              </div>
                                {/* <div style={{ display: 'flex', width: '100%' }}> */}                                
                                  <div
                                    className={cx(
                                      styles.dropZoneSubitem,
                                      dragOver == item.id &&
                                      drag !== item.id &&
                                      styles.active,
                                      )}
                                      style={{
                                        backgroundColor: theme.darkMode ? "rgba(25, 25, 25, 0.83)" : "#f4f4f4",
                                        display: 'flex'
                                      }}
                                    >                                        
                                      <div style={{ minWidth: '50px', height: '100%'}}></div>
                                      <div
                                        className={cx(
                                          styles.dropZoneTest,
                                          dragOver == item.id &&
                                          drag !== item.id &&
                                          styles.active,
                                        )}
                                      ></div>
                                    </div>                              
                                  {/* </div> */}
                              </>
                            );
                          })}
                          {newSubitemLoading && (
                            <div style={{ display: "flex" }}>
                              <div
                                className={styles.loadingCell}
                                style={{ width: "375px", height: "50px" }}
                              ></div>
                              <div
                                className={styles.loadingCell}
                                style={{ width: "150px" }}
                              ></div>
                              <div
                                className={styles.loadingCell}
                                style={{ width: "200px" }}
                              ></div>
                              <div
                                className={styles.loadingCell}
                                style={{ width: "200px" }}
                              ></div>
                              <div
                                className={styles.loadingCell}
                                style={{ width: "200px" }}
                              ></div>
                              <div
                                className={styles.loadingCell}
                                style={{ width: "200px" }}
                              ></div>
                              <div
                                className={styles.loadingCell}
                                style={{ width: "200px" }}
                              ></div>
                            </div>
                          )}
                          <div
                            className={styles.subItemRow}
                            style={{
                              display: expandedProjects.includes(project.id)
                                ? "flex"
                                : "none",
                            }}
                          >
                            <div
                              className={styles.bufferDiv}
                              style={{
                                backgroundColor: theme.darkMode
                                  ? "#252423"
                                  : "#F4F4F4",
                              }}
                            ></div>
                            <div
                              className={cx(
                                styles.newSubItemRow,
                                theme.darkMode && styles.darkMode,
                              )}
                              style={{ borderRadius: "0 0 0 8px" }}
                            >
                              <div
                                className={cx(
                                  styles.projectCell,
                                  theme.darkMode && styles.darkMode,
                                )}
                                style={{
                                  borderRadius: "0 0 0 8px",
                                  minWidth: "450px",
                                  maxWidth: "450px",
                                  position: "sticky",
                                  left: "50px",
                                  zIndex: 999,
                                }}
                              >
                                <input
                                  type="checkbox"
                                  disabled
                                  style={{ height: "20px", width: "20px" }}
                                />
                                <div
                                  style={{ width: "100%", padding: "4px 5px" }}
                                >
                                  <NewSubitemCell
                                    newSubitem={newSubitem}
                                    projectId={project.id}
                                  />
                                </div>
                              </div>
                              <div
                                className={cx(
                                  styles.blankDiv,
                                  theme.darkMode && styles.darkMode,
                                )}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div
                  className={cx(
                    styles.dropZone,
                    dragOver == project.id &&
                      drag !== project.id &&
                      styles.active,
                  )}
                  style={{
                    backgroundColor: theme.darkMode ? "rgba(25, 25, 25, 0.83)" : "#f4f4f4",
                  }}
                ></div>
              </>
            );
          })}
          <div className={cx(styles.newItemRow, theme.darkMode && styles.darkMode)}>
            <div style={{ display: "flex", alignItems: 'center'}} onClick={() => newProject(status)}>
              <input type="checkbox" style={{ height: "18px", width: "18px", marginRight: "20px" }} disabled/>
              <p>+ New Item</p>                        
            </div>          
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskTable;
