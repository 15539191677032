import React from 'react'
import LeadManagementView from '../features/leadManagement'

const LeadManagement = () => {
  return (
    <>
      <LeadManagementView />
    </>
  )
}

export default LeadManagement
