import React from "react";
import RequestAccessView from "../features/requestAccess";

const RequestAccessPage = () => {
  return (
    <>
      <RequestAccessView />
    </>
  );
};
export default RequestAccessPage;
