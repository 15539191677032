export const searchData = [
  {
    title: "AVB Automation",
    href: "/dashboard/automations/?automation=avb-automation",
  },
  {
    title: "MPQ Automation",
    href: "/dashboard/automations/?automation=mpq-automation",
  },
  {
    title: "Pitch Automation",
    href: "/dashboard/automations/?automation=pitch-automation",
  },
  {
    title: "Contract Database",
    href: "/dashboard/contracts/contract-database",
  },
  {
    title: "Contract Dashboard",
    href: "/dashboard/contracts/contract-dashboard",
  },
  {
    title: "Add/Edit Contract",
    href: "/dashboard/contracts/add-edit-contract",
  },
  {
    title: "AVB Benchmarks",
    href: "/dashboard/benchmarks/avbs",
  },
  {
    title: "MPQ Benchmarks",
    href: "/dashboard/benchmarks/price-and-quality",
  },
  {
    title: "Account Settings",
    href: "/dashboard/account-settings",
  },
  {
    title: "Change Password",
    href: "/dashboard/account-settings#security-info",
  },
];
