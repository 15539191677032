import React from "react";
import AVBView from "../features/AVB";
import UnfinishedPage from "../common/components/UnfinishedPage";

const AVB = () => {
  return (
    // <UnfinishedPage />
    <AVBView />
  );
};

export default AVB;
